import { ethers } from 'ethers';
import { getProviderByChainId } from './providers';
import { getAllocationModelId } from '../models/dealAllocationModel';
import { getDealStatusId } from '../models/dealStatusModel';
import DealFactoryArtifact from './abis/DealFactory.json';
import TransferProxyArtifact from './abis/ERC677TransferProxy.json';
import DealArtifactV2 from './abis/DealV2.json';

import { DEAL_FACTORY_ADDRESS, USDT_ADDRESS, TRANSFER_PROXY_ADDRESS } from '../constants/config';

const ZERO_BYTES32 = ethers.constants.HashZero;

const getCurrentPhase = async (provider, dealAddress) => {
  const dealV2Contract = new ethers.Contract(dealAddress, DealArtifactV2.abi, provider);
  return dealV2Contract.getCurrentPhase();
};

export const getMyContributionAmountLeftV2 = async (
  provider,
  dealAddress,
  walletAddress,
  { amountOnWhitelist, amountOnLock, whitelistProof, lockProof }
) => {
  const dealV2Contract = new ethers.Contract(dealAddress, DealArtifactV2.abi, provider);
  try {
    const contributionAmountBN = await dealV2Contract.getMaxContributionAmount(walletAddress, {
      amountOnWhitelist,
      amountOnLock,
      whitelistProof,
      lockProof,
    });

    return ethers.utils.formatUnits(contributionAmountBN, 'mwei');
  } catch (error) {
    return '0';
  }
};

export const createUserParams = (deal, phaseIndex) => {
  const dbCurrentPhase = deal.phases?.sort((a, b) => a.index - b.index)[+phaseIndex.toString()];
  return {
    amountOnWhitelist: dbCurrentPhase?.amount || 0,
    amountOnLock: deal.lockAmount || 0,
    whitelistProof: dbCurrentPhase?.proof || [ZERO_BYTES32],
    lockProof: deal.lockProof || [ZERO_BYTES32],
  };
};

export const getCurrentPhaseInfo = async (provider, deal, walletAddress) => {
  if (!deal.isV2) throw new Error('Deal is not V2');
  const phaseInfo = await getCurrentPhase(provider, deal.address);

  const { minAccessLevel, minViewLevel, endTimestamp } = phaseInfo.phase;
  const personalCap = walletAddress
    ? await getMyContributionAmountLeftV2(
        provider,
        deal.address,
        walletAddress,
        await createUserParams(deal, phaseInfo.index)
      )
    : 0;

  return {
    minAccessLevel,
    minViewLevel,
    personalCap,
    phaseEndTimestamp: +endTimestamp < 10 ** 20 ? endTimestamp : null,
    currentPhaseIndex: phaseInfo.index.toString(),
  };
};

export async function contributeDeal(deal, amount) {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const transferProxyContract = new ethers.Contract(
      TRANSFER_PROXY_ADDRESS,
      TransferProxyArtifact.abi,
      injectedProvider.getSigner()
    );

    const provider = getProviderByChainId(deal.chainId);
    const currentPhase = await getCurrentPhase(provider, deal.address);
    const userParams = await createUserParams(deal, currentPhase.index);

    const data = ethers.utils.defaultAbiCoder.encode(
      ['tuple(uint256, uint256, bytes32[], bytes32[])'],
      [
        [
          userParams.amountOnWhitelist,
          userParams.amountOnLock,
          userParams.whitelistProof,
          userParams.lockProof,
        ],
      ]
    );

    const tx = await transferProxyContract.transferAndCall(
      deal.address,
      ethers.utils.parseUnits(amount, 6),
      data
    );

    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const claimFromDeal = async (deal, tokenAddresses) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const dealContract = new ethers.Contract(
      deal.address,
      DealArtifactV2.abi,
      injectedProvider.getSigner()
    );
    const tx = await dealContract.claim(tokenAddresses);
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deployDeal = async (deal, phases) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  const factoryContract = new ethers.Contract(
    DEAL_FACTORY_ADDRESS,
    DealFactoryArtifact.abi,
    injectedProvider.getSigner()
  );

  const dealParams = {
    stablecoin: USDT_ADDRESS,
    transferProxy: TRANSFER_PROXY_ADDRESS,
    maxAllocation: ethers.utils.parseUnits(deal.dealSize, 'mwei'),
    minContribution: ethers.utils.parseUnits(deal.minContribution, 'mwei'),
    id: deal.id,
    lockSnapshotRoot: deal.lockSnapshotRoot,
  };

  const phaseParams = phases.map((phase) => ({
    allocationModel: getAllocationModelId(phase.model),
    minAccessLevel: phase.minAccessLevel,
    minViewLevel: phase.minViewLevel,
    contributionCap: ethers.utils.parseUnits(phase.cap.toString(), 6),
    contributionCapPerLevel: phase.levels.map((level) =>
      ethers.utils.parseUnits(level.toString(), 6)
    ),
    phaseCap: ethers.utils.parseUnits(phase.cap.toString(), 6),
    totalContributions: ethers.BigNumber.from('0'),
    circulatingSupply: phase.proRataCirculatingSupply || ethers.BigNumber.from('0'),
    whitelistMerkleRoot: phase.root || ZERO_BYTES32,
    endTimestamp: ethers.BigNumber.from(Math.floor(Date.parse(phase.endTimestamp) / 1000)),
  }));

  const tx = await factoryContract.createDeal(dealParams, phaseParams);

  return tx;
};

export const updateDealParameters = async (minContribution, dealSize, contractAddress) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  const dealContract = new ethers.Contract(
    contractAddress,
    DealArtifactV2.abi,
    injectedProvider.getSigner()
  );

  await dealContract.updateParameters(
    ethers.utils.parseUnits(dealSize, 'mwei'),
    ethers.utils.parseUnits(minContribution, 'mwei')
  );
};

export async function cancelDeal(dealAddress) {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const dealContract = new ethers.Contract(
      dealAddress,
      DealArtifactV2.abi,
      injectedProvider.getSigner()
    );
    const tx = await dealContract.cancel();
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function closeDeal(dealAddress, amounts, wallets) {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);

  try {
    const amountsWei = amounts.map((amount) => ethers.utils.parseUnits(amount, 'mwei'));
    const dealContract = new ethers.Contract(
      dealAddress,
      DealArtifactV2.abi,
      injectedProvider.getSigner()
    );

    const tx = await dealContract.close(amountsWei, wallets);
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function pauseDeal(dealAddress) {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);

  try {
    const dealContract = new ethers.Contract(
      dealAddress,
      DealArtifactV2.abi,
      injectedProvider.getSigner()
    );
    const tx = await dealContract.pause();
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function unpauseDeal(dealAddress) {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);

  try {
    const dealContract = new ethers.Contract(
      dealAddress,
      DealArtifactV2.abi,
      injectedProvider.getSigner()
    );
    const tx = await dealContract.unpause();
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function setStatus(dealAddress, status, isV2) {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);

  try {
    let statusId = getDealStatusId(status);
    if (statusId === '5' && isV2) {
      statusId = '0';
    }
    const dealContract = new ethers.Contract(
      dealAddress,
      DealArtifactV2.abi,
      injectedProvider.getSigner()
    );
    const tx = await dealContract.setStatus(statusId);
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
}
