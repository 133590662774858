import { XDAI_CHAIN_ID, USDT_ADDRESS, XUSDT_ADDRESS } from 'constants/config';
import { getProviderByChainId } from '../../contracts/providers';
import { queryCalls } from 'contracts/multicallContract';
import { getDealStatusName } from 'models/dealStatusModel';
import {
  createUserParams,
  getMyContributionAmountLeftV2,
  getCurrentPhaseInfo as fetchCurrentPhaseInfo,
} from '../../contracts/dealV2Contract';
import { parseClaimerModels, isClaimable } from '../dealModel';
import buildCall from 'contracts/calls';
import { decodeTokenInfo } from 'models/common/decoders';
import { CALL_TYPE } from 'contracts/calls/constants';
import { formatClaimAmount } from 'models/claimerModel';
import { groupByChain, isFieldMulticallSupported } from 'models/common/helpers';
import { DealStatus } from 'models/constants';
import { mergeClaimers, prepareClaimers } from './claimers';
import { getDealInfo, getMyContributionAmount, isWalletWhitelisted } from 'contracts/dealContract';

export async function enrichWithMetadata(rawDeals, accountInfo) {
  return await Promise.all(
    rawDeals.map(async (rawDeal) => {
      const { deal, ...preFetchedData } = rawDeal;

      return await getDealMetadata({
        dealApi: deal,
        accountInfo,
        preFetchedData,
      });
    })
  );
}

export const getDealMetadata = async ({ dealApi, accountInfo, preFetchedData = {} }) => {
  const { address: walletAddress } = accountInfo;

  if (!dealApi.address) {
    return dealApi;
  }

  const dealAddress = dealApi.address;
  const provider = getProviderByChainId(dealApi.chainId);

  const deal = {
    ...dealApi,
    ...parseClaimerModels(dealApi.claimers, dealAddress, walletAddress),
    status: getDealStatusName(dealApi.statusId),
    id: dealApi.id.toString(),
  };

  deal.contributedAmount = await getContributedAmount({
    dealAddress: deal.address,
    preFetchedData,
    walletAddress,
    provider,
  });

  if (isClaimable(deal)) {
    return await handleClaimableDeal({ deal, walletAddress, provider, preFetchedData });
  }

  const dealChainData = await getDealChainInfo({
    dealAddress: deal.address,
    preFetchedData,
    provider,
  });

  if (!deal.isV2) {
    const personalCap = await handleContributions(deal, accountInfo, preFetchedData.personalCap);

    const userWhitelisted = await getUserWhitelisted({
      preFetchedData,
      walletAddress,
      provider,
      deal,
    });

    return {
      ...deal,
      personalCap,
      userWhitelisted,
      ...dealChainData,
    };
  }

  const currentPhaseInfo = await getCurrentPhase({
    preFetchedData,
    walletAddress,
    provider,
    deal,
  });

  if (dealChainData?.minAccessLevel === 4 || dealChainData?.minVieLevel === 4) {
    deal.userWhitelisted = deal.userWhitelisted || !!+deal.personalCap;
  }

  return {
    ...deal,
    ...dealChainData,
    ...currentPhaseInfo,
  };
};

const handleClaimableDeal = async ({ deal, walletAddress }) => {
  const claimers = prepareClaimers(deal, walletAddress);

  let multicallCalls = [];
  let dealClaimInfo = null;

  const tokenAddress = +deal.chainId === XDAI_CHAIN_ID ? XUSDT_ADDRESS : USDT_ADDRESS;

  if (deal.statusId === DealStatus.Canceled) {
    multicallCalls = getDealClaimAmountCall(deal, walletAddress, multicallCalls);
  }

  const claimerAmountCalls = claimers.flatMap(({ claimableAmountCalls }) =>
    claimableAmountCalls.map((call, index) => ({
      call,
      index: multicallCalls.length + index,
      chainId: call.chainId,
    }))
  );

  multicallCalls.push(...claimerAmountCalls);

  const claimersByChain = groupByChain(multicallCalls);

  const multicallResults = await queryCallsByChain(claimersByChain, multicallCalls);

  let offset = 0;
  if (deal.statusId === DealStatus.Canceled) {
    dealClaimInfo = getDealClaimInfo(multicallResults, tokenAddress);
    offset = 5; // if dealClaimAmount is fetched it uses first 4 spaces in array
  }

  const mergedClaimers = await mergeClaimers({
    claimers,
    walletAddress,
    contributedAmount: deal.contributedAmount,
    multicallResults: multicallResults.slice(offset),
  });

  return {
    ...deal,
    dealClaimInfo,
    claimers: mergedClaimers,
  };
};

const getDealClaimAmountCalls = (deal, walletAddress) => {
  const refundTokenAddress = +deal.chainId === XDAI_CHAIN_ID ? XUSDT_ADDRESS : USDT_ADDRESS;

  const claimAmountCall = buildCall(CALL_TYPE.DEAL_CLAIM_AMOUNT)(deal.address, deal.chainId, [
    walletAddress,
    refundTokenAddress,
  ]);

  const tokenCalls = buildCall(CALL_TYPE.TOKEN_INFO)(refundTokenAddress, deal.chainId);

  return [claimAmountCall, ...tokenCalls];
};

function getDealClaimInfo(multicallResults, tokenAddress) {
  const dealClaimToken = decodeTokenInfo(multicallResults, 1);

  return {
    claimAmount: formatClaimAmount(multicallResults[0].returnData, dealClaimToken),
    token: {
      address: tokenAddress,
      ...dealClaimToken,
    },
  };
}

async function queryCallsByChain(claimersByChain, multicallCalls) {
  const resultsMap = new Map();

  for await (const [chainId, callsWithIndex] of Object.entries(claimersByChain)) {
    const chainResultMap = new Map();

    const provider = getProviderByChainId(chainId);

    const calls = callsWithIndex.map(({ call }) => call);

    const results = await queryCalls(provider, calls);

    // reorganize results back into their original index
    results.forEach((result, idx) => {
      const originalIndex = callsWithIndex[idx].index;

      chainResultMap.set(originalIndex, result);
    });

    resultsMap.set(+chainId, chainResultMap);
  }

  return multicallCalls.map((call, i) => {
    return resultsMap.get(call.chainId).get(i % 4);
  });
}

function getDealClaimAmountCall(deal, walletAddress, multicallCalls) {
  const dealClaimAmountCalls = getDealClaimAmountCalls(deal, walletAddress);

  multicallCalls = dealClaimAmountCalls.map((call, index) => ({
    call,
    index,
    chainId: deal.chainId,
  }));
  return multicallCalls;
}

export async function handleContributions(deal, accountInfo, prefetchedPersonalCap) {
  const { userAccessLevel, relockMessage } = accountInfo;

  if (
    (relockMessage && relockMessage.length && userAccessLevel === -1) ||
    deal.raisedAmount === deal.dealSize ||
    (deal.minAccessLevel === 4 && !deal.userWhitelisted) ||
    (deal.minAccessLevel !== 4 && deal.minAccessLevel > userAccessLevel)
  ) {
    return '0';
  }

  if (
    parseFloat(prefetchedPersonalCap) === 0 &&
    deal.allocationModel === 'Personal Cap' &&
    parseFloat(deal.userCap) <= parseFloat(deal.contributedAmount)
  ) {
    return '0';
  }

  return prefetchedPersonalCap;
}

export const getCurrentPhaseInfo = async ({ provider, deal, walletAddress, preFetchedData }) => {
  const {
    dealChainData: { minAccessLevel, minViewLevel },
    phaseInfo: { currentPhaseIndex, phaseEndTimestamp },
  } = preFetchedData;

  let personalCap = 0;
  if (phaseEndTimestamp !== undefined || currentPhaseIndex !== undefined) {
    const userParams = createUserParams(deal, currentPhaseIndex);

    personalCap = await getMyContributionAmountLeftV2(
      provider,
      deal.address,
      walletAddress,
      userParams
    );
  }

  return {
    minAccessLevel,
    minViewLevel,
    personalCap,
    currentPhaseIndex: currentPhaseIndex?.toString(),
    phaseEndTimestamp: +phaseEndTimestamp < 10 ** 20 ? phaseEndTimestamp : null,
  };
};

async function getCurrentPhase({ preFetchedData, provider, walletAddress, deal }) {
  if (isFieldMulticallSupported(preFetchedData.phaseInfo)) {
    return await getCurrentPhaseInfo({
      provider,
      deal,
      walletAddress,
      preFetchedData,
    });
  }

  return await fetchCurrentPhaseInfo(provider, deal, walletAddress);
}

async function getUserWhitelisted({ preFetchedData, provider, deal, walletAddress }) {
  if (isFieldMulticallSupported(preFetchedData.userWhitelisted)) {
    return preFetchedData.userWhitelisted || false;
  }

  return await isWalletWhitelisted(provider, deal.address, walletAddress);
}

async function getContributedAmount({ preFetchedData, provider, dealAddress, walletAddress }) {
  if (isFieldMulticallSupported(preFetchedData.contributedAmount)) {
    return preFetchedData.contributedAmount || 0;
  }

  return await getMyContributionAmount(provider, dealAddress, walletAddress);
}

async function getDealChainInfo({ preFetchedData, provider, dealAddress }) {
  if (isFieldMulticallSupported(preFetchedData.dealChainData)) {
    return preFetchedData.dealChainData;
  }

  return await getDealInfo(dealAddress, provider);
}
