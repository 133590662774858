import {
  ETHEREUM_CHAIN_ID,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  BASE_CHAIN_ID,
  MERLIN_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
} from 'constants/config';
import { OMNI_DEAL_ADDRESS, OMNI_FIRST_CLAIMERS, makeOmniClaimer } from './omniClaimerModel';
import { DealStatus } from './constants';

export const parseClaimerModels = (claimers, dealAddress, wallet) => {
  if (
    wallet &&
    dealAddress === OMNI_DEAL_ADDRESS &&
    OMNI_FIRST_CLAIMERS.some((x) => x.toLowerCase() === wallet.toLowerCase())
  ) {
    // HACK FOR OMNI
    claimers = [makeOmniClaimer()];
  }

  const result = {};

  const ethClaimer = claimers.find((c) => c.chainId === ETHEREUM_CHAIN_ID);
  if (ethClaimer) {
    result.ethClaimerAddress = ethClaimer.address;
    result.ethClaimerCreationBlock = ethClaimer.number;
    result.ethClaimerTokens = ethClaimer.tokens;
    result.ethClaimerId = ethClaimer.id;
    result.ethMerkleRoot = ethClaimer.root;
  }

  const bscClaimer = claimers.find((c) => c.chainId === BSC_CHAIN_ID);
  if (bscClaimer) {
    result.bscClaimerAddress = bscClaimer.address;
    result.bscClaimerCreationBlock = bscClaimer.number;
    result.bscClaimerTokens = bscClaimer.tokens;
    result.bscClaimerId = bscClaimer.id;
    result.bscMerkleRoot = bscClaimer.root;
  }

  const polygonClaimer = claimers.find((c) => c.chainId === POLYGON_CHAIN_ID);
  if (polygonClaimer) {
    result.polygonClaimerAddress = polygonClaimer.address;
    result.polygonClaimerCreationBlock = polygonClaimer.number;
    result.polygonClaimerTokens = polygonClaimer.tokens;
    result.polygonClaimerId = polygonClaimer.id;
    result.polygonMerkleRoot = polygonClaimer.root;
  }

  const avalancheClaimer = claimers.find((c) => c.chainId === AVALANCHE_CHAIN_ID);
  if (avalancheClaimer) {
    result.avalancheClaimerAddress = avalancheClaimer.address;
    result.avalancheClaimerCreationBlock = avalancheClaimer.number;
    result.avalancheClaimerTokens = avalancheClaimer.tokens;
    result.avalancheClaimerId = avalancheClaimer.id;
    result.avalancheMerkleRoot = avalancheClaimer.root;
  }

  const baseClaimer = claimers.find((c) => c.chainId === BASE_CHAIN_ID);
  if (baseClaimer) {
    result.baseClaimerAddress = baseClaimer.address;
    result.baseClaimerCreationBlock = baseClaimer.number;
    result.baseClaimerTokens = baseClaimer.tokens;
    result.baseClaimerId = baseClaimer.id;
    result.baseMerkleRoot = baseClaimer.root;
  }

  const merlinClaimer = claimers.find((c) => c.chainId === MERLIN_CHAIN_ID);
  if (merlinClaimer) {
    result.merlinClaimerAddress = merlinClaimer.address;
    result.merlinClaimerCreationBlock = merlinClaimer.number;
    result.merlinClaimerTokens = merlinClaimer.tokens;
    result.merlinClaimerId = merlinClaimer.id;
    result.merlinMerkleRoot = merlinClaimer.root;
  }

  const arbitrumClaimer = claimers.find((c) => c.chainId === ARBITRUM_CHAIN_ID);
  if (arbitrumClaimer) {
    result.arbitrumClaimerAddress = arbitrumClaimer.address;
    result.arbitrumClaimerCreationBlock = arbitrumClaimer.number;
    result.arbitrumClaimerTokens = arbitrumClaimer.tokens;
    result.arbitrumClaimerId = arbitrumClaimer.id;
    result.arbitrumMerkleRoot = arbitrumClaimer.root;
  }

  return result;
};

export const isClaimable = (deal) =>
  [DealStatus.Closed, DealStatus.Canceled, DealStatus.Distributed].includes(deal.statusId);
