import React from 'react';
import { getDealStatusClass } from 'utils';
import SvgIcon from 'components/svgIcon';
import './index.scss';

const DealStatus = ({ dealStatus }) => {
  if (!dealStatus) return null;
  return (
    <div className={`deal-status deal-status--${getDealStatusClass(dealStatus.toLowerCase())}`}>
      <span className="deal-status-icon">
        <SvgIcon name="dot" />
      </span>
      <span className="deal__field-status__name">{dealStatus}</span>
    </div>
  );
};

export default DealStatus;
