import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, Divider, FormControlLabel } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import './index.scss';

const BasicAnalyticsPickerModal = ({ open, onOk, onClose, options }) => {
  const [pickedOptions, setPickedOptions] = useState([]);

  useEffect(() => {
    if (!options) return;
    setPickedOptions(
      options.map((value) => ({
        value,
        label: value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' '),
        checked: false,
      }))
    );
  }, [options]);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="basic-analytics-picker-modal">
        <div className="basic-analytics-picker-modal__head">
          <span>Export CSV</span>
          <div>
            <RoundedButton type="transparent" onClick={onClose}>
              Cancel
            </RoundedButton>
            <RoundedButton
              type="primary"
              onClick={() => onOk(pickedOptions.filter(({ checked }) => checked))}
            >
              Export
            </RoundedButton>
          </div>
        </div>
        <Divider />
        <div className="basic-analytics-picker-modal__body">
          {pickedOptions.map((option, i) => (
            <div key={option.value}>
              <FormControlLabel
                label={option.label}
                control={
                  <Checkbox
                    checked={option.checked}
                    onChange={(e) => {
                      e.persist();
                      setPickedOptions((prev) => {
                        const optionsToSet = [...prev];
                        optionsToSet[i] = { ...optionsToSet[i], checked: e.target.checked };
                        return optionsToSet;
                      });
                    }}
                  />
                }
              />
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default BasicAnalyticsPickerModal;
