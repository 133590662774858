import { useQuery } from 'react-query';
import { apiGet, baseUrl } from 'services/apiService';

const getAnalyticOverview = async ({ queryKey }) => {
  const response = await apiGet(`${baseUrl}/api/dashboard/deals-analytics?year=${queryKey[1]}`);
  return response.data;
};

const useAnalyticOverview = (year, activeTab) => {
  return useQuery(['analyticOverview', year], getAnalyticOverview, {
    enabled: activeTab === 'overview',
  });
};

const getDashboardData = async () => {
  const response = await apiGet(`${baseUrl}/api/dashboard`);
  return response.data;
};

const useDashboardOverview = (activeTab) => {
  return useQuery(['dashboardOverview'], getDashboardData, {
    enabled: activeTab === 'dashboard',
  });
};

export default {
  useAnalyticOverview,
  useDashboardOverview,
};
