import React from 'react';
import RoundedButton from 'components/button/rounded-button';

const Tabs = ({ selectTab, selectedTab }) => {
  return (
    <div className="tabs">
      <RoundedButton
        className={`tab-btn ${selectedTab === 'history' ? 'selected' : ''}`}
        onClick={() => selectTab('history')}
      >
        <span>History</span>
      </RoundedButton>
      <RoundedButton
        className={`tab-btn ${selectedTab === 'batch-schedule' ? 'selected' : ''}`}
        onClick={() => selectTab('batch-schedule')}
      >
        <span>Batch Schedule</span>
      </RoundedButton>
      <RoundedButton
        className={`tab-btn ${selectedTab === 'compare' ? 'selected' : ''}`}
        onClick={() => selectTab('compare')}
      >
        <span>Compare</span>
      </RoundedButton>
    </div>
  );
};

export default Tabs;
