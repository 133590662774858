const invoicesTableHead = [
  {
    column: 'dealName',
    label: 'Deal Name',
    sort: (a, b) => (b.dealName.trim().toLowerCase() > a.dealName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'invoiceNumber',
    label: 'Invoice #',
    sort: (a, b) => {
      const yearA = +a.year;
      const numA = +a.number;
      const invoiceNumberA = yearA + numA;

      const yearB = +b.year;
      const numB = +b.number;
      const invoiceNumberB = yearB + numB;

      return invoiceNumberA - invoiceNumberB ? 1 : -1;
    },
  },
  {
    column: 'date',
    label: 'Invoice Date',
    sort: (a, b) => {
      if (!a.date) {
        return 1;
      }
      if (!b.date) {
        return -1;
      }
      return new Date(b.date) > new Date(a.date) ? 1 : -1;
    },
    defaultSortField: true,
  },
  {
    column: 'totalTransactionAmount',
    label: 'Investment amount',
    sort: (a, b) =>
      parseFloat(b.totalTransactionAmount) > parseFloat(a.totalTransactionAmount) ? 1 : -1,
  },
  {
    column: 'feeUsd',
    label: 'Fee',
    sort: (a, b) => (parseFloat(b.feeUsd) > parseFloat(a.feeUsd) ? 1 : -1),
  },
  { label: '' },
];

export default { invoicesTableHead };
