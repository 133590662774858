import { apiPut, baseUrl } from 'services/apiService';
import { useQueryClient, useMutation } from 'react-query';

const updateDealAthPrice = async (dealId, price) => {
  const response = await apiPut(`${baseUrl}/api/analytics/admin/deal/${dealId}/athPrice`, {
    manualAthPrice: +parseFloat(price),
  });
  return response.data;
};

const updateOldDealAthPrice = async (dealId, price) => {
  const response = await apiPut(`${baseUrl}/api/analytics/admin/olddeal/${dealId}/athPrice`, {
    manualAthPrice: +parseFloat(price),
  });
  return response.data;
};

const usePutAthPrice = (isOldDeal) => {
  const queryClient = useQueryClient();

  const updateAthPrice = isOldDeal ? updateOldDealAthPrice : updateDealAthPrice;

  return useMutation(({ dealId, price }) => updateAthPrice(dealId, price), {
    onSuccess: () => {
      queryClient.invalidateQueries(['analyticOverview']);
    },
  });
};

export default usePutAthPrice;
