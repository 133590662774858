import { ethers } from 'ethers';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import NumberInput from 'components/input/number-input';

import {
  ARBITRUM_CHAIN_ID,
  ARBITRUM_CHAIN_NAME,
  TRANSFER_PROXY_ADDRESS,
  USDT_ADDRESS,
} from 'constants/config';

import { switchCurrentNetwork } from 'contracts/browserWallet';

import { approveErc20, getAllowance } from 'contracts/erc20';
import { arbitrumProvider } from 'contracts/providers';

import { setActiveHashes, addNotification, updateAuth, setSharedNotification } from 'store/actions';

import notificationTypes from 'constants/notificationTypes';
import './index.scss';

const Allowance = () => {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);

  const { accountInfo } = authReducer;
  const { chainId, activeHashes } = globalReducer;

  const [errorMessage] = useState('');
  const [currentAllowance, setCurrentAllowance] = useState('0.0');
  const [selectedAmount, setSelectedAmount] = useState('0');

  const showErrorNotification = (message) => {
    dispatch(
      setSharedNotification({
        status: 'error',
        title: 'Error',
        description: message,
      })
    );
  };

  useEffect(() => {
    setCurrentAllowance(accountInfo.usdtAllowance);
  }, [accountInfo]);

  const handleApprove = async () => {
    const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);
    if (networkChecked) {
      const tx = await approveErc20(
        USDT_ADDRESS,
        TRANSFER_PROXY_ADDRESS,
        ethers.utils.parseUnits(selectedAmount.toString(), 6)
      );

      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            {
              hash: tx.hash,
              pending: false,
              chain: ARBITRUM_CHAIN_NAME,

              callback: async () => {
                const usdtAllowance = await getAllowance(
                  arbitrumProvider,
                  USDT_ADDRESS,
                  accountInfo.address,
                  TRANSFER_PROXY_ADDRESS,
                  6
                );

                dispatch(
                  updateAuth({
                    accountInfo: {
                      ...accountInfo,
                      usdtAllowance,
                    },
                  })
                );
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: ARBITRUM_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.LOCKUP,
          })
        );
      } else {
        showErrorNotification('Something went wrong. Please try again.');
      }
    } else {
      showErrorNotification('You need to change your network to "Ethereum Mainnet" to continue.');
    }
  };

  const onAmountChange = (e) => {
    // const { value } = e.target;
    // if (Number(value) > Number(currentBalance)) {
    //   setErrorMessage('Insufficient funds');
    // } else {
    //   setErrorMessage('');
    // }
    setSelectedAmount(e.target.value.replaceAll(',', ''));
  };

  return (
    <div className="allowance-content">
      <div className="allowance-title-holder">
        <h1 className="title">Contribution Allowance</h1>
      </div>
      <div className="transfer-holder">
        <div className="col col-from">
          <p className="col-paragraph">From</p>
          <div className="col-content">
            <p className="label">Enter Amount</p>
            <div className="select-holder">
              <div className="select">
                <div className="selected">
                  <span>USDT</span>
                </div>
              </div>
              <div className="amount">
                <NumberInput
                  type="text"
                  placeholder="0.0"
                  onChange={onAmountChange}
                  value={Number(selectedAmount) > 0 ? selectedAmount : ''}
                  decimalNumber="6"
                />
              </div>
            </div>
            <div className="line" />
            <div className="bottom-content">
              <div className="balance">
                <p>
                  Current Allowance:{' '}
                  <span>
                    {(Math.floor(Number(currentAllowance) * 100) / 100)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-actions">
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <RoundedButton
            type="primary"
            // disabled={
            //   Number(selectedAmount) > Number(swapperBalance) ||
            //   Number(selectedAmount) > Number(currentBalance)
            // }
            onClick={handleApprove}
          >
            <div className="d-flex">
              <span>Approve</span>
              <SvgIcon name="iconBridgeArrowWhite" />
            </div>
          </RoundedButton>
          {/* <p className="fees">Fee: 1%</p> */}
        </div>
      </div>
      <div className="horizontal-line" />
    </div>
  );
};

export default Allowance;
