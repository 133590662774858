import React, { useState } from 'react';
import SvgIcon from 'components/svgIcon';
import MiscContainer from '../MiscInfo/MiscContainer';
import DeleteNoteModal from './DeleteNoteModal';
import CreateNoteModal from './CreateNoteModal';

const formatDate = (date) => {
  if (!date) return '-';
  return date.split('T').join(' ').substring(0, 16);
};

const NotesContainer = ({ dealInfo }) => {
  const [isCreateNoteModalOpen, setIsCreateNoteModalOpen] = useState(false);
  const openCreateNoteModal = () => setIsCreateNoteModalOpen(true);
  const closeCreateNoteModal = () => setIsCreateNoteModalOpen(false);

  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);
  const openDeleteNoteModal = () => setIsDeleteNoteModalOpen(true);
  const closeDeleteNoteModal = () => setIsDeleteNoteModalOpen(false);

  if (!dealInfo) return null;

  return (
    <MiscContainer
      title="Notes"
      className="notes"
      actionButton={
        <div className="edit-row" onClick={openCreateNoteModal}>
          <SvgIcon name="addDeal" />
        </div>
      }
    >
      <table>
        <thead>
          <tr>
            <td colSpan="2">User</td>
            <td>Date</td>
            <td colSpan="3">Message</td>
            <td />
          </tr>
        </thead>
        <tbody>
          {dealInfo.notes.map((item) => {
            return (
              <>
                <tr key={item.id} className="note-row">
                  <td data-label="User" colSpan="2">
                    {item.user}
                  </td>
                  <td data-label="Date">{formatDate(item.date)}</td>
                  <td data-label="Message" colSpan="3">
                    {item.message}
                  </td>
                  <td>
                    <div className="delete-note" onClick={openDeleteNoteModal}>
                      <SvgIcon name="closeRed" />
                    </div>
                  </td>
                </tr>
                <DeleteNoteModal
                  open={isDeleteNoteModalOpen}
                  onClose={closeDeleteNoteModal}
                  deal={{ id: dealInfo.dealId }}
                  noteId={item.id}
                />
              </>
            );
          })}
        </tbody>
      </table>
      <CreateNoteModal
        open={isCreateNoteModalOpen}
        onClose={closeCreateNoteModal}
        dealId={dealInfo.dealId}
        isOldDeal={dealInfo.platformOrLegacy === 'Legacy'}
      />
    </MiscContainer>
  );
};

export default NotesContainer;
