import React from 'react';
import UserContributionsTable from '../Contributions';
import UserLegalDetails from '../UserDetails';
import AppendixOne from '../../AppendixOne';
import AppendixTwo from '../../AppendixTwo';
import AppendixThree from '../../AppendixThree';

const useTabs = (address) => {
  return [
    {
      id: 1,
      label: 'Details',
      content: <UserLegalDetails address={address} />,
    },
    {
      id: 2,
      label: 'Contributions',
      content: <UserContributionsTable address={address} />,
    },
    {
      id: 3,
      label: 'PKP - Otvaranje predmeta',
      content: <AppendixOne address={address} />,
    },
    {
      id: 4,
      label: 'PKP - Poslovni odnos',
      content: <AppendixTwo address={address} />,
    },
    {
      id: 5,
      label: 'PKP - Dubinska analiza',
      content: <AppendixThree address={address} />,
    },
  ];
};

export default useTabs;
