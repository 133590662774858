import { AdditionalPepInfoFields } from './components/PoliticallyExposedPerson/PepRelationshipInfo/constants';

function validateDependentFields(field, formState, acc) {
  field.dependentFields.forEach((dependentField) => {
    if (!formState[dependentField]) {
      acc[dependentField] = true;
    }
  });
}

function validateRequiredFields(field, formState, acc) {
  const { dependentFields } = field.required;

  const missingDependentField = dependentFields.find(
    (dependentField) => !formState[dependentField]
  );

  if (missingDependentField) {
    acc[field.id] = true;
  }
}

function isFormValid(formState) {
  const newErrors = {
    isPep: !formState.isPep,
    primaryIntentions: !formState.primaryIntentions && formState.primaryIntentions !== 'other',
    sourceOfFunds: !formState.sourceOfFunds && formState.sourceOfFunds !== 'other',
    ...(formState.isPep === 'true' &&
      AdditionalPepInfoFields.reduce((acc, field) => {
        const currentValue = formState[field.id];

        if (field.required && !currentValue) {
          validateRequiredFields(field, formState, acc);
        }

        if (currentValue && field.dependentFields) {
          validateDependentFields(field, formState, acc);
        }
        return acc;
      }, {})),
  };

  if (formState.primaryIntentions === 'other' && !formState.otherIntentions) {
    newErrors.primaryIntentions = true;
  }
  if (formState.sourceOfFunds === 'other' && !formState.otherSourceOfFunds) {
    newErrors.sourceOfFunds = true;
  }

  return newErrors;
}

export default isFormValid;
