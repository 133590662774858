import { ethers } from 'ethers';
import IMulticall from '../contracts/abis/Multicall.json';
import { MULTICALL_ADDRESS } from 'constants/config';

function initContract(provider) {
  return new ethers.Contract(MULTICALL_ADDRESS, IMulticall, provider);
}

function decodeFallbackResult(calls, index) {
  let returnData = [];

  if (!calls[index].fallbackValue) {
    returnData = [];
  } else {
    returnData = ethers.utils.defaultAbiCoder.decode(
      calls[index].returnTypes,
      calls[index].fallbackValue
    );
  }

  return {
    success: false,
    returnData,
  };
}

function decodeResult(calls, index, result) {
  let returnData;

  try {
    returnData = ethers.utils.defaultAbiCoder.decode(calls[index].returnTypes, result.returnData);
  } catch {
    //fallback decoder
    returnData = result.returnData;
  }

  return {
    success: true,
    returnData,
  };
}

async function queryCalls(provider, calls) {
  const multicallContract = initContract(provider);

  const multicallResults = await multicallContract.callStatic.aggregate3(calls);

  return multicallResults.map((result, index) => {
    if (result.success) {
      return decodeResult(calls, index, result);
    } else {
      return decodeFallbackResult(calls, index);
    }
  });
}

export { queryCalls };
