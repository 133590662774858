import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import CustomSelect from 'components/select/custom-select';
import SvgIcon from 'components/svgIcon';
import './index.scss';
import CustomInput from 'components/input/custom-input';
import BdtLevelCapInput from 'features/deals/AdminDealsTable/BdtLevelCapInput';
import WhitelistPickerModal from 'features/whitelists/WhitelistPickerModal';
import Whitelist from 'features/deals/AdminDealsTable/Whitelist';
import { createPhases } from 'services/apiService';
import NumberInput from 'components/input/number-input';
import RoundedButton from 'components/button/rounded-button';
import { switchCurrentNetwork } from 'contracts/browserWallet';
import { deployDeal, updateDealParameters } from 'contracts/dealV2Contract';
import IconButton from 'components/button/icon-button';
import { useDispatch, useSelector } from 'react-redux';
import { updateDeal } from 'store/actions';
import { ARBITRUM_CHAIN_ID } from 'constants/index';
import { ethers } from 'ethers';
import { useNotifications } from 'hooks';

const levelItems = [
  { name: 'Level 0', value: '0' },
  { name: 'Level 1', value: '1' },
  { name: 'Level 2', value: '2' },
  { name: 'Level 3', value: '3' },
  { name: 'Whitelist', value: '4' },
];

const allocationModelItems = [
  { name: 'Pro Rata', value: 'ProRata' },
  { name: 'Cappped', value: 'Capped' },
  { name: 'Unlimited', value: 'Unlimited' },
  { name: 'Level Cap', value: 'CappedPerLevel' },
  { name: 'Wallet Cap', value: 'CappedPerWallet', subtitle: 'per Address' },
  { name: 'Phase Cap', value: 'PhaseCap', tooltip: 'Lorem ipsum dolor sit amet' },
];

const DeployContractTab = ({ deal }) => {
  const [whitelistPickerData, setWhitelistPickerData] = useState(null);
  const [minContribution, setMinContribution] = useState('0');
  const [dealSize, setDealSize] = useState('0');
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const dispatch = useDispatch();
  const [phases, setPhases] = useState([
    {
      minAccessLevel: '0',
      minViewLevel: '0',
      model: 'ProRata',
      endDate: new Date(),
      phaseName: '',
      whitelist: [],
      cap: 0,
      levels: [0, 0, 0, 0],
      expanded: false,
    },
  ]);
  const [isLocked, setIsLocked] = useState(!!deal.phases);
  const [isBeingDeployed, setIsBeingDeployed] = useState(false);
  const globalReducer = useSelector((state) => state.global);
  const { chainId } = globalReducer;

  useEffect(() => {
    if (!deal.phases) return;

    setPhases(() =>
      deal.phases.map(
        ({
          minAccessLevel,
          minViewLevel,
          model,
          name,
          endTimestamp,
          whitelist,
          cap,
          levelCaps,
          proRataCirculatingSupply,
        }) => ({
          minAccessLevel,
          minViewLevel,
          model,
          phaseName: name,
          endDate: new Date(endTimestamp),
          expanded: false,
          cap,
          levels: levelCaps,
          proRataCirculatingSupply,
          whitelist: whitelist?.map(({ amount, wallet }) => ({
            address: wallet,
            personalCap: amount,
          })),
        })
      )
    );
  }, [deal.phases]);

  useEffect(() => {
    if (deal.minContribution) {
      setMinContribution(deal.minContribution);
    }
    if (deal.dealSize) {
      setDealSize(deal.dealSize);
    }
  }, [deal]);

  const toggleExpand = (index) => {
    setPhases((prev) =>
      prev.map((phase, i) => (index === i ? { ...phase, expanded: !phase.expanded } : phase))
    );
  };

  const handlePhaseChange = (name, value, index) => {
    let { expanded } = phases[index];

    if (['Capped', 'PhaseCap', 'CappedPerLevel', 'CappedPerWallet', '4'].includes(value)) {
      expanded = true;
    }

    setPhases((prev) =>
      prev.map((phase, i) => (index === i ? { ...phase, [name]: value, expanded } : phase))
    );
  };

  const addNewPhase = () => {
    setPhases((prev) => [
      ...prev,
      {
        minAccessLevel: '0',
        minViewLevel: '0',
        model: 'ProRata',
        endDate: new Date(),
        phaseName: '',
        whitelist: [],
        cap: 0,
        levels: [0, 0, 0, 0],
        expanded: false,
      },
    ]);
  };

  const handleSave = async () => {
    const areDatesInvalid = phases.some(({ endDate }, i) =>
      i ? phases[i - 1].endDate > endDate : false
    );

    if (areDatesInvalid) {
      showErrorNotification(
        'Deal phases are not aligned with deal phases end time. Please check and correct before save.'
      );
      return;
    }
    setIsLocked(true);
    try {
      const res = await createPhases(
        phases.map((phase, index) => ({
          ...phase,
          name: phase.phaseName,
          index,
          whitelist:
            phase.whitelist &&
            phase.whitelist.map(({ address, personalCap }) => ({
              address,
              amount: ethers.utils.parseUnits(personalCap.toString(), 6).toString(),
            })),
        })),
        deal.id,
        dealSize
      );
      dispatch(
        updateDeal({
          id: deal.id,
          lockSnapshotMerkleRoot: res.lockSnapshotMerkleRoot,
          phases: res.phases,
          dealSize,
        })
      );
      showSuccessNotification('Phases saved successfully');
    } catch (error) {
      showErrorNotification(error.response.data);
      setIsLocked(false);
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    if (!deal.address) return;
    if (+dealSize < +deal.dealSize) {
      showErrorNotification('Deal size can not be reduced');
      return;
    }
    try {
      setIsBeingDeployed(true);

      const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);
      if (networkChecked) {
        await updateDealParameters(minContribution, dealSize, deal.address);

        updateDeal({
          id: deal.id,
          minContribution,
          dealSize,
        });
      }
      setIsBeingDeployed(false);
    } catch (error) {
      setIsBeingDeployed(false);
      showErrorNotification('Something went wrong while updating deal');
    }
  };

  const handleDeploy = async () => {
    try {
      setIsBeingDeployed(true);
      const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);

      if (networkChecked) {
        await deployDeal(
          { ...deal, lockSnapshotRoot: deal.lockSnapshotMerkleRoot, minContribution, dealSize },
          deal.phases
            .map((phase) => ({
              ...phase,
              cap: phase.cap,
              levels: phase.levelCaps,
            }))
            .sort((a, b) => (a.index > b.index ? +1 : -1))
        );
      }
    } catch (error) {
      setIsBeingDeployed(false);
      showErrorNotification('Something went wrong while deploying phases');
      console.log(error);
    }
  };

  const handleRemovePhase = (index) => {
    setPhases((prev) => prev.filter((_, i) => i !== index));
  };

  if (!deal.isV2) return <h4>V1 deal, no phases available</h4>;

  return (
    <div className="deploy-contract">
      <div className="general">
        <div className="general__inputs">
          <NumberInput
            label="Min Contribution"
            value={minContribution}
            onChange={(e) => setMinContribution(e.target.value)}
            decimalNumber="2"
            disabled={!isLocked && isBeingDeployed}
          />
          <NumberInput
            label="Deal Size"
            value={dealSize}
            onChange={(e) => setDealSize(e.target.value)}
            decimalNumber="2"
            disabled={!isLocked && isBeingDeployed}
          />
        </div>
        <div className="general__actions">
          <RoundedButton onClick={handleSave} disabled={isLocked} type="primary">
            Save
          </RoundedButton>
          <RoundedButton
            onClick={deal.address ? handleUpdate : handleDeploy}
            disabled={!isLocked || isBeingDeployed}
            type="secondary"
          >
            {deal.address ? 'Update' : 'Deploy'}
          </RoundedButton>
        </div>
      </div>
      <Divider />
      <div className="header-column">
        <label>#</label>
        <label>Access Level</label>
        <label>Phase</label>
        <label>Model</label>
        <label>End Time</label>
        <label>View Level</label>
      </div>
      {phases.map(
        (
          {
            minAccessLevel,
            minViewLevel,
            model,
            endDate,
            phaseName,
            whitelist,
            levels,
            cap,
            expanded,
          },
          i
        ) => (
          <Accordion
            key={i}
            classes={{ root: 'deal-edit-modal accordion', rounded: 'accordion__rounded' }}
            expanded={expanded}
          >
            <AccordionSummary
              onClick={() => toggleExpand(i)}
              classes={{
                content: 'accordion-summary__content',
                root: 'accordion-summary',
                focusVisible: 'accordion-summary__focused',
              }}
              expandIcon={<SvgIcon name="arrowDown" />}
            >
              <label>{i + 1}. </label>
              <CustomSelect
                items={levelItems}
                name="minAccessLevel"
                value={minAccessLevel}
                onSelect={(name, value) => handlePhaseChange(name, value, i)}
                disabled={isLocked}
              />
              <div className="phase-name" onClick={(e) => e.stopPropagation()}>
                <CustomInput
                  value={phaseName}
                  onChange={(e) => handlePhaseChange('phaseName', e.target.value, i)}
                  disabled={isLocked}
                />
              </div>
              <CustomSelect
                items={allocationModelItems}
                name="model"
                value={model}
                onSelect={(name, value) => handlePhaseChange(name, value, i)}
                disabled={isLocked}
              />
              <div onClick={(e) => e.stopPropagation()} className="date-picker__container">
                <DatePicker
                  selected={endDate}
                  showTimeSelect
                  onChange={(date) => handlePhaseChange('endDate', date, i)}
                  dateFormat="MM/dd HH:mm"
                  disabled={isLocked}
                />
              </div>
              <CustomSelect
                items={levelItems}
                name="minViewLevel"
                value={minViewLevel}
                onSelect={(name, value) => handlePhaseChange(name, value, i)}
                disabled={isLocked}
              />
              {!isLocked && (
                <div className="delete-icon">
                  <IconButton
                    icon="iconDeleteX"
                    onClick={() => handleRemovePhase(i)}
                    disabled={isLocked}
                  />
                </div>
              )}
            </AccordionSummary>
            <AccordionDetails classes={{ root: 'accordion-details' }}>
              {model === 'CappedPerLevel' && (
                <BdtLevelCapInput
                  phaseLevels={levels}
                  setPhaseLevels={(updatedLevels) => handlePhaseChange('levels', updatedLevels, i)}
                  disabled={isLocked}
                />
              )}
              {(model === 'Capped' || model === 'PhaseCap') && (
                <div className="cap-container">
                  <NumberInput
                    label="Cap"
                    value={cap}
                    onChange={(e) => handlePhaseChange('cap', e.target.value, i)}
                    decimalNumber="2"
                    disabled={isLocked}
                  />
                </div>
              )}
              {(minAccessLevel.toString() === '4' || model === 'CappedPerWallet') && (
                <div className="deal-edit-modal__content whitelist-content">
                  <label>
                    Whitelist
                    <button
                      disabled={isLocked}
                      type="button"
                      onClick={() => setWhitelistPickerData({ whitelist, i })}
                    >
                      <SvgIcon name="iconDownloadPledge" width={16} height={16} />
                    </button>
                  </label>
                  <Whitelist
                    showAmount={model === 'CappedPerWallet'}
                    whitelistId={`${deal.id}-${i}`}
                    whitelist={whitelist}
                    onChange={(updatedWhitelist) =>
                      handlePhaseChange('whitelist', updatedWhitelist, i)
                    }
                    onValidityChange={() => null}
                    disabled={isLocked}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        )
      )}
      {!isLocked && (
        <div className="add-phase" onClick={addNewPhase}>
          <SvgIcon name="plus" />
        </div>
      )}
      <WhitelistPickerModal
        open={!!whitelistPickerData}
        onClose={() => setWhitelistPickerData(null)}
        onSave={(newAddresses) =>
          handlePhaseChange(
            'whitelist',
            [
              ...whitelistPickerData.whitelist,
              ...newAddresses.map((address) => ({ address, personalCap: 0 })),
            ],
            whitelistPickerData.i
          )
        }
      />
    </div>
  );
};

export default DeployContractTab;
