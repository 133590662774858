import React, { useCallback, useEffect, useState } from 'react';
import SvgIcon from 'components/svgIcon';
import IconButton from 'components/button/icon-button';
import { useSelector } from 'react-redux';

const PollsHeader = ({ polls, onChange, onAddPoll }) => {
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin } = authReducer;
  const [value, setValue] = useState('');
  const [showInput, setShowInput] = useState(false);

  const toggleInput = () => {
    if (window.innerWidth < 767) {
      setShowInput(!showInput);
    }
  };

  const getFilteredPolls = useCallback(() => {
    if (!value) {
      return polls;
    }

    return polls.filter(({ title }) => title.includes(value));
  }, [polls, value]);

  useEffect(() => {
    onChange(getFilteredPolls());
  }, [onChange, getFilteredPolls]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
    });
  }, [setShowInput]);

  return (
    <div className="polls__header">
      <div className="polls__title-container">
        <h1>Polls</h1>
        <span>{polls.length} Total</span>
      </div>
      <div className="polls__filter-container">
        {isAdmin && (
          <div className="add_poll">
            <IconButton icon="plus" onClick={onAddPoll} />
          </div>
        )}
        <div className={`search-input${showInput ? ' active' : ''}`}>
          <div className="input-icon-wrapper vertical-center" onClick={toggleInput}>
            <SvgIcon name="search" />
          </div>
          <input
            type="text"
            value={value}
            placeholder="Search polls by name"
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default PollsHeader;
