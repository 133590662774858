import { ethers } from 'ethers';
import ClaimerArtifact from './abis/Claimer.json';

// eslint-disable-next-line import/prefer-default-export
export const claimOldDeal = async (claimerAddress, tokenAddress, merkleProof, walletAddress) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);

  try {
    const claimerContract = new ethers.Contract(
      claimerAddress,
      ClaimerArtifact.abi,
      injectedProvider.getSigner()
    );

    // address erc20Address, uint256 index, address account, uint256 amount, bytes32[] calldata merkleProof
    const tx = await claimerContract.claimToken(
      tokenAddress,
      walletAddress,
      merkleProof.amount,
      merkleProof.proof
    );
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};
