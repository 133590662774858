/* eslint-disable no-case-declarations */
import { contributeUpdateDeal } from 'contracts/eventHelper';
import { SYSTEM_PARTS } from 'features/sidebar/LeftSideBar/utils';
import {
  getChangedProperties,
  subStringAndBigNumberAmounts,
  addStringAndBigNumberAmounts,
} from 'utils/helpers';
import * as actionTypes from '../constants';

const getInitialState = () => ({
  loading: false,
  notificationDropdownOpened: false,
  activeDeal: null,
  dealApprovedStatus: 'default',
  sharedNotification: {},
  resolvedNotification: null,
  notifications: JSON.parse(localStorage.getItem('notifications')) || [],
  chainId: '',
  userDeals: [],
  oldDeals: [],
  activeHashes: [],
  backupDeal: JSON.parse(localStorage.getItem('backupDeal')) || null,
  dealEditModalAction: '',

  animateDeals: {},
  accountInfoAnimation: {},
  fetchingDeals: false,
  alertNotification: {},
  isSidebarExpanded: window.innerWidth > 700,
  isInitialLoadFinished: false,
  isPreviewMode: !!JSON.parse(localStorage.getItem('isPreviewMode')),
  systemPart: SYSTEM_PARTS.DEALS,
});

function globalReducer(state = getInitialState(), action) {
  let notification;
  let notificationsArr;
  let maxId;
  let minId;
  let ids;
  switch (action.type) {
    case actionTypes.ACTION_INIT_GLOBAL:
      return { ...getInitialState() };
    case actionTypes.ACTION_UPDATE_GLOBAL:
      return { ...state, ...action.payload };
    case actionTypes.ACTION_UPDATE_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.ACTION_UPDATE_FETCHING_DEALS:
      return { ...state, fetchingDeals: action.payload };
    case actionTypes.ACTION_SET_SHARED_NOTIFICATION:
      return {
        ...state,
        sharedNotification: {
          ...state.sharedNotification,
          ...{
            status: action.payload.status,
            title: action.payload.title,
            description: action.payload.description,
          },
        },
      };
    case actionTypes.ACTION_SET_ALERT_NOTIFICATION:
      return {
        ...state,
        alertNotification: {
          ...state.alertNotification,
          ...{
            status: action.payload.status,
            title: action.payload.title,
            description: action.payload.description,
          },
        },
      };
    case actionTypes.ACTION_SET_RESOLVED_NOTIFICATION:
      return {
        ...state,
        resolvedNotification: {
          ...state.resolvedNotification,
          ...{
            title: action.payload.title,
            description: action.payload.description,
          },
        },
      };
    case actionTypes.ACTION_REMOVE_RESOLVED_NOTIFICATION:
      return {
        ...state,
        resolvedNotification: null,
      };
    case actionTypes.ACTION_ADD_NOTIFICATION:
      notification = action.payload;
      notificationsArr = [...state.notifications, notification];

      ids = state.notifications.map((el) => el.id);

      maxId = ids.length ? Math.max(...ids) : 0;

      notification.id = maxId + 1 || 1;
      notification.seen = false;

      minId = Math.min(...notificationsArr.map((el) => el.id));

      if (notificationsArr.length > 10) {
        notificationsArr = notificationsArr.filter((el) => el.id !== minId);
      }

      localStorage.removeItem('notifications');
      localStorage.setItem('notifications', JSON.stringify(notificationsArr));

      return {
        ...state,
        notifications: [...notificationsArr],
      };
    case actionTypes.ACTION_CHECK_NOTIFICATIONS:
      notificationsArr = [...state.notifications];

      notificationsArr.forEach((el) => {
        // eslint-disable-next-line no-return-assign
        return (el.seen = true);
      });

      localStorage.removeItem('notifications');
      localStorage.setItem('notifications', JSON.stringify(notificationsArr));

      return {
        ...state,
        notifications: [...notificationsArr],
      };
    case actionTypes.ACTION_UPDATE_NOTIFICATION:
      notificationsArr = [...state.notifications];
      // eslint-disable-next-line no-case-declarations
      const notif = notificationsArr.find((x) => x.name === action.payload.hash);
      if (notif) {
        if (action.payload.status) {
          notif.status = action.payload.status;
        }
        if (action.payload.statusText) {
          notif.statusText = action.payload.statusText;
        }
        if (action.payload.dealAddress) {
          notif.dealAddress = action.payload.dealAddress;
        }
        if (action.payload.dealImage) {
          notif.dealImage = action.payload.dealImage;
        }
      }
      localStorage.removeItem('notifications');
      localStorage.setItem('notifications', JSON.stringify(notificationsArr));
      return {
        ...state,
        notifications: [...notificationsArr],
      };
    case actionTypes.ACTION_SET_CHAIN_ID:
      return { ...state, chainId: action.payload };
    case actionTypes.ACTION_SET_USER_DEALS:
      return { ...state, userDeals: action.payload };
    case actionTypes.ACTION_SET_OLD_DEALS:
      return { ...state, oldDeals: action.payload };
    case actionTypes.ACTION_SET_USER_DEAL:
      return {
        ...state,
        userDeals: state.userDeals.map((content) =>
          content.id === action.payload.id ? { ...content, ...action.payload } : content
        ),
      };
    case actionTypes.ACTION_DELETE_USER_DEAL:
      return {
        ...state,
        userDeals: state.userDeals.filter((deal) => deal.id !== action.payload),
      };
    case actionTypes.ACTION_SET_OLD_DEAL:
      return {
        ...state,
        oldDeals: state.oldDeals.map((content) =>
          content.id === action.payload.id ? { ...content, ...action.payload } : content
        ),
      };
    case actionTypes.ACTION_ADD_USER_DEAL:
      return {
        ...state,
        userDeals: [...state.userDeals, action.payload],
      };
    case actionTypes.ACTION_SET_ACTIVE_HASHES:
      return { ...state, activeHashes: action.payload };
    case actionTypes.ACTION_REMOVE_ACTIVE_HASH:
      return { ...state, activeHashes: state.activeHashes.filter((x) => x !== action.payload) };
    case actionTypes.ACTION_SET_BACKUP_DEAL:
      localStorage.setItem('backupDeal', JSON.stringify(action.payload));
      return { ...state, backupDeal: action.payload };
    case actionTypes.ACTION_REMOVE_BACKUP_DEAL:
      localStorage.removeItem('backupDeal');
      return { ...state, backupDeal: null };
    case actionTypes.ACTION_ADD_DEAL_TO_ANIMATE:
      return { ...state, animateDeals: action.payload };
    case actionTypes.ACTION_REMOVE_ANIMATE_DEAL:
      return { ...state, animateDeals: {} };
    case actionTypes.ACTION_ADD_ACCOUNT_INFO_ANIMATION:
      return { ...state, accountInfoAnimation: action.payload };
    case actionTypes.ACTION_REMOVE_ACCOUNT_INFO_ANIMATION:
      return { ...state, accountInfoAnimation: {} };
    case actionTypes.ACTION_CLOSE_SIDEBAR:
      return { ...state, isSidebarExpanded: false };
    case actionTypes.ACTION_OPEN_SIDEBAR:
      return { ...state, isSidebarExpanded: true };
    case actionTypes.ACTION_HANDLE_CONTRIBUTE:
      const { log, contributionAmountLeft, walletAddress } = action.payload;
      const deal = state.userDeals.find((d) => d.address === log.address);
      if (!deal) return state;

      const dealCopy = { ...deal };
      contributeUpdateDeal(deal, log, walletAddress, contributionAmountLeft);
      const fields = getChangedProperties(dealCopy, deal);
      if (fields.length === 0) return state;
      return {
        ...state,
        userDeals: state.userDeals.map((content) =>
          content.id === deal.id ? { ...content, ...deal } : content
        ),
        animateDeals: {
          address: deal.address,
          fields,
        },
      };
    case actionTypes.ACTION_HANDLE_V2_CONTRIBUTE:
      const v2Deal = state.userDeals.find((d) => d.address === action.payload.dealAddress);
      if (!v2Deal) return state;
      const v2dealCopy = { ...v2Deal };
      v2Deal.raisedAmount = addStringAndBigNumberAmounts(
        v2Deal.raisedAmount,
        action.payload.amount,
        6
      );
      if (action.payload.isOwnContribution) {
        v2Deal.contributedAmount = addStringAndBigNumberAmounts(
          v2Deal.contributedAmount,
          action.payload.amount,
          6
        );
        v2Deal.personalCap = subStringAndBigNumberAmounts(
          v2Deal.personalCap,
          action.payload.amount,
          6
        );
      }
      const changedFields = getChangedProperties(v2dealCopy, v2Deal);

      if (changedFields.length === 0) return state;
      return {
        ...state,
        userDeals: state.userDeals.map((content) =>
          content.id === v2Deal.id ? { ...content, ...v2Deal } : content
        ),
        animateDeals: {
          address: v2Deal.address,
          fields: changedFields,
        },
      };
    case actionTypes.ACTION_UPDATE_DEAL:
      return {
        ...state,
        userDeals: state.userDeals.map((deal) =>
          action.payload.id === deal.id ? { ...deal, ...action.payload } : deal
        ),
      };
    case actionTypes.ACTION_SET_POLL_LIST:
      return { ...state, pollList: action.payload };
    case actionTypes.ACTION_UPDATE_SYSTEM_PART:
      return { ...state, systemPart: action.payload };
    case actionTypes.ACTION_RESET_GLOBAL:
      return { ...getInitialState() };
    default:
      return state;
  }
}

export default globalReducer;
