import React, { useState } from 'react';
import SvgIcon from 'components/svgIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import IconButton from 'components/button/icon-button';
import { deleteWhitelist, updateAdminUserData, updateWhitelist } from 'services/apiService';
import { useNotifications } from 'hooks';
import AddressEditDialog from 'features/whitelists/AddressEditDialog';
import EditUserDataDialog from 'features/whitelists/EditUserDataDialog';
import WhitelistDeleteDialog from 'features/whitelists/WhitelistDeleteDialog';
import NameEditModal from 'components/NameEditModal';

const WhitelistList = ({ whitelists, fetchWhitelists }) => {
  const [expandedId, setExpandedId] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [whitelistToEdit, setWhitelistToEdit] = useState(null);
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const [whitlistToDelete, setWhitelistToDelete] = useState(null);
  const [whitelistToEditName, setWhitelistToEditName] = useState(null);

  const onDelete = async () => {
    if (!whitlistToDelete) return;
    try {
      await deleteWhitelist(whitlistToDelete.id);
      fetchWhitelists();
      setWhitelistToDelete(null);
      showSuccessNotification('Whitelist successfully deleted');
    } catch {
      showErrorNotification('Something went wrong, please try again!');
    }
  };

  const handleChange = (whitelistId) => (e, isExpanded) => {
    setExpandedId(isExpanded ? whitelistId : false);
  };

  const onEditAddresses = async () => {
    try {
      await updateWhitelist({
        ...whitelistToEdit,
        addresses: whitelistToEdit.addresses.map(({ address }) => address),
      });
      showSuccessNotification(`Whitelist updated successfully`);
      fetchWhitelists();
      setWhitelistToEdit(null);
    } catch {
      showErrorNotification('Something went wrong, please try again!');
    }
  };

  const onEditUserData = async () => {
    try {
      await updateAdminUserData(userToUpdate);
      fetchWhitelists();

      setUserToUpdate(null);
      showSuccessNotification(`Updated successfully`);
    } catch {
      showErrorNotification('Something went wrong, please try again!');
    }
  };

  const handleWhitelistNameChange = async (newName) => {
    try {
      await updateWhitelist({
        ...whitelistToEditName,
        addresses: whitelistToEditName.addresses.map(({ address }) => address),
        name: newName,
      });
      fetchWhitelists();

      setWhitelistToEditName(null);
      showSuccessNotification(`Name updated successfully`);
    } catch {
      showErrorNotification('Something went wrong, please try again!');
    }
  };

  return (
    <>
      <AddressEditDialog
        whitelistId={whitelistToEdit?.id}
        addresses={whitelistToEdit?.addresses}
        setAddresses={(addresses) => setWhitelistToEdit((prev) => ({ ...prev, addresses }))}
        onSave={onEditAddresses}
      />
      <EditUserDataDialog
        userInfo={userToUpdate}
        setUserInfo={setUserToUpdate}
        onSave={onEditUserData}
      />
      <WhitelistDeleteDialog
        whitelist={whitlistToDelete}
        setWhitelist={setWhitelistToDelete}
        onDelete={onDelete}
      />
      <NameEditModal
        name={whitelistToEditName?.name}
        open={!!whitelistToEditName}
        onOk={handleWhitelistNameChange}
        onCancel={() => setWhitelistToEditName(null)}
      />
      <div className="whitelists-content">
        {whitelists.map((whitelist) => (
          <Accordion
            expanded={whitelist.id === expandedId}
            key={whitelist.id}
            classes={{ expanded: 'whitelist-accordion__expanded', root: 'whitelist-accordion' }}
            onChange={handleChange(whitelist.id)}
          >
            <AccordionSummary classes={{ content: 'whitelist-accordion__content' }}>
              <div className="name-field">
                <span>{whitelist.name}</span>
                <RoundedButton
                  type="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    setWhitelistToEditName(whitelist);
                  }}
                  className="name-edit-btn"
                >
                  Edit
                </RoundedButton>
              </div>
              <div>
                <RoundedButton
                  type="transaparent"
                  onClick={(e) => {
                    e.stopPropagation();
                    setWhitelistToDelete(whitelist);
                  }}
                >
                  Delete
                </RoundedButton>
                <RoundedButton type="primary">
                  {expandedId === whitelist.id ? 'Save' : 'Edit'}
                </RoundedButton>
              </div>
            </AccordionSummary>
            <Divider />
            <AccordionDetails classes={{ root: 'whitelist-accordion__details' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ADDRESS</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Note</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {whitelist.addresses.map((user, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {i + 1}. {user.address}
                        <SvgIcon name="success" />
                      </TableCell>
                      <TableCell>{user.adminName}</TableCell>
                      <TableCell>{user.note}</TableCell>
                      <TableCell>
                        <IconButton icon="iconDealsEdit" onClick={() => setUserToUpdate(user)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className="add-more" onClick={() => setWhitelistToEdit(whitelist)}>
                <div>
                  <SvgIcon name="plus" width={16} height={16} />
                  {whitelist.length ? 'Add more' : 'Add adresses'}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default WhitelistList;
