const dealInfoTableHead = [
  {
    column: 'dealName',
    label: 'Name',
    sort: (a, b) => (b.dealName.trim().toLowerCase() > a.dealName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'ticker',
    label: 'Ticker',
    sort: (a, b) => (b.ticker.trim().toLowerCase() > a.ticker.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'dealStatus',
    label: 'Status',
    sort: (a, b) =>
      b.dealStatus.trim().toLowerCase() > a.dealStatus.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'blockchain',
    label: 'Network',
    sort: (a, b) =>
      b.blockchain.trim().toLowerCase() > a.blockchain.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'statusInfo',
    label: 'Status Info',
    sort: (a, b) =>
      b.statusInfo?.trim()?.toLowerCase() > a.statusInfo?.trim()?.toLowerCase() ? 1 : -1,
  },
  {
    column: 'batchesReceived',
    label: 'Batches received',
    sort: (a, b) => (parseInt(b.batchesReceived, 10) > parseInt(a.batchesReceived, 10) ? 1 : -1),
  },
  {
    column: 'totalBatches',
    label: 'Total Batches',
    sort: (a, b) => (parseInt(b.totalBatches, 10) > parseInt(a.totalBatches, 10) ? 1 : -1),
  },
  {
    column: 'tokensReceived',
    label: 'Tokens Received',
    sort: (a, b) => (parseFloat(b.tokensReceived) > parseFloat(a.tokensReceived) ? 1 : -1),
  },
  {
    column: 'tokensReceivedPercentage',
    label: 'Tokens Received (%)',
    sort: (a, b) =>
      parseFloat(b.tokensReceivedPercentage) > parseFloat(a.tokensReceivedPercentage) ? 1 : -1,
  },
  {
    column: 'tokensExpected',
    label: 'Tokens Expected',
    sort: (a, b) => (parseFloat(b.tokensExpected) > parseFloat(a.tokensExpected) ? 1 : -1),
  },
  {
    column: 'distributionModel',
    label: 'Distribution Model',
    sort: (a, b) =>
      b.distributionModel.trim().toLowerCase() > a.distributionModel.trim().toLowerCase() ? 1 : -1,
  },
];

const addressTableHead = [
  {
    column: 'blockchain',
    label: 'Blockchain',
    sort: (a, b) =>
      b.blockchain.trim().toLowerCase() > a.blockchain.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'tokenAddress',
    label: 'Token Address',
    sort: (a, b) =>
      b.tokenAddress.trim().toLowerCase() > a.tokenAddress.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'dealAddress',
    label: 'Deal Address',
    sort: (a, b) =>
      b.dealAddress.trim().toLowerCase() > a.dealAddress.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'receiveWallet',
    label: 'Receive Wallet',
    sort: (a, b) =>
      b.receiveWallet.trim().toLowerCase() > a.receiveWallet.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'claimerAddress',
    label: 'Claimer Address',
    sort: (a, b) =>
      b.claimerAddress.trim().toLowerCase() > a.claimerAddress.trim().toLowerCase() ? 1 : -1,
  },
];

export default { dealInfoTableHead, addressTableHead };
