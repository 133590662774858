import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, Divider, DialogContent, DialogActions } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import usePutAthPrice from './remote';
import './index.scss';

const EditAthModal = ({ isModalOpen, closeModal, dealId, dealInitialAth, isOldDeal }) => {
  const [price, setPrice] = useState();
  const updateAthPrice = usePutAthPrice(isOldDeal);

  useEffect(() => {
    setPrice(dealInitialAth);
  }, [dealInitialAth]);

  const [errorMessage, setErrorMessage] = useState('');
  const clearErrorMessage = () => setErrorMessage('');

  const [successMessage, setSuccesMessage] = useState('');
  const clearSuccessMessage = () => setSuccesMessage('');

  const onModalClose = () => {
    clearSuccessMessage();
    clearErrorMessage();
    setPrice(null);
    closeModal();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!dealId || !price) return;

    try {
      await updateAthPrice.mutateAsync({ dealId, price });
      setSuccesMessage(`Updated deal ${dealId} ath price!`);
      setTimeout(onModalClose, 1500);
    } catch (err) {
      setErrorMessage('Error while updating...');
    }
  };

  const resetToCoinGecko = async () => {
    if (!dealId) return;

    try {
      await updateAthPrice.mutateAsync({ dealId, price: null });
      setSuccesMessage(`Reset deal ${dealId} to coingecko ath!`);
      setTimeout(onModalClose, 1500);
    } catch (err) {
      setErrorMessage('Error while updating...');
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={onModalClose}
      classes={{ paper: 'edit-deal-modal' }}
      disableBackdropClick
    >
      <DialogTitle>Update ATH #{dealId}</DialogTitle>
      <Divider />
      <DialogContent>
        {!!errorMessage && <h5 className="message message--error">{errorMessage}</h5>}
        {!!successMessage && <h5 className="message message--success">{successMessage}</h5>}
        <form id="seed-form" onSubmit={handleFormSubmit}>
          <div className="input-holder">
            <label>ATH price</label>
            <input
              type="number"
              name="price"
              value={price}
              onFocus={clearErrorMessage}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Enter price"
              step="any"
            />
          </div>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <div className="action-buttons">
          <RoundedButton
            className="action-button action-button--close"
            onClick={onModalClose}
            disabled={updateAthPrice.isLoading}
          >
            Close
          </RoundedButton>
          <RoundedButton
            form="seed-form"
            className="action-button action-button--submit"
            buttonType="submit"
            disabled={updateAthPrice.isLoading}
          >
            Update
          </RoundedButton>
          <RoundedButton
            form="seed-form"
            className="action-button action-button--submit"
            disabled={updateAthPrice.isLoading || !dealInitialAth}
            onClick={resetToCoinGecko}
          >
            RESET
          </RoundedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EditAthModal;
