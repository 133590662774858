const getTruthyFields = (filter) =>
  Object.entries(filter)
    .filter((entry) => Boolean(entry[1]))
    .map((el) => el[0].toLowerCase());

const applyDealTypeFilter = (userDeals, oldDeals, type) => {
  if (type === 'old') return oldDeals.filter(({ isArchived }) => !isArchived);
  if (type === 'platform')
    return userDeals.filter(
      ({ isArchived, contributedAmount }) => Number(contributedAmount) > 0 && !isArchived
    );
  if (type === 'old-archive') return oldDeals.filter(({ isArchived }) => isArchived);
  return userDeals.filter(({ isArchived }) => isArchived);
};

const applySearchFilter = (search, deal) => {
  return deal.name.toLowerCase().includes(search.toLowerCase());
};

const applyBlockchainFilter = (blockchains, deal) => {
  if (!blockchains.length) return true;
  return deal.claimers.some((claimer) => blockchains.includes(claimer.name?.toLowerCase()));
};

const isOldDealClaimable = (deal) => deal.claimers.some(({ claimable }) => +claimable > 0);
const isPlatformDealClaimable = (deal) =>
  deal.claimers.some(({ claimableAmounts }) =>
    claimableAmounts?.some(({ claimAmount }) => +claimAmount > 0)
  );
const hasDealRefund = (deal) => +deal.dealClaimInfo?.claimAmount > 0;

const applyIsClaimableFilter = (isClaimable, deal) => {
  if (!isClaimable) return true;
  return isOldDealClaimable(deal) || isPlatformDealClaimable(deal) || hasDealRefund(deal);
};

const useDeals = (userDeals, oldDeals, filters) => {
  const appliedBlockchains = getTruthyFields(filters.blockchain);

  const applyFilters = [
    (deal) => applySearchFilter(filters.search.toLowerCase(), deal),
    (deal) => applyBlockchainFilter(appliedBlockchains, deal),
    (deal) => applyIsClaimableFilter(filters.isClaimable, deal),
  ];

  const deals = applyDealTypeFilter(userDeals, oldDeals, filters.type);
  const filterResult = applyFilters.reduce((array, filterFunc) => array.filter(filterFunc), deals);

  return filterResult;
};

export default useDeals;
