import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addAccountInfoAnimation, subBdtBalance, addBdtBalance } from 'store/actions';
import { arbitrumWsProvider } from 'contracts/providers';
import { BDT_ADDRESS } from 'constants/index';
import { getTransferEventFilter, parseTransferLog } from '../contracts/erc20';

function useBdtTransferSubscriber() {
  const globalReducer = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const { walletAddress } = authReducer;
  const { isInitialLoadFinished } = globalReducer;

  useEffect(() => {
    if (!isInitialLoadFinished) return () => null;

    const bdtTransferFromWalletFilter = getTransferEventFilter(BDT_ADDRESS, walletAddress, null);
    const bdtTransferToWalletFilter = getTransferEventFilter(BDT_ADDRESS, null, walletAddress);

    const bdtTransferFromWalletHandler = async (rawLog) => {
      const log = parseTransferLog(rawLog);

      const accountInfoAnimation = {
        fields: ['bdtBalance'],
      };

      dispatch(addAccountInfoAnimation(accountInfoAnimation));
      dispatch(subBdtBalance(log.args.value));
    };

    const bdtTransferToWalletHandler = async (rawLog) => {
      const log = parseTransferLog(rawLog);

      const accountInfoAnimation = {
        fields: ['bdtBalance'],
      };

      dispatch(addAccountInfoAnimation(accountInfoAnimation));
      dispatch(addBdtBalance(log.args.value));
    };

    arbitrumWsProvider.on(bdtTransferFromWalletFilter, bdtTransferFromWalletHandler);
    arbitrumWsProvider.on(bdtTransferToWalletFilter, bdtTransferToWalletHandler);

    return () => {
      arbitrumWsProvider.off(bdtTransferFromWalletFilter, bdtTransferFromWalletHandler);
      arbitrumWsProvider.off(bdtTransferToWalletFilter, bdtTransferToWalletHandler);
    };
  }, [isInitialLoadFinished, walletAddress, dispatch]);
}

export default useBdtTransferSubscriber;
