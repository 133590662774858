import React, { useMemo } from 'react';
import AddSellTransactionDialog from 'features/analytics/AddSellTransactionDialog';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import axios from 'axios';
import SortableTable from 'components/sortable-table';
import SellTransactionsRows from './SellTransactionsRows';
import remote from './remote';

const SellTransactions = ({
  txToAddEdit,
  setTxToAddEdit,
  searchValue,
  deal,
  deals = [],
  isOldDeal,
}) => {
  const authReducer = useSelector((state) => state.auth);
  const { walletAddress } = authReducer;

  const { data } = remote.useGetSellTransactions(walletAddress, deal, isOldDeal);

  const exchanges = useQuery(['exchanges'], () =>
    axios.get('https://api.coingecko.com/api/v3/exchanges/list')
  );

  const searchedTransactions = useMemo(() => {
    if (!data) return [];
    let transactions = [...data];

    if (searchValue !== '') {
      transactions = data.reduce((accum, tx) => {
        if (!tx.deal.name.toLowerCase().includes(searchValue.toLowerCase())) return accum;
        return [
          ...accum,
          {
            ...tx,
            exchange: exchanges.data?.data.find(({ id }) => id === tx.exchange),
          },
        ];
      }, []);
    }
    return transactions;
  }, [data, searchValue, exchanges]);

  const addTx = remote.useAddTx(walletAddress);
  const updateTx = remote.useUpdateTx(walletAddress);
  const deleteTx = remote.useDeleteTx(walletAddress);

  const handleSave = async (transaction) => {
    const save = transaction.id ? updateTx : addTx;
    const updatedDealId = transaction.deal?.dealId || transaction.dealId;
    const updatedOldDealId = transaction.deal?.dealId || transaction.oldDealId;

    await save.mutateAsync({
      ...transaction,
      exchange: transaction.exchange?.id ?? null,
      chainId: transaction.chainId || null,
      oldDealId: transaction.deal.isOldDeal || transaction.oldDealId ? updatedOldDealId : null,
      dealId: !transaction.deal.isOldDeal ? updatedDealId : null,
    });
    setTxToAddEdit(null);
  };

  const handleDelete = async (tx) => {
    console.log({ tx });
    await deleteTx.mutateAsync(tx.id);
    setTxToAddEdit(null);
  };

  const tableHead = [
    {
      column: 'name',
      label: 'Deal name',
      sort: (a, b) =>
        b.deal.name.trim().toLowerCase() > a.deal.name.trim().toLowerCase() ? 1 : -1,
    },
    {
      column: 'date',
      label: 'Sales date',
      sort: (a, b) => (new Date(b.date) > new Date(a.date) ? 1 : -1),
      defaultSortField: true,
    },
    {
      column: 'exchange',
      label: 'Exchange',
      sort: (a, b) =>
        b?.exchange?.trim()?.toLowerCase() > a?.exchange?.trim()?.toLowerCase() ? 1 : -1,
    },
    {
      column: 'blockchain',
      label: 'Blockchain',
      sort: (a, b) =>
        b?.blockchain?.name?.trim()?.toLowerCase() > a?.blockchain?.name?.trim()?.toLowerCase()
          ? 1
          : -1,
    },
    { column: 'sold', label: 'Token sold' },
    { column: 'received', label: 'Amount received' },
  ];

  return (
    <div>
      <AddSellTransactionDialog
        open={!!txToAddEdit}
        onOk={handleSave}
        onCancel={() => setTxToAddEdit(null)}
        existingTx={txToAddEdit}
        deals={[...deals]}
      />
      <div>
        {data ? (
          <div className="analytics-content__table">
            <SortableTable
              tableHead={tableHead}
              rows={searchedTransactions}
              type="sell-transactions"
            >
              {(sortedRows) => (
                <SellTransactionsRows
                  sellTransactions={sortedRows}
                  handleDelete={handleDelete}
                  deal={deal}
                  setTxToAddEdit={setTxToAddEdit}
                />
              )}
            </SortableTable>
          </div>
        ) : (
          <h1>Loading</h1>
        )}
      </div>
    </div>
  );
};

export default SellTransactions;
