import React from 'react';
import { Dialog } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';

const WhitelistDeleteDialog = ({ whitelist, setWhitelist, onDelete }) => {
  return (
    <Dialog
      open={!!whitelist}
      onClose={() => setWhitelist(null)}
      classes={{ paper: 'whitelists-modal' }}
    >
      <div className="whitelists-modal__header">
        <h2>
          <SvgIcon name="help" />
          <span>Are you sure you want to delete?</span>
        </h2>
        <div>
          <RoundedButton type="transparent" onClick={() => setWhitelist(null)}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={onDelete}>
            Ok
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default WhitelistDeleteDialog;
