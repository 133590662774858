import { getProviderByChainId } from '../../contracts/providers';
import { queryCalls } from 'contracts/multicallContract';
import buildCall from 'contracts/calls';
import { decodeDealInfo, decodePhase } from 'models/common/decoders';
import { parseClaimerModels } from 'models/dealModel';
import { CALL_TYPE } from 'contracts/calls/constants';

export async function enrichWithMetadata(rawDeals) {
  return await Promise.all(
    rawDeals.map(async (rawDeal) => {
      const { deal, dealChainData, currentPhaseIndex, personalCap, ...phaseData } = rawDeal;

      return await getAdminDealMetadata({
        dealApi: deal,
        preFetchedData: { dealChainData, personalCap, ...phaseData },
      });
    })
  );
}

async function getAdminDealMetadata({ dealApi, preFetchedData = {} }) {
  // NOTE: deal has not been deployed yet
  if (!dealApi.address) {
    return dealApi;
  }

  const provider = getProviderByChainId(dealApi.chainId);
  const dealAddress = dealApi.address;

  const whitelists = [];

  const deal = {
    ...dealApi,
    ...parseClaimerModels(dealApi.claimers, dealAddress, null),
    status: (dealApi.vestingStatus ?? dealApi.status).toLowerCase(),
    id: dealApi.id.toString(),
    whitelists,
  };

  if (deal.statusId !== 1) {
    return deal;
  }

  // check if we already have prefetched data
  let dealChainData = preFetchedData.dealChainData;
  let currentPhaseInfo = preFetchedData.currentPhaseInfo;
  let personalCap = preFetchedData.personalCap;

  // if prefetched data is not available, batch dealChainData and currentPhaseInfo
  if (!dealChainData || !currentPhaseInfo) {
    const calls = prepareCalls({ dealChainData, deal, currentPhaseInfo });

    const multicallResults = await queryCalls(provider, calls);

    if (!currentPhaseInfo) {
      currentPhaseInfo = getCurrentPhaseInfo(dealChainData, multicallResults);
    }

    if (!dealChainData) {
      dealChainData = getDealInfo(multicallResults);
    }
  }

  return {
    ...deal,
    ...dealChainData,
    ...currentPhaseInfo,
    personalCap,
  };
}

function getCurrentPhaseInfo(dealChainData, multicallResults) {
  // if dealChainData was fetched, phase info is the second call
  const phaseInfoResultIndex = dealChainData ? 0 : 1;

  const rawPhaseInfo = multicallResults[phaseInfoResultIndex].returnData;

  return decodePhase(rawPhaseInfo);
}

function getDealInfo(multicallResults) {
  const dealInfoResultIndex = 0;

  const rawDealInfo = multicallResults[dealInfoResultIndex].returnData;

  return decodeDealInfo(rawDealInfo);
}

function prepareCalls({ dealChainData, deal, currentPhaseInfo }) {
  const multicallCalls = [];

  if (!dealChainData) {
    multicallCalls.push(buildCall(CALL_TYPE.DEAL_INFO)(deal.address));
  }

  if (!currentPhaseInfo) {
    multicallCalls.push(buildCall(CALL_TYPE.PHASE_INFO)(deal.address));
  }

  return multicallCalls;
}
