import React from 'react';
import useTableColumns from 'screens/Legal/screens/Details/hooks/useTableColumns';
import moment from 'moment';
import AddressLink from 'components/address-link';
import { DEFAULT_CHAIN_NAME } from 'constants/config';
import DealStatus from 'components/deal-status';
import { formatTokenPrice } from 'utils/helpers';
import RiskFactor from 'components/risk-factor';

const VerifiedStatus = ['verification_approved', 'approved'];

const formatDateOrNull = (date) => (date ? moment(date).format('DD-MMM-YY') : null);

const useUsersLegalColumns = () => {
  const columns = [
    {
      column: 'name',
      label: 'Name',
      type: 'string',
      accessor: (row) => row.name,
      value: (row) => row.name,
    },
    {
      column: 'wallet',
      label: 'Wallet',
      type: 'string',
      accessor: (row) => (
        <AddressLink
          chainName={DEFAULT_CHAIN_NAME}
          chainAddress={row.wallet}
          shortAddress={false}
        />
      ),
      value: (row) => row.wallet,
    },
    {
      column: 'country',
      label: 'Country',
      type: 'string',
      accessor: (row) => row.country,
      value: (row) => row.country,
    },
    {
      column: 'kycDate',
      label: 'KYC Date',
      type: 'date',
      accessor: (row) => formatDateOrNull(row.kycDate),
      value: (row) => row.kycDate,
    },
    {
      column: 'kycStatus',
      label: 'KYC Status',
      type: 'string',
      accessor: (row) =>
        VerifiedStatus.includes(row.kycStatus) ? <DealStatus dealStatus="Verified" /> : null,
      value: (row) => row.kycStatus,
    },
    {
      column: 'lastLogin',
      label: 'Last Login Date',
      type: 'date',
      accessor: (row) => formatDateOrNull(row.lastLogin),
      value: (row) => row.lastLogin,
    },
    {
      column: 'lastTransactionDate',
      label: 'Last Tx Date',
      type: 'date',
      accessor: (row) => formatDateOrNull(row.lastContributionDate),
      value: (row) => row.lastContributionDate,
    },
    {
      column: 'lastTransactionAmount',
      label: 'Last Tx Amount',
      type: 'number',
      accessor: (row) =>
        row.lastContributionAmount
          ? formatTokenPrice(row.lastContributionAmount, true, 6, true)
          : null,
      value: (row) => row.lastContributionAmount,
    },
    {
      column: 'riskFactor',
      label: 'Risk Factor',
      type: 'number',
      accessor: (row) => {
        switch (row.riskFactor) {
          case 1:
            return <RiskFactor riskFactor="Low" />;
          case 2:
            return <RiskFactor riskFactor="Medium" />;
          case 3:
            return <RiskFactor riskFactor="High" />;
          default:
            return null;
        }
      },
      value: (row) => row.riskFactor,
    },
  ];

  return useTableColumns(columns);
};

export default useUsersLegalColumns;
