import { addStringAndBigNumberAmounts, subStringAndBigNumberAmounts } from 'utils/helpers';
import * as actionTypes from '../constants';

const getInitialState = () => ({
  accountInfo: {},
  walletAddress: localStorage.getItem('token_wallet'),
  isAdmin: false,
  finalBlockNumber: null,
  idVerified: 'id_not_verified',
  profileWallets: [],
  email: null,
  profileIsUs: false,
  discord: null,
  enabledNotifications: {},
  token: localStorage.getItem('token'),
  cloudMessagingToken: '',
  role: '',
  features: [],
});

function authReducer(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.ACTION_INIT_AUTH:
      return { ...getInitialState() };
    case actionTypes.ACTION_UPDATE_AUTH:
      return { ...state, ...action.payload };
    case actionTypes.ACTION_SET_FINAL_BLOCK_NUMBER:
      return { ...state, finalBlockNumber: action.payload + 8 };
    case actionTypes.ACTION_LOGOUT_USER:
      return { ...state, ...action.payload };
    case actionTypes.ACTION_SET_ID_VERIFIED:
      return { ...state, idVerified: action.payload };
    case actionTypes.ACTION_ADD_PROFILE_WALLET:
      return { ...state, profileWallets: [...state.profileWallets, action.payload] };
    case actionTypes.ACTION_SET_PROFILE_WALLETS:
      return { ...state, profileWallets: action.payload };
    case actionTypes.ACTION_SET_PROFILE_IS_US:
      return { ...state, profileIsUs: action.payload };

    case actionTypes.ACTION_SUB_BDT_BALANCE:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          bdtBalance: subStringAndBigNumberAmounts(
            state.accountInfo.bdtBalance,
            action.payload,
            18
          ),
        },
      };
    case actionTypes.ACTION_ADD_BDT_BALANCE:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          bdtBalance: addStringAndBigNumberAmounts(
            state.accountInfo.bdtBalance,
            action.payload,
            18
          ),
        },
      };
    case actionTypes.ACTION_SUB_USDT_BALANCE:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          usdtBalance: subStringAndBigNumberAmounts(
            state.accountInfo.usdtBalance,
            action.payload,
            6
          ),
        },
      };
    case actionTypes.ACTION_ADD_USDT_BALANCE:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          usdtBalance: addStringAndBigNumberAmounts(
            state.accountInfo.usdtBalance,
            action.payload,
            6
          ),
        },
      };
    case actionTypes.ACTION_RESET_AUTH:
      return { ...getInitialState(), token: null };
    default:
      return state;
  }
}

export default authReducer;
