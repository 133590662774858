import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import CustomSelect from 'components/select/custom-select';
import remote from 'screens/AllDealsSingle/remote';
import './index.scss';

const selectItems = [
  { value: 3, name: 'Closed' },
  { value: 4, name: 'Canceled' },
];

const EditDealStatusModal = ({ open, onClose, deal }) => {
  const findDealStatusId = selectItems.find((item) => item.name === deal.dealStatus?.name)?.value;

  const updateOldDealStatus = remote.useUpdateOldDealStatus(deal.id);
  const [data, setData] = useState({
    statusId: findDealStatusId,
  });
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      await updateOldDealStatus.mutateAsync({
        statusId: data.statusId || null,
      });
      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal basic-metrics-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Deal status</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" buttonType="submit" form="basic-metrics-form">
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="basic-metrics-form" onSubmit={onConfirm}>
        <div className="col col-left">
          <CustomSelect
            items={selectItems}
            value={data.statusId}
            onSelect={(_, value) => onChangeData({ target: { name: 'statusId', value } })}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default EditDealStatusModal;
