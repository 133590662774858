import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import LogInfoTooltip from 'screens/CustomClaims/LogInfoPopper';
import { decimalThousandSeparator, formatAddress } from 'utils';
import { getTransactionLinkByChainName } from 'contracts/explorers';
import utils from 'screens/CustomClaims/utils';
import useTokenName from './hooks/useTokenName';

const HistoryTableRow = ({
  isModalOpen,
  jobName,
  createdAt,
  dealName,
  successfulRun,
  log,
  tokenName,
  claimed,
  chainName,
  chainId,
  tokenAddress,
  txHash,
  dealId,
  isOldDeal,
}) => {
  const { data: fetchedTokenName } = useTokenName(chainId, tokenAddress);
  const history = useHistory();
  const displayTokenName = () => {
    if (tokenName) return tokenName;
    if (fetchedTokenName) return fetchedTokenName;
    return '-';
  };

  const handleRowClick = () => {
    !isModalOpen &&
      history.push(`/all-deals/${dealId}?isOldDeal=${isOldDeal}`, {
        pathname: window.location.pathname,
        activeTab: 'history',
      });
  };

  return (
    <TableRow onClick={handleRowClick}>
      <TableCell>{jobName}</TableCell>
      <TableCell>{utils.formatJobDate(createdAt)}</TableCell>
      <TableCell>
        <p className="deal-name">{dealName}</p>
      </TableCell>
      <TableCell>
        <LogInfoTooltip successfulRun={successfulRun} log={log}>
          <span>{log.substring(0, 64)}</span>
        </LogInfoTooltip>
      </TableCell>
      <TableCell>{displayTokenName()}</TableCell>
      <TableCell>{decimalThousandSeparator(claimed) || claimed}</TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <a
          href={getTransactionLinkByChainName(chainName, txHash)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {formatAddress(txHash)}
        </a>
      </TableCell>
    </TableRow>
  );
};

export default HistoryTableRow;
