import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { getUserToken } from 'services/apiService';
import { updateAuth, updateGlobal, updateSystemPart } from 'store/actions';
import CustomInput from 'components/input/custom-input';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { Divider } from '@material-ui/core';
import { useNotifications } from 'hooks';
import './index.scss';
import { getSystemPartsBaseRoute, SYSTEM_PARTS } from 'features/sidebar/LeftSideBar/utils';
import { useHistory } from 'react-router-dom';

const ViewAsUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [previewAddress, setPreviewAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const { isAdmin, token, walletAddress } = authReducer;
  const { isPreviewMode } = globalReducer;
  const [, showErrorNotification] = useNotifications();

  const updateUserState = (userToken, address, isPreview) => {
    localStorage.setItem('isPreviewMode', isPreview);
    localStorage.setItem('token_wallet', address.toLowerCase());
    localStorage.setItem('token', userToken);
    dispatch(
      updateGlobal({
        isPreviewMode: isPreview,
        userDeals: [],
        oldDeals: [],
        isInitialLoadFinished: false,
      })
    );
    dispatch(
      updateAuth({
        token: userToken,
        walletAddress: address.toLowerCase(),
      })
    );
  };

  const handlePreview = async () => {
    try {
      const { token: responseToken } = await getUserToken(previewAddress);
      localStorage.setItem('adminToken', token);
      localStorage.setItem('adminWallet', walletAddress);
      updateUserState(responseToken, previewAddress, true);
      dispatch(updateSystemPart(SYSTEM_PARTS.DEALS));
      setIsModalOpen(false);
      const redirectRoute = getSystemPartsBaseRoute(SYSTEM_PARTS.DEALS);
      history.push(redirectRoute);
    } catch (error) {
      console.log(error);
      showErrorNotification(error.response?.data);
    }
  };

  const handleChange = (e) => {
    const validAddressRegex = /^(0x){1}[0-9a-fA-F]{40}$/i;
    setIsValidAddress(validAddressRegex.test(e.target.value));
    setPreviewAddress(e.target.value);
  };

  const toggleMode = () => {
    if (!isPreviewMode) {
      setIsModalOpen(true);
      return;
    }
    const adminToken = localStorage.getItem('adminToken');
    const adminWallet = localStorage.getItem('adminWallet');
    localStorage.removeItem('adminToken');
    localStorage.removeItem('adminWallet');
    updateUserState(adminToken, adminWallet, false);
  };

  return (
    <>
      {(isAdmin || isPreviewMode) && (
        <div className="profile-dropdown__popper-settings view-as-user" onClick={toggleMode}>
          <div>
            <span>
              <SvgIcon name="iconVisibility" />
              {isPreviewMode ? 'Switch to admin' : 'View as user'}
            </span>
          </div>
        </div>
      )}
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="view-as-user-modal">
          <div className="view-as-user-modal-header">
            <div className="view-as-user-modal-header__left">
              <SvgIcon name="iconVisibility" />
              <span>View as user</span>
            </div>
            <div className="view-as-user-modal-header__right">
              <RoundedButton onClick={() => setIsModalOpen(false)}>Cancel</RoundedButton>
              <RoundedButton onClick={handlePreview} type="primary" disabled={!isValidAddress}>
                Preview
              </RoundedButton>
            </div>
          </div>
          <Divider />
          <div className="view-as-user-modal-body">
            <CustomInput
              label="Preview address"
              value={previewAddress}
              onChange={handleChange}
              status={isValidAddress ? 'success' : 'error'}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ViewAsUser;
