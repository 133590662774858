import { ethers, utils } from 'ethers';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import IconButton from 'components/button/icon-button';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import CustomSlider from 'components/progress-bar/custom-slider';
import NumberInput from 'components/input/number-input';
import {
  setSharedNotification,
  updateGlobal,
  updateLoading,
  setActiveHashes,
  addNotification,
  updateAuth,
} from 'store/actions';
import notificationTypes from 'constants/notificationTypes';
import { getNetworkNameById, switchCurrentNetwork } from 'contracts/browserWallet';
import { contributeDeal } from 'contracts/dealV2Contract';
import { getAllowance, approveErc20 } from 'contracts/erc20';
import { getProviderByChainId, arbitrumProvider } from 'contracts/providers';
import {
  TRANSFER_PROXY_ADDRESS,
  USDT_ADDRESS,
  ARBITRUM_CHAIN_ID,
  ARBITRUM_CHAIN_NAME,
} from 'constants/config';
import {
  addStringAmounts,
  roundNumberToDecimals,
  subStringAmounts,
  getDealStatusClass,
} from 'utils/helpers';

import './index.scss';

const DealEditRow = ({ deal }) => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.global);
  const authReducer = useSelector((state) => state.auth);
  const [contributionValue, setContributionValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { chainId, activeHashes } = globalReducer;
  const { accountInfo } = authReducer;

  const getErrorMessage = () => {
    if (errorMessage === 'min') return `Min. = ${+deal.minContribution} USDT`;
    if (errorMessage === 'max')
      return `Max. = ${Number(deal.personalCap)
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} USDT`;
    if (errorMessage === 'personalCap')
      return `Unfilled deal balance must be at least ${deal.minContribution} or equal to 0.`;
    if (errorMessage === 'funds') return 'Approve additional allowance';
    return '';
  };

  const onChangeContributionValue = (e) => {
    const { value } = e.target;
    setContributionValue(value);
  };

  const minAmountLeftCondition = () => {
    const raisedAfterContribution = utils
      .parseUnits(deal.raisedAmount, 6)
      .add(utils.parseUnits(contributionValue || '0', 6));
    const dealSizeWei = utils.parseUnits(deal.dealSize, 6);
    const unfilledAmount = dealSizeWei.sub(raisedAfterContribution);
    const oneUsdtWei = utils.parseUnits('1', 2);
    return (
      unfilledAmount.gte(utils.parseUnits(deal.minContribution, 6)) ||
      unfilledAmount.lte(oneUsdtWei)
    );
  };

  const sliderMaxValue = () => {
    const sumAmount = addStringAmounts(deal.personalCap, deal.contributedAmount, 6);
    const rounded = roundNumberToDecimals(sumAmount, 6);

    return Number(rounded);
  };

  const sliderCurrentValue = () => {
    const sumAmount = addStringAmounts(deal.contributedAmount, contributionValue || '0', 6);
    return roundNumberToDecimals(sumAmount, 6).toString();
  };

  useEffect(() => {
    if (Number(contributionValue) < Number(deal.minContribution)) {
      // setContributionValue(Number(deal.minContribution).toString());
      setErrorMessage('min');
    } else if (Number(contributionValue) > Number(deal.personalCap)) {
      setErrorMessage('max');
    } else {
      setErrorMessage('');
    }
    if (!minAmountLeftCondition()) {
      setErrorMessage('personalCap');
    }
    if (Number(accountInfo.usdtAllowance) < Number(contributionValue)) {
      setErrorMessage('allowance');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributionValue]);

  const onChangeContributionSlider = (event, val) => {
    if (val < Number(deal.contributedAmount)) {
      setContributionValue('0');
      return;
    }
    const contrValue = subStringAmounts(val.toString(), deal.contributedAmount, 6);
    setContributionValue(contrValue);
  };

  const onCloseDealModal = () => {
    dispatch(updateGlobal({ activeDeal: null }));
    setContributionValue('');
  };

  const showErrorNotification = (message) => {
    dispatch(
      setSharedNotification({
        status: 'error',
        title: 'Error',
        description: message,
      })
    );
  };

  const handleApprove = async () => {
    const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);
    if (networkChecked) {
      if (+contributionValue === 0) {
        return;
      }

      const tx = await approveErc20(
        USDT_ADDRESS,
        TRANSFER_PROXY_ADDRESS,
        ethers.utils.parseUnits(contributionValue.toString(), 6)
      );

      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            {
              hash: tx.hash,
              pending: false,
              chain: ARBITRUM_CHAIN_NAME,

              callback: async () => {
                const usdtAllowance = await getAllowance(
                  arbitrumProvider,
                  USDT_ADDRESS,
                  accountInfo.address,
                  TRANSFER_PROXY_ADDRESS,
                  6
                );

                dispatch(
                  updateAuth({
                    accountInfo: {
                      ...accountInfo,
                      usdtAllowance,
                    },
                  })
                );
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: ARBITRUM_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.LOCKUP,
          })
        );
      } else {
        showErrorNotification('Something went wrong. Please try again.');
      }
    } else {
      showErrorNotification('You need to change your network to "Ethereum Mainnet" to continue.');
    }
  };

  const callContribute = async () => {
    if (minAmountLeftCondition()) {
      const networkChecked = await switchCurrentNetwork(chainId, deal.chainId);
      if (networkChecked) {
        dispatch(updateLoading(true));
        const tx = await contributeDeal(deal, contributionValue);
        if (!tx) {
          dispatch(
            updateGlobal({
              dealApprovedStatus: 'contributeFailed',
            })
          );
        } else {
          dispatch(
            setActiveHashes([
              ...activeHashes,
              {
                hash: tx.hash,
                pending: false,
                chain: getNetworkNameById(deal.chainId),
                callback: async () => {
                  const usdtAllowance = await getAllowance(
                    getProviderByChainId(deal.chainId),
                    USDT_ADDRESS,
                    accountInfo.address,
                    TRANSFER_PROXY_ADDRESS,
                    6
                  );

                  dispatch(
                    updateAuth({
                      accountInfo: {
                        ...accountInfo,
                        usdtAllowance,
                      },
                    })
                  );
                },
              },
            ])
          );
          dispatch(
            updateGlobal({
              activeDeal: null,
            })
          );
          dispatch(
            addNotification({
              name: tx.hash,
              chain: getNetworkNameById(deal.chainId),
              dealAddress: deal.address,
              dealImage: deal.imageUrl,
              status: 'pending',
              statusText: 'Pending!',
              time: Date.now(),
              type: notificationTypes.GENERAL,
            })
          );
        }
        dispatch(updateLoading(false));
      }
    } else {
      showErrorNotification(
        `Unfilled deal balance must be at least ${deal.minContribution} or equal to 0.`
      );
    }
  };

  return (
    <div className="deal-holder d-flex full-width">
      <div className="deal-row-top">
        <div className="deal__field deal__field-avatar vertical-center">
          <RoundedAvatar src={deal.imageUrl} />
        </div>
        <div className="deal__field deal__field-name vertical-center">
          <div>
            <span>{deal.name}</span>
            <CustomProgressBar total={Number(deal.dealSize)} value={Number(deal.raisedAmount)} />
          </div>
        </div>
        <div
          className={`deal__field deal__field-status deal__field-status--${getDealStatusClass(
            deal.status
          )} vertical-center`}
        >
          <span className="deal__field-status__icon">
            <SvgIcon name="dot" />
          </span>
          <span className="deal__field-status__name">
            {deal.status === 'opened' ? 'live' : deal.status}
          </span>
        </div>
        <div className="deal__field deal__field-modal-bar vertical-center">
          <CustomSlider
            value={sliderCurrentValue()}
            min={0}
            max={sliderMaxValue()}
            onChange={onChangeContributionSlider}
          />
        </div>
        <div className="deal__field deal__field-modal-contribution vertical-center">
          <span className="number-input-holder">
            <NumberInput
              placeholder="0.0"
              value={contributionValue}
              onChange={onChangeContributionValue}
              error={getErrorMessage()}
              decimalNumber="6"
            />
            <div className="max">
              <IconButton
                icon="iconBridgeMax"
                onClick={() =>
                  setContributionValue(
                    +deal.personalCap > +accountInfo.usdtBalance
                      ? accountInfo.usdtBalance
                      : deal.personalCap
                  )
                }
              />
            </div>
          </span>
          <span>USDT</span>
        </div>
        <div className="deal__field deal__field-modal-action vertical-center">
          <RoundedButton onClick={onCloseDealModal}>Cancel</RoundedButton>
          <RoundedButton
            type="secondary"
            disabled={Number(contributionValue) <= Number(accountInfo.usdtAllowance)}
            onClick={handleApprove}
          >
            Approve
          </RoundedButton>
          <RoundedButton
            type="primary"
            disabled={
              Number(contributionValue) < Number(deal.minContribution) ||
              Number(contributionValue) > Number(deal.personalCap) ||
              Number(accountInfo.usdtBalance) < Number(contributionValue) ||
              Number(accountInfo.usdtAllowance) < Number(contributionValue) ||
              !minAmountLeftCondition()
            }
            onClick={callContribute}
          >
            <div className="d-flex">Contribute</div>
          </RoundedButton>
        </div>
      </div>

      <div className="deal-info-mobile show">
        <div className="deal-info-mobile-actions">
          <RoundedButton onClick={onCloseDealModal}>Cancel</RoundedButton>
          <RoundedButton type="secondary" onClick={handleApprove}>
            Approve
          </RoundedButton>
          <RoundedButton
            type="primary"
            disabled={
              Number(contributionValue) < Number(deal.minContribution) ||
              Number(contributionValue) > Number(deal.personalCap) ||
              Number(accountInfo.usdtBalance) < Number(contributionValue) ||
              Number(accountInfo.usdtAllowance) < Number(contributionValue) ||
              !minAmountLeftCondition()
            }
            onClick={callContribute}
          >
            <div className="d-flex">Contribute</div>
          </RoundedButton>
        </div>
      </div>
    </div>
  );
};

DealEditRow.propTypes = {
  deal: PropTypes.shape(),
};

DealEditRow.defaultProps = {
  deal: {},
};

export default React.memo(DealEditRow);
