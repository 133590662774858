import { Divider } from '@material-ui/core';
import Tabs from 'components/tabs';
import React, { useEffect, useState } from 'react';
import { getAllFeatures, getAllRoles, getUsersWithRole } from 'services/apiService';
import './index.scss';
import RoleManagementUsers from 'features/roleManagement/RoleManagementUsers';
import RoleManagmentRoles from 'features/roleManagement/RoleManagmentRoles';
import RoleManagementContract from 'features/roleManagement/RoleManagementContract';

const RoleManagement = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [roles, setRoles] = useState(null);
  const [contractRoles, setContractRoles] = useState(null);
  const [customRoles, setCustomRoles] = useState(null);
  const [users, setUsers] = useState(null);
  const [features, setFeatures] = useState([]);

  const fetchAllFeatures = async () => {
    setFeatures(await getAllFeatures());
  };

  const refetchRoles = async () => {
    const allRoles = await getAllRoles();
    const onlyCustomRoles = allRoles.filter(role => role.isCustom);
    setRoles(allRoles);
    setCustomRoles(onlyCustomRoles);
  };

  const refetchAdmins = async () => {
    setUsers(await getUsersWithRole());
  };

  useEffect(() => {
    refetchRoles();
    refetchAdmins();
    fetchAllFeatures();
  }, []);

  useEffect(() => {
    if (roles) {
      const filteredDealFactoryRoles = roles.filter(role => role?.dealFactoryInfo);
      const dealFactoryRolesInfo = filteredDealFactoryRoles.map(role => ({
        ...role.dealFactoryInfo,
        roleName: role.name,
      }));
      setContractRoles(dealFactoryRolesInfo);
    }
  }, [roles]);

  if (!roles) return null;

  return (
    <div className="user-management">
      <div className="user-management__header">
        <h2>User Management</h2>
        <span>
          {users ? users.length : 0} {users && users.length === 1 ? 'User' : 'Users'}
        </span>
      </div>
      <Divider />
      <div className="user-management__content">
        <Tabs
          tabs={[
            {
              id: 1,
              label: 'User',
              content: (
                <RoleManagementUsers users={users} roles={roles} refetchUsers={refetchAdmins} />
              ),
            },
            {
              id: 2,
              label: 'Roles',
              content: <RoleManagmentRoles roles={customRoles} refetchRoles={refetchRoles} refetchUsers={refetchAdmins} features={features} />,
            },
            {
              id: 3,
              label: 'Contract',
              content: <RoleManagementContract contractRoles={contractRoles} />,
            },
          ]}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </div>
    </div>
  );
};

export default RoleManagement;
