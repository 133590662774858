import React from 'react';
import { Tooltip } from '@material-ui/core';
import IconButton from 'components/button/icon-button';

const CustomClaimTooltipButton = ({ title, action, icon, disabled = false, className = '' }) => {
  return (
    <Tooltip
      title={title}
      classes={{
        tooltip: 'action-tooltip',
      }}
    >
      <div className="tooltip-wrapper">
        <IconButton onClick={action} icon={icon} disabled={disabled} className={className} />
      </div>
    </Tooltip>
  );
};

export default CustomClaimTooltipButton;
