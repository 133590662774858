import { Box, TableCell } from '@material-ui/core';
import SortableTable from 'components/sortable-table';
import React, { useState } from 'react';
import SearchInput from 'components/filters/SearchInput';
import SelectAllCheckbox from './SelectAllCheckbox';
import tableHead from '../utils/getTableHead';
import BalanceTableRow from './BalanceTableRow';
import './index.scss';
import TransferButton from '../TransferButton';
import BalancesFilters from '../BalancesFilters';
import Tabs from '../Tabs';
import useFilters from '../hooks/useFilters';
import filtersPipeline from '../utils/filtersPipeline';

const BalancesTable = ({ selectTab, selectedTab, balances }) => {
  const [isSelectAllClaimersChecked, setIsSelectAllClaimersChecked] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const { filters, actions } = useFilters(balances);
  const filteredBalances = filtersPipeline(balances, filters);

  const [selectedClaimers, setSelectedClaimers] = useState([]);

  if (selectedTab !== 'wallets') return null;

  const ActionHeaderCellComponent = () => {
    return (
      <TableCell>
        <SelectAllCheckbox
          balances={filteredBalances}
          setSelectedClaimers={setSelectedClaimers}
          setIsSelectAllClaimersChecked={setIsSelectAllClaimersChecked}
          isSelectAllClaimersChecked={isSelectAllClaimersChecked}
        />
      </TableCell>
    );
  };

  return (
    <>
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">Wallets</div>
          <div className="deals-header__deals-cnt vertical-center">
            <span>{filteredBalances.length} Total</span>
          </div>
          <TransferButton selectedClaimers={selectedClaimers} balances={filteredBalances} />
        </div>
        <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
          <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
            <SearchInput
              searchValue={filters.search}
              updateSearchValue={actions.dispatchUpdateSearch}
              showInput={showInput}
              setShowInput={setShowInput}
            />
          </div>
          <Box display="flex" alignItems="center" ml={1}>
            <BalancesFilters filters={filters} actions={actions} />
          </Box>
        </div>
      </div>
      <Tabs selectTab={selectTab} selectedTab={selectedTab} />
      <div className="balances-table">
        <div className="distro-container">
          <SortableTable
            tableHead={tableHead.balancesTableHead}
            rows={filteredBalances}
            type="balances"
            actionHeaderCellComponent={<ActionHeaderCellComponent />}
            classNames={['with-empty-col']}
          >
            {(sortedRows) =>
              sortedRows.map((row) => (
                <BalanceTableRow
                  key={`${row.dealName}-${row.walletId}-${row.claimerId}`}
                  row={row}
                  selectedClaimers={selectedClaimers}
                  setSelectedClaimers={setSelectedClaimers}
                  setIsSelectAllClaimersChecked={setIsSelectAllClaimersChecked}
                />
              ))
            }
          </SortableTable>
        </div>
      </div>
    </>
  );
};

export default BalancesTable;
