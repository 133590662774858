const getTruthyFields = (filter) =>
  Object.entries(filter)
    .filter((entry) => Boolean(entry[1]))
    .map((el) => el[0].toLowerCase());

const applySearchFilter = (search, balance) => {
  return balance.dealName.toLowerCase().includes(search.toLowerCase());
};

const applyDealStatusFilter = (statuses, balance) => {
  if (!statuses.length) return true;
  return statuses.includes(balance.dealStatus.toLowerCase());
};

const filtersPipeline = (deals, filters) => {
  if (!deals) return [];

  const appliedDealStatuses = getTruthyFields(filters.dealStatus);

  const applyFilters = [
    (deal) => applySearchFilter(filters.search, deal),
    (deal) => applyDealStatusFilter(appliedDealStatuses, deal),
  ];

  const filterResult = applyFilters.reduce(
    (filteredDeals, filterFunc) => filteredDeals.filter(filterFunc),
    deals
  );

  return filterResult;
};

export default filtersPipeline;
