import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import remote from '../remote';
import '../index.scss';
import Tabs from '../Tabs';

const shortenAddress = (address) => {
  const firstPart = address.slice(0, 5);
  const lastPart = address.slice(address.length - 4, address.length);
  return `${firstPart}...${lastPart}`;
};

const AssignedWalletTable = ({ selectTab, selectedTab }) => {
  const { data: wallets } = remote.useGetAllAssignedWallets();

  if (!wallets || selectedTab !== 'assigned-wallets') return null;

  return (
    <>
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">Assigned Wallets</div>
          <div className="deals-header__deals-cnt vertical-center">
            <span>{wallets.length} Total</span>
          </div>
        </div>
      </div>
      <Tabs selectTab={selectTab} selectedTab={selectedTab} />
      <div className="distro-container">
        <TableContainer classes={{ root: 'distro-table' }}>
          <Table className="sticky-table-header">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Seed name</TableCell>
                <TableCell>Seed path</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {wallets.map(({ id, name, address, seed: { name: seedName }, path }) => (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{shortenAddress(address)}</TableCell>
                  <TableCell>{seedName}</TableCell>
                  <TableCell>{path}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default AssignedWalletTable;
