import React from 'react';
import { Dialog, DialogContent, Divider } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomInput from 'components/input/custom-input';

const EditUserDataDialog = ({ userInfo, setUserInfo, onSave }) => {
  return (
    <Dialog
      open={!!userInfo}
      onClose={() => setUserInfo(null)}
      classes={{ paper: 'whitelists-modal' }}
    >
      <div className="whitelists-modal__header">
        <h2>
          <SvgIcon name="iconDealsModalEdit" />
          <span>Edit</span>
        </h2>
        <div>
          <RoundedButton type="transparent" onClick={() => setUserInfo(null)}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={onSave}>
            Save
          </RoundedButton>
        </div>
      </div>
      <DialogContent>
        <Divider />
        <div className="deal-edit-modal__content whitelist-content">
          <CustomInput
            value={userInfo?.address}
            disabled
            status="success"
            className="whitelists-modal__name-input"
            label="Address"
          />
          <CustomInput
            value={userInfo?.adminName || ''}
            onChange={(e) => setUserInfo((prev) => ({ ...prev, adminName: e.target.value }))}
            className="whitelists-modal__name-input"
            label="Name"
          />
          <div className="field-set">
            <span>Note</span>
            <textarea
              onChange={(e) => setUserInfo((prev) => ({ ...prev, note: e.target.value }))}
              value={userInfo?.note || ''}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditUserDataDialog;
