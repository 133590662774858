import { utils } from 'ethers';
import { addStringAndBigNumberAmounts } from 'utils/helpers';
import DealArtifact from './abis/Deal.json';
import LockArtifact from './abis/Lock.json';
import ClaimerFactoryArtifact from './abis/ClaimerFactory.json';
import { getUserLevel } from '../models/userLevelModel';

const dealInterface = new utils.Interface(DealArtifact.abi);
const lockInterface = new utils.Interface(LockArtifact.abi);
const claimerFactoryInterface = new utils.Interface(ClaimerFactoryArtifact.abi);

export function contributeUpdateDeal(deal, rawLog, walletAddress, personalCap) {
  const log = dealInterface.parseLog(rawLog);

  deal.raisedAmount = addStringAndBigNumberAmounts(deal.raisedAmount, log.args.amount, 6);

  if (log.args.addr.toLowerCase() === walletAddress.toLowerCase()) {
    deal.contributedAmount = addStringAndBigNumberAmounts(
      deal.contributedAmount,
      log.args.amount,
      6
    );
  }

  deal.personalCap = personalCap;
}

export function startUnlockUpdateAccountInfo(accountInfo, rawLog) {
  const log = lockInterface.parseLog(rawLog);

  if (log.args.addr.toLowerCase() === accountInfo.address.toLowerCase()) {
    accountInfo.lockInfo.releaseTime = log.args.releaseTime.toString();
  }
  accountInfo.userAccessLevel = -1;
}

export function lockUpdateAccountInfo(accountInfo, rawLog) {
  const log = lockInterface.parseLog(rawLog);

  if (log.args.addr.toLowerCase() === accountInfo.address.toLowerCase()) {
    accountInfo.lockInfo.releaseTime = log.args.releaseTime.toString();
    accountInfo.lockInfo.amount = addStringAndBigNumberAmounts(
      accountInfo.lockInfo.amount,
      log.args.amount,
      18
    );

    accountInfo.userAccessLevel = getUserLevel(accountInfo.lockInfo.amount);
    accountInfo.userViewLevel =
      accountInfo.lockInfo.releaseTime > Date.now() / 1000
        ? getUserLevel(accountInfo.lockInfo.amount)
        : -1;
  }

  accountInfo.tvl = addStringAndBigNumberAmounts(accountInfo.tvl, log.args.amount, 18);

  accountInfo.proRataShare = (
    (parseFloat(accountInfo.lockInfo.amount) / parseFloat(accountInfo.tvl)) *
    100
  ).toFixed(4);
}

export function unlockUpdateAccountInfo(accountInfo, rawLog) {
  const log = lockInterface.parseLog(rawLog);

  if (log.args.addr.toLowerCase() === accountInfo.address.toLowerCase()) {
    accountInfo.lockInfo.releaseTime = '0';
    accountInfo.lockInfo.amount = '0.0';

    accountInfo.userAccessLevel = -1;
    accountInfo.userViewLevel = -1;
  }

  accountInfo.tvl = addStringAndBigNumberAmounts(accountInfo.tvl, log.args.amount, 18);

  accountInfo.proRataShare = (
    (parseFloat(accountInfo.lockInfo.amount) / parseFloat(accountInfo.tvl)) *
    100
  ).toFixed(4);
}

export function ownerUnlockedUpdateAccountInfo(accountInfo, rawLog) {
  const log = lockInterface.parseLog(rawLog);

  accountInfo.isOwnerUnlocked = log.args.unlocked;
}

export function claimerCreatedUpdateDeals(deals, rawLog, chain) {
  const log = claimerFactoryInterface.parseLog(rawLog);

  const deal = deals.find((d) => d.address === log.args.dealAddress);
  if (!deal) {
    return;
  }

  if (chain === 'ethereum') {
    deal.ethClaimerAddress = log.args.claimerAddress;
    deal.ethClaimerCreationBlock = rawLog.blockNumber;
  }

  if (chain === 'bsc') {
    deal.bscClaimerAddress = log.args.claimerAddress;
    deal.bscClaimerCreationBlock = rawLog.blockNumber;
  }

  if (chain === 'polygon') {
    deal.polygonClaimerAddress = log.args.claimerAddress;
    deal.polygonClaimerCreationBlock = rawLog.blockNumber;
  }

  if (chain === 'avalanche') {
    deal.avalancheClaimerAddress = log.args.claimerAddress;
    deal.avalancheClaimerCreationBlock = rawLog.blockNumber;
  }

  if (chain === 'base') {
    deal.baseClaimerAddress = log.args.claimerAddress;
    deal.baseClaimerCreationBlock = rawLog.blockNumber;
  }

  if (chain === 'merlin') {
    deal.merlinClaimerAddress = log.args.claimerAddress;
    deal.merlinClaimerCreationBlock = rawLog.blockNumber;
  }

  if (chain === 'arbitrum') {
    deal.arbitrumClaimerAddress = log.args.claimerAddress;
    deal.arbitrumClaimerCreationBlock = rawLog.blockNumber;
  }

  return deal;
}
