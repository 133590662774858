import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popper } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import IconButton from 'components/button/icon-button';
import { CSVLink } from 'react-csv';
import { ethers } from 'ethers';

const csvHeader = ['MM/YYYY', 'Report No', 'Amount USD', 'Amount HRK', 'Report Date'];

const BookkeepingHeader = ({ reports, onChange }) => {
  const buttonRef = useRef();
  const csvExportRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportData, setExportData] = useState([]);

  const toggleInput = () => {
    if (window.innerWidth < 767) {
      setShowInput(!showInput);
    }
  };

  const getFilteredReports = useCallback(() => {
    if (!value && !startDate && !endDate) {
      return reports;
    }

    return reports.filter(({ month, year }) => {
      const date = new Date(year, month - 1);

      return (
        `${month}/${year}`.includes(value) &&
        (!startDate || date >= startDate) &&
        (!endDate || date < endDate)
      );
    });
  }, [reports, value, startDate, endDate]);

  useEffect(() => {
    onChange(getFilteredReports());
  }, [onChange, getFilteredReports]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
    });
  }, [setShowInput]);

  const handleCsvDownload = async () => {
    setExportData(
      getFilteredReports().map(({ year, month, number, totalFeeHrk, totalFeeUsd, date }) => [
        `${month}/${year}`,
        number && `${year}/${number}`,
        totalFeeUsd && ethers.utils.formatUnits(totalFeeUsd, 6),
        totalFeeHrk && ethers.utils.formatUnits(totalFeeHrk, 6),
        date,
      ])
    );
    setTimeout(() => {
      csvExportRef.current.link.click();
      setExportData([]);
    }, 0);
  };

  return (
    <div className="invoices__header">
      <div className="invoices__title-container">
        <h1>Invoices</h1>
        <span>{reports.length} Total</span>
        <div onClick={handleCsvDownload} className="invoices__export-container">
          <SvgIcon name="invoiceExport" />
          <span>Export</span>
        </div>
      </div>
      <div className="invoices__filter-container">
        <CSVLink
          ref={csvExportRef}
          data={[csvHeader, ...exportData]}
          style={{ display: 'none' }}
          filename="bookkeeping_reports.csv"
        >
          Export basic analytics to CSV
        </CSVLink>
        <div className={`search-input${showInput ? ' active' : ''}`}>
          <div className="input-icon-wrapper vertical-center" onClick={toggleInput}>
            <SvgIcon name="search" />
          </div>
          <input
            type="text"
            value={value}
            placeholder="Search invoices by name"
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="invoice-filter-picker" ref={buttonRef}>
          <RoundedButton onClick={() => setIsOpen((prev) => !prev)}>
            <SvgIcon name="filter" />
            <span>Filter</span>
          </RoundedButton>
          <Popper open={isOpen} anchorEl={buttonRef.current} placement="bottom-end">
            <div className="invoice-filter-picker__dropdown">
              <span>Invoice created</span>
              <div className="date-picker-wrapper">
                {startDate && (
                  <div className="close-icon">
                    <IconButton icon="iconDeleteX" onClick={() => setStartDate(null)} />
                  </div>
                )}
                <DatePicker
                  selected={startDate}
                  onChange={setStartDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start date"
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                />
              </div>
              <div className="date-picker-wrapper">
                {endDate && (
                  <div className="close-icon">
                    <IconButton icon="iconDeleteX" onClick={() => setEndDate(null)} />
                  </div>
                )}
                <DatePicker
                  selected={endDate}
                  onChange={setEndDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End date"
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                />
              </div>
            </div>
          </Popper>
        </div>
      </div>
    </div>
  );
};

export default BookkeepingHeader;
