import React, { useState } from 'react';

import LogTab from 'features/deals/LogTab';
import { useSelector } from 'react-redux';
import DealInfoTab from './DealInfoTab';
import PledgeTab from './PledgeTab';
import InvoiceTab from './InvoiceTab';
import SellTransactionsTab from './SellTransactionTab';
import VestingTab from './VestingTab';
import AdminTab from './AdminTab';

const DealInfoDesktop = ({ deal, showDropdown }) => {
  const [activeInfoTab, setActiveInfoTab] = useState('info');
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin } = authReducer;

  return (
    <div className={`deal-info-desktop${showDropdown ? ' show' : ''}`}>
      <div className="deal-info-tabs">
        <div
          className={`deal-info-tab${activeInfoTab === 'info' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('info')}
        >
          DEAL INFO
        </div>
        <div
          className={`deal-info-tab${activeInfoTab === 'pledge' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('pledge')}
        >
          PLEDGE
        </div>
        <div
          className={`deal-info-tab${activeInfoTab === 'log' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('log')}
        >
          CHANGE LOG
        </div>
        {deal.invoiceId && (
          <div
            className={`deal-info-tab${activeInfoTab === 'invoice' ? ' active' : ''}`}
            onClick={() => setActiveInfoTab('invoice')}
          >
            INVOICE
          </div>
        )}
        {[3, 4, 5].includes(deal.statusId) && (
          <div
            className={`deal-info-tab${activeInfoTab === 'sell-transactions' ? ' active' : ''}`}
            onClick={() => setActiveInfoTab('sell-transactions')}
          >
            SELL TRANSACTIONS
          </div>
        )}
        <div
          className={`deal-info-tab${activeInfoTab === 'vesting' ? ' active' : ''}`}
          onClick={() => setActiveInfoTab('vesting')}
        >
          VESTING
        </div>
        {isAdmin && (
          <div
            className={`deal-info-tab${activeInfoTab === 'vesting' ? ' active' : ''}`}
            onClick={() => setActiveInfoTab('admin')}
          >
            ADMIN
          </div>
        )}
      </div>
      <div className="deal-info-content">
        {activeInfoTab === 'info' && <DealInfoTab deal={deal} open={showDropdown} />}
        {activeInfoTab === 'pledge' && <PledgeTab deal={deal} />}
        {activeInfoTab === 'log' && <LogTab deal={deal} />}
        {activeInfoTab === 'invoice' && <InvoiceTab deal={deal} />}
        {activeInfoTab === 'sell-transactions' && <SellTransactionsTab deal={deal} />}
        {activeInfoTab === 'vesting' && <VestingTab deal={deal} readOnly />}
        {activeInfoTab === 'admin' && isAdmin && <AdminTab deal={deal} />}
      </div>
    </div>
  );
};

export default DealInfoDesktop;
