import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiGet, apiPost, apiPut, baseUrl } from 'services/apiService';

const getAllStatusInfos = async () => {
  const response = await apiGet(`${baseUrl}/api/deal/status-info`);
  return response.data;
};

const useGetAllStatusInfos = () => {
  return useQuery(['deal', 'status-info'], getAllStatusInfos, { placeholderData: [] });
};

const createNewStatusInfo = async (name) => {
  const response = await apiPost(`${baseUrl}/api/deal/status-info`, {
    name,
  });
  return response.data;
};

const useCreateNewStatusInfo = () => {
  const queryClient = useQueryClient();

  return useMutation(({ name }) => createNewStatusInfo(name), {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', 'status-info']);
    },
  });
};

const getDealStatusInfo = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/${dealId}/status-info`);
  return response.data;
};

const useGetDealStatusInfo = (dealId) => {
  return useQuery(['deal', dealId, 'status-info'], () => getDealStatusInfo(dealId));
};

const updateDealStatusInfo = async (dealId, statusInfoId) => {
  const response = await apiPut(`${baseUrl}/api/deal/${dealId}/status-info`, {
    statusInfoId,
  });
  return response.data;
};

const useUpdateDealStatusInfo = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(({ statusInfoId }) => updateDealStatusInfo(dealId, statusInfoId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', dealId, 'status-info']);
      queryClient.invalidateQueries(['distribution', 'deals', dealId, false]);
    },
  });
};

export default {
  useGetAllStatusInfos,
  useCreateNewStatusInfo,
  useGetDealStatusInfo,
  useUpdateDealStatusInfo,
};
