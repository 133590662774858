import { ClickAwayListener, Divider, Popper } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import CheckboxAccordion from 'components/filters/CheckboxAccordion';
import RadioButtonsAccordion from 'components/filters/RadioButtonsAccordion';
import SwitchInput from 'components/filters/SwitchInput';
import SvgIcon from 'components/svgIcon';
import React, { useState } from 'react';
import './index.scss';

const jobStatusOptions = ['all', 'active', 'paused'];

const CustomClaimsFilters = ({ filters, actions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'custom-claims-popper' : undefined;

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div className="custom-claims-filter">
        <RoundedButton onClick={handleClick}>
          <SvgIcon name="filter" />
          <span>Filter</span>
        </RoundedButton>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <div className="custom-claims-popper-dropdown">
            <CheckboxAccordion
              title="Deal Status"
              options={filters.dealStatus}
              updateOptions={actions.dispatchUpdateDealStatus}
              className="gray-filter-options"
            />
            <Divider />
            <CheckboxAccordion
              title="Blockchain"
              options={filters.blockchain}
              updateOptions={actions.dispatchUpdateBlockchain}
              className="gray-filter-options"
            />
            <Divider />
            <RadioButtonsAccordion
              title="Job status"
              selectedValue={filters.jobStatus}
              options={jobStatusOptions}
              updateOptions={actions.dispatchUpdateJobStatus}
              className="gray-filter-options"
            />
            <Divider />
            <SwitchInput
              title="Successful run"
              value={filters.successfulRun}
              updateValue={actions.dispatchUpdateSuccessfulRun}
            />
            <Divider />
            <SwitchInput
              title="Unsuccessful run"
              value={filters.unsuccessfulRun}
              updateValue={actions.dispatchUpdateUnsuccessfulRun}
            />
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default CustomClaimsFilters;
