import React from 'react';
import './index.scss';
import useRiskReportData from 'screens/Legal/screens/Details/hooks/useRiskReportData';
import { RiskReportType } from 'screens/Legal/screens/Details/hooks/constants';
import DownloadReportButton from 'screens/Legal/components/DownloadReportButton';
import InfoSection from 'screens/Legal/components/sections/InfoSection';

const AppendixThree = ({ address }) => {
  const { data: riskReportData } = useRiskReportData(RiskReportType.AppendixThree, address);

  if (!riskReportData) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '20px',
      }}
    >
      <DownloadReportButton type={RiskReportType.AppendixThree} address={address} />
      <section className="deal-info-section grid">
        <InfoSection title="Stranka" data={riskReportData.client} />
        <InfoSection title="Ocjena Rizika" data={riskReportData.assesment} />
        <InfoSection title="Dubinska Analiza" data={riskReportData.deeperAnalysis} />
        <InfoSection title="Završni Podaci" data={riskReportData.txData} />
      </section>
    </div>
  );
};

export default AppendixThree;
