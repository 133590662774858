import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox as MUICheckbox } from '@material-ui/core';

const GreenCheckbox = withStyles({
  root: {
    color: '#bebebe',
    '&$checked': {
      color: '#029e80',
    },
    padding: 0,
  },
  checked: {},
})((props) => <MUICheckbox color="default" {...props} />);

const Checkbox = (props) => {
  return <GreenCheckbox {...props} />;
};

export default Checkbox;
