import {
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_MEASURMENT_ID,
  FIREBASE_APP_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_API_KEY,
  FIREBASE_VAPID_KEY,
} from 'constants/config';
import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken as firebaseGetToken,
  onMessage,
  isSupported,
} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASURMENT_ID,
};

initializeApp(firebaseConfig);

export const getToken = async () => {
  if (!(await isSupported())) throw new Error('Not supported');
  const messaging = getMessaging();
  return firebaseGetToken(messaging, {
    vapidKey: FIREBASE_VAPID_KEY,
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage((payload) => {
      resolve(payload);
    });
  });
