function isValidDate(date) {
  const dateWrapper = new Date(date);
  return !Number.isNaN(dateWrapper.getDate());
}

const formatJobDate = (date) => {
  if (!isValidDate(date)) return date;
  const splitDateAndTime = date.split('T');
  const formatedDate = splitDateAndTime[0];
  const formattedTime = splitDateAndTime[1].substring(0, 5);
  return `${formatedDate} ${formattedTime}`;
};

export default { formatJobDate, isValidDate };
