import React, { useRef, useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import ImageCrop from 'components/imageCrop';
import { uploadImage } from 'services/apiService';
import { useNotifications } from 'hooks';
import './index.scss';

const AddDraftModal = ({ onOk, onCancel }) => {
  const cropInputRef = useRef();
  const [, showErrorNotification] = useNotifications();
  const [data, setData] = useState({
    imageUrl: '',
    name: '',
  });

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async () => {
    const newDeal = {
      imageUrl: data.imageUrl,
      name: data.name,
      statusId: 6,
      address: null,
      overview: '',
      highlights: [],
      ticker: '',
      tokenType: '',
      tge: '',
      hardCap: null,
      initCircSupply: null,
      initMarketCap: null,
      totalySupply: null,
      fullyDilutedMarketCap: null,
      vesting: '',
      projectLinks: [],
      userHidden: false,
      isV2: true,
    };
    await onOk({ ...newDeal });
  };

  const onImageUpload = async (file) => {
    const imageData = new FormData();
    imageData.append('image', file);
    try {
      const { path } = await uploadImage(imageData);

      setData({
        ...data,
        imageUrl: path,
      });
    } catch {
      showErrorNotification(`Something went wrong while uploading image`);
    }
  };

  return (
    <div className="bd-modal">
      <div className="bd-modal-inner draft-modal-inner">
        <div className="bd-modal-header">
          <RoundedButton onClick={onCancel}>Cancel</RoundedButton>
          <RoundedButton type="secondary" onClick={onConfirm}>
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
        <div className="bd-modal-body">
          <div className="input-holder">
            <ImageCrop onImageCropped={onImageUpload} label="Deal photo" inputRef={cropInputRef} />
          </div>
          <div className="input-holder">
            <label>Deal name:</label>
            <input name="name" value={data.name} onChange={onChangeData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDraftModal;
