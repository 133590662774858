import { Dialog } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import CustomInput from 'components/input/custom-input';
import React, { useEffect, useState } from 'react';

const NameEditModal = ({ open, name, onOk, onCancel }) => {
  const [newName, setNewName] = useState(name);

  useEffect(() => {
    setNewName(name);
  }, [open, name]);

  return (
    <Dialog open={open} className="bd-modal" onClose={onCancel}>
      <div className="bd-modal-inner">
        <div className="bd-modal-header">
          <RoundedButton onClick={onCancel}>Cancel</RoundedButton>
          <RoundedButton type="secondary" onClick={() => onOk(newName)}>
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
        <div className="bd-modal-form">
          <CustomInput name="name" value={newName} onChange={(e) => setNewName(e.target.value)} />
        </div>
      </div>
    </Dialog>
  );
};

export default NameEditModal;
