import React from 'react';
import './index.scss';

const MiscContainer = ({ title, children, className = '', actionButton = null }) => {
  return (
    <div className={`misc-container ${className}`}>
      <div className="misc-header">
        <h3 className="misc-container-title">{title}</h3>
        {actionButton}
      </div>
      {children}
    </div>
  );
};

export default MiscContainer;
