import {
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import React from 'react';
import PepRelationshipInfo from '../PepRelationshipInfo';

function PepDetails({ values, errors, onChange }) {
  const details = [
    {
      value: 'relatedPepPoliticalPosition',
      label: 'I am a politically exposed person because (please state your political position):',
      component: null,
    },
    {
      value: 'relatedPepRelationship',
      label:
        'I am a family member or close associate of a politically exposed person because (please describe the relationship):',
      component: <PepRelationshipInfo values={values} errors={errors} onChange={onChange} />,
    },
  ];

  const dependentFields = details.map(({ value }) => value);

  return (
    <div style={{ marginLeft: '16px' }}>
      <RadioGroup
        aria-label="politically-exposed"
        name="pepDetails"
        value={values.pepDetails}
        onChange={(event) => onChange(event, dependentFields)}
      >
        {details.map(({ value, label, component }) => (
          <FormControl key={value} margin="dense" fullWidth error={!!errors?.[value]}>
            <FormControlLabel value={value} control={<Radio />} label={label} />
            {values.pepDetails === value && (
              <>
                <TextField
                  fullWidth
                  margin="dense"
                  id={values.pepDetails}
                  name={values.pepDetails}
                  variant="filled"
                  value={values[values.pepDetails]}
                  onChange={onChange}
                />
                {errors[values.pepDetails] ? (
                  <FormHelperText>This field is required.</FormHelperText>
                ) : null}
                {component}
              </>
            )}
          </FormControl>
        ))}
        {details.some(({ value }) => errors[value]) && !values.pepDetails && (
          <p className="pep-error">Select the reason why you are a politically exposed person.</p>
        )}
      </RadioGroup>
    </div>
  );
}

export default PepDetails;
