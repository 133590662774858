import React from 'react';
import getParsedBatchScheduleRows from 'components/distributions/utils/getParsedBatchScheduleRows';
import getParsedHistoryRows from 'components/distributions/utils/getParsedHistoryRows';
import BatchScheduleTable from 'components/distributions/BatchScheduleTable';
import HistoryTable from 'components/distributions/HistoryTable';
import remote from 'screens/AllDealsSingle/remote';
import './index.scss';

const Distributions = ({ dealId, isOldDeal }) => {
  const { data: batchSchedule } = remote.useGetDealSchedule(dealId, isOldDeal);
  const { data: dealHistory } = remote.useGetDealHistory(dealId, isOldDeal);

  const parsedBatchSchedule = getParsedBatchScheduleRows(batchSchedule);
  const parsedHistory = getParsedHistoryRows(dealHistory);

  return (
    <>
      <div className="fixed-table">
        <HistoryTable historyRows={parsedHistory} type="history-distributions-fixed" />
      </div>
      <div className="fixed-table">
        <BatchScheduleTable
          batchScheduleRows={parsedBatchSchedule}
          type="batch-schedule-distributions-fixed"
        />
      </div>
    </>
  );
};

export default Distributions;
