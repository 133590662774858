import { useDispatch } from 'react-redux';
import { setSharedNotification } from 'store/actions';

const useNotifications = () => {
  const dispatch = useDispatch();

  const showErrorNotification = (message) => {
    dispatch(
      setSharedNotification({
        status: 'error',
        title: 'Error',
        description: message,
      })
    );
  };

  const showSuccessNotification = (message, isWaiting = false) => {
    dispatch(
      setSharedNotification({
        status: 'success',
        title: isWaiting ? 'Waiting' : 'Success',
        description: message,
      })
    );
  };

  return [showSuccessNotification, showErrorNotification];
};

export default useNotifications;
