import React from 'react';
import getParsedHistoryRows from 'components/distributions/utils/getParsedHistoryRows';
import HistoryTable from 'components/distributions/HistoryTable';
import remote from 'screens/AllDealsSingle/remote';

const History = ({ dealId, isOldDeal }) => {
  const { data: dealHistory } = remote.useGetDealHistory(dealId, isOldDeal);

  const parsedHistory = getParsedHistoryRows(dealHistory);

  return <HistoryTable historyRows={parsedHistory} type="history-distributions" />;
};

export default History;
