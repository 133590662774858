import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Logo from 'assets/logo.svg';
import { DashboardPreview } from 'constants/index';
import DisclaimModal from 'features/login/DisclaimModal';
import RoundedButton from 'components/button/rounded-button';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { ethers } from 'ethers';
import { login } from 'services/apiService';
import { setAlertNotification, updateAuth } from 'store/actions';

function Login({ loadWeb3Modal, logoutOfWeb3Modal }) {
  const authReducer = useSelector((state) => state.auth);
  const [isPendingSignature, setIsPendingSignature] = useState(false);
  const [disclaimModalOpen, setDisclaimModalOpen] = useState(false);
  const { walletAddress } = authReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (walletAddress && isPendingSignature) {
        setIsPendingSignature(false);
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signedMessage = await provider.getSigner().signMessage('Enter The BlackDragon');
          const { token: responseToken, exp } = await login(signedMessage);
          localStorage.setItem('token', responseToken);
          localStorage.setItem('token_exp', exp);
          localStorage.setItem('token_wallet', walletAddress);
          dispatch(updateAuth({ token: responseToken }));
        } catch (err) {
          logoutOfWeb3Modal();
          dispatch(
            setAlertNotification({
              status: 'error',
              title: 'Error',
              description: err.message,
            })
          );
        }
      }
    })();
  }, [walletAddress, dispatch, logoutOfWeb3Modal, isPendingSignature]);

  const onLogin = () => {
    setIsPendingSignature(true);
    loadWeb3Modal();
  };

  const onToggleDisclaimModal = () => {
    setDisclaimModalOpen(!disclaimModalOpen);
  };

  return (
    <>
      <DisclaimModal open={disclaimModalOpen} onClose={onToggleDisclaimModal} onConfirm={onLogin} />
      <div className="auth-container">
        <div className="login-left">
          <div className="login-left-content">
            <div className="logo-container">
              <img src={Logo} alt="logo" />
            </div>
            <div className="get-started">
              <h1 className="header1">Sign in to BlackDragon</h1>
              <h4 className="header4">MetaMask is locked or not connected!</h4>
              <h4 className="header4">Please click Connect Account button to proceed.</h4>
              <RoundedButton
                className="login-btn"
                type="secondary"
                onClick={() => setDisclaimModalOpen(true)}
              >
                <span className="button-big">Connect Account</span>
              </RoundedButton>
            </div>
          </div>
        </div>
        <div className="login-right">
          <div className="dashboard-preview">
            <img src={DashboardPreview} alt="dashboard-preview" />
          </div>
        </div>
        <div className="login-gradient-bg" />
      </div>
    </>
  );
}

Login.propTypes = {
  loadWeb3Modal: PropTypes.func,
};

Login.defaultProps = {
  loadWeb3Modal: () => {},
};

export default Login;
