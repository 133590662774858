import React, { useEffect, useState } from 'react';
import { Dialog, Divider } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import './index.scss';
import CustomSelect from 'components/select/custom-select';
import CustomInput from 'components/input/custom-input';
import DatePicker from 'react-datepicker';

const changeLogTypes = [
  { id: 0, name: 'Basic metric', value: 'BASIC_METRIC_CHANGES' },
  { id: 1, name: 'Blackdragon deal', value: 'BLACKDRAGON_DEAL_CHANGES' },
  { id: 2, name: 'Raise stats', value: 'RAISE_STATS_CHANGES' },
];

const ChangeLogAddEditModal = ({ open, onOk, onClose, log }) => {
  const [logToSave, setLogToSave] = useState(null);

  useEffect(() => {
    setLogToSave(log ? { ...log, date: new Date(log.date) } : null);
  }, [log]);

  const handleChange = (name, value) => {
    setLogToSave((prev) => ({ ...prev, [name]: value }));
  };

  if (!log || !logToSave) return null;

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: 'change-log-modal__container' }}>
      <div className="change-log-modal">
        <div className="change-log-modal__head">
          <span>{logToSave.id ? 'Edit' : 'Add'} log</span>
          <div>
            <RoundedButton type="transparent" onClick={onClose}>
              Cancel
            </RoundedButton>
            <RoundedButton
              type="primary"
              onClick={() => onOk({ ...logToSave, date: logToSave.date.toISOString() })}
            >
              Save
            </RoundedButton>
          </div>
        </div>
        <Divider />
        <div className="change-log-modal__body">
          <CustomSelect
            label="Type"
            name="type"
            value={logToSave.type}
            items={changeLogTypes}
            onSelect={handleChange}
            absolute
          />
          <CustomInput
            label="Description"
            name="description"
            value={logToSave.description}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <span className="date-picker">Date</span>
          <DatePicker
            selected={logToSave.date}
            showTimeSelect
            onChange={(date) => handleChange('date', date)}
            dateFormat="d MMMM yyyy, HH:mm"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ChangeLogAddEditModal;
