import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomSlider from 'components/progress-bar/custom-slider';
import NumberInput from 'components/input/number-input';
import CustomInput from 'components/input/custom-input';
import './index.scss';

const ClaimLockupModal = ({ open, onOk, onClose, address }) => {
  const authReducer = useSelector((state) => state.auth);
  const { accountInfo } = authReducer;

  const onConfirm = () => {
    onOk();
  };

  return (
    <Dialog
      open={open}
      className="close-deal-modal update-lockup-modal"
      id="close-deal-modal"
      onClose={onClose}
    >
      <div className="close-deal-modal-header d-flex">
        <div className="close-deal-modal-header__left d-flex vertical-center">
          <div className="deal-name vertical-center">
            <div className="full-width d-flex">
              <SvgIcon name="iconTokenClaimedDisabled" />
              <span>Claim</span>
            </div>
          </div>
        </div>
        <div className="close-deal-modal-header__right vertical-center">
          <RoundedButton onClick={onClose}>Cancel</RoundedButton>
          <RoundedButton type="primary" onClick={onConfirm}>
            <div className="d-flex">Claim</div>
          </RoundedButton>
        </div>
      </div>
      <div className="close-deal-modal-body claim">
        <div className="d-flex close-amount">
          <div className="close-amount-slider vertical-center">
            <p>Remove BDT amount</p>
            <CustomSlider
              value={accountInfo.lockInfo.amount}
              disabled
              hideValueLabel
              min={1}
              max={Number(accountInfo.lockInfo.amount)}
              onChange={() => {}}
            />
          </div>
          <div className="close-amount-input vertical-center">
            <span className="input-holder">
              <NumberInput
                placeholder=""
                value={Number(accountInfo.lockInfo.amount).toString()}
                disabled
                noFake
                decimalNumber="18"
              />
            </span>
            <span className="close-amount-input-unit">BDT</span>
          </div>
        </div>
        <div className="modal-inputs destination">
          <CustomInput label="Destination Address" name="destinationAddress" value={address} />
        </div>

        <div className="update-lockup-mobile-actions">
          <RoundedButton onClick={onClose}>Cancel</RoundedButton>
          <RoundedButton type="primary" onClick={onConfirm}>
            Claim
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ClaimLockupModal;
