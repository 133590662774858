const FundSources = [
  'Salary/Employment Income',
  'Business Income',
  'Investment Returns',
  'Sale of Property',
  'Inheritance',
  'Gifts',
  'Savings/Personal Funds',
  'Loan Proceeds',
  'Pension/Retirement Funds',
];

const PrimaryIntentions = ['Purchase of digital assets'];

export { FundSources, PrimaryIntentions };
