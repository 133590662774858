import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TableRow, TableCell } from '@material-ui/core';
import { thousandSeparatorRound } from 'utils';
import Checkbox from 'components/checkbox';
import moment from 'moment';
import remote from './remote';

const BatchScheduleTableRow = ({ row }) => {
  const [isCheckboxActive, setIsCheckboxActive] = useState(row.isDelivered);
  const updateIsDelivered = remote.useUpdateIsDelivered();
  const history = useHistory();

  const handleRowClick = () => {
    history.push(`/all-deals/${row.id}?isOldDeal=${false}`, {
      pathname: window.location.pathname,
    });
  };

  const toggleCheckbox = async () => {
    setIsCheckboxActive(!isCheckboxActive);
    await updateIsDelivered.mutateAsync({
      vestingItemId: row.vestingItemId,
      isDelivered: !isCheckboxActive,
    });
  };

  return (
    <TableRow key={`${row.id} - ${row.currentBatch} - ${row.date}`} onClick={handleRowClick}>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <Checkbox checked={isCheckboxActive} onChange={toggleCheckbox} />
      </TableCell>
      <TableCell>{moment(row.date).format('YYYY-MM-DD, hh:mm')}</TableCell>
      <TableCell>{row.dealName}</TableCell>
      <TableCell>{row.ticker}</TableCell>
      <TableCell>{row.blockchain}</TableCell>
      <TableCell>{row.expectedBatch}</TableCell>
      <TableCell>{thousandSeparatorRound(row.expectedTokens)}</TableCell>
      <TableCell>{row.expectedTokensPercentage}%</TableCell>
      <TableCell>{thousandSeparatorRound(row.totalTokensReceived)}</TableCell>
      <TableCell>{thousandSeparatorRound(row.totalTokensExpected)}</TableCell>
      <TableCell>{row.totalTokensReceivedPercentage}%</TableCell>
    </TableRow>
  );
};

export default BatchScheduleTableRow;
