import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserDeal } from 'store/actions';
import { updateDeal } from 'services/apiService';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';

import './index.scss';

const OverviewModal = ({ open, onClose, deal }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    text: deal.overview,
    url: deal.projectUrl || '',
  });
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async () => {
    const asteriskCount = (data.text.match(/\*/g) || []).length;
    const degreeCount = (data.text.match(/°/g) || []).length;
    if (asteriskCount % 2 !== 0) {
      setErrorMessage('Must have an even number of asterisks');
      return;
    }
    if (degreeCount % 2 !== 0) {
      setErrorMessage('Must have an even number of degree signs');
      return;
    }
    try {
      const response = await updateDeal(deal.id, { overview: data.text, projectUrl: data.url });
      const changedData = JSON.parse(response.config.data);
      const newDeal = JSON.parse(JSON.stringify(deal));
      newDeal.overview = changedData.overview;
      newDeal.projectUrl = changedData.projectUrl;
      dispatch(setUserDeal(newDeal));
      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal overview-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Overview</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={onConfirm}>
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="overview-form">
        <div className="input-holder">
          <label>Text Edit</label>
          <textarea
            name="text"
            cols="30"
            rows="6"
            value={data.text || ''}
            onChange={onChangeData}
            className={`${errorMessage ? 'error' : ''}`}
          ></textarea>
          {errorMessage && <span className="input-error-message">{errorMessage}</span>}
        </div>
        <div className="input-holder">
          <label>Project URL</label>
          <input type="text" name="url" value={data.url} onChange={onChangeData} />
        </div>
      </form>
    </Dialog>
  );
};

export default OverviewModal;
