import React, { useEffect, useState } from 'react';
import { BigNumber } from 'ethers';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateLoading,
  setSharedNotification,
  setActiveHashes,
  addNotification,
  removeAccountInfoAnimation,
  addAccountInfoAnimation,
  updateAuth,
} from 'store/actions';
import notificationTypes from 'constants/notificationTypes';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import './index.scss';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {
  startUnlockUpdateAccountInfo,
  lockUpdateAccountInfo,
  unlockUpdateAccountInfo,
} from 'contracts/eventHelper';
import RoundedButton from 'components/button/rounded-button';
import Icon from 'components/svgIcon';
import UpdateLockupModal from 'features/lockup/UpdateLockupModal';
import ClaimLockupModal from 'features/lockup/ClaimLockupModal';
import ConfimUnlockModal from 'features/lockup/ConfirmUnlockModal';
import { subStringAmounts } from 'utils';
import { ARBITRUM_CHAIN_NAME, ARBITRUM_CHAIN_ID } from 'constants/config';
import { switchCurrentNetwork } from '../../contracts/browserWallet';
import {
  lockTokens,
  unlockTokens,
  specialUnlock,
  startUnlock,
  setOwnerUnlocked,
} from '../../contracts/lockContract';

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const INDEFINITE_RELEASE_TIME = BigNumber.from(2).pow(32).sub(1);

const timerProps = {
  isPlaying: true,
  size: 150,
  strokeWidth: 6,
};

const getTimeMinutes = (time) => (time % hourSeconds) / minuteSeconds || 0;
const getTimeHours = (time) => (time % daySeconds) / hourSeconds || 0;
const getTimeDays = (time) => time / daySeconds || 0;

const Lockup = () => {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const [durationLeft, setDurationLeft] = useState('days');
  const [readyToRender, setReadyToRender] = useState(false);
  const [lockupModalOpen, setLockupModalOpen] = useState(false);
  const [unlockModalOpen, setUnlockModalOpen] = useState(false);
  const [claimLockupModalOpen, setClaimLockupModalOpen] = useState(false);
  const [timerEnd, setTimerEnd] = useState(null);
  const [timerExpired, setTimerExpired] = useState(false);
  const [timer, setTimer] = useState(null);

  const { accountInfo, isAdmin } = authReducer;
  const { chainId, activeHashes, accountInfoAnimation } = globalReducer;

  const startTime = Math.floor(Date.now() / 1000);
  const endTime = timerEnd;

  const remainingTime = endTime - startTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  const isInfiniteLock = accountInfo?.lockInfo?.releaseTime === INDEFINITE_RELEASE_TIME.toString();

  useEffect(() => {
    if (accountInfo?.lockInfo?.releaseTime && !isInfiniteLock) {
      const interval = setInterval(() => {
        const duration = moment.duration(accountInfo.lockInfo.releaseTime * 1000 - Date.now());
        setTimer({
          days: duration.days(),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    return () => null;
  }, [accountInfo, isInfiniteLock]);

  useEffect(() => {
    if (accountInfo) {
      if (moment(Number(accountInfo?.lockInfo?.releaseTime) * 1000).unix() - startTime < 0) {
        setTimerEnd(startTime);
        setTimerExpired(true);
      } else {
        setTimerEnd(moment(Number(accountInfo?.lockInfo?.releaseTime) * 1000).unix());
        setTimerExpired(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo]);

  useEffect(() => {
    if (timerEnd) {
      setReadyToRender(true);
    }
  }, [timerEnd]);

  const renderTime = (elapsedTime) => {
    let time;
    let dimension;

    if (elapsedTime === 0) {
      return (
        <div className="time-wrapper">
          <div className="time">0</div>
          <div className="segment">days</div>
        </div>
      );
    }

    if (durationLeft === 'days') {
      time = Math.ceil(getTimeDays(daysDuration - elapsedTime));
      dimension = 'days';
      if (time === 1) {
        setDurationLeft('hours');
      }
    }
    if (durationLeft === 'hours') {
      time = Math.ceil(getTimeHours(daysDuration - elapsedTime));
      dimension = 'hours';
      if (time === 1) {
        setDurationLeft('minutes');
      }
    }
    if (durationLeft === 'minutes') {
      time = Math.ceil(getTimeMinutes(daysDuration - elapsedTime));
      dimension = 'minutes';
      if (time === 1) {
        setDurationLeft('seconds');
      }
    }
    if (durationLeft === 'seconds') {
      time = Math.abs(Math.ceil(elapsedTime - daySeconds));
      dimension = 'seconds';
      if (time === 0) {
        dimension = 'EXPIRED';
      }
    }
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div className="segment">{dimension}</div>
      </div>
    );
  };

  const toggleUpdateModal = () => {
    setLockupModalOpen(!lockupModalOpen);
  };

  const toggleClaimModal = () => {
    setClaimLockupModalOpen(!claimLockupModalOpen);
  };

  const showErrorNotification = () => {
    dispatch(
      setSharedNotification({
        status: 'error',
        title: 'Error',
        description: 'Something went wrong. Please try again.',
      })
    );
  };

  const handleAdminUnlock = async () => {
    dispatch(updateLoading(true));
    const tx = await setOwnerUnlocked(!accountInfo.isOwnerUnlocked);
    if (tx) {
      dispatch(
        setActiveHashes([
          ...activeHashes,
          { hash: tx.hash, pending: false, chain: ARBITRUM_CHAIN_NAME },
        ])
      );
      dispatch(updateLoading(false));
    } else {
      dispatch(updateLoading(false));
      showErrorNotification();
    }
  };

  const onStartUnlock = async () => {
    const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);
    console.log(networkChecked);
    if (networkChecked) {
      dispatch(updateLoading(true));
      const tx = await startUnlock();
      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            {
              hash: tx.hash,
              pending: false,
              chain: ARBITRUM_CHAIN_NAME,
              callback: (transaction) => {
                startUnlockUpdateAccountInfo(accountInfo, transaction.logs[0]);
                dispatch(
                  updateAuth({
                    accountInfo: { ...accountInfo },
                  })
                );
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: ARBITRUM_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.LOCKUP,
          })
        );
      } else {
        showErrorNotification();
      }
      dispatch(updateLoading(false));
    }
  };

  const updateLockup = async (amount) => {
    const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);
    const amountDiff = subStringAmounts(amount.toString(), accountInfo.lockInfo.amount);
    if (networkChecked) {
      dispatch(updateLoading(true));
      const tx = await lockTokens(amountDiff);
      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            {
              hash: tx.hash,
              pending: false,
              chain: ARBITRUM_CHAIN_NAME,
              callback: (transaction) => {
                lockUpdateAccountInfo(accountInfo, transaction.logs[2]);
                dispatch(
                  updateAuth({
                    accountInfo: {
                      ...accountInfo,
                      bdtBalance: subStringAmounts(accountInfo.bdtBalance, amountDiff),
                    },
                  })
                );
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: ARBITRUM_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.LOCKUP,
          })
        );
      } else {
        showErrorNotification();
      }
      toggleUpdateModal();
      dispatch(updateLoading(false));
    }
  };

  const claimLockup = async () => {
    const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);
    if (networkChecked) {
      dispatch(updateLoading(true));
      try {
        const tx = accountInfo.isOwnerUnlocked ? await specialUnlock() : await unlockTokens();
        dispatch(updateLoading(false));
        if (tx) {
          dispatch(
            setActiveHashes([
              ...activeHashes,
              {
                hash: tx.hash,
                pending: false,
                chain: ARBITRUM_CHAIN_NAME,
                callback: (transaction) => {
                  unlockUpdateAccountInfo(accountInfo, transaction.logs[0]);

                  const accountInfoAnimation = {
                    fields: ['allFields'],
                  };

                  dispatch(addAccountInfoAnimation(accountInfoAnimation));
                  dispatch(
                    updateAuth({
                      accountInfo: { ...accountInfo },
                    })
                  );
                },
              },
            ])
          );
          dispatch(
            addNotification({
              name: tx.hash,
              chain: ARBITRUM_CHAIN_NAME,
              status: 'pending',
              statusText: 'Pending!',
              time: Date.now(),
              type: notificationTypes.LOCKUP,
            })
          );
          toggleClaimModal();
        } else {
          showErrorNotification();
        }
      } catch (err) {
        console.error(err);
        dispatch(updateLoading(false));
        showErrorNotification();
      }
    }
  };

  const isClaimButtonDisabled = () => {
    if (accountInfo?.isOwnerUnlocked && Number(accountInfo?.lockInfo?.amount) > 0) {
      return false;
    }

    return (
      Number(accountInfo?.lockInfo?.amount) === 0 ||
      Number(accountInfo?.lockInfo?.releaseTime) > Date.now() / 1000
    );
  };

  const getAnimateClass = (val) => {
    if (
      accountInfoAnimation &&
      accountInfoAnimation.fields &&
      (accountInfoAnimation?.fields.includes(val) ||
        accountInfoAnimation?.fields.includes('allFields'))
    ) {
      return 'animate';
    }
    return val;
  };

  const addLeadingZero = (num) => {
    if (num < 0 || num > 9) return num.toString();
    return `0${num}`;
  };

  useEffect(() => {
    if (accountInfoAnimation && accountInfoAnimation.fields) {
      setTimeout(() => {
        dispatch(removeAccountInfoAnimation());
      }, 3000);
    }
  }, [accountInfoAnimation, dispatch]);

  return (
    <>
      {lockupModalOpen && (
        <UpdateLockupModal
          open={lockupModalOpen}
          onOk={updateLockup}
          onClose={toggleUpdateModal}
          accountInfo={accountInfo}
          chainId={chainId}
        />
      )}
      {claimLockupModalOpen && (
        <ClaimLockupModal
          open={claimLockupModalOpen}
          onOk={claimLockup}
          onClose={toggleClaimModal}
          address={accountInfo.address}
        />
      )}
      {unlockModalOpen && (
        <ConfimUnlockModal
          open={unlockModalOpen}
          onOk={() => {
            onStartUnlock();
            setUnlockModalOpen(false);
          }}
          onClose={() => setUnlockModalOpen(false)}
        />
      )}
      <div className="lockup-content-container">
        <div className="lockup-header">
          <div className="lockup-header-info">
            <h1>My Wallet</h1>
            {/* <p>21958192581958</p> */}
          </div>
          <div className="lockup-header-actions">
            {isAdmin && (
              <RoundedButton
                type={accountInfo.isOwnerUnlocked ? 'secondary' : 'primary'}
                onClick={handleAdminUnlock}
              >
                <div className="d-flex">
                  {accountInfo.isOwnerUnlocked ? 'Deactivate Unlock' : 'Activate Unlock'}
                </div>
              </RoundedButton>
            )}
            <RoundedButton
              type="primary"
              disabled={isClaimButtonDisabled()}
              onClick={toggleClaimModal}
            >
              <div className="d-flex">Claim</div>
            </RoundedButton>
            <RoundedButton
              type="secondary"
              onClick={() => setUnlockModalOpen(true)}
              disabled={!isInfiniteLock}
            >
              <div className="d-flex">Start Unlock</div>
            </RoundedButton>
            <RoundedButton type="secondary" onClick={toggleUpdateModal}>
              <div className="d-flex">
                {accountInfo?.lockInfo?.releaseTime === '0' ? 'Lock' : 'Re-Lock'}
              </div>
            </RoundedButton>
          </div>
        </div>
        <div className="lockup-content">
          <div className="content-left">
            <div className="lockup-col tokens-locked">
              <div className="lockup-card">
                {accountInfo?.lockInfo?.releaseTime !== '0' &&
                  accountInfo?.lockInfo?.releaseTime !== INDEFINITE_RELEASE_TIME.toString() && (
                    <span className="unlock-started-label">UNLOCK STARTED</span>
                  )}
                <div className="lockup-info-holder">
                  <div className="lockup-card-info">
                    <Icon name="iconLogoOrange" width={48} />
                    <div className="lockup-card-info-text">
                      <p>Locked</p>
                      <h1 className={`${getAnimateClass('lockInfo.amount')}`}>
                        <NumberFormat
                          value={Number(accountInfo?.lockInfo?.amount).toFixed(2)}
                          thousandSeparator
                          displayType="text"
                        />{' '}
                        BDT
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lockup-col total-tokens-available">
              <div className="lockup-card">
                <div className="lockup-info-holder">
                  <div className="lockup-card-info">
                    <Icon name="iconLogoGreen" width={48} />
                    <div className="lockup-card-info-text">
                      <p>My Balance</p>
                      <h1 className={`${getAnimateClass('xBdtBalance')}`}>
                        <NumberFormat
                          value={Number(accountInfo.bdtBalance).toFixed(2)}
                          thousandSeparator
                          displayType="text"
                        />{' '}
                        BDT
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lockup-col total-tokens-available">
              <div className="lockup-card flex justify-space-between full-height">
                <div className="lockup-info-holder">
                  <div className="lockup-card-info">
                    <Icon name="iconTotalTokensLocked" width={48} />
                    <div className="lockup-card-info-text">
                      <p>Vault</p>
                      <h1 className={`${getAnimateClass('tvl')}`}>
                        <NumberFormat
                          value={Number(accountInfo.tvl).toFixed(0)}
                          thousandSeparator
                          displayType="text"
                        />{' '}
                        BDT
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lockup-col total-tokens-available">
              <div className="lockup-card">
                <div className="lockup-info-holder">
                  <div className="lockup-card-info">
                    <Icon name="iconMyProRataAllocation" width={48} />
                    <div className="lockup-card-info-text">
                      <p>My ProRata Allocation</p>
                      <h1 className={`${getAnimateClass('proRataShare')}`}>
                        <NumberFormat
                          value={Number(accountInfo.proRataShare).toFixed(4)}
                          thousandSeparator
                          displayType="text"
                        />{' '}
                        %
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-right mb-20">
            {Number(accountInfo?.lockInfo?.releaseTime) > 0 && (
              <div className="lockup-card">
                <div className="timer">
                  {(accountInfo?.isOwnerUnlocked || Number(accountInfo?.lockInfo?.amount) === 0) &&
                    readyToRender &&
                    !timerExpired && <h1 className="expiredHeading">Unlocked</h1>}
                  {!accountInfo?.isOwnerUnlocked &&
                    readyToRender &&
                    !timerExpired &&
                    timer &&
                    accountInfo?.lockInfo?.releaseTime !== INDEFINITE_RELEASE_TIME.toString() && (
                      <div className="timer-info">
                        <div className="info">
                          <p>Claim available in</p>
                        </div>
                        <CountdownCircleTimer
                          {...timerProps}
                          colors={[['#e9504f', 1]]}
                          duration={timer.days * daySeconds}
                        >
                          {({ elapsedTime }) => renderTime(elapsedTime)}
                        </CountdownCircleTimer>
                        {timer && (
                          <div className="date">
                            <h3>
                              {addLeadingZero(timer.hours)}:{addLeadingZero(timer.minutes)}:
                              {addLeadingZero(timer.seconds)}
                            </h3>
                          </div>
                        )}
                      </div>
                    )}
                  {timerExpired && <h1 className="expiredHeading">Expired</h1>}
                  {isInfiniteLock && (
                    <div>
                      <h2 className="expiredHeading">Locked indefinitely</h2>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Lockup;
