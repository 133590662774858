import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RoundedButton from 'components/button/rounded-button';
import {
  updateLoading,
  setSharedNotification,
  setActiveHashes,
  addNotification,
} from 'store/actions';
import notificationTypes from 'constants/notificationTypes';
import { switchCurrentNetwork, getNetworkNameById } from 'contracts/browserWallet';
import { closeDeal } from 'contracts/dealV2Contract';
import { sortDeals } from 'utils/helpers';
import { useNotifications, usePagination } from 'hooks';
import DealRow from './DealRow';
import DraftDealRow from './DraftDealRow';
import './index.scss';

function AdminDealsTable({ userDeals, handleDealsLength, dealsFilter, setDealsFilter, hideTabs }) {
  const dispatch = useDispatch();
  const [sortDirection, setSortDirection] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [deals, setDeals] = useState([]);
  const [closeModalOpened, setCloseModalOpened] = useState(false);
  const globalReducer = useSelector((state) => state.global);
  const { chainId, activeHashes } = globalReducer;
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const { Pagination, paginatedItems, resetPage } = usePagination(deals, 50);

  const getFilteredDeals = () => {
    let filteredDeals;
    switch (dealsFilter) {
      case 'all deals':
        filteredDeals = userDeals.filter((deal) => ![0, 6, 7, 8].includes(deal.statusId));
        break;
      case 'live deals':
        filteredDeals = userDeals.filter((deal) => {
          return (
            (deal.status === 'opened' || deal.status === 'paused') &&
            ![0, 6, 7, 8].includes(deal.statusId)
          );
        });
        break;
      case 'drafts':
        filteredDeals = userDeals.filter((deal) => [0, 6].includes(deal.statusId));
        break;
      case 'pledges':
        filteredDeals = userDeals.filter(
          (deal) => [7, 8].includes(deal.statusId) && !deal.isArchived
        );
        break;
      case 'archived':
        filteredDeals = userDeals.filter(
          (deal) => [7, 8].includes(deal.statusId) && deal.isArchived
        );
        break;
      case 'unlisted':
        filteredDeals = userDeals.filter((deal) => !deal.coingeckoApiId);
        break;
      default:
        filteredDeals = userDeals.filter((d) => d.status === dealsFilter);
        filteredDeals.sort((a, b) => {
          if (!a.statusChangedLogs) return 0;
          const lastStatusChangeA = Math.max(
            ...a.statusChangedLogs.filter((l) => l.status === dealsFilter).map((l) => l.blockNumber)
          );
          const lastStatusChangeB = Math.max(
            ...b.statusChangedLogs.filter((l) => l.status === dealsFilter).map((l) => l.blockNumber)
          );

          return lastStatusChangeB - lastStatusChangeA;
        });
        break;
    }
    handleDealsLength(filteredDeals.length);
    return filteredDeals;
  };

  const onToggleCloseModal = () => {
    setCloseModalOpened(!closeModalOpened);
  };

  const sortInvalidDatesBy = (direction) => {
    if (direction === 'asc') {
      return (a, b) => {
        return (
          !a.dateClosed - !b.dateClosed ||
          new Date(a.dateClosed).getTime() - new Date(b.dateClosed).getTime() ||
          new Date(a.datePublished).getTime() - new Date(b.datePublished).getTime()
        );
      };
    }
    return (a, b) => {
      return (
        !b.dateClosed - !a.dateClosed ||
        new Date(b.dateClosed).getTime() - new Date(a.dateClosed).getTime() ||
        new Date(a.datePublished).getTime() - new Date(b.datePublished).getTime()
      );
    };
  };

  const getSortField = () => {
    switch (dealsFilter) {
      case 'drafts':
        return 'createdAt';
      case 'pledges':
      case 'archived':
        return 'dateCreated';
      default:
        return 'dateClosed';
    }
  };

  const isPledge = dealsFilter === 'pledges' || dealsFilter === 'archived';

  useEffect(() => {
    resetPage();
    setDeals(
      sortDeals(getSortField(), getFilteredDeals(), 'asc', isPledge, false, sortInvalidDatesBy(''))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDeals, dealsFilter]);

  const checkDealsLength = (str) => {
    if (str === 'live deals') {
      return userDeals.filter((deal) => deal.status === 'opened' || deal.status === 'paused')
        .length;
    }
    return userDeals.filter((deal) => deal.status === str).length;
  };

  const onSelectFilter = (val) => {
    setSortDirection('');
    setSortColumn('');
    if (val !== dealsFilter) setDealsFilter(val);
  };

  const getSortArrow = (val) => {
    if (val !== sortColumn) return null;
    if (sortDirection === '') return null;
    if (sortDirection === 'desc') return <span style={{ color: '#e9504f' }}>&nbsp;&uarr;</span>;
    if (sortDirection === 'asc') return <span style={{ color: '#e9504f' }}>&nbsp;&darr;</span>;
  };

  const getNextSortDirection = () => {
    if (sortDirection === 'asc') {
      return 'desc';
    }
    if (sortDirection === 'desc') {
      return '';
    }
    return 'asc';
  };

  const onDateClosedSort = () => {
    const nextSortDirection = getNextSortDirection();
    setSortDirection(nextSortDirection);
    setSortColumn('dateClosed');

    const dealsArr = [...deals];
    const sortedDealsArray = dealsArr.sort(sortInvalidDatesBy(nextSortDirection));
    setDeals(sortedDealsArray);
  };

  const onSortDeals = (field, isNumber, isPledge) => {
    const nextSortDirection = getNextSortDirection();
    setSortDirection(nextSortDirection);
    setSortColumn(field);
    setDeals(
      sortDeals(
        field,
        deals,
        nextSortDirection,
        isPledge,
        isNumber,
        sortInvalidDatesBy(nextSortDirection)
      )
    );
  };

  const onCloseDeal = async (deal, amount, destinationAddress) => {
    if (deal.status === 'closed') {
      dispatch(
        setSharedNotification({
          status: 'error',
          title: 'Error',
          description: 'Deal is already closed.',
        })
      );
      return;
    }
    const networkChecked = await switchCurrentNetwork(chainId, deal.chainId);
    if (networkChecked) {
      dispatch(updateLoading(true));
      const tx = await closeDeal(deal.address, amount, destinationAddress);
      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            { hash: tx.hash, pending: false, chain: getNetworkNameById(deal.chainId) },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: getNetworkNameById(deal.chainId),
            dealAddress: deal.address,
            dealImage: deal.imageUrl,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
        dispatch(
          setSharedNotification({
            status: 'pending',
            title: 'Warning',
            description: 'Confirm TX via Hardware Wallet',
          })
        );
      } else {
        showErrorNotification();
      }
      onToggleCloseModal();
      dispatch(updateLoading(false));
    } else {
      showErrorNotification(
        `You need to change your network to ${getNetworkNameById(deal.chainId)} to continue.`
      );
    }
  };

  return (
    <div className="deals-table admin-deals-table">
      {!hideTabs && (
        <div className="deals-table-options d-flex">
          {['pledges', 'archived'].includes(dealsFilter) ? (
            <>
              <div className="filter-btn-wrapper">
                <RoundedButton
                  className={`filter-btn ${dealsFilter === 'pledges' ? 'filter-btn--active' : ''}`}
                  onClick={() => onSelectFilter('pledges')}
                >
                  <span>Pledges</span>
                </RoundedButton>
              </div>
              <div className="filter-btn-wrapper">
                <RoundedButton
                  className={`filter-btn ${dealsFilter === 'archived' ? 'filter-btn--active' : ''}`}
                  onClick={() => onSelectFilter('archived')}
                >
                  <span>Archived</span>
                </RoundedButton>
              </div>
            </>
          ) : (
            <>
              <div className="filter-btn-wrapper">
                <RoundedButton
                  className={`filter-btn ${
                    dealsFilter === 'all deals' ? 'filter-btn--active' : ''
                  }`}
                  onClick={() => onSelectFilter('all deals')}
                >
                  <span>All Deals</span>
                </RoundedButton>
              </div>
              <div className="filter-btn-wrapper">
                <RoundedButton
                  className={`filter-btn ${
                    dealsFilter === 'live deals' ? 'filter-btn--active' : ''
                  }`}
                  onClick={() => onSelectFilter('live deals')}
                  // disabled={!checkDealsLength('active')}
                >
                  <span>Live</span>
                </RoundedButton>
              </div>
              <div className="filter-btn-wrapper">
                <RoundedButton
                  className={`filter-btn ${dealsFilter === 'closed' ? 'filter-btn--active' : ''}`}
                  onClick={() => onSelectFilter('closed')}
                  disabled={!checkDealsLength('closed')}
                >
                  <span>Closed</span>
                </RoundedButton>
              </div>
              <div className="filter-btn-wrapper">
                <RoundedButton
                  className={`filter-btn ${dealsFilter === 'canceled' ? 'filter-btn--active' : ''}`}
                  onClick={() => onSelectFilter('canceled')}
                  disabled={!checkDealsLength('canceled')}
                >
                  <span>Canceled</span>
                </RoundedButton>
              </div>
              <div className="filter-btn-wrapper">
                <RoundedButton
                  className={`filter-btn ${
                    dealsFilter === 'distributed' ? 'filter-btn--active' : ''
                  }`}
                  onClick={() => onSelectFilter('distributed')}
                  disabled={!checkDealsLength('distributed')}
                >
                  <span>Completed</span>
                </RoundedButton>
              </div>
              <div className="filter-btn-wrapper">
                <RoundedButton
                  className={`filter-btn ${dealsFilter === 'unlisted' ? 'filter-btn--active' : ''}`}
                  onClick={() => onSelectFilter('unlisted')}
                >
                  <span>Unlisted</span>
                </RoundedButton>
              </div>
            </>
          )}
        </div>
      )}
      <div className="deals-table-content">
        {dealsFilter !== 'drafts' && dealsFilter !== 'pledges' && dealsFilter !== 'archived' ? (
          <>
            <div className="deals-table-header d-flex">
              <div className="deal__field deal__field-avatar vertical-center" />
              <div
                className="deal__field deal__field-name vertical-center"
                onClick={() => onSortDeals('name')}
              >
                PROJECT{getSortArrow('name')}
              </div>
              <div
                className="deal__field deal__field-date vertical-center"
                onClick={() => onSortDeals('datePublished')}
              >
                LIVE DATE{getSortArrow('datePublished')}
              </div>
              <div
                className="deal__field deal__field-date vertical-center"
                onClick={onDateClosedSort}
              >
                DATE CLOSED{getSortArrow('dateClosed')}
              </div>
              <div
                className="deal__field deal__field-status vertical-center"
                onClick={() => onSortDeals('status')}
              >
                STATUS{getSortArrow('status')}
              </div>
              <div
                className="deal__field deal__field-raised-amount vertical-center"
                onClick={() => onSortDeals('raisedAmount', true)}
              >
                FILLED{getSortArrow('raisedAmount')}
              </div>
              <div
                className="deal__field deal__field-size vertical-center"
                onClick={() => onSortDeals('dealSize', true)}
              >
                DEAL SIZE{getSortArrow('dealSize')}
              </div>
              <div className="deal__field deal__field-access-level vertical-center">
                ACCESS LEVEL
              </div>
              <div
                className="deal__field deal__field-model vertical-center"
                onClick={() => onSortDeals('unlimitedTimestamp', true)}
              >
                RAISE MODEL{getSortArrow('unlimitedTimestamp')}
              </div>
              <div className="deal__field deal__field-status-stepper vertical-center">CONTROLS</div>
            </div>
            <div>
              {paginatedItems
                .filter((x) => ![0, 6, 7, 8].includes(x.statusId))
                .map((deal) => (
                  <div key={deal.id} className="deals-table-content__row">
                    <DealRow
                      deal={deal}
                      closeModalOpened={closeModalOpened}
                      onToggleCloseModal={onToggleCloseModal}
                      onCloseDeal={onCloseDeal}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <>
            <div className="deals-table-header draft-deal-table-header d-flex">
              <div className="deal__field deal__field-avatar vertical-center" />
              <div
                className="deal__field deal__field-name vertical-center"
                onClick={() => onSortDeals('name', null, true)}
              >
                PROJECT{getSortArrow('name')}
              </div>
              <div
                className="deal__field deal__field-status vertical-center"
                onClick={() => onSortDeals('status', null, true)}
              >
                STATUS{getSortArrow('status')}
              </div>
              <div
                className="deal__field deal__field-status vertical-center"
                onClick={() =>
                  onSortDeals(dealsFilter === 'drafts' ? 'createdAt' : 'dateCreated', null, true)
                }
              >
                {dealsFilter === 'drafts' ? 'DRAFT' : 'PLEDGE'} DATE
                {getSortArrow(dealsFilter === 'drafts' ? 'createdAt' : 'dateCreated')}
              </div>
              <div className="deal__field deal__field-pledge-amount vertical-center">
                PLEDGE AMOUNT
              </div>
            </div>
            {dealsFilter === 'drafts' && (
              <div>
                {paginatedItems.map((deal) => (
                  <div key={deal.id} className="deals-table-content__row">
                    <DraftDealRow
                      deal={deal}
                      closeModalOpened={closeModalOpened}
                      onToggleCloseModal={onToggleCloseModal}
                      showSuccessNotification={showSuccessNotification}
                      showErrorNotification={showErrorNotification}
                      onCloseDeal={onCloseDeal}
                    />
                  </div>
                ))}
              </div>
            )}
            {['pledges', 'archived'].includes(dealsFilter) && (
              <div>
                {paginatedItems.map((deal) => (
                  <div key={deal.id} className="deals-table-content__row">
                    <DraftDealRow
                      deal={deal}
                      closeModalOpened={closeModalOpened}
                      onToggleCloseModal={onToggleCloseModal}
                      showSuccessNotification={showSuccessNotification}
                      showErrorNotification={showErrorNotification}
                      onCloseDeal={onCloseDeal}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <Pagination />
      </div>
    </div>
  );
}

AdminDealsTable.propTypes = {
  userDeals: PropTypes.arrayOf(PropTypes.shape()),
};

AdminDealsTable.defaultProps = {
  userDeals: [],
};

export default AdminDealsTable;
