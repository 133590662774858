import React from 'react';
import InfoContainer from 'screens/Legal/components/InfoContainer';
import { renderSafeValue } from 'utils';
import './index.scss';

const InfoSection = ({
  title,
  data,
  className,
  itemClassName,
  valueTransform = renderSafeValue,
}) => {
  if (!data) return null;

  return (
    <InfoContainer title={title} className={className}>
      <ul className="item-list">
        {data.map((info) => (
          <li key={info.label} className={`item ${itemClassName}`}>
            <p className="label text-transform-none">{info.label}</p>
            <p className="value">{valueTransform(info.value)}</p>
          </li>
        ))}
      </ul>
    </InfoContainer>
  );
};

export default InfoSection;
