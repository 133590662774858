import React, { useState } from 'react';
import { Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import remote from '../../remote';
import './index.scss';

const UnlockSeedDialog = ({ isDecryptOpen, closeDecryptModal, selectedSeedId }) => {
  const decryptSeed = remote.usePostDecryptSeed();

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const clearErrorMessage = () => setErrorMessage('');

  const [password, setPassword] = useState('');
  const updatePassword = (event) => setPassword(event.target.value);

  const closeModal = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setPassword('');
    closeDecryptModal();
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await decryptSeed.mutateAsync({ seedId: selectedSeedId, password });
      setSuccessMessage(`Seed ${selectedSeedId} unlocked!`);
      setTimeout(closeModal, 750);
    } catch (err) {
      setErrorMessage(err.response?.data?.message);
    }
  };

  const disableFormSubmit =
    (decryptSeed.isSuccess && successMessage) || decryptSeed.isLoading || !password;

  return (
    <Dialog
      open={isDecryptOpen}
      onClose={closeModal}
      classes={{ paper: 'seed-modal' }}
      disableBackdropClick
    >
      <DialogTitle>Decrypt seed</DialogTitle>
      <Divider />
      <DialogContent>
        {!!errorMessage && <h5 className="message message--error">{errorMessage}</h5>}
        {!!successMessage && <h5 className="message message--success">{successMessage}</h5>}
        <form id="seed-form" onSubmit={handleFormSubmit}>
          <div className="input-holder">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onFocus={clearErrorMessage}
              onChange={updatePassword}
              placeholder="Enter password"
            />
          </div>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <div className="action-buttons">
          <RoundedButton
            className="action-button action-button--close"
            onClick={closeModal}
            disabled={decryptSeed.isLoading}
          >
            Close
          </RoundedButton>
          <RoundedButton
            form="seed-form"
            className="action-button action-button--submit"
            buttonType="submit"
            disabled={disableFormSubmit}
          >
            Decrypt
          </RoundedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UnlockSeedDialog;
