import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import {
  getAllAdminPolls,
  getAllUserPolls,
  createPoll,
  startPoll,
  endPoll,
  deletePoll,
  updatePoll,
  votePoll,
} from 'services/apiService';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import IconButton from 'components/button/icon-button';
import RoundedButton from 'components/button/rounded-button';
import { useDispatch, useSelector } from 'react-redux';
import AddEditPollModal from 'features/polls/AddEditPollModal';
import PollManageDropdown from 'features/polls/PollManageDropdown';
import PollsHeader from 'features/polls/PollsHeader';
import VoteModal from 'features/polls/VoteModal';
import PollResultModal from 'features/polls/PollResultModal';
import { setPollList } from 'store/actions';
import './index.scss';

const Polls = () => {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const { isAdmin } = authReducer;
  const { isInitialLoadFinished } = globalReducer;
  const [polls, setPolls] = useState([]);
  const [pollToVote, setPollToVote] = useState(null);
  const [pollToAddEdit, setPollToAddEdit] = useState(null);
  const [statusCounters, setStatusCounters] = useState({ draft: 0, done: 0, active: 0 });
  const [filteredPolls, setFilteredPolls] = useState([]);
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const [pollForResult, setPollForResult] = useState(null);

  const fetchPolls = useCallback(() => {
    (async () => {
      if (!isInitialLoadFinished) return;
      const getPolls = isAdmin ? getAllAdminPolls : getAllUserPolls;
      const res = await getPolls();
      setPolls(res);
      setStatusCounters(
        res.reduce(
          (accum, { status }) => ({
            ...accum,
            [status.toLowerCase()]: +accum[status.toLowerCase()] + 1,
          }),
          {
            draft: 0,
            done: 0,
            active: 0,
          }
        )
      );
      dispatch(setPollList(res));
    })();
  }, [isAdmin, isInitialLoadFinished, dispatch]);

  useEffect(() => {
    fetchPolls();
  }, [fetchPolls]);

  const onSave = async (poll) => {
    const save = poll.id ? updatePoll : createPoll;
    try {
      await save(poll);
      setPollToAddEdit(null);
      fetchPolls();
      showSuccessNotification(`${poll.id ? 'Edited' : 'Saved'} poll "${poll.title}"`);
    } catch (error) {
      showErrorNotification(`Something went wrong while ${poll.id ? 'editing' : 'saving'} poll`);
    }
  };

  const handleStart = async (pollId) => {
    try {
      await startPoll(pollId);
      fetchPolls();
      showSuccessNotification(`Started poll`);
    } catch (error) {
      showErrorNotification('Something went wrong while starting poll');
    }
  };

  const handleEnd = async (pollId) => {
    try {
      await endPoll(pollId);
      fetchPolls();
      showSuccessNotification(`Ended poll`);
    } catch (error) {
      showErrorNotification('Something went wrong while ending poll');
    }
  };

  const handleDelete = async (pollId) => {
    try {
      await deletePoll(pollId);
      fetchPolls();
      showSuccessNotification(`Deleted poll`);
    } catch (error) {
      showErrorNotification('Something went wrong while deleting poll');
    }
  };

  const handleVote = async (optionId) => {
    try {
      await votePoll(optionId);
      setPollToVote(null);
      fetchPolls();
    } catch (error) {
      showErrorNotification('Something went wrong while voting');
    }
  };

  return (
    <div className="polls">
      <PollsHeader
        polls={polls}
        onChange={setFilteredPolls}
        onAddPoll={() => setPollToAddEdit({})}
      />
      <VoteModal poll={pollToVote} onClose={() => setPollToVote(null)} onVote={handleVote} />
      <AddEditPollModal
        poll={pollToAddEdit}
        onClose={() => setPollToAddEdit(null)}
        onOk={onSave}
        open={!!pollToAddEdit}
      />
      <PollResultModal poll={pollForResult} onClose={() => setPollForResult(null)} />
      <div className="poll-status-counters">
        <div>
          <SvgIcon width={48} name="iconFinishedPoll" />
          <div>
            <span>Finished</span>
            <span>{statusCounters.done}</span>
          </div>
        </div>
        <div>
          <SvgIcon width={48} name="iconInProgressPoll" />
          <div>
            <span>In progress</span>
            <span>{statusCounters.active}</span>
          </div>
        </div>
        {isAdmin && (
          <div>
            <SvgIcon width={48} name="iconDraftPoll" />
            <div>
              <span>Draft</span>
              <span>{statusCounters.draft}</span>
            </div>
          </div>
        )}
      </div>
      <TableContainer classes={{ root: 'polls-table' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>POLL TYPE</TableCell>
              <TableCell>VOTING TYPE</TableCell>
              <TableCell>START DATE</TableCell>
              <TableCell>END DATE</TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPolls.map(
              ({ id, title, pollType, votingType, dateStart, dateEnd, status, pollVote, ...rest }) => (
                <TableRow key={id}>
                  <TableCell>{title}</TableCell>
                  <TableCell>{pollType}</TableCell>
                  <TableCell>{votingType}</TableCell>
                  <TableCell>{moment(dateStart).format('D-MMMM-YYYY')}</TableCell>
                  <TableCell>{moment(dateEnd).format('D-MMMM-YYYY')}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <div className={`${status.toLowerCase()}-dot`} />
                      <span>{status}</span>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <div className="action-buttons">
                      <IconButton
                        icon="iconPreview"
                        onClick={() =>
                          setPollForResult({
                            id,
                            title,
                            pollType,
                            dateStart,
                            dateEnd,
                            status,
                            voteId: pollVote[0]?.id,
                            ...rest,
                          })
                        }
                      />
                      {isAdmin ? (
                        <>
                          <IconButton
                            icon="statusCanceled"
                            className={status === 'Done' ? 'end-icon-active' : ''}
                            disabled={status !== 'Active'}
                            onClick={() => handleEnd(id)}
                          />
                          <IconButton
                            icon="statusOpened"
                            className={status === 'Active' ? 'start-icon-active' : ''}
                            disabled={status !== 'Draft'}
                            onClick={() => handleStart(id)}
                          />
                          <PollManageDropdown
                            onDelete={() => handleDelete(id)}
                            onEdit={() =>
                              setPollToAddEdit({
                                id,
                                title,
                                pollType,
                                dateStart: new Date(dateStart),
                                dateEnd: new Date(dateEnd),
                                status,
                                ...rest,
                              })
                            }
                          />
                        </>
                      ) : (
                        <RoundedButton
                          type="primary"
                          onClick={() =>
                            setPollToVote({
                              id,
                              title,
                              pollType,
                              dateStart,
                              dateEnd,
                              status,
                              ...rest,
                            })
                          }
                          disabled={status !== 'Active' || !!pollVote[0]}
                        >
                          Vote
                        </RoundedButton>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isAdmin && (
        <div className="add_poll_row" onClick={() => setPollToAddEdit({})}>
          <IconButton icon="plus" />
          <span>Add new poll</span>
        </div>
      )}
    </div>
  );
};

export default Polls;
