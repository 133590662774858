import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { getBasicDealAnalytics, getFullDealAnalytics } from 'services/apiService';
import RoundedButton from 'components/button/rounded-button';
import { ClipLoader } from 'react-spinners';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import './index.scss';
import BasicAnalyticsPickerModal from 'features/deals/BasicAnalyticsPickerModal';

const INITIAL_NUMBER_SHOWN = 10;

const ContributionsTab = ({ deal }) => {
  const [isDeployed, setIsDeployed] = useState(false);
  const [isFullAnalyticsModalOpen, setIsFullAnalyticsModalOpen] = useState(false);
  const [isBasicAnalyticsModalOpen, setIsBasicAnalyticsModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [basicDealAnalytics, setBasicDealAnalytics] = useState(null);
  const [fullDealAnalytics, setFullDealAnalytics] = useState(null);
  const [basicAnalyticsToExport, setBasicAnalyticsToExport] = useState([]);
  const fullAnalyticsRef = useRef();
  const basicAnalyticsRef = useRef();

  useEffect(() => {
    if (!deal.address || !deal.creationBlockNumber) {
      setBasicDealAnalytics([]);
      setIsDeployed(false);
      return;
    }
    setIsDeployed(true);

    (async () => {
      const result = await getBasicDealAnalytics(deal.id);
      setBasicDealAnalytics(result);
    })();
  }, [deal]);

  useEffect(() => {
    if (!fullDealAnalytics) return;

    setTimeout(() => fullAnalyticsRef.current.link.click(), 0);
  }, [fullDealAnalytics]);

  if (!basicDealAnalytics) return <ClipLoader size={22} color="#ffffff" loading />;

  if (!basicDealAnalytics.length || !isDeployed) return <label>No contributions</label>;

  const handleCsvAnalyticsClose = () => {
    setIsFullAnalyticsModalOpen(false);
  };

  const handleSetPassword = async () => {
    setFullDealAnalytics(await getFullDealAnalytics(deal.id, password));
  };

  const handleFullAnalytics = () => {
    if (!fullDealAnalytics) {
      setIsFullAnalyticsModalOpen(true);
      return;
    }

    fullAnalyticsRef.current.link.click();
  };

  const handleBasicAnalytics = (choosenFields) => {
    const { result } = basicDealAnalytics.reduce(
      (accum, item, i) => {
        let { choosenIndexes } = accum;

        if (i === 0) {
          choosenIndexes = choosenFields.map(({ value }) => item.indexOf(value));
        }

        // Round contributions and pledges to 2 decimals
        item[1] = (+item[1]).toFixed(2);
        item[4] = (+item[4]).toFixed(2);

        return {
          choosenIndexes,
          result: [...accum.result, item.filter((_, i) => choosenIndexes.includes(i))],
        };
      },
      { choosenIndexes: [], result: [] }
    );
    setBasicAnalyticsToExport(result);
    setTimeout(() => basicAnalyticsRef.current.link.click(), 0);
    setIsBasicAnalyticsModalOpen(false);
  };

  return (
    <>
      <BasicAnalyticsPickerModal
        open={isBasicAnalyticsModalOpen}
        onClose={() => setIsBasicAnalyticsModalOpen(false)}
        onOk={handleBasicAnalytics}
        options={basicDealAnalytics?.[0] || []}
      />
      <div className="contribution-tab">
        <div className="actions-container">
          {basicDealAnalytics.length > INITIAL_NUMBER_SHOWN && (
            <RoundedButton type="primary" onClick={() => setShowAll((prev) => !prev)}>
              {showAll ? 'Show less' : 'Show all'}
            </RoundedButton>
          )}
          <RoundedButton onClick={() => setIsBasicAnalyticsModalOpen(true)} type="primary">
            Export basic analytics to CSV
          </RoundedButton>
          {basicDealAnalytics && (
            <CSVLink
              ref={basicAnalyticsRef}
              data={basicAnalyticsToExport}
              style={{ display: 'none' }}
              filename={`contributions_${deal.address}.csv`}
            >
              Export basic analytics to CSV
            </CSVLink>
          )}
          <RoundedButton type="primary" onClick={handleFullAnalytics}>
            Export full analytics to CSV
          </RoundedButton>
          {fullDealAnalytics && (
            <CSVLink
              ref={fullAnalyticsRef}
              data={fullDealAnalytics}
              style={{ display: 'none' }}
              filename={`contributions_${deal.address}.csv`}
            />
          )}
          <Dialog
            open={isFullAnalyticsModalOpen && !fullDealAnalytics}
            onClose={handleCsvAnalyticsClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Enter Password</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCsvAnalyticsClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSetPassword} color="primary">
                Unlock
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {basicDealAnalytics
          .slice(1, showAll ? undefined : INITIAL_NUMBER_SHOWN)
          .map(([address, value]) => (
            <div className="item" key={address}>
              <label>{address}</label>
              <label>${(+value).toFixed(2)}</label>
            </div>
          ))}
      </div>
    </>
  );
};

ContributionsTab.propTypes = {
  deal: PropTypes.object.isRequired,
};

export default ContributionsTab;
