import { BDT_ADDRESS, USDT_ADDRESS, TRANSFER_PROXY_ADDRESS } from '../constants/config';
import { getErc20Balance, getAllowance } from '../contracts/erc20';
import { getLockInfo, getTvl, getOwnerUnlocked } from '../contracts/lockContract';
import { arbitrumProvider } from '../contracts/providers';
import { getUserLevel } from './userLevelModel';

function getNetworkId() {
  return window.ethereum.request({ method: 'eth_chainId' });
}

function getLockDetails(lockInfo) {
  const sixMonthsSeconds = 6 * 30 * 24 * 60 * 60;
  const tenDaysSeconds = 10 * 24 * 60 * 60;
  const nowSeconds = Math.floor(Date.now() / 1000);

  const releaseTime = Number(lockInfo.releaseTime);

  const isLockedFor6Months = releaseTime > nowSeconds + sixMonthsSeconds;

  const contributionExpiry = releaseTime - nowSeconds - sixMonthsSeconds;

  let relockMessage = null;
  if (contributionExpiry > 0 && contributionExpiry < tenDaysSeconds) {
    const contributionExpiryDays = Math.floor(contributionExpiry / 24 / 60 / 60);
    relockMessage = [
      `Lockup validity expiring in ${contributionExpiryDays} ${
        contributionExpiryDays === 1 ? 'day' : 'days'
      }.`,
      'Please update/extend your lockup in order to keep your access level and deal participation enabled.',
    ];
  }
  if (releaseTime > 0 && !isLockedFor6Months) {
    relockMessage = ['Please lock or re-lock BDT tokens in order to contribute to deals!'];
  }
  if (releaseTime === 0) {
    relockMessage = [
      '',
      'Please lock your BDT tokens in order to get access level and enable deal participation.',
      '',
    ];
  }
  return { relockMessage, isLockedFor6Months };
}

// eslint-disable-next-line import/prefer-default-export
export async function loadUserModel(address) {
  try {
    const [
      bdtBalance,
      usdtBalance,
      usdtAllowance,
      lockInfo,
      tvl,
      isOwnerUnlocked,
      chainId,
    ] = await Promise.all([
      getErc20Balance(arbitrumProvider, BDT_ADDRESS, address, 18),
      getErc20Balance(arbitrumProvider, USDT_ADDRESS, address, 6),
      getAllowance(arbitrumProvider, USDT_ADDRESS, address, TRANSFER_PROXY_ADDRESS, 6),
      getLockInfo(address),
      getTvl(),
      getOwnerUnlocked(),
      getNetworkId(),
    ]);

    const proRataShare = ((parseFloat(lockInfo.amount) / parseFloat(tvl)) * 100).toFixed(4);

    const { relockMessage, isLockedFor6Months } = getLockDetails(lockInfo);

    const userModel = {
      address,
      bdtBalance,
      usdtBalance,
      usdtAllowance,
      userAccessLevel: isLockedFor6Months ? getUserLevel(lockInfo.amount) : -1,
      userViewLevel: lockInfo.releaseTime > Date.now() / 1000 ? getUserLevel(lockInfo.amount) : -1,
      proRataShare,
      lockInfo,
      tvl,
      isOwnerUnlocked,
      chainId,
      relockMessage,
    };

    return userModel;
  } catch (error) {
    console.error(error);
    return {};
  }
}
