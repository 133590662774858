import { useQuery } from 'react-query';
import { apiGet, baseUrl } from 'services/apiService';

const allYears = 'All';

const getUserPortfolioAnalytics = async ({ queryKey }) => {
  const response = await apiGet(`${baseUrl}/api/analytics/portfolio?year=${queryKey[2]}`);
  return response.data;
};

const useAllPortfolioAnalytics = (walletAddress, activeTab) => {
  return useQuery(['userPortfolio', walletAddress, allYears], getUserPortfolioAnalytics, {
    enabled: activeTab === 'portfolio' || activeTab === 'sell-transactions',
  });
};

const usePortfolioAnalytics = (walletAddress, year, activeTab) => {
  return useQuery(['userPortfolio', walletAddress, year], getUserPortfolioAnalytics, {
    enabled: activeTab === 'portfolio' || activeTab === 'sell-transactions',
  });
};

export default {
  useAllPortfolioAnalytics,
  usePortfolioAnalytics,
};
