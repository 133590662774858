import { ClickAwayListener, Popper } from '@material-ui/core';
import IconButton from 'components/button/icon-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { duplicateDeal, updateDeal } from 'services/apiService';
import { addUserDeal, setUserDeal } from 'store/actions';

const DealManageDropdown = ({ deal, showDuplicate, showHide, showDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef();
  const dispatch = useDispatch();
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onHide = async () => {
    const newUserHidden = !deal.userHidden;

    try {
      const response = await updateDeal(deal.id, { userHidden: newUserHidden });
      const updatedDeal = response.data;

      dispatch(setUserDeal({ ...deal, ...updatedDeal, id: updatedDeal.id.toString() }));
      showSuccessNotification(`${deal.name} ${newUserHidden ? 'hidden' : 'unhidden'} successfully`);
    } catch (error) {
      console.error(error);
      showErrorNotification(
        `Something went wrong while ${newUserHidden ? 'hiding' : 'unhiding'} ${deal.name}`
      );
    }
  };

  const onDuplicate = async () => {
    try {
      const duplicatedDeal = await duplicateDeal(deal.id);
      dispatch(addUserDeal(duplicatedDeal));
      showSuccessNotification(`Succesfully duplicated ${deal.name}`);
    } catch {
      showErrorNotification('Something went wrong while duplicating deal');
    }
  };

  const onDelete = () => null;

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className="deal-manage">
        <div ref={buttonRef} className="deal-manage__button">
          <IconButton icon="iconMore" onClick={() => setIsOpen((prev) => !prev)} />
        </div>
        <Popper open={isOpen} anchorEl={buttonRef.current} placement="bottom-end">
          <div className="deal-manage__dropdown" onClick={(e) => e.stopPropagation()}>
            {showDuplicate && (
              <div onClick={onDuplicate}>
                <SvgIcon name="iconCopy" />
                <span>Duplicate</span>
              </div>
            )}
            {showHide && (
              <div onClick={onHide}>
                <SvgIcon name="iconDropdownVisibilityOffGray" />
                <span>{deal.userHidden ? 'Unhide' : 'Hide'}</span>
              </div>
            )}
            {showDelete && (
              <div onClick={onDelete}>
                <SvgIcon name="iconErase" />
                <span>Delete</span>
              </div>
            )}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default DealManageDropdown;
