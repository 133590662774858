import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertNotification } from 'store/actions';
import RoundedButton from 'components/button/rounded-button';

import './index.scss';

const AlertNotification = () => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.global);
  const { alertNotification } = globalReducer;

  const onClose = () => {
    dispatch(setAlertNotification({ status: '' }));
  };

  if (!alertNotification.status) return null;
  return (
    <div className={`alert-notification ${alertNotification.status}`}>
      <div className="alert-notification-inner">
        <div className="alert-notification-header">
          <h3>{alertNotification.title}</h3>
        </div>
        <div className="alert-notification-content">
          <div className="description">{alertNotification.description}</div>
        </div>
        <div className="alert-notification-actions">
          <RoundedButton onClick={onClose}>Cancel</RoundedButton>
        </div>
      </div>
    </div>
  );
};

export default AlertNotification;
