import React, { useEffect, useMemo, useState } from 'react';
import { formatFiatPrice, formatTokenPrice, formatChartPrice } from 'utils';
import { Line } from 'react-chartjs-2';
import useCoingeckoChart from 'hooks/useCoingeckoChart';
import CustomSelect from 'components/select/custom-select';
import moment from 'moment';
import SvgIcon from 'components/svgIcon';
import 'chartjs-adapter-moment';
import './index.scss';

let sellPoints = [];

const chartOptions = {
  animation: {
    duration: 1000,
  },
  onHover: ({ x, y }, activeElements, chart) => {
    const { ctx } = chart;
    if (!ctx) {
      return;
    }
    chart.render();
    const leftX = chart.chartArea.left;
    const topY = chart.chartArea.top;
    const RightX = chart.chartArea.right;
    const bottomY = chart.chartArea.bottom;
    ctx.beginPath();
    ctx.setLineDash([5, 10]);
    ctx.moveTo(x, topY);
    ctx.lineTo(x, bottomY);
    ctx.moveTo(leftX, y);
    ctx.lineTo(RightX, y);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#029e80';
    ctx.stroke();
    ctx.closePath();
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      backgroundColor: '#029e80',
      borderWidth: 1,
      padding: 20,
      titleFontSize: 14,
      bodyFontSize: 11,
      titleAlign: 'center',
      bodyAlign: 'center',
      caretPadding: 10,
      callbacks: {
        label: ({ parsed }) => {
          return moment(parsed.x).format('DD MMM, YYYY');
        },
        title: ([{ parsed }]) => {
          return `$ ${formatChartPrice(parsed.y)}`;
        },
        afterBody: ([{ parsed }]) => {
          const sellPoint = sellPoints.find(
            ({ date }) => new Date(date).toString() === new Date(parsed.x).toString()
          );
          if (!sellPoint) return '';
          return `${formatTokenPrice(sellPoint.sold)} → $${formatFiatPrice(sellPoint.received)}`;
        },
      },
    },
  },
  scales: {
    xAxis: {
      type: 'time',
      ticks: {
        padding: 15,
      },
      time: {
        unit: 'month',
        unitStepSize: 1,
        displayFormats: {
          month: 'MMM',
        },
      },
    },
    yAxis: {
      position: 'right',
      ticks: {
        padding: 25,
      },
      grid: {
        color: '#4d4d4d',
        borderWidth: 1,
        drawTicks: false,
      },
    },
  },
};

const DealAnalyticChart = ({ dealAnalytics, hideSellPointsFeature }) => {
  const [chartOption, setChartOption] = useState();
  const [showSellPoints, setShowSellPoints] = useState(true);
  const { chart, queryOptions } = useCoingeckoChart(
    dealAnalytics.data?.coingeckoApiId,
    chartOption
  );

  const sellTransactionsChart = useMemo(() => {
    if (!chart.data || !showSellPoints) return [];
    const [min] = chart.data[0];
    const [max] = chart.data[chart.data.length - 1];

    return dealAnalytics.data.sellTransactions.reduce((accum, { date, sold, received }) => {
      const timestamp = new Date(date).getTime();
      if (timestamp < min || timestamp > max) return accum;
      return [...accum, { x: timestamp, y: +received / +sold }];
    }, []);
  }, [dealAnalytics.data, chart, showSellPoints]);

  useEffect(() => {
    sellPoints = dealAnalytics.data.sellTransactions;
  }, [dealAnalytics.data.sellTransactions]);

  return (
    <div className="analytics-chart">
      <div className="analytics-chart__title">
        <h2>${formatChartPrice(dealAnalytics.data.currentPrice)}</h2>
        <div>
          <button onClick={() => setShowSellPoints((prev) => !prev)} type="button">
            {hideSellPointsFeature ? '' : showSellPoints ? 'Hide sell points' : 'Show sell points'}
          </button>
          <CustomSelect
            items={queryOptions}
            value={chartOption || 365}
            onSelect={(_, value) => setChartOption(value)}
          />
          <div className="chart-icon-container">
            <SvgIcon name="chart" />
          </div>
        </div>
      </div>
      <Line
        options={chartOptions}
        data={{
          labels: chart.data?.map(([timestamp]) => timestamp),
          datasets: [
            {
              label: 'Sell',
              data: sellTransactionsChart,
              showLine: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 6,
              pointBackgroundColor: '#b43938',
            },
            {
              label: 'Price',
              borderColor: '#029e80',
              data: chart.data?.map(([, price]) => price),
              pointRadius: 0,
              pointHitRadius: 5,
            },
          ],
        }}
      />{' '}
    </div>
  );
};

export default DealAnalyticChart;
