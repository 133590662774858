import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import EntityEditField from 'features/deals/EntityEditField';
import remote from './remote';

const initialState = [
  ['', ''],
  ['', ''],
  ['', ''],
  ['', ''],
  ['', ''],
  ['', ''],
  ['', ''],
  ['', ''],
  ['', ''],
  ['', ''],
];

const PartnersModal = ({ open, onClose, deal, partnersInvestors }) => {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const [data, setData] = useState(initialState);
  const updatePartners = remote.useUpdateDealPartnersInvestors(deal.id);

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      const filteredData = data.filter((partner) => partner.some(Boolean));

      const promises = filteredData.map((partner) => {
        return updatePartners.mutateAsync({
          id: partner[2] || 0,
          data: { logo: partner[0], website: partner[1], sortField: partner[3] },
        });
      });
      await Promise.all(promises);

      showSuccessNotification(`Updated successfully`);
      onClose();
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  useEffect(() => {
    if (partnersInvestors && partnersInvestors.length > 0) {
      const dataToSet = initialState.map((item, index) => {
        const found = partnersInvestors.find((pi) => pi.sortField === index + 1);
        if (found) {
          return [found.logo || '', found.website || '', found.id, found.sortField];
        }
        return item;
      });
      setData(dataToSet);
    }
  }, [partnersInvestors]);

  const onUploadUrlChange = (itemIndex, newValue) => {
    setData((prev) =>
      prev.map((item, i) =>
        i === itemIndex ? [newValue, item[1], item[2], itemIndex + 1] : [...item]
      )
    );
  };

  const onWebsiteUrlChange = (itemIndex, newValue) => {
    setData((prev) =>
      prev.map((item, i) =>
        i === itemIndex ? [item[0], newValue, item[2], itemIndex + 1] : [...item]
      )
    );
  };

  return (
    <Dialog open={open} className="deal-info-modal partners-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Partners &amp; Investors</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={onConfirm}>
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="partners-form" onSubmit={onConfirm}>
        {data?.map((item, i) => {
          if (item) {
            return (
              <div className="col" key={i}>
                <EntityEditField
                  uploadUrl={item[0]}
                  websiteUrl={item[1]}
                  onUploadUrlChange={(newValue) => onUploadUrlChange(i, newValue)}
                  onWebsiteUrlChange={(newValue) => onWebsiteUrlChange(i, newValue)}
                  aspectRatio={45 / 24}
                />
              </div>
            );
          }
          return null;
        })}
      </form>
    </Dialog>
  );
};

export default PartnersModal;
