import { useDispatch } from 'react-redux';
import { setUserDeal } from 'store/actions';
import { updateDeal } from 'services/apiService';
import useNotifications from './useNotifications';

const useApiService = () => {
  const dispatch = useDispatch();
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onUpdateDeal = async (deal, data) => {
    try {
      const response = await updateDeal(deal.id, data);
      const changedData = JSON.parse(response.config.data);
      const newDeal = JSON.parse(JSON.stringify(deal));
      newDeal.imageUrl = changedData.imageUrl;
      dispatch(setUserDeal(newDeal));
      showSuccessNotification('Updated successfully');
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong');
    }
  };

  return [onUpdateDeal];
};

export default useApiService;
