import axios from 'axios';
import { useQuery } from 'react-query';

const getHistoricalPrices = async (coingeckoApiId, days) => {
  return (
    await axios.get(`https://api.coingecko.com/api/v3/coins/${coingeckoApiId}/market_chart`, {
      params: {
        vs_currency: 'usd',
        days,
      },
    })
  ).data.prices;
};

const queryOptions = [
  { name: '1 day', value: 1 },
  { name: '7 days', value: 7 },
  { name: '30 days', value: 30 },
  { name: '6 month', value: 180 },
  { name: '1 year', value: 365 },
  { name: 'max', value: 'max' },
];

const useCoingeckoChart = (coingeckoId, option = 365) => {
  const chart = useQuery(
    ['coingeckoChart', coingeckoId, option],
    () => getHistoricalPrices(coingeckoId, option),
    { enabled: !!coingeckoId && !!queryOptions.find(({ value }) => value === option) }
  );

  return {
    chart,
    queryOptions,
  };
};

export default useCoingeckoChart;
