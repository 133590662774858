import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'components/svgIcon';
import { useOnClickOutside } from 'hooks';
import './index.scss';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
  Typography,
} from '@material-ui/core';

function CustomSelect({
  label,
  value,
  name,
  items,
  onSelect,
  disabled,
  fixed,
  absolute,
  maxHeight,
}) {
  const [opened, setOpened] = useState(false);
  const [tooltipModalItem, setTooltipModalItem] = useState(null);
  const ref = useRef();

  const onToggle = (e) => {
    e.stopPropagation();
    setOpened(!opened);
  };

  useOnClickOutside(ref, () => {
    setOpened(false);
  });

  const onSelectItem = (e, item) => {
    e.stopPropagation();

    onSelect(name, item.value);
    setOpened(false);
  };

  const getActiveItem = () => {
    const result = items.find((item) => item.value.toString() === value.toString());
    return result || items[0];
  };

  const activeItem = getActiveItem();

  const getDropStyle = () => {
    if (fixed) {
      const { left, bottom } = ref.current.getBoundingClientRect();
      const selectWidth = ref.current.clientWidth;
      return {
        position: 'fixed',
        width: `${selectWidth}px`,
        left: `${left}px`,
        top: `${bottom}px`,
        maxHeight: `${maxHeight || '100px'}`,
        overflowY: 'auto',
      };
    }
    if (absolute) {
      const selectWidth = ref.current.clientWidth;
      const selectHeight = ref.current.clientHeight;
      return {
        position: 'absolute',
        width: `${selectWidth}px`,
        left: `0`,
        top: `${selectHeight}px`,
        maxHeight: '200px',
        overflowY: 'auto',
      };
    }
  };

  return (
    <div className="custom-select" ref={ref}>
      {label.length > 0 && <label htmlFor="custom-input">{label}</label>}
      <div className={`custom-select__origin ${opened ? 'custom-select__origin--opened' : ''}`}>
        <div className="select-icon-wrapper vertical-center">
          <SvgIcon name="arrowDown" />
        </div>
        <button type="button" disabled={disabled} onClick={onToggle}>
          {activeItem?.name || activeItem?.value}
        </button>
      </div>
      {opened && (
        <div className="custom-select__expanded" style={getDropStyle()}>
          <div className="custom-select__expanded-menu">
            {items.map((item, index) =>
              item.hide ? null : (
                <button
                  type="button"
                  key={item.id || index}
                  className={`custom-select__expanded-menu__item ${
                    activeItem.value === item.value
                      ? 'custom-select__expanded-menu__item--active'
                      : ''
                  }`}
                  onClick={(e) => onSelectItem(e, item)}
                >
                  <div>
                    <label>{item.name || item.value}</label>
                    {item.subtitle && <label>{item.subtitle}</label>}
                  </div>
                  <div>
                    {item.tooltip && (
                      <Tooltip
                        title={item.tooltip}
                        classes={{
                          tooltip: 'custom-select__expanded-menu__tooltip',
                          arrow: 'custom-select__expanded-menu__arrow',
                        }}
                        arrow
                      >
                        <span
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            return item.tooltip && setTooltipModalItem(item);
                          }}
                        >
                          <SvgIcon name="help" width={16} height={16} />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </button>
              )
            )}
          </div>
        </div>
      )}
      <Dialog
        open={!!tooltipModalItem}
        onClose={() => setTooltipModalItem(null)}
        classes={{ paper: 'custom-select__tooltip-modal' }}
      >
        <div>
          <DialogTitle>
            <SvgIcon width={30} height={30} name="help" />
            {tooltipModalItem?.name || tooltipModalItem?.value} {tooltipModalItem?.subtitle}
          </DialogTitle>
          <button type="button" onClick={() => setTooltipModalItem(null)}>
            <SvgIcon name="closeRed" />
          </button>
        </div>
        <DialogContent>
          <Divider />
          <Typography gutterBottom>{tooltipModalItem?.tooltip}</Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape),
  onSelect: PropTypes.func,
  fixed: PropTypes.bool,
};

CustomSelect.defaultProps = {
  label: '',
  value: '',
  name: '',
  items: [],
  onSelect: () => {},
  fixed: false,
};

export default CustomSelect;
