import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import './index.scss';

const CheckboxAccordion = ({ title, options, updateOptions, className }) => {
  const handleChange = (e, option) => {
    updateOptions({ key: option, value: e.target.checked });
  };

  return (
    <Accordion className="custom-accordion">
      <AccordionSummary expandIcon={<SvgIcon name="arrowDown" />}>{title}</AccordionSummary>
      <AccordionDetails>
        {Object.entries(options).map(([option, isChecked]) => (
          <div key={option}>
            <span className={className}>{option}</span>
            <Checkbox checked={isChecked} onChange={(e) => handleChange(e, option)} />
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default CheckboxAccordion;
