export const getAllocationModelName = (allocationModelId, unlimitedTimestamp) => {
  if (unlimitedTimestamp > 0 && unlimitedTimestamp * 1000 < Date.now()) {
    return 'Unlimited';
  }

  switch (allocationModelId.toString()) {
    case '0':
      return 'ProRata';

    case '1':
      return 'Capped';

    case '2':
      return 'Unlimited';

    case '3':
      return 'CappedPerLevel';

    case '4':
      return 'CappedPerWallet';

    case '5':
      return 'PhaseCap';

    default:
      throw new Error(`Invalid allocation model ${allocationModelId.toString()}`);
  }
};

export const getAllocationModelId = (allocationModel) => {
  switch (allocationModel) {
    case 'ProRata':
      return 0;

    case 'Capped':
      return 1;

    case 'Unlimited':
      return 2;

    case 'CappedPerLevel':
      return 3;

    case 'CappedPerWallet':
      return 4;

    case 'PhaseCap':
      return 5;

    default:
      throw new Error(`Invalid allocation model ${allocationModel}`);
  }
};
