const disclaimRules = [
  {
    id: 0,
    text: `By clicking "confirm" I accept Terms of service, Privacy policy and KYC Policy related to functioning of BlackDragon App.`,
  },
  {
    id: 1,
    text: `https://blackdragon.io/legal/PrivacyPolicy.pdf`,
    type: 'link',
    title: 'Privacy Policy',
  },
  {
    id: 2,
    text: `https://blackdragon.io/legal/TermsOfService.pdf`,
    type: 'link',
    title: 'Terms of Service',
  },
  {
    id: 3,
    text: `https://blackdragon.io/legal/KYCPolicy.pdf`,
    type: 'link',
    title: 'Know Your Customer Policy',
  },
  {
    id: 4,
    text: 'Any tokens are offered only outside of the United States to non-U.S. persons, pursuant to the provisions of Regulation S of the U.S. Securities Act of 1933, as amended. The tokens will not be registered under the Securities Act, and may not be offered or sold in the United States absent registration or under an applicable exemption from the registration requirements and the purchasers should not assume they will be able to resell their tokens in the United States. Neither the Securities and Exchange Commission nor any state regulator has passed upon the merits of or given its approval to the tokens, the terms of the sale of the token, or the accuracy or completeness of any associated materials. Buying tokens involves risks, and purchasers should be able to bear the loss of their entire purchase. All purchasers should make their own determination of whether or not to make any purchase, based on their own independent evaluation and analysis.',
  },
];

export default disclaimRules;
