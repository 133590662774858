import React from 'react';
import SvgIcon from 'components/svgIcon';
import './index.scss';
import numeral from 'numeral';

const GeneralAnalytics = ({ analytics }) => {
  const realisedGains = analytics?.portfolioReturn
    ? `${numeral(analytics.portfolioReturn).format('$0,0')}`
    : '-';
  const pnl = analytics?.pnl ? `${numeral(analytics.pnl).format('$0,0')}` : '-';

  return (
    <div className="analytics-content__overview">
      <div className="analytic-container">
        <SvgIcon width={48} height={48} name="iconAnalyticDeals" />
        <div className="analytic-info">
          <span>DEALS</span>
          <span>{analytics.numberOfDeals}</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={80} height={48} name="iconRoi" />
        <div className="analytic-info">
          <span>CURRENT ROI</span>
          <span>{+analytics.roi.toFixed(1)}x</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={80} height={48} name="iconAthRoi" />
        <div className="analytic-info">
          <span>ATH ROI</span>
          <span>{+analytics.athRoi.toFixed(1)}x</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={48} height={48} name="iconInvest" />
        <div className="analytic-info">
          <span>INVESTED</span>
          <span>${numeral(analytics.totalContributedOnPlatform).format('0,0')}</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={48} height={48} name="iconReturn" />
        <div className="analytic-info">
          <span>REALISED GAINS</span>
          <span>{realisedGains}</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={48} height={48} name="iconProfit" />
        <div className="analytic-info">
          <span>PNL</span>
          <span>{pnl}</span>
        </div>
      </div>
    </div>
  );
};

export default GeneralAnalytics;
