import React from 'react';
import SortableTable from 'components/sortable-table';
import tableHead from '../utils/getTableHead';
import HistoryTableRow from './HistoryTableRow';
import './index.scss';

const HistoryTable = ({ logs, type, isModalOpen }) => {
  return (
    <div className="custom-claims-history-table">
      <SortableTable tableHead={tableHead.jobHistoryTableHead} rows={logs} type={type}>
        {(sortedRows) =>
          sortedRows.map((row) => (
            <HistoryTableRow isModalOpen={isModalOpen} key={row.id} {...row} />
          ))
        }
      </SortableTable>
    </div>
  );
};

export default HistoryTable;
