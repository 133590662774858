import React, { useState, useCallback, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import DragAndDrop from 'components/dragAndDrop';
import './index.scss';
import { useNotifications } from 'hooks';
import { Slider } from '@material-ui/core';

function ImageCrop({ onImageCropped, aspectRatio, label, inputRef }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [imageName, setImageName] = useState();
  // eslint-disable-next-line no-unused-vars
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const imageUrl = useMemo(
    () => uploadedImage && URL.createObjectURL(uploadedImage),
    [uploadedImage]
  );
  const [rotation, setRotation] = useState(0);

  const onRotate = () =>
    setRotation((prev) => {
      if (prev === -240) return 0;
      return prev - 90;
    });

  const onImageUpload = async (files) => {
    const file = files[0];
    if (!file) return showErrorNotification('No file selected');
    if (
      !(
        file.type.includes('image/png') ||
        file.type.includes('image/svg') ||
        file.type.includes('image/jpeg')
      )
    ) {
      return showErrorNotification('Invalid file type');
    }

    setUploadedImage(file);
  };

  const onImageCrop = (e) => {
    e.preventDefault();
    if (!croppedArea) return;
    const { width, height, x, y } = croppedArea;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const imageObj = new Image();

    canvas.width = width;
    canvas.height = height;

    imageObj.onload = function () {
      const heightOffset = canvas.height / 2;
      const widthOffset = canvas.width / 2;
      context.translate(widthOffset, heightOffset);
      context.rotate((rotation * Math.PI) / 180);
      context.translate(0, 0);
      context.drawImage(
        imageObj,
        x,
        y,
        width,
        height,
        widthOffset * -1,
        heightOffset * -1,
        width,
        height
      );

      const blobBin = window.atob(canvas.toDataURL('image/jpeg', 0.7).split(',')[1]);
      const array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new File([new Uint8Array(array)], uploadedImage.name, {
        type: uploadedImage.type,
      });

      if (file.size > 500000) {
        showErrorNotification('File is too big');
        return;
      }
      onImageCropped(file);
      setImageName(file.name);
      setUploadedImage(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedArea(null);
    };

    imageObj.src = imageUrl;
  };

  const incrementZoom = () => {
    setZoom((prev) => {
      const zoomToSet = prev + 0.1;
      return zoomToSet > 3 ? 3 : zoomToSet;
    });
  };

  const decrementZoom = () => {
    setZoom((prev) => {
      const zoomToSet = prev - 0.1;
      return zoomToSet < 1 ? 1 : zoomToSet;
    });
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);
  // TODO: move inline styles to class and set aspect ratio after design is ready
  return (
    <div className="image-crop">
      <div className="field">
        {label && <label className="label">{label}</label>}
        <DragAndDrop onDrop={onImageUpload}>
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={(e) => onImageUpload(e.target.files)}
          />
          <label className="input">
            {imageName || (
              <>
                <label onClick={() => inputRef.current.click()} className="highlight">
                  Choose a file
                </label>{' '}
                or drag it here
              </>
            )}
          </label>
        </DragAndDrop>
      </div>
      {uploadedImage && (
        <Dialog
          open={true}
          className="deal-info-modal team-modal"
          onClose={() => setUploadedImage(null)}
        >
          <div>
            <div className="modal-header">
              <div className="header-title">
                <SvgIcon name="iconDealsModalEdit" />
                <h3>Photo editor</h3>
              </div>
              <div className="header-actions">
                <RoundedButton type="transparent" onClick={() => setUploadedImage(null)}>
                  Cancel
                </RoundedButton>
                <RoundedButton type="primary" onClick={onImageCrop}>
                  <div className="d-flex">Apply</div>
                </RoundedButton>
              </div>
            </div>
            <div className="crop-area__container">
              <div className="crop-actions">
                <button onClick={onRotate}>
                  <SvgIcon name="iconCropRotate" />
                </button>
                <div className="crop-zoom-slider">
                  <div onClick={decrementZoom}>
                    <label>-</label>
                  </div>
                  <Slider
                    size="small"
                    color="secondary"
                    min={1}
                    max={3}
                    step={0.05}
                    onChange={(_, value) => setZoom(value)}
                    value={zoom}
                  />
                  <div onClick={incrementZoom}>
                    <label>+</label>
                  </div>
                </div>
              </div>
              <Cropper
                image={imageUrl}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                showGrid={false}
                minZoom={1}
                rotation={rotation}
                aspect={aspectRatio}
                track={false}
                style={{
                  cropAreaStyle: { borderColor: '#e9504f', boxShadow: 'none' },
                  containerStyle: { maxHeight: '400px', background: '#1b1b1b', top: '60px' },
                }}
              />
            </div>
            <div className="crop-picker">
              <div>
                <label>{uploadedImage.name}</label>
                <label>
                  {uploadedImage.size / 1000} KB | {uploadedImage.type}
                </label>
              </div>
              <button onClick={() => inputRef.current.click()}>
                <SvgIcon name="iconDealsModalEdit" />
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}

ImageCrop.propTypes = {
  onImageCropped: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
  label: PropTypes.string,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

ImageCrop.defaultProps = { aspectRatio: 1 / 1 };

export default ImageCrop;
