import React from 'react';
import './index.scss';
import { getTransactionLinkByChainId } from 'contracts/explorers';
import { formatAddress } from 'utils';

const TxHashLink = ({ chainId, hash }) => {
  return <a href={getTransactionLinkByChainId(chainId, hash)}>{formatAddress(hash)}</a>;
};

export default TxHashLink;
