import { useQuery } from 'react-query';
import remote from '../../../../remote';

function useContributionsQuery(address) {
  const { data, isFetching } = useQuery({
    queryKey: ['user-contributions', address],
    queryFn: () => remote.getUserContributions(address),
  });

  return {
    data,
    isLoading: isFetching,
  };
}

export default useContributionsQuery;
