import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import CustomSelect from 'components/select/custom-select';
import remote from 'screens/AllDealsSingle/remote';
import './index.scss';

const selectItems = [
  { value: 0, name: '-' },
  { value: 1, name: 'Vesting' },
  { value: 2, name: 'Distributed' },
];

const EditVestingStatusModal = ({ open, onClose, deal, isOldDeal }) => {
  const findVestingStatusId =
    selectItems.find((item) => item.name === deal.vestingStatus?.name)?.value ?? 0;

  const updateOldDealStatus = remote.useUpdateVestingStatus(deal.id);
  const [data, setData] = useState({
    vestingStatusId: findVestingStatusId,
  });
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      await updateOldDealStatus.mutateAsync({
        vestingStatusId: data.vestingStatusId || null,
        isOldDeal,
      });
      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal basic-metrics-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Vesting status</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" buttonType="submit" form="basic-metrics-form">
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="basic-metrics-form" onSubmit={onConfirm}>
        <div className="col col-left">
          <CustomSelect
            items={selectItems}
            value={data.vestingStatusId}
            onSelect={(_, value) => onChangeData({ target: { name: 'vestingStatusId', value } })}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default EditVestingStatusModal;
