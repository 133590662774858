import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import { formatFiatPrice } from 'utils/helpers';
import { getInvoicePdf } from 'services/apiService';
import PdfHandler from 'components/PdfHandler';
import './index.scss';

const InvoiceTableRows = ({ rows }) => {
  return (
    <>
      {rows.map(
        ({ dealName, invoiceNumber, date, totalTransactionAmount, feeUsd, id, isStorno }, i) => (
          <TableRow key={i.toString()}>
            <TableCell>{dealName}</TableCell>
            <TableCell>{invoiceNumber}</TableCell>
            <TableCell>{moment(date).format('D-MMMM-YYYY')}</TableCell>
            <TableCell>{formatFiatPrice(totalTransactionAmount, true)} USDT</TableCell>
            <TableCell>{formatFiatPrice(feeUsd, true)} USDT</TableCell>
            <TableCell>
              <div className="invoice-actions">
                <PdfHandler
                  id={id}
                  getInvoicePdf={(invoiceId) => getInvoicePdf(invoiceId, isStorno)}
                  open
                />
                <div className="icon-download">
                  <PdfHandler
                    id={id}
                    getInvoicePdf={(invoiceId) => getInvoicePdf(invoiceId, isStorno)}
                  />
                </div>
              </div>
            </TableCell>
          </TableRow>
        )
      )}
    </>
  );
};

export default InvoiceTableRows;
