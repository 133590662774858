import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiGet, apiPost, baseUrl } from 'services/apiService';

const getAllClaims = async () => {
  const response = await apiGet(`${baseUrl}/api/jobs/all-claims`);
  return response.data;
};

const useGetAllClaims = () => {
  return useQuery(['all-claims'], getAllClaims);
};

const startJob = async (jobName) => {
  const response = await apiPost(`${baseUrl}/api/jobs/start/${jobName}`);
  return response.data;
};

const useStartJob = () => {
  const queryClient = useQueryClient();

  return useMutation(({ jobName }) => startJob(jobName), {
    onSuccess: () => {
      queryClient.invalidateQueries(['all-claims']);
    },
  });
};

const stopJob = async (jobName) => {
  const response = await apiPost(`${baseUrl}/api/jobs/stop/${jobName}`);
  return response.data;
};

const useStopJob = () => {
  const queryClient = useQueryClient();

  return useMutation(({ jobName }) => stopJob(jobName), {
    onSuccess: () => {
      queryClient.invalidateQueries(['all-claims']);
    },
  });
};

const getJobHistory = async () => {
  const response = await apiGet(`${baseUrl}/api/jobs/claim-logs`);
  return response.data;
};

const useGetJobHistory = () => {
  return useQuery(['claim-logs'], getJobHistory);
};

const getLogsForJob = async (jobId) => {
  const response = await apiGet(`${baseUrl}/api/jobs/claim-logs/${jobId}`);
  return response.data;
};

const useGetLogsForJob = (jobId, enabled) => {
  return useQuery(['claim-logs', jobId], () => getLogsForJob(jobId), { enabled });
};

const runJobManually = async (type, jobName) => {
  const response = await apiPost(`${baseUrl}/api/jobs/run/${type}/${jobName}`);
  return response.data;
};

const useRunJobManually = () => {
  const queryClient = useQueryClient();

  return useMutation(({ type, jobName }) => runJobManually(type, jobName), {
    onSuccess: () => {
      queryClient.invalidateQueries(['all-claims']);
    },
  });
};

export default {
  useGetAllClaims,
  useStartJob,
  useStopJob,
  useGetLogsForJob,
  useGetJobHistory,
  useRunJobManually,
};
