import React from 'react';
import moment from 'moment';

import { formatTokenPrice } from 'utils/helpers';
import { renderSafeValue } from 'utils';
import InfoContainer from '../../../../../components/InfoContainer';

const ContributionInfo = ({ user }) => {
  if (!user) return null;

  const contributionInfo = [
    {
      label: 'Total',
      value: user.contributionData
        ? `${formatTokenPrice(user.contributionData.totalContributions, true, 6, true)} USDT`
        : null,
    },
    {
      label: 'Last Year Total',
      value: user.contributionData
        ? `${formatTokenPrice(
            user.contributionData.lastYearTotalContributions,
            true,
            6,
            true
          )} USDT`
        : null,
    },
    {
      label: 'Average',
      value: user.contributionData
        ? `${formatTokenPrice(
            Math.floor(user.contributionData.averageContribution),
            true,
            6,
            true
          )} USDT`
        : null,
    },
    {
      label: 'Last Contribution',
      value: user.contributionData?.lastContribution
        ? `${formatTokenPrice(user.contributionData.lastContribution.amount, true, 6, true)} USDT`
        : null,
    },
    {
      label: 'Last Contribution Date',
      value: user.contributionData?.lastContribution
        ? moment(user.contributionData.lastContribution.createdAt).format('DD MMM YYYY')
        : null,
    },
  ];

  return (
    <InfoContainer title="CONTRIBUTION INFO">
      <ul className="item-list">
        {contributionInfo.map((info) => (
          <li key={info.label} className="item">
            <p className="label">{info.label}</p>
            <p className="value">{renderSafeValue(info.value)}</p>
          </li>
        ))}
      </ul>
    </InfoContainer>
  );
};

export default ContributionInfo;
