import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiGet, apiPut, baseUrl } from 'services/apiService';

const getDealTeamAdvisors = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/${dealId}/team-advisors`);
  return response.data;
};

const useGetDealTeamAdvisors = (dealId, enabled) => {
  return useQuery(['deal', dealId, 'team-advisors'], () => getDealTeamAdvisors(dealId), {
    enabled: !!dealId && !!enabled,
    placeholderData: [],
  });
};

const updateDealTeamAdvisors = async (dealId, id, data) => {
  const response = await apiPut(`${baseUrl}/api/deal/admin/${dealId}/team-advisors`, {
    id,
    dealId: +dealId,
    logo: data.logo,
    website: data.website,
    sortField: +data.sortField,
  });

  return response.data;
};

const useUpdateDealTeamAdvisors = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(({ id, data }) => updateDealTeamAdvisors(dealId, id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', dealId, 'team-advisors']);
    },
  });
};

export default { useGetDealTeamAdvisors, useUpdateDealTeamAdvisors };
