import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { deleteChangeLog, getChangeLogs, updateChangeLog, addChangeLog } from 'services/apiService';
import IconButton from 'components/button/icon-button';
import SvgIcon from 'components/svgIcon';
import './index.scss';
import { useNotifications } from 'hooks';
import ChangeLogAddEditModal from 'features/deals/ChangeLogAddEditModal';
import { useSelector } from 'react-redux';

const LogTab = ({ deal }) => {
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin } = authReducer;
  const [logs, setLogs] = useState([]);
  const [logToAddEdit, setLogToAddEdit] = useState(null);
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  useEffect(() => {
    (async () => {
      if (deal) {
        setLogs(await getChangeLogs(deal.id));
      }
    })();
  }, [deal]);

  const parseType = (type) =>
    type
      .split('_')
      .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
      .join(' ');

  const handleDelete = async (log) => {
    try {
      await deleteChangeLog(log.id);
      setLogs((prev) => [...prev.filter(({ id }) => id !== log.id)]);
      showSuccessNotification('Sucessfully deleted log');
    } catch {
      showErrorNotification('Something went wrong while deleting log');
    }
  };

  const handleLogAdd = async (log) => {
    try {
      const newLog = await addChangeLog(log, deal.id);
      setLogs((prev) => [...prev, newLog].sort((a, b) => (a.date > b.date ? -1 : 1)));
      setLogToAddEdit(null);
      showSuccessNotification('Sucessfully added log');
    } catch {
      showErrorNotification('Something went wrong while adding log');
    }
  };

  const handleLogUpdate = async (log) => {
    try {
      await updateChangeLog(log);
      setLogs((prev) =>
        [...prev.map((l) => (l.id === log.id ? log : l))].sort((a, b) => (a.date > b.date ? -1 : 1))
      );
      setLogToAddEdit(null);
      showSuccessNotification('Sucessfully edited log');
    } catch {
      showErrorNotification('Something went wrong while editing log');
    }
  };

  return (
    <div className="content log-content">
      <ChangeLogAddEditModal
        open={!!logToAddEdit}
        onOk={(log) => (log.id ? handleLogUpdate(log) : handleLogAdd(log))}
        onClose={() => setLogToAddEdit(null)}
        log={logToAddEdit}
      />
      <div className="content-row">
        {logs.map((log) => (
          <div key={log.id} className="log-item">
            {isAdmin && (
              <div className="item-actions">
                <IconButton onClick={() => setLogToAddEdit(log)} icon="iconDealsEdit" />
                <IconButton onClick={() => handleDelete(log)} icon="iconErase" />
              </div>
            )}
            <div className="item-date">
              <p className="info">Date</p>
              <p className="value">{moment(log.date).format('D MMMM YYYY, HH:mm')}</p>
            </div>
            <div className="item-type">
              <p className="info">Type</p>
              <p className="value">{parseType(log.type)}</p>
            </div>
            <div className="item-description">
              <p className="info">Description</p>
              <p className="value">{log.description}</p>
            </div>
          </div>
        ))}
        {isAdmin && (
          <div className="log-item">
            <div
              className="add-log"
              onClick={() =>
                setLogToAddEdit({ date: new Date(), type: 'BASIC_METRIC_CHANGES', description: '' })
              }
            >
              <div className="add-log__icon">
                <SvgIcon name="plus" width={16} height={16} />
              </div>
              <p>Add new</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LogTab;
