import { useMutation, useQueryClient } from 'react-query';
import { updateIsDelivered } from 'services/apiService';

const useUpdateIsDelivered = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ vestingItemId, isDelivered }) => updateIsDelivered(vestingItemId, isDelivered),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['distribution', 'schedule']);
      },
    }
  );
};

export default {
  useUpdateIsDelivered,
};
