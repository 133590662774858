import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from 'containers/app';
import store from 'store';
import 'typeface-barlow';
import 'react-sweet-progress/lib/style.css';
import 'sass/global.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'services/apiService';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

const Root = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
);

render(<Root />, document.getElementById('root'));
