import React from 'react';
import { FormControl, FormHelperText, TextField, FormLabel } from '@material-ui/core';
import { AdditionalPepInfoFields } from './constants';

export default function PepRelationshipInfo({ values, errors, onChange }) {
  return (
    <FormControl disabled={!values.isPep} className="politically-exposed-form">
      <FormLabel style={{ marginTop: '20px', marginBottom: '10px' }}>
        Information on the politically exposed person of whom I am a family member or close
        associate:
      </FormLabel>
      {AdditionalPepInfoFields.slice(2).reduce((acc, field, index, array) => {
        if (index % 2 === 0) {
          const fieldsToRender = [field, array[index + 1]].filter(Boolean);
          acc.push(
            <div key={field.id} style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              {fieldsToRender.map(({ id, label, props, required: { dependentFields } = {} }) => (
                <FormControl key={id} fullWidth margin="dense" error={!!errors?.[id]}>
                  <p className="profile-col-title">{label}</p>
                  <TextField
                    fullWidth
                    margin="dense"
                    id={id}
                    name={id}
                    variant="filled"
                    value={values[id]}
                    onChange={(e) => onChange(e, dependentFields)}
                    {...(props ? props(values, id, onChange) : {})}
                  />
                  {errors?.[id] && <FormHelperText>This field is required.</FormHelperText>}
                </FormControl>
              ))}
            </div>
          );
        }
        return acc;
      }, [])}
    </FormControl>
  );
}
