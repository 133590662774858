const getTruthyFields = (filter) =>
  Object.entries(filter)
    .filter((entry) => Boolean(entry[1]))
    .map((el) => el[0].toLowerCase());

const applySearchFilter = (search, balance) => {
  return balance.dealName.toLowerCase().includes(search.toLowerCase());
};

const applyDealStatusFilter = (statuses, balance) => {
  if (!statuses.length) return true;
  return statuses.includes(balance.dealStatus.toLowerCase());
};

const applyBlockchainFilter = (blockchains, balance) => {
  if (!blockchains.length) return true;
  return blockchains.includes(balance.chain.toLowerCase());
};

const applyNativeTokensFilter = (hasNativeTokens, balance) => {
  if (!hasNativeTokens) return true;
  return +balance.balance > 0;
};

const applyHasClaimerFilter = (filter, balance) => {
  if (filter.toLowerCase() === 'all') return true;
  if (filter.toLowerCase() === 'yes') return Boolean(balance.claimerAddress);
  return Boolean(!balance.claimerAddress);
};

const filtersPipeline = (balances, filters) => {
  if (!balances) return [];

  const appliedDealStatuses = getTruthyFields(filters.dealStatus);
  const appliedBlockchains = getTruthyFields(filters.blockchain);

  const applyFilters = [
    (balance) => applySearchFilter(filters.search, balance),
    (balance) => applyDealStatusFilter(appliedDealStatuses, balance),
    (balance) => applyBlockchainFilter(appliedBlockchains, balance),
    (balance) => applyNativeTokensFilter(filters.hasNativeTokens, balance),
    (balance) => applyHasClaimerFilter(filters.hasClaimer, balance),
  ];

  const filterResult = applyFilters.reduce(
    (filteredBalances, filterFunc) => filteredBalances.filter(filterFunc),
    balances
  );

  return filterResult;
};

export default filtersPipeline;
