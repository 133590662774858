import { useReducer } from 'react';

const parseFilterArray = (arr) =>
  arr.filter(Boolean).reduce((acc, item) => ({ ...acc, [item]: false }), {}); // {Live: false, Paused: false}

function init(deals) {
  const rawBlockchain = [...new Set(deals.map((deal) => deal.blockchain))];
  const rawOrigin = [...new Set(deals.map((deal) => deal.origin))];
  const rawDistributionModel = [...new Set(deals.map((deal) => deal.distributionModel))];

  const blockchain = parseFilterArray(rawBlockchain);
  const origin = parseFilterArray(rawOrigin);
  const distributionModel = parseFilterArray(rawDistributionModel);

  return {
    search: '',
    blockchain,
    origin,
    distributionModel,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'updateSearch':
      return { ...state, search: action.payload };
    case 'updateBlockchain':
      return {
        ...state,
        blockchain: { ...state.blockchain, [action.payload.key]: action.payload.value },
      };
    case 'updateOrigin':
      return {
        ...state,
        origin: { ...state.origin, [action.payload.key]: action.payload.value },
      };
    case 'updateDistributionModel':
      return {
        ...state,
        distributionModel: {
          ...state.distributionModel,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      throw new Error();
  }
}

const useFilters = (deals) => {
  const [filters, dispatch] = useReducer(reducer, deals, init);

  const dispatchUpdateSearch = (search) => dispatch({ type: 'updateSearch', payload: search });
  const dispatchUpdateBlockchain = ({ key, value }) =>
    dispatch({ type: 'updateBlockchain', payload: { key, value } });
  const dispatchUpdateOrigin = ({ key, value }) =>
    dispatch({ type: 'updateOrigin', payload: { key, value } });
  const dispatchUpdateDistributionModel = ({ key, value }) =>
    dispatch({ type: 'updateDistributionModel', payload: { key, value } });

  return {
    filters,
    actions: {
      dispatchUpdateSearch,
      dispatchUpdateBlockchain,
      dispatchUpdateOrigin,
      dispatchUpdateDistributionModel,
    },
  };
};

export default useFilters;
