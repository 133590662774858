import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, TableCell, TableRow } from '@material-ui/core';
import SortableTable from 'components/sortable-table';
import RoundedButton from 'components/button/rounded-button';
import SearchInput from 'components/filters/SearchInput';
import remote from 'screens/AllDeals/remote';
import { useNotifications } from 'hooks';
import utils from './utils';
import './index.scss';

const AssignWalletModal = ({
  isModalOpen,
  closeModal,
  assigneWallet,
  assignedWallet,
  wallets = [],
  dealId,
  isOldDeal,
}) => {
  const updateReceiveWallet = remote.useUpdateReceiveWallet(dealId);
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const [searchValue, setSearchValue] = useState('');
  const filteredWallets = wallets?.filter((wallet) =>
    wallet.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const onUpdateReceiveWallet = async () => {
    try {
      await updateReceiveWallet.mutateAsync({
        walletId: assignedWallet,
        isOldDeal,
      });
      showSuccessNotification('Receive wallet updated!');
    } catch (err) {
      showErrorNotification('Receive wallet not updated!');
    } finally {
      closeModal();
    }
  };

  if (!wallets) return null;

  return (
    <Dialog open={isModalOpen} onClose={closeModal} classes={{ paper: 'assign-wallet-modal' }}>
      <DialogContent>
        <div className="search-container">
          <SearchInput
            searchValue={searchValue}
            updateSearchValue={setSearchValue}
            showInput
            setShowInput={() => {}}
            placeholder="Search by wallet name"
          />
        </div>
        <div className="table-container">
          <SortableTable rows={filteredWallets} tableHead={utils.tableHead} type="assign-wallet">
            {(sortedRows) =>
              sortedRows.map((row) => (
                <TableRow
                  key={row.id}
                  className={row.id === assignedWallet ? 'assigned' : ''}
                  onClick={() => assigneWallet(row.id)}
                >
                  <TableCell colSpan="1">{row.address}</TableCell>
                  <TableCell colSpan="1">{row.name}</TableCell>
                </TableRow>
              ))
            }
          </SortableTable>
        </div>
      </DialogContent>
      <DialogActions>
        <RoundedButton className="action-button action-button--close" onClick={closeModal}>
          Cancel
        </RoundedButton>
        <RoundedButton
          className="action-button action-button--submit"
          onClick={onUpdateReceiveWallet}
          disabled={!assignedWallet}
        >
          Assign
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignWalletModal;
