import { Dialog, Divider, LinearProgress } from '@material-ui/core';
import IconButton from 'components/button/icon-button';
import React, { useEffect, useState } from 'react';
import { getPollResults } from 'services/apiService';
import { formatOverviewText } from 'utils/helpers';
import DOMPurify from 'dompurify';

const loaderColors = ['purple', 'green', 'red', 'orange', 'yellow', 'turquoise'];

const PollResultModal = ({ poll, onClose }) => {
  const [optionsWithResults, setOptionsWithResults] = useState([]);

  useEffect(() => {
    if (poll) {
      (async () => {
        const result = await getPollResults(poll.id);

        setOptionsWithResults(
          poll.options.map((option) => {
            const optionResult = result.find(({ optionId }) => +optionId === option.id);
            if (!optionResult) {
              return {
                ...option,
                voteCount: 0,
                percent: 0,
                percentageOutOfTotal: 0,
              };
            }
            return {
              ...option,
              voteCount: optionResult.voteCount,
              percent: Math.round(optionResult.percent * 100),
              percentageOutOfTotal: Math.round(optionResult.percentageOutOfTotal * 100),
            };
          })
        );
      })();
    } else {
      setOptionsWithResults([]);
    }
  }, [poll]);

  return (
    <Dialog open={!!poll} onClose={onClose} classes={{ paper: 'poll-modal__container' }}>
      <div className="poll-modal">
        <div className="poll-modal__head">
          <div>
            <span>{poll?.title}</span>
          </div>
          <div>
            <IconButton icon="close" onClick={onClose} />
          </div>
        </div>
        <Divider />
        <div className="poll-modal__body">
          <div className="results-wrapper">
            <h2>{poll?.question}</h2>
            {poll?.name && (
              <div className="poll-info">
                <h2>Deal name:</h2>
                <h2>{poll.name}</h2>
              </div>
            )}
            {poll?.pollInfo && (
              <div className="poll-info">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: formatOverviewText(DOMPurify.sanitize(poll.pollInfo)),
                  }}
                ></h2>
              </div>
            )}
            <ul>
              {optionsWithResults?.map(
                ({ label, id, percent, voteCount, percentageOutOfTotal }, i) => (
                  <li key={id}>
                    <div className="results-wrapper__above-loader">
                      <span>{label}</span>
                      <span>{percent}%</span>
                    </div>
                    <div className={`results-wrapper__loader-${loaderColors[i] || 'purple'}`}>
                      <LinearProgress variant="determinate" value={percent} />
                    </div>
                    <div className="results-wrapper__under-loader">
                      <span>Votes: {voteCount}</span>
                      <span>Participation: {percentageOutOfTotal}%</span>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PollResultModal;
