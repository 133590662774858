import { useReducer } from 'react';

const parseFilterArray = (arr) => arr.reduce((acc, item) => ({ ...acc, [item]: false }), {}); // {Live: false, Paused: false}

function init(deals) {
  const rawDealStatus = [...new Set(deals.map((balance) => balance.dealStatus))];

  const dealStatus = parseFilterArray(rawDealStatus);

  return {
    search: '',
    dealStatus,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'updateSearch':
      return { ...state, search: action.payload };
    case 'updateDealStatus':
      return {
        ...state,
        dealStatus: { ...state.dealStatus, [action.payload.key]: action.payload.value },
      };
    default:
      throw new Error();
  }
}

const useFilters = (deals) => {
  const [filters, dispatch] = useReducer(reducer, deals, init);

  const dispatchUpdateSearch = (search) => dispatch({ type: 'updateSearch', payload: search });
  const dispatchUpdateDealStatus = ({ key, value }) =>
    dispatch({ type: 'updateDealStatus', payload: { key, value } });

  return {
    filters,
    actions: {
      dispatchUpdateSearch,
      dispatchUpdateDealStatus,
    },
  };
};

export default useFilters;
