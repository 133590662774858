import RoundedButton from 'components/button/rounded-button';
import AnalyticList from 'features/analytics/AnalyticList';
import DashboardAnalytics from 'features/dashboard/DashboardAnalytics';
import GeneralAnalytics from 'features/analytics/GeneralAnalytics';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SvgIcon from 'components/svgIcon';
import CustomSelect from 'components/select/custom-select';
import DealAnalyticChart from 'features/analytics/DealAnalyticChart';

import remote from './remote';
import './index.scss';

const allYears = 'All';

const Dashboard = () => {
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin } = authReducer;

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(
    history.location.state?.activeTab ? 'overview' : 'dashboard'
  );

  const [searchValue, setSearchValue] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [year, setYear] = useState('All');

  const analyticOverview = remote.useAnalyticOverview(year, activeTab);
  const allYearsAnalyticOverview = remote.useAnalyticOverview('All', activeTab);
  const dashboardOverview = remote.useDashboardOverview(activeTab);

  const filterableYears = useMemo(() => {
    const years = new Set([allYears]);
    if (!allYearsAnalyticOverview?.data) return [...years];
    allYearsAnalyticOverview.data.deals.forEach((deal) => {
      if (deal.dateClosed) {
        const dealYear = new Date(deal.dateClosed).getFullYear();
        years.add(dealYear);
      }
    });
    return [...years].sort((a, b) => a - b);
  }, [allYearsAnalyticOverview.data]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleInput = () => {
    if (window.innerWidth < 767) {
      setShowInput(!showInput);
    }
  };

  return (
    <>
      <div className="dash-header">
        <div className="dash-header-left">
          <div className="dash-header__title">Dashboard</div>
        </div>
        <div className="dash-header-right">
          {activeTab !== 'dashboard' && (
            <div className={`search-input${showInput ? ' active' : ''}`}>
              <div className="input-icon-wrapper vertical-center" onClick={toggleInput}>
                <SvgIcon name="search" />
              </div>
              <input
                type="text"
                value={searchValue}
                placeholder="Search deals by name"
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="dash-content">
        <div className="analytics-tabs-header">
          <div className="dash-tabs">
            <RoundedButton
              className={activeTab === 'dashboard' ? 'active' : ''}
              onClick={() => setActiveTab('dashboard')}
            >
              General Info
            </RoundedButton>
            <RoundedButton
              className={activeTab === 'overview' ? 'active' : ''}
              onClick={() => setActiveTab('overview')}
            >
              Deals Overview
            </RoundedButton>
          </div>
          {activeTab === 'overview' && (
            <CustomSelect
              items={filterableYears.map((item) => ({ value: item }))}
              value={year}
              onSelect={(_, value) => {
                setYear(value);
              }}
            />
          )}
        </div>
        {analyticOverview.isError && (
          <h6
            style={{ color: 'white' }}
          >{`${analyticOverview.error?.response?.statusText}. Please try again later...`}</h6>
        )}
        {activeTab === 'dashboard' && dashboardOverview.data && (
          <>
            <DashboardAnalytics dashboardOverview={dashboardOverview.data} />
            <div className="chart-title">BlackDragon Token</div>
            <DealAnalyticChart
              dealAnalytics={{
                data: {
                  coingeckoApiId: 'blackdragon-token',
                  sellTransactions: [],
                  currentPrice: dashboardOverview.data.bdtPriceInUSD,
                },
              }}
              hideSellPointsFeature
            />
          </>
        )}
        {activeTab === 'overview' && analyticOverview.data && (
          <>
            {isAdmin && <GeneralAnalytics analytics={analyticOverview.data} />}
            <AnalyticList
              analyticDeals={analyticOverview.data.deals}
              searchValue={searchValue}
              type="deals-overview"
            />
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
