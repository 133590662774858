import React from 'react';
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import TransferModalTable from './TransferModalTable';
import './index.scss';
import remote from '../remote';

const TransferModal = ({ isModalOpen, closeModal, transfers, transferData }) => {
  const transferFunds = remote.useTransferBalancesToWallets();
  const onTransferClick = async () => {
    await transferFunds.mutateAsync(transferData);
    closeModal();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      classes={{ paper: 'transfer-modal' }}
      disableBackdropClick
    >
      <DialogTitle>
        Transfer funds{' '}
        <IconButton aria-label="close" onClick={closeModal}>
          X
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TransferModalTable transfers={transfers} />
      </DialogContent>
      <DialogActions className="tranfer-modal-actions">
        <div className="action-buttons">
          <RoundedButton className="action-button action-button--close" onClick={closeModal}>
            Cancel
          </RoundedButton>
          <RoundedButton
            className="action-button action-button--submit"
            onClick={onTransferClick}
            disabled={transferFunds.isLoading}
          >
            Transfer
          </RoundedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default TransferModal;
