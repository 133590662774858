import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import Checkbox from 'components/checkbox';
import './index.scss';
import { updateSendDealCancelationInvoices } from 'services/apiService';

const ConfirmDealCancellationModal = ({ open, onOk, onClose, dealId, dealName }) => {
  const [sendCancellationInvoices, setSendCancellationInvoices] = useState(false);

  const onCancel = async () => {
    if (sendCancellationInvoices) {
      await updateSendDealCancelationInvoices(dealId, sendCancellationInvoices);
    }

    await onOk();
  };

  return (
    <Dialog
      open={open}
      className="close-deal-modal unlock-lockup-modal"
      id="close-deal-modal"
      onClose={onClose}
    >
      <div className="close-deal-modal-header d-flex">
        <div className="close-deal-modal-header__left d-flex vertical-center">
          <div className="deal-name vertical-center">
            <div>
              <span>
                Are you sure you want to cancel deal <strong>{dealName}</strong>?
              </span>
            </div>
          </div>
        </div>
        <div className="close-deal-modal-header__right vertical-center">
          <RoundedButton type="primary" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton onClick={onCancel}>
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
        <div className="cancellation-invoice">
          <label>Create cancelation (storno) invoices for users</label>
          <Checkbox
            checked={sendCancellationInvoices}
            onChange={(e) => setSendCancellationInvoices(e.target.checked)}
          />
        </div>
      </div>
      <div className="close-deal-modal-body">
        <div className="message-wrapper">
          <SvgIcon name="error" />
          <div className="modal-inputs">
            <label>
              Cancelling the deal will cancel all generated invoices and allow users to re-claim
              their contributions.
            </label>
          </div>
        </div>
        <div className="update-lockup-mobile-actions">
          <RoundedButton type="primary" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton onClick={onCancel}>Confirm</RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDealCancellationModal;
