import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Divider } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomInput from 'components/input/custom-input';
import CustomSelect from 'components/select/custom-select';

const AddUserRoleDialog = ({ open, onSave, onCancel, roles, userToEdit, setUserToEdit }) => {
  const [userRoleToAdd, setUserRoleToAdd] = useState({ username: '', email: '', address: '', role: roles?.[0]?.name || '' });

  useEffect(() => {
    if (userToEdit)
      setUserRoleToAdd({ username: userToEdit.name, email: userToEdit.email, address: userToEdit.wallet, role: userToEdit.role })
  }, [userToEdit]);

  useEffect(() => {
    if (roles) {
      setUserRoleToAdd((prev) => ({
        ...prev,
        role: roles?.[0]?.name || '',
      }));
    }
    
    roles.sort((r1, r2) =>
      r1.name.toLowerCase().localeCompare(r2.name.toLowerCase())
    );
  }, [roles]);

  const handleSave = async () => {
    await onSave(userRoleToAdd);
    setUserRoleToAdd({
      username: '',
      email: '',
      address: '',
      role: roles?.[0]?.name || '',
    });
    setUserToEdit(null);
  };

  const handleCancel = () => {
    setUserRoleToAdd({
      username: '',
      email: '',
      address: '',
      role: roles?.[0]?.name || '',
    });
    setUserToEdit(null);
    onCancel();
  };

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: 'add-user-modal' }}>
      <div className="add-user-modal__header">
        <h2>
          <SvgIcon name="iconDealsModalEdit" />
          <span>Add new member</span>
        </h2>
        <div>
          <RoundedButton type="transparent" onClick={handleCancel}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={handleSave}>
            Save
          </RoundedButton>
        </div>
      </div>
      <DialogContent>
        <Divider />
        <div className="add-user-modal__content">
          <CustomInput
            label="User Name"
            name="username"
            value={userRoleToAdd.username}
            onChange={(e) => setUserRoleToAdd((prev) => ({ ...prev, username: e.target.value }))}
          />
          <CustomInput
            label="Email"
            name="email"
            value={userRoleToAdd.email}
            onChange={(e) => setUserRoleToAdd((prev) => ({ ...prev, email: e.target.value }))}
          />
          <CustomInput
            label="Account"
            name="address"
            disabled={userToEdit?.wallet}
            value={userRoleToAdd.address}
            onChange={(e) => setUserRoleToAdd((prev) => ({ ...prev, address: e.target.value }))}
          />
          <CustomSelect
            items={roles.map(({ name }) => ({ name, value: name }))}
            name="role"
            value={userRoleToAdd.role}
            fixed
            maxHeight="12vh"
            onSelect={(name, role) => setUserRoleToAdd((prev) => ({ ...prev, [name]: role }))}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddUserRoleDialog;
