import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { usePagination } from 'hooks';
import Tooltip from 'components/tooltip';
import './index.scss';

const SortableTable = ({
  tableHead,
  rows,
  type,
  children,
  actionHeaderCellComponent,
  tableFooter,
  paginationItemsCount,
  classNames,
}) => {
  const initialSortValues = JSON.parse(sessionStorage.getItem(type));
  const initialColumnValue = initialSortValues?.column ?? '';
  const initialDirectionValue = initialSortValues?.direction ?? '';

  const [sortBy, setSortBy] = useState({
    column: initialColumnValue,
    direction: initialDirectionValue,
  });

  const getSortArrow = (columnName) => {
    if (!columnName || columnName !== sortBy.column) return null;
    if (sortBy.direction === '') return null;
    if (sortBy.direction === 'desc') return <span style={{ color: '#e9504f' }}>&nbsp;&uarr;</span>;
    if (sortBy.direction === 'asc') return <span style={{ color: '#e9504f' }}>&nbsp;&darr;</span>;
    return null;
  };

  const getNextSortDirection = () => {
    if (sortBy.direction === 'asc') return 'desc';
    if (sortBy.direction === 'desc') return '';
    return 'asc';
  };

  const onSort = (column, direction) => {
    setSortBy({ column, direction });
    if (type) {
      sessionStorage.setItem(type, JSON.stringify({ column, direction }));
    }
  };

  const sortRows = (tableRows, column, direction) => {
    if (tableRows.length === 0) return tableRows;

    const defaultSortFunc = (a, b) => (b[column] > a[column] ? 1 : -1);
    const currentSortFunc =
      tableHead.find((head) => head.column === sortBy.column)?.sort ?? defaultSortFunc;

    const descSort = tableRows.sort(currentSortFunc);
    return direction === 'desc' ? descSort : descSort.reverse();
  };

  const initialSortFunc = tableHead.find((row) => row.defaultSortField)?.sort ?? rows[0]?.sort;

  const getSortedRows = () => {
    const rowsToSort = [...rows];
    if (!sortBy.column || !sortBy.direction) {
      return rowsToSort.sort(initialSortFunc);
    }
    return sortRows(rowsToSort, sortBy.column, sortBy.direction);
  };
  const sortedRows = getSortedRows();
  const { Pagination, paginatedItems } = usePagination(sortedRows, paginationItemsCount);
  const rowsToDisplay = paginationItemsCount ? paginatedItems : sortedRows;

  const isFirstColumnEmpty = Object.keys(tableHead[0]).length === 0;
  const tableClassNames = [
    'sortable-table',
    type ?? '',
    isFirstColumnEmpty && 'with-empty-col',
    ...classNames,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <Table className={tableClassNames}>
        <TableHead>
          <TableRow>
            {actionHeaderCellComponent}
            {tableHead.map((cell, i) => (
              <TableCell
                key={i.toString()}
                onClick={() => onSort(cell.column, getNextSortDirection())}
              >
                <Tooltip title={cell.tooltipTitle || ''}>
                  <span className="d-flex">
                    {cell.label} {getSortArrow(cell.column)}
                  </span>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children(rowsToDisplay)}</TableBody>
        {tableFooter}
      </Table>
      {paginationItemsCount && <Pagination />}
    </>
  );
};

SortableTable.propTypes = {
  tableHead: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      label: PropTypes.string,
      sort: PropTypes.func,
      defaultSortField: PropTypes.bool,
    })
  ),
  type: PropTypes.oneOf([
    'deals-overview',
    'portfolio',
    'sell-transactions',
    'custom-claims',
    'job-logs',
    'balances',
    'balances-modal',
    'transfer-modal',
    'all-deals',
    'history',
    'deal-info',
    'assign-wallet',
    'deal-info-addresses',
    'batch-schedule-distributions',
    'batch-schedule-distributions-fixed',
    'history-distributions',
    'history-distributions-fixed',
    'custom-claims-history',
    'users-legal',
  ]),
  children: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

SortableTable.defaultProps = {
  tableHead: [],
  children: null,
  type: undefined,
  classNames: [],
};

export default SortableTable;
