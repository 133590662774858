import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NotificationSelect from 'components/select/notification-select';
import DealApproveNotification from 'features/notifications/Notification';
import { updateGlobal, checkNotifications } from 'store/actions';
import Logo from 'assets/logo.svg';
import './index.scss';
import ProfileDropdown from 'features/header/ProfileDropdown';

function HeaderBar({ logoutOfWeb3Modal }) {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.global);
  const authReducer = useSelector((state) => state.auth);
  const {
    notificationDropdownOpened,
    notifications,
    activeDeal,
    isSidebarExpanded,
    isPreviewMode,
  } = globalReducer;
  const { walletAddress, role } = authReducer;

  const onToggleNotification = () => {
    if (window.innerWidth < 1023) {
      if (!isSidebarExpanded) {
        dispatch(checkNotifications());
        dispatch(updateGlobal({ notificationDropdownOpened: !notificationDropdownOpened }));
      }
    } else {
      dispatch(checkNotifications());
      dispatch(updateGlobal({ notificationDropdownOpened: !notificationDropdownOpened }));
    }
  };

  const onCloseNotification = () => {
    if (!notificationDropdownOpened) return;
    dispatch(updateGlobal({ notificationDropdownOpened: false }));
  };

  const clearOverlay = () => {
    if (activeDeal || notificationDropdownOpened) {
      dispatch(
        updateGlobal({
          activeDeal: false,
          notificationDropdownOpened: false,
        })
      );
    }
  };

  return (
    <div className="header-bar" onClick={clearOverlay}>
      <div className="header-bar__left vertical-center">
        <img src={Logo} alt="logo" />
      </div>
      <div className="header-bar__right vertical-center">
        {isPreviewMode && (
          <div className="preview-mode-warning">
            <h3>
              Preview mode<span>: {walletAddress}</span>
            </h3>
          </div>
        )}
        {role && role !== 'user' && <div className="role-container">{role}</div>}
        <div className="notification-container">
          <NotificationSelect
            opened={notificationDropdownOpened}
            notifications={notifications}
            onToggle={onToggleNotification}
            onClose={onCloseNotification}
          />
        </div>
        <DealApproveNotification />
        <ProfileDropdown handleLogout={logoutOfWeb3Modal} />
      </div>
    </div>
  );
}

export default HeaderBar;
