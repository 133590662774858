import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { shortenAddress } from 'utils';
import Tooltip from 'components/tooltip';
import SvgIcon from 'components/svgIcon';
import InfoContainer from 'screens/Legal/components/InfoContainer';

const LastLogins = ({ user }) => {
  const [copyId, setCopyId] = useState(null);

  const history = useHistory();

  if (!user) return null;

  const onCopy = (item) => {
    navigator.clipboard.writeText(item.ipAddress);
    setCopyId(item.id);

    setTimeout(() => {
      setCopyId(null);
    }, 2000);
  };

  const onViewAll = () => {
    history.push(`/users-legal/${user.wallet}/logins`, {
      pathname: window.location.pathname,
    });
  };

  const hasLogins = user.logins?.length > 0;

  return (
    <InfoContainer
      title="LAST LOGINS"
      sideLink={
        hasLogins ? (
          <button onClick={onViewAll} className="link" type="button">
            View all
          </button>
        ) : null
      }
      className="notes"
    >
      <div className="login-container">
        <div className="login-item">
          <div className="login-item-date-label">Date</div>
          <div className="login-item-address-label">Address</div>
          <div className="login-item-country-label">Country</div>
        </div>
        {!hasLogins && (
          <div className="no-logins-found">
            <div className="no-logins-found-text">No logins found</div>
          </div>
        )}
        {user.logins?.map((item) => {
          return (
            <div key={item.id} className="login-record">
              <div className="login-record-date">
                {moment(item.lastLogin).format('DD-MMM-YYYY')}
              </div>
              <div className="login-record-address">
                <div className="login-record-address-wrapper" onClick={() => onCopy(item)}>
                  {item.ipAddress?.length <= 15 ? (
                    <div>{item.ipAddress}</div>
                  ) : (
                    <Tooltip
                      title={item.ipAddress}
                      disabled={item.ipAddress?.length <= 15}
                      placement="bottom-start"
                    >
                      <div>{shortenAddress(item.ipAddress)}</div>
                    </Tooltip>
                  )}
                  <SvgIcon
                    width={16}
                    height={16}
                    name={copyId === item.id ? 'success' : 'iconCopy'}
                  />
                </div>
              </div>
              <div className="login-record-country">{item.countryName}</div>
            </div>
          );
        })}
      </div>
    </InfoContainer>
  );
};

export default LastLogins;
