import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import remote from 'screens/AllDeals/remote';
import './index.scss';

const CreateNoteModal = ({ open, onClose, dealId, isOldDeal }) => {
  const createNote = remote.useCreateNote(dealId);
  const [data, setData] = useState('');
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onChangeData = (e) => {
    setData(e.target.value);
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      await createNote.mutateAsync({ message: data, isOldDeal });
      setData('');
      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal basic-metrics-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Create note</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" buttonType="submit" form="basic-metrics-form">
            <div className="d-flex">Create</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="basic-metrics-form" onSubmit={onConfirm}>
        <div className="input-holder">
          <label>Note</label>
          <input name="message" value={data} onChange={onChangeData} />
        </div>
      </form>
    </Dialog>
  );
};

export default CreateNoteModal;
