import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import SortableTable from 'components/sortable-table';
import DealStatus from 'components/deal-status';
import { thousandSeparatorRound } from 'utils';
import utils from '../utils';

const calculatePercentage = (tokensReceived, tokensExpected) => {
  if (!tokensReceived || !tokensExpected) return 0.0;
  return parseFloat((tokensReceived / tokensExpected) * 100).toFixed(2);
};

const DealInfoTable = ({ dealInfo }) => {
  return (
    <SortableTable
      tableHead={utils.dealInfoTableHead}
      rows={dealInfo.dealInfoTable}
      type="deal-info"
    >
      {(sortedRows) =>
        sortedRows.map((row, index) => (
          <TableRow key={index.toString()}>
            <TableCell>{row.dealName}</TableCell>
            <TableCell>{row.ticker}</TableCell>
            <TableCell>
              <DealStatus dealStatus={row.dealStatus} />
            </TableCell>
            <TableCell>{row.blockchain}</TableCell>
            <TableCell>{row.statusInfo ?? '-'}</TableCell>
            <TableCell>{row.batchesReceived}</TableCell>
            <TableCell>{row.totalBatches}</TableCell>
            <TableCell>{thousandSeparatorRound(parseInt(row.tokensReceived, 10))}</TableCell>
            <TableCell>{calculatePercentage(row.tokensReceived, row.tokensExpected)}%</TableCell>
            <TableCell>{thousandSeparatorRound(parseInt(row.tokensExpected, 10))}</TableCell>
            <TableCell>{row.distributionModel}</TableCell>
          </TableRow>
        ))
      }
    </SortableTable>
  );
};

export default DealInfoTable;
