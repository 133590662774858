import { Dialog, Divider, Radio } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import React, { useEffect, useState } from 'react';
import { formatOverviewText } from 'utils';
import DOMPurify from 'dompurify';

const VoteModal = ({ poll, onClose, onVote }) => {
  const [choiceId, setChoiceId] = useState(poll?.options[0].id);

  useEffect(() => {
    setChoiceId(poll?.options[0].id);
  }, [poll]);

  return (
    <Dialog open={!!poll} onClose={onClose} classes={{ paper: 'poll-modal__container' }}>
      <div className="poll-modal">
        <div className="poll-modal__head">
          <div>
            <span>{poll?.title}</span>
          </div>
          <div>
            <RoundedButton type="transparent" onClick={onClose}>
              Cancel
            </RoundedButton>
            <RoundedButton type="primary" onClick={() => onVote(choiceId)}>
              Confirm
            </RoundedButton>
          </div>
        </div>
        <Divider />
        <div className="poll-modal__body">
          {poll?.name && (
            <div className="question-wrapper">
              <span>Deal name:</span>
              <p>{poll?.name}</p>
            </div>
          )}
          {poll?.pollInfo && (
            <div className="question-wrapper">
              <span>Poll question & description</span>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: formatOverviewText(DOMPurify.sanitize(poll.pollInfo)),
                }}
              />
            </div>
          )}
          <span>Choices:</span>
          <ul>
            {poll?.options.map(({ label, id }) => (
              <li key={id}>
                <Radio checked={id === choiceId} onClick={() => setChoiceId(id)} />
                <span>{label}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Dialog>
  );
};

export default VoteModal;
