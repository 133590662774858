import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserDeal } from 'store/actions';
import { updateDeal } from 'services/apiService';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import './index.scss';

const claimMethodOptions = [
  'Claim via BD platform',
  "Claim via project's web",
  'Airdrop from Project',
  'Airdrop from BD',
];

const formatClaimUrl = (claimUrl) => {
  if (!claimUrl) return null;
  return claimUrl.startsWith('https://') ? claimUrl : `https://${claimUrl}`;
};

const VestingClaimInfoModal = ({ open, onClose, deal }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    claimMethod: deal.claimMethod || '',
    claimUrl: deal.claimUrl || '',
  });
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    const newData = {
      claimMethod: data.claimMethod || null,
      claimUrl: formatClaimUrl(data.claimUrl),
    };
    try {
      const response = await updateDeal(deal.id, { ...newData });
      const changedData = JSON.parse(response.config.data);
      const newDeal = JSON.parse(JSON.stringify(deal));
      newDeal.claimMethod = changedData.claimMethod;
      newDeal.claimUrl = changedData.claimUrl;
      dispatch(setUserDeal(newDeal));
      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal basic-metrics-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Claim info</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" buttonType="submit" form="basic-metrics-form">
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="basic-metrics-form" onSubmit={onConfirm}>
        <div className="col col-left">
          <div className="input-holder">
            <label>Claim method</label>
            <Autocomplete
              disableClearable
              freeSolo
              onInput={(e) =>
                onChangeData({ target: { name: 'claimMethod', value: e.target.value } })
              }
              classes={{ root: 'deals-input' }}
              options={claimMethodOptions}
              renderInput={(params) => <TextField {...params} />}
              value={data.claimMethod}
              onChange={(_, value) => onChangeData({ target: { name: 'claimMethod', value } })}
            />
          </div>

          <div className="input-holder">
            <label>Claim URL</label>
            <input name="claimUrl" value={data.claimUrl} onChange={onChangeData} />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default VestingClaimInfoModal;
