import React from 'react';
import './index.scss';
import Login from 'containers/login';
import { useSelector } from 'react-redux';
import AlertNotification from 'features/notifications/AlertNotification';
import { useWeb3Modal } from 'hooks';
import PageContent from 'containers/pageContent';
import CustomLoading from 'components/loading/custom-loading';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import EmailVerifyContent from 'screens/EmailVerify';

function App() {
  const authReducer = useSelector((state) => state.auth);

  const { loadWeb3Modal, logoutOfWeb3Modal } = useWeb3Modal();
  const { walletAddress, token } = authReducer;

  return (
    <div className="app">
      <CustomLoading />
      <AlertNotification />
      <BrowserRouter>
        <Switch>
          <Route
            path="/verify-email"
            render={() => {
              return <EmailVerifyContent />;
            }}
          />
          <Route
            path="/"
            render={() => {
              return walletAddress && token ? (
                <PageContent loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
              ) : (
                <Login loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
