import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MyDealsContent from './MyDealsContent';

const MyDeals = () => {
  const globalReducer = useSelector((state) => state.global);
  const { userDeals, oldDeals, fetchingDeals } = globalReducer;

  const history = useHistory();
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin, role } = authReducer;

  useEffect(() => {
    if (isAdmin) return history.push('/deals');
  }, [isAdmin, history]);

  if (!role) return <></>;

  return (
    <div className="deals-container">
      {!fetchingDeals && <MyDealsContent userDeals={userDeals} oldDeals={oldDeals} />}
    </div>
  );
};

export default MyDeals;
