import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import IconButton from 'components/button/icon-button';
import CreateClaimerModal from 'features/deals/CreateClaimerModal';
import { useNotifications } from 'hooks';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AddressLink from 'components/address-link';

const AddressTableFooter = ({ dealInfo, openAssignModal }) => {
  const { userDeals } = useSelector((state) => state.global);
  const formattedDeal = userDeals.find((userDeal) => +userDeal.id === +dealInfo.dealId);

  const [claimerModalOpen, setClaimerModalOpen] = useState(false);
  const closeClaimerModal = () => setClaimerModalOpen(false);
  const openClaimerModal = () => setClaimerModalOpen(true);

  const [, showErrorNotification] = useNotifications();

  if (dealInfo.platformOrLegacy === 'Legacy') return null;

  return (
    <TableFooter>
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell>
          {!dealInfo.addressTable.length && dealInfo.dealAddress && (
            <AddressLink chainName="xDai" chainAddress={dealInfo.dealAddress} />
          )}
          {!dealInfo.addressTable.length && dealInfo.dealWallet && (
            <AddressLink chainName="ethereum" chainAddress={dealInfo.dealWallet} />
          )}
        </TableCell>
        <TableCell>
          {!dealInfo.addressTable.length && dealInfo.receiveWallet && (
            <AddressLink chainName="ethereum" chainAddress={dealInfo.receiveWallet} />
          )}
          {!dealInfo.receiveWallet && (
            <div className="address-add-button" onClick={openAssignModal}>
              <b>Assign</b>
              <IconButton onClick={openAssignModal} icon="addDeal" />
            </div>
          )}
        </TableCell>
        <TableCell>
          {formattedDeal ? (
            <div className="address-add-button">
              <b>Create</b>
              <IconButton onClick={openClaimerModal} icon="addDeal" />
            </div>
          ) : (
            'Create disabled'
          )}
        </TableCell>
      </TableRow>
      {formattedDeal && (
        <CreateClaimerModal
          open={claimerModalOpen}
          onClose={closeClaimerModal}
          deal={formattedDeal}
          showErrorNotification={showErrorNotification}
        />
      )}
    </TableFooter>
  );
};

export default AddressTableFooter;
