import { PaginationItem } from '@material-ui/lab';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const usePagination = (items, itemsPerPage = 10) => {
  const [page, setPage] = useState(0);

  const paginatedItems = useMemo(() => {
    if (!items) return [];
    return items.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);
  }, [items, page, itemsPerPage]);

  const resetPage = useCallback(() => {
    setPage(0);
  }, []);

  const numberOfPages = Math.ceil(items.length / itemsPerPage);

  useEffect(() => {
    if (numberOfPages <= page && page !== 0) {
      setPage(numberOfPages - 1);
    }
  }, [page, numberOfPages]);

  const Pagination = () => {
    if (numberOfPages === 1 || !items?.length) return null;
    return (
      <div className="deals-table-pagintaion">
        <ul>
          <PaginationItem
            type="previous"
            page={page}
            onClick={() => setPage((prev) => prev - 1)}
            disabled={page === 0}
            variant="outlined"
            color="secondary"
          />
          {[...Array(numberOfPages)].map((_, i) => {
            if (i > page + 2 || i < page - 1) return null;

            return (
              <PaginationItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                type="page"
                page={i + 1}
                selected={i === page}
                onClick={() => setPage(i)}
                variant="outlined"
                color="secondary"
              />
            );
          })}
          {numberOfPages > 3 && page < numberOfPages - 3 && (
            <>
              {page < numberOfPages - 4 && <span>...</span>}
              <PaginationItem
                type="page"
                page={numberOfPages}
                onClick={() => setPage(numberOfPages - 1)}
                variant="outlined"
                color="secondary"
              />
            </>
          )}
          <PaginationItem
            type="next"
            page={page + 1}
            onClick={() => setPage((prev) => prev + 1)}
            disabled={page === numberOfPages - 1}
            variant="outlined"
            color="secondary"
          />
        </ul>
      </div>
    );
  };

  return { Pagination, paginatedItems, resetPage };
};

export default usePagination;
