const jobHistoryTableHead = [
  {
    column: 'jobName',
    label: 'Job Name',
    sort: (a, b) => (b.jobName.trim().toLowerCase() > a.jobName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'createdAt',
    label: 'Timestamp',
    sort: (a, b) => {
      if (!a.createdAt) {
        return 1;
      }
      if (!b.createdAt) {
        return -1;
      }
      return new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1;
    },
    defaultSortField: true,
  },
  {
    column: 'dealName',
    label: 'Deal Name',
    sort: (a, b) => (b.dealName.trim().toLowerCase() > a.dealName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'successfulRun',
    label: 'Status',
  },
  {
    column: 'tokenName',
    label: 'Token Name',
    sort: (a, b) =>
      b.tokenName?.trim()?.toLowerCase() > a.tokenName?.trim()?.toLowerCase() ? 1 : -1,
  },
  {
    column: 'claimed',
    label: 'Amount',
    sort: (a, b) => (parseInt(b.claimed, 10) > parseInt(a.claimed, 10) ? 1 : -1),
  },
  {
    label: 'Transaction',
  },
];

export default { jobHistoryTableHead };
