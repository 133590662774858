import buildCall from 'contracts/calls';
import { CALL_TYPE } from 'contracts/calls/constants';
import { createUserParams } from 'contracts/dealV2Contract';
import { queryCalls } from 'contracts/multicallContract';

async function getPersonalCaps({ deals, rawDeals, walletAddress, provider }) {
  const personalCapCalls = buildPersonalCapCalls({ deals, rawDeals, walletAddress });

  const personalCapResults = await queryCalls(provider, personalCapCalls);

  return deals.map((deal, idx) => {
    const rawPersonalCap = personalCapResults[idx].returnData[0];

    return {
      deal,
      personalCap: Number(rawPersonalCap),
    };
  });
}

function buildPersonalCapCalls({ deals, rawDeals, walletAddress }) {
  return deals.flatMap((deal, index) => {
    const { currentPhaseIndex } = rawDeals[index];

    const userParams = createUserParams(deal, currentPhaseIndex);

    return buildCall(CALL_TYPE.MAX_CONTRIBUTION_AMOUNT_V2)(deal.address, [
      walletAddress,
      userParams,
    ]);
  });
}

export { getPersonalCaps };
