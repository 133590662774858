import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiGet, apiPut, baseUrl } from 'services/apiService';

const getDealPartnersInvestors = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/${dealId}/partners-investors`);
  return response.data;
};

const useGetDealPartnersInvestors = (dealId, enabled) => {
  return useQuery(['deal', dealId, 'partners-investors'], () => getDealPartnersInvestors(dealId), {
    enabled: !!dealId && !!enabled,
    placeholderData: [],
  });
};

const updateDealPartnersInvestors = async (dealId, id, data) => {
  const response = await apiPut(`${baseUrl}/api/deal/admin/${dealId}/partners-investors`, {
    id,
    dealId: +dealId,
    logo: data.logo,
    website: data.website,
    sortField: +data.sortField,
  });

  return response.data;
};

const useUpdateDealPartnersInvestors = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(({ id, data }) => updateDealPartnersInvestors(dealId, id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', dealId, 'partners-investors']);
    },
  });
};

export default { useGetDealPartnersInvestors, useUpdateDealPartnersInvestors };
