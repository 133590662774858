import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import SvgIcon from 'components/svgIcon';
import { switchCurrentNetwork } from 'contracts/browserWallet';
import { assignRoleToUser, unassignRoleFromUser } from 'contracts/roleContract';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { addRoleToUser, editUserWithRole, removeRoleFromUser } from 'services/apiService';
import { useNotifications } from 'hooks';
import { ARBITRUM_CHAIN_ID } from 'constants/config';
import AddUserRoleDialog from './AddUserRoleDialog';
import UserManageDropdown from './UserManageDropdown';

const RoleManagementUsers = ({ users, refetchUsers, roles }) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const globalReducer = useSelector((state) => state.global);
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const { chainId } = globalReducer;

  const handleDelete = async (wallet, role) => {
    const selectedRole = roles.find((roleItem) => roleItem.name === role);
    try {
      if (selectedRole.maxCloseAmount) {
        const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);
        if (networkChecked) {
          await unassignRoleFromUser(wallet);
        }
      }

      await removeRoleFromUser(wallet);
      showSuccessNotification('Successfully removed role from user!');
      refetchUsers();
      setUserToEdit(null);
    } catch (error) {
      showErrorNotification('Something went wrong!');
    }
  };

  const handleSave = async ({ address, username, email, role }) => {
    try {
      const userRoleBeforeChange = userToEdit
        ? roles.find((roleItem) => roleItem.name === userToEdit.role)
        : null;
      const selectedRole = roles.find((roleItem) => roleItem.name === role);
      const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);

      if (userToEdit) {
        await editUserWithRole(username, email, address, selectedRole.id);
      } else {
        await addRoleToUser(address, username, email, selectedRole.id);
      }

      if (networkChecked) {
        if (selectedRole.maxCloseAmount) await assignRoleToUser(address, role);
        else if (userRoleBeforeChange?.maxCloseAmount && !selectedRole.maxCloseAmount)
          await unassignRoleFromUser(address);
      }

      showSuccessNotification('Role successfully asigned to user!');
      refetchUsers();
      setIsAddDialogOpen(false);
      setUserToEdit(null);
    } catch (error) {
      showErrorNotification(error.response?.data?.message || error.response?.data);
    }
  };

  const handleEdit = (selectedUser) => {
    setIsAddDialogOpen(true);
    setUserToEdit(selectedUser);
  };

  if (!users) return null;

  return (
    <>
      <AddUserRoleDialog
        open={isAddDialogOpen}
        onCancel={() => setIsAddDialogOpen(false)}
        onSave={handleSave}
        roles={roles}
        userToEdit={userToEdit}
        setUserToEdit={setUserToEdit}
      />
      <TableContainer classes={{ root: 'users-table' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>EMAIL</TableCell>
              <TableCell>ADDRESS</TableCell>
              <TableCell>USER TYPE</TableCell>
              <TableCell>JOINED</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(({ id, name, email, role, joined, wallet }) => (
              <TableRow key={id}>
                <TableCell scope="row" classes={{ root: 'username-column' }}>
                  <RoundedAvatar
                    src={`https://api.dicebear.com/7.x/bottts/svg?seed=${wallet.toLowerCase()}&background=%2370bceb&size=100`}
                  />
                  {name || 'Unassigned'}
                </TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>{wallet}</TableCell>
                <TableCell>{role}</TableCell>
                <TableCell>{moment(joined).format('DD-MMM-YYYY')}</TableCell>
                <TableCell align="right">
                  <div className="more-icon-container">
                    <UserManageDropdown
                      onDelete={() => handleDelete(wallet, role)}
                      onEdit={() => handleEdit({ name, email, wallet, role })}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="users-table__add" onClick={() => setIsAddDialogOpen(true)}>
        <div>
          <SvgIcon name="plus" width={24} height={24} />
          Add member
        </div>
      </div>
    </>
  );
};

export default RoleManagementUsers;
