import React, { useEffect, useState } from 'react';
import SvgIcon from 'components/svgIcon';
import './index.scss';
import {
  BSC_CHAIN_ID,
  ETHEREUM_CHAIN_ID,
  POLYGON_CHAIN_ID,
  XDAI_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  BASE_CHAIN_ID,
  MERLIN_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
} from 'constants/config';

const filters = [
  {
    key: 'binance smart chain',
    chainId: BSC_CHAIN_ID,
    keyWords: ['bsc', 'binance', 'binance smart chain'],
  },
  { key: 'ethereum', chainId: ETHEREUM_CHAIN_ID, keyWords: ['eth', 'ethereum'] },
  { key: 'polygon', chainId: POLYGON_CHAIN_ID, keyWords: ['matic', 'polygon'] },
  { key: 'xdai', chainId: XDAI_CHAIN_ID, keyWords: ['xdai'] },
  { key: 'avalanche', chainId: AVALANCHE_CHAIN_ID, keyWords: ['avax', 'avalanche'] },
  { key: 'base', chainId: BASE_CHAIN_ID, keyWords: ['base'] },
  { key: 'merlin', chainId: MERLIN_CHAIN_ID, keyWords: ['merlin', 'merlinchain'] },
  { key: 'bitcoin', keyWords: ['btc', 'bitcoin'] },
  { key: 'polkadot', keyWords: ['dot', 'polkadot'] },
  { key: 'cardano', keyWords: ['ada', 'cardano'] },
  { key: 'solana', keyWords: ['sol', 'solana'] },
  { key: 'cosmos', keyWords: ['atom', 'cosmos'] },
  { key: 'thorchain', keyWords: ['rune', 'thorchain'] },
  { key: 'concordium', keyWords: ['concordium'] },
  { key: 'centrifuge', keyWords: ['cfg', 'centrifuge'] },
  { key: 'terra', keyWords: ['luna', 'terra'] },
  { key: 'internet computer', keyWords: ['icp', 'internet computer'] },
  { key: 'tron', keyWords: ['trx', 'tron'] },
  { key: 'kusama', keyWords: ['ksm', 'kusama'] },
  { key: 'rootstock', keyWords: ['rsk', 'rootstock'] },
  { key: 'algorand', keyWords: ['algo', 'algorand'] },
  { key: 'kucoin', keyWords: ['ksc', 'kucoin'] },
  { key: 'NEAR', keyWords: ['NEAR'] },
  { key: 'Juno', keyWords: ['Juno'] },
  { key: 'Nolus', keyWords: ['Nolus'] },
  { key: 'AlephZero', keyWords: ['AlephZero'] },
  { key: 'SelfChain', keyWords: ['SelfChain'] },
  { key: 'Sui', keyWords: ['Sui'] },
];

function DealSearchInput({ deals, onChange, showInput, setShowInput }) {
  const [value, setValue] = useState('');
  const toggleInput = () => {
    if (window.innerWidth < 767) {
      setShowInput(!showInput);
    }
  };

  useEffect(() => {
    if (!value) onChange(deals);
    // Fake hardcoded search AI
    if (['claim', 'claimable'].includes(value.toLowerCase())) {
      onChange(
        deals.filter(
          ({ dealClaimInfo, claimers }) =>
            +dealClaimInfo?.claimAmount > 0 || claimers.some(({ claimable }) => +claimable > 0)
        )
      );
      return;
    }
    if (['xdai claim'].includes(value.toLowerCase())) {
      onChange(deals.filter(({ dealClaimInfo }) => +dealClaimInfo?.claimAmount > 0));
      return;
    }

    if (['arbitrum claim'].includes(value.toLowerCase())) {
      onChange(
        deals.filter(
          ({ dealClaimInfo, claimers }) =>
            +dealClaimInfo?.claimAmount > 0 ||
            claimers.some(
              ({ claimable, chainId }) => +claimable > 0 && chainId === ARBITRUM_CHAIN_ID
            )
        )
      );
      return;
    }

    if (['ethereum claim', 'eth claim'].includes(value.toLowerCase())) {
      onChange(
        deals.filter(({ claimers }) =>
          claimers.some(({ claimable, chainId }) => +claimable > 0 && chainId === ETHEREUM_CHAIN_ID)
        )
      );
      return;
    }
    if (['bsc claim', 'binance claim', 'binance smart chain'].includes(value.toLowerCase())) {
      onChange(
        deals.filter(({ claimers }) =>
          claimers.some(({ claimable, chainId }) => +claimable > 0 && chainId === BSC_CHAIN_ID)
        )
      );
      return;
    }
    if (['polygon claim', 'matic claim'].includes(value.toLowerCase())) {
      onChange(
        deals.filter(({ claimers }) =>
          claimers.some(({ claimable, chainId }) => +claimable > 0 && chainId === POLYGON_CHAIN_ID)
        )
      );
      return;
    }
    if (['avalanche claim', 'avax claim'].includes(value.toLowerCase())) {
      onChange(
        deals.filter(({ claimers }) =>
          claimers.some(
            ({ claimable, chainId }) => +claimable > 0 && chainId === AVALANCHE_CHAIN_ID
          )
        )
      );
      return;
    }
    if (['baseclaim'].includes(value.toLowerCase())) {
      onChange(
        deals.filter(({ claimers }) =>
          claimers.some(({ claimable, chainId }) => +claimable > 0 && chainId === BASE_CHAIN_ID)
        )
      );
      return;
    }
    if (['merlin claim', 'merlinchain claim'].includes(value.toLowerCase())) {
      onChange(
        deals.filter(({ claimers }) =>
          claimers.some(({ claimable, chainId }) => +claimable > 0 && chainId === MERLIN_CHAIN_ID)
        )
      );
      return;
    }
    const filter = filters.find(({ keyWords }) =>
      keyWords.some((keyWord) => keyWord === value.toLowerCase())
    );
    if (filter) {
      onChange(
        deals.filter(
          ({ claimers, requiredChain }) =>
            claimers?.some(({ chainId }) => chainId === filter.chainId) ||
            requiredChain?.toLowerCase() === filter.key
        )
      );
      return;
    }

    onChange(deals.filter((deal) => deal.name.toLowerCase().includes(value.toLowerCase())));
  }, [value, deals, onChange]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`search-input${showInput ? ' active' : ''}`}>
      <div className="input-icon-wrapper vertical-center" onClick={toggleInput}>
        <SvgIcon name="search" />
      </div>
      <input
        type="text"
        value={value}
        placeholder="Search deals by name"
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}

export default DealSearchInput;
