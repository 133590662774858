import { getOldDealClaimer } from './oldClaimerModel';

// eslint-disable-next-line import/prefer-default-export
export const getOldDealAllocationModel = (allocationModel) => {
  if (allocationModel === 'prorata') return 'ProRata';
  if (allocationModel === 'nolock') return 'NoLock';
  if (allocationModel === 'level') return 'Level';

  throw new Error(`Unrecognized allocation model ${allocationModel}`);
};

async function getOldDealModel(oldDeal, wallet) {
  const oldDealModel = {
    id: oldDeal.id,
    name: oldDeal.name,
    imageUrl: oldDeal.imageUrl,
    allocationModel: getOldDealAllocationModel(oldDeal.allocationModel),
    amount: oldDeal.amount,
    isArchived: oldDeal.isArchived,
    tokensExpected: oldDeal.tokensExpected,
  };

  const claimers = await Promise.all(
    oldDeal.claimers.map((x) => getOldDealClaimer(x, wallet, oldDeal.amount))
  );

  oldDealModel.claimers = claimers.filter((x) => !!x);

  return oldDealModel;
}

export async function getOldDealModels(wallet, oldDealsApi) {
  const oldDeals = await Promise.all(oldDealsApi.map((x) => getOldDealModel(x, wallet)));

  return oldDeals;
}
