import remote from '../remote';

const useBalances = () => {
  const { data: balances } = remote.useGetAllClaimerBalances();

  const results = remote.useGetClaimerNativeTokenBalanceAmountQueries(balances);
  const formattedResults = results?.filter((res) => res.status === 'success').map((r) => r.data);

  const findAmountForWalletAddress = (walletAddress, chainId) =>
    formattedResults?.find(
      (result) => walletAddress === result.walletAddress && chainId === result.chainId
    );

  const formattedBalances = balances?.map((balance) => {
    const foundAmountWithAddress = findAmountForWalletAddress(
      balance.walletAddress,
      balance.chainId
    );
    if (foundAmountWithAddress)
      return { ...balance, balance: Number(foundAmountWithAddress.amount).toFixed(4) };
    return { ...balance, balance: 0 };
  });

  return { formattedBalances };
};

export default useBalances;
