import { AdditionalPepInfoFields } from './PepRelationshipInfo/constants';

export function getInitialPepError() {
  return AdditionalPepInfoFields.reduce((acc, field) => {
    acc[field.id] = false;
    return acc;
  }, {});
}

export function getInitialPepData(data) {
  if (!data) return {};

  return AdditionalPepInfoFields.reduce((acc, field) => {
    if (field.id === 'relatedPepDob') {
      acc[field.id] = data[field.id] ? new Date(data[field.id]) : null;
    } else {
      acc[field.id] = data[field.id] || '';
    }

    acc.pepDetails = data.relatedPepPoliticalPosition
      ? 'relatedPepPoliticalPosition'
      : data.relatedPepRelationship
      ? 'relatedPepRelationship'
      : '';

    return acc;
  }, {});
}
