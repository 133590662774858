import React from 'react';
import './index.scss';

const InfoContainer = ({ title, children, sideLink, className = '' }) => {
  return (
    <div className={`misc-container ${className}`}>
      <div className="misc-header">
        <h3 className="misc-container-title">{title}</h3>
        {sideLink}
      </div>
      {children}
    </div>
  );
};

export default InfoContainer;
