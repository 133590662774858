import React, { useState } from 'react';
import { addStringAmounts, roundNumberToDecimals } from 'utils/helpers';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomSlider from 'components/progress-bar/custom-slider';
import NumberInput from 'components/input/number-input';
import './index.scss';

const UpdateLockupModal = ({ open, onOk, onClose, accountInfo }) => {
  const [finalAmount, setFinalAmount] = useState(accountInfo.lockInfo.amount);

  const onConfirm = async () => {
    if (Number(finalAmount) < Number(accountInfo.lockInfo.amount)) {
      setFinalAmount(finalAmount);
      return;
    }
    await onOk(finalAmount);
  };

  const onChangeSlider = (_event, val) => {
    if (val < Number(accountInfo.lockInfo.amount)) {
      setFinalAmount(accountInfo.lockInfo.amount);
    } else {
      setFinalAmount(val.toString());
    }
  };

  const onChangeBdtAmount = (e) => {
    const { value } = e.target;

    if (value) {
      setFinalAmount(value);
    } else {
      setFinalAmount(accountInfo.lockInfo.amount);
    }
  };

  const isConfirmDisabled =
    +finalAmount > +addStringAmounts(accountInfo.lockInfo.amount, accountInfo.bdtBalance, 18) ||
    +finalAmount < +accountInfo.lockInfo.amount;

  const handleInputBlur = () => {
    if (+finalAmount > +addStringAmounts(accountInfo.lockInfo.amount, accountInfo.bdtBalance, 18)) {
      setFinalAmount(+addStringAmounts(accountInfo.lockInfo.amount, accountInfo.bdtBalance, 18));
    } else if (+finalAmount < +accountInfo.lockInfo.amount) {
      setFinalAmount(+accountInfo.lockInfo.amount);
    }
  };

  return (
    <Dialog
      open={open}
      className="close-deal-modal update-lockup-modal"
      id="close-deal-modal"
      onClose={onClose}
    >
      <div className="close-deal-modal-header d-flex">
        <div className="close-deal-modal-header__left d-flex vertical-center">
          <div className="deal-name vertical-center">
            <div className="full-width d-flex">
              <SvgIcon name="iconLockupGreen" />
              <span>Update Lockup</span>
            </div>
          </div>
        </div>
        <div className="close-deal-modal-header__right vertical-center">
          <RoundedButton onClick={onClose}>Cancel</RoundedButton>
          <RoundedButton type="secondary" disabled={isConfirmDisabled} onClick={onConfirm}>
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
      </div>
      <div className="close-deal-modal-body">
        <div className="d-flex close-amount">
          <div className="close-amount-slider vertical-center">
            {Number(accountInfo.lockInfo.amount) > 0 ? <p>My BDT Locked</p> : <p>Add BDT amount</p>}
            <CustomSlider
              value={finalAmount}
              min={0}
              hideValueLabel
              max={Number(
                roundNumberToDecimals(
                  addStringAmounts(accountInfo.bdtBalance, accountInfo.lockInfo.amount),
                  10
                )
              )}
              onChange={onChangeSlider}
            />
          </div>
          <div className="close-amount-input vertical-center">
            <span className="input-holder">
              <NumberInput
                onBlur={handleInputBlur}
                placeholder="0.0"
                value={finalAmount?.toString() || ''}
                onChange={onChangeBdtAmount}
                decimalNumber="18"
              />
            </span>
            <span className="close-amount-input-unit">BDT</span>
          </div>
        </div>
        <div className="message-wrapper">
          <SvgIcon name="error" />
          <div className="modal-inputs">
            <label>
              You are locking your BDT indefinitely. To start 6 months unlock please click start
              unlock.
            </label>
          </div>
        </div>
        <div className="update-lockup-mobile-actions">
          <RoundedButton onClick={onClose}>Cancel</RoundedButton>
          <RoundedButton type="secondary" disabled={isConfirmDisabled} onClick={onConfirm}>
            Confirm
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateLockupModal;
