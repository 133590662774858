import React from 'react';
import './index.scss';

const Tabs = ({ tabs, selectedTabIndex, setSelectedTabIndex }) => {
  return (
    <div>
      <div className="tabs__container">
        {tabs.map((tab, i) => (
          <button
            type="button"
            onClick={() => setSelectedTabIndex(i)}
            key={tab.id}
            className={i === selectedTabIndex ? 'selected' : ''}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div>{tabs[selectedTabIndex].content}</div>
    </div>
  );
};

export default Tabs;
