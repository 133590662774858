import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import { removeAnimateDeal, updateLoading, setUserDeal } from 'store/actions';
import './index.scss';
import { getPledge, postPledge } from 'services/apiService';
import { useNotifications } from 'hooks';
import { getDealStatusClass } from 'utils/helpers';
import moment from 'moment';
import Tooltip from 'components/tooltip';
import PledgeModal from './PledgeModal';
import DealInfoDesktop from '../DealInfoUser/DealInfoDesktop';

const DraftDealRow = ({ deal }) => {
  const globalReducer = useSelector((state) => state.global);
  const authReducer = useSelector((state) => state.auth);
  const { accountInfo } = authReducer;
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeInfoTab, setActiveInfoTab] = useState('info');
  const [pledge, setPledge] = useState({});
  const [pledgeModalOpen, setPledgeModalOpen] = useState(false);
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const [noPledgeMessage, setNoPledgeMessage] = useState('');

  const setActivePledgeTab = async () => {
    setActiveInfoTab('pledge');
    await fetchPledge();
  };

  const fetchPledge = async () => {
    dispatch(updateLoading(true));
    try {
      const pledgeApi = await getPledge(deal.id);
      setPledge(pledgeApi);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(updateLoading(false));
    }
  };

  const onPledge = async (data) => {
    try {
      await postPledge(deal.id, data);
      const newDeal = { ...deal, pledge: data.amount, pledgeDate: new Date() };
      dispatch(setUserDeal(newDeal));
      showSuccessNotification('Pledged successfully');
    } catch (err) {
      showErrorNotification(err.response.data.message);
    }
  };

  const { animateDeals } = globalReducer;

  const onManage = (e) => {
    const disabledTags = ['input', 'button'];
    if (disabledTags.includes(e.target.tagName.toLowerCase())) return;
    toggleDrop();
  };

  const toggleDrop = () => {
    setShowDropdown(!showDropdown);
  };

  const getAnimateClass = (val) => {
    if (
      animateDeals &&
      animateDeals.fields &&
      deal.address === animateDeals.address &&
      (animateDeals?.fields.includes(val) || animateDeals?.fields.includes('allFields'))
    ) {
      return 'animate';
    }
    return val;
  };

  useEffect(() => {
    if (animateDeals && animateDeals.fields && deal.address === animateDeals.address) {
      setTimeout(() => {
        dispatch(removeAnimateDeal());
      }, 3000);
    }
  }, [animateDeals, deal, dispatch]);

  const handleNoPledge = (accountInfo) => {
    if (!accountInfo) return;
    if (accountInfo.userAccessLevel === -1 && accountInfo.relockMessage?.length) {
      setNoPledgeMessage(accountInfo.relockMessage);
      return;
    }

    setNoPledgeMessage('');
  };

  return (
    <>
      <PledgeModal
        isOpen={pledgeModalOpen}
        onOk={onPledge}
        onCancel={() => setPledgeModalOpen(false)}
        fetchPledge={fetchPledge}
        defaultValue={deal.pledge}
      />
      <div className="deal-holder deal-holder-draft d-flex full-width">
        <div className="deal-row-top" onClick={onManage}>
          <div
            className={`deal__field deal__field-avatar vertical-center ${getAnimateClass(
              'imageUrl'
            )}`}
          >
            <RoundedAvatar src={deal.imageUrl} />
          </div>
          <div
            className={`deal__field deal__field-name vertical-center ${getAnimateClass('name')}`}
          >
            <div>
              <span>{deal.name}</span>
            </div>
          </div>
          <div className="deal__field deal__field-status deal__field-status vertical-center">
            <span className="deal__field-status__name">
              {moment(deal.dateCreated || deal.createdAt).format('D-MMM-YYYY')}
            </span>
          </div>
          <div
            className={`deal__field deal__field-status deal__field-status--${getDealStatusClass(
              deal.status
            )} vertical-center ${getAnimateClass('status')}`}
          >
            <span className="deal__field-status__icon">
              <SvgIcon name="dot" />
            </span>
            <span className="deal__field-status__name">
              {deal.statusId === 6 || deal.statusId === 0 ? 'Draft' : deal.status}
            </span>
          </div>
          {deal.statusId === 7 && (
            <Tooltip title={noPledgeMessage}>
              <div
                className="deal__field deal__field-status-stepper vertical-center"
                onMouseEnter={() => handleNoPledge(accountInfo)}
              >
                <RoundedButton
                  type={`${
                    (!deal.pledge || Number(deal.pledge) !== 0) && deal.pledgeDate
                      ? 'default'
                      : 'primary'
                  }`}
                  disabled={accountInfo.userAccessLevel === -1}
                  onClick={() => setPledgeModalOpen(true)}
                >
                  {!!deal.pledge && Number(deal.pledge) !== 0 ? 'Pledged' : 'Pledge'}
                </RoundedButton>
              </div>
            </Tooltip>
          )}
        </div>

        <DealInfoDesktop
          deal={deal}
          showDropdown={showDropdown}
          activeInfoTab={activeInfoTab}
          setActiveInfoTab={setActiveInfoTab}
          setActivePledgeTab={setActivePledgeTab}
          pledge={pledge}
        />
      </div>
    </>
  );
};

DraftDealRow.propTypes = {
  deal: PropTypes.shape(),
};

DraftDealRow.defaultProps = {
  deal: {},
};

export default React.memo(DraftDealRow);
