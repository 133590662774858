import { ethers } from 'ethers';
import {
  ETH_CLAIMER_FACTORY_ADDRESS,
  BSC_CLAIMER_FACTORY_ADDRESS,
  POLYGON_CLAIMER_FACTORY_ADDRESS,
  AVALANCHE_CLAIMER_FACTORY_ADDRESS,
  BASE_CLAIMER_FACTORY_ADDRESS,
  MERLIN_CLAIMER_FACTORY_ADDRESS,
  ARBITRUM_CLAIMER_FACTORY_ADDRESS,
} from 'constants/config';
import ClaimerArtifact from './abis/Claimer.json';
import ClaimerFactoryArtifact from './abis/ClaimerFactory.json';

const getDealClaimerAddressForChain = (deal, chain) => {
  switch (chain) {
    case 'ethereum':
      return deal.ethClaimerAddress;

    case 'bsc':
      return deal.bscClaimerAddress;

    case 'polygon':
      return deal.polygonClaimerAddress;

    case 'avalanche':
      return deal.avalancheClaimerAddress;

    case 'base':
      return deal.baseClaimerAddress;

    case 'merlin':
      return deal.merlinClaimerAddress;

    case 'arbitrum':
      return deal.arbitrumClaimerAddress;

    default:
      throw new Error(
        `Could not get deal ${JSON.stringify(deal)} claimer address for chain ${chain}`
      );
  }
};

export const getAvailableClaimAmountFromClaimer = async (
  provider,
  claimerAddress,
  userWalletAddress,
  contributionAmount,
  tokenAddress
) => {
  const claimerContract = new ethers.Contract(claimerAddress, ClaimerArtifact.abi, provider);

  const availableClaimAmount = await claimerContract.getAddressAvailableClaimAmount(
    userWalletAddress,
    ethers.utils.parseUnits(contributionAmount, 6),
    tokenAddress
  );

  return availableClaimAmount;
};

export const claimFromClaimer = async (deal, tokenAddress, walletAddress, chain, merkleProof) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  const claimerAddress = getDealClaimerAddressForChain(deal, chain);

  try {
    const claimerContract = new ethers.Contract(
      claimerAddress,
      ClaimerArtifact.abi,
      injectedProvider.getSigner()
    );

    const tx = await claimerContract.claimToken(
      tokenAddress,
      walletAddress,
      merkleProof.amount,
      merkleProof.proof || []
    );
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const getChainClaimerFactoryAddress = (chain) => {
  switch (chain) {
    case 'ethereum':
      return ETH_CLAIMER_FACTORY_ADDRESS;

    case 'bsc':
      return BSC_CLAIMER_FACTORY_ADDRESS;

    case 'polygon':
      return POLYGON_CLAIMER_FACTORY_ADDRESS;

    case 'avalanche':
      return AVALANCHE_CLAIMER_FACTORY_ADDRESS;

    case 'base':
      return BASE_CLAIMER_FACTORY_ADDRESS;

    case 'merlin':
      return MERLIN_CLAIMER_FACTORY_ADDRESS;

    case 'arbitrum':
      return ARBITRUM_CLAIMER_FACTORY_ADDRESS;

    default:
      throw new Error(`Unknown chain ${chain}`);
  }
};

export const createClaimer = async (dealAddress, chainName, merkleRoot, total) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const claimerFactoryContract = new ethers.Contract(
      getChainClaimerFactoryAddress(chainName),
      ClaimerFactoryArtifact.abi,
      injectedProvider.getSigner()
    );
    const tx = await claimerFactoryContract.createClaimer(merkleRoot, total, dealAddress);
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};
