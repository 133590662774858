import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import CreateWalletDialog from './CreateWalletDialog';
import remote from '../remote';
import '../index.scss';
import Tabs from '../Tabs';

const NewWalletTable = ({ selectTab, selectedTab }) => {
  const { data: wallets } = remote.useGetAllNewWallets();

  const [isCreateWalletModalOpen, setIsCreateWalletModalOpen] = useState(false);
  const openCreateWalletModal = () => setIsCreateWalletModalOpen(true);
  const closeCreateWalletModal = () => setIsCreateWalletModalOpen(false);

  if (!wallets || selectedTab !== 'new-wallets') return null;

  return (
    <>
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">New Wallets</div>
          <div className="deals-header__deals-cnt vertical-center">
            <span>{wallets.length} Total</span>
          </div>
        </div>
      </div>
      <Tabs selectTab={selectTab} selectedTab={selectedTab} />
      <div className="distro-container">
        <div className="distro-actions">
          <RoundedButton onClick={openCreateWalletModal} className="action-button">
            <span>New wallet</span>
          </RoundedButton>
        </div>
        <TableContainer classes={{ root: 'distro-table' }}>
          <Table className="sticky-table-header">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Deal</TableCell>
                <TableCell>Receive Wallet</TableCell>
                <TableCell>Seed name</TableCell>
                <TableCell>Seed path</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {wallets.map(({ id, name, address, seed, path }) => (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{address}</TableCell>
                  <TableCell>{seed?.name}</TableCell>
                  <TableCell>{path}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <CreateWalletDialog
        isModalOpen={isCreateWalletModalOpen}
        closeModal={closeCreateWalletModal}
      />
    </>
  );
};

export default NewWalletTable;
