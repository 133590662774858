import { useReducer } from 'react';

function init() {
  return {
    search: '',
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'updateSearch':
      return { ...state, search: action.payload };

    default:
      throw new Error();
  }
}

const useFilters = (users) => {
  const [filters, dispatch] = useReducer(reducer, users, init);

  const dispatchUpdateSearch = (search) => dispatch({ type: 'updateSearch', payload: search });

  return {
    filters,
    actions: {
      dispatchUpdateSearch,
    },
  };
};

export default useFilters;
