import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiGet, apiPut, baseUrl } from 'services/apiService';

const getDealSchedule = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/distribution/schedule/deal/${dealId}`);
  return response.data;
};

const getOldDealSchedule = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/distribution/schedule/old-deal/${dealId}`);
  return response.data;
};

const getGeneralDealSchedule = (isOldDeal) => (isOldDeal ? getOldDealSchedule : getDealSchedule);

const useGetDealSchedule = (dealId, isOldDeal) => {
  return useQuery(['distribution', 'schedule', 'deal', dealId, isOldDeal], () =>
    getGeneralDealSchedule(isOldDeal)(dealId)
  );
};

const getDealHistory = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/distribution/history/deal/${dealId}`);
  return response.data;
};

const getOldDealHistory = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/distribution/history/old-deal/${dealId}`);
  return response.data;
};

const getGeneralDealHistory = (isOldDeal) => (isOldDeal ? getOldDealHistory : getDealHistory);

const useGetDealHistory = (dealId, isOldDeal) => {
  return useQuery(['distribution', 'history', 'deal', dealId, isOldDeal], () =>
    getGeneralDealHistory(isOldDeal)(dealId)
  );
};

const updateOldDealStatus = async (dealId, statusId) => {
  const response = await apiPut(`${baseUrl}/api/distribution/deals/${dealId}/status`, {
    statusId,
    isOldDeal: true,
  });
  return response.data;
};

const useUpdateOldDealStatus = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(({ statusId }) => updateOldDealStatus(dealId, statusId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['distribution', 'deals']);
    },
  });
};

const updateVestingStatus = async (dealId, vestingStatusId, isOldDeal) => {
  const response = await apiPut(`${baseUrl}/api/distribution/deals/${dealId}/vesting-status`, {
    vestingStatusId,
    isOldDeal,
  });
  return response.data;
};

const useUpdateVestingStatus = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ vestingStatusId, isOldDeal }) => updateVestingStatus(dealId, vestingStatusId, isOldDeal),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['distribution', 'deals']);
      },
    }
  );
};

export default {
  useGetDealSchedule,
  useGetDealHistory,
  useUpdateOldDealStatus,
  useUpdateVestingStatus,
};
