import { Box } from '@material-ui/core';
import BatchScheduleTable from 'components/distributions/BatchScheduleTable';
import SearchInput from 'components/filters/SearchInput';
import React, { useState } from 'react';
import HistoryFilters from '../HistoryFilters';
import useFilters from '../hooks/useFilters';
import Tabs from '../Tabs';
import filtersPipeline from '../utils/filtersPipeline';

const BatchScheduleSection = ({ selectTab, selectedTab, batchScheduleRows }) => {
  const [showInput, setShowInput] = useState(false);
  const { filters, actions } = useFilters(batchScheduleRows);
  const filteredBatchScheduleRows = filtersPipeline(batchScheduleRows, filters);

  if (selectedTab !== 'batch-schedule') return null;

  return (
    <>
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">Batch Schedule</div>
          <div className="deals-header__deals-cnt vertical-center">
            <span>{filteredBatchScheduleRows.length} Total</span>
          </div>
        </div>

        <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
          <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
            <SearchInput
              searchValue={filters.search}
              updateSearchValue={actions.dispatchUpdateSearch}
              showInput={showInput}
              setShowInput={setShowInput}
            />
          </div>
          <Box display="flex" alignItems="center" ml={1}>
            <HistoryFilters filters={filters} actions={actions} />
          </Box>
        </div>
      </div>
      <Tabs selectTab={selectTab} selectedTab={selectedTab} />
      <BatchScheduleTable
        batchScheduleRows={filteredBatchScheduleRows}
        type="history-distributions"
      />
    </>
  );
};

export default BatchScheduleSection;
