import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import SortableTable from 'components/sortable-table';
import { renderSafeValue } from 'utils';
import { useRouteMatch, useHistory } from 'react-router-dom';
import RoundedButton from 'components/button/rounded-button';
import useLoginsTableColumns from './hooks/useLoginsTableColumns';
import useLoginsQuery from './hooks/useLoginsQuery';

const UserLoginsTable = () => {
  const { params } = useRouteMatch();

  const history = useHistory();
  const goBack = () => {
    history.push(history.location.state.pathname, { activeTab: history.location.state.activeTab });
  };

  const { data: logins, isLoading } = useLoginsQuery(params.wallet);

  const columns = useLoginsTableColumns();

  if (isLoading) return null;

  return (
    <div className="all-deals-page">
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">
            <span className="title-span">Users</span>/ {params.wallet} / Logins
          </div>
        </div>

        <RoundedButton className="back-button" type="primary" onClick={goBack}>
          Go back
        </RoundedButton>
      </div>
      {logins?.length === 0 ? (
        <div className="no-data-found">No logins found</div>
      ) : (
        <SortableTable tableHead={columns} rows={logins} type="deal-info" paginationItemsCount={25}>
          {(sortedRows) =>
            sortedRows.map((row) => (
              <TableRow key={row.id}>
                {columns.map(({ column, accessor }) => (
                  <TableCell key={column}>{renderSafeValue(accessor(row))}</TableCell>
                ))}
              </TableRow>
            ))
          }
        </SortableTable>
      )}
    </div>
  );
};

export default UserLoginsTable;
