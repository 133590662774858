import React from 'react';
import IconButton from 'components/button/icon-button';
import { useNotifications } from 'hooks';
import FileSaver from 'file-saver';
import RoundedButton from 'components/button/rounded-button';

const PdfHandler = ({ id, getInvoicePdf, open = false }) => {
  const [, showErrorNotification] = useNotifications();

  const handlePdf = async (id) => {
    try {
      const arrayBuffer = await getInvoicePdf(id);
      const pdfFile = new Blob([arrayBuffer], { type: 'application/pdf' });
      if (open) {
        const pdfFileURL = URL.createObjectURL(pdfFile);
        window.open(pdfFileURL);
      } else {
        FileSaver.saveAs(pdfFile, `invoice-${id}.pdf`);
      }
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  return open ? (
    <RoundedButton type="primary" onClick={() => handlePdf(id)}>
      View{' '}
    </RoundedButton>
  ) : (
    <IconButton icon="iconDownload" onClick={() => handlePdf(id)} />
  );
};

export default PdfHandler;
