import { Box } from '@material-ui/core';
import BatchScheduleTable from 'components/distributions/BatchScheduleTable';
import HistoryTable from 'components/distributions/HistoryTable';
import SearchInput from 'components/filters/SearchInput';
import React, { useState } from 'react';
import HistoryFilters from '../HistoryFilters';
import useFilters from '../hooks/useFilters';
import Tabs from '../Tabs';
import filtersPipeline from '../utils/filtersPipeline';
import './index.scss';

const CompareSection = ({ selectTab, selectedTab, batchScheduleRows, historyRows }) => {
  const [showInput, setShowInput] = useState(false);

  const { filters, actions } = useFilters([...batchScheduleRows, ...historyRows]);
  const filteredBatchSchedule = filtersPipeline(batchScheduleRows, filters);
  const filteredHistoryRows = filtersPipeline(historyRows, filters);

  if (selectedTab !== 'compare') return null;

  return (
    <>
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">Compare</div>
        </div>

        <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
          <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
            <SearchInput
              searchValue={filters.search}
              updateSearchValue={actions.dispatchUpdateSearch}
              showInput={showInput}
              setShowInput={setShowInput}
            />
          </div>
          <Box display="flex" alignItems="center" ml={1}>
            <HistoryFilters filters={filters} actions={actions} />
          </Box>
        </div>
      </div>
      <Tabs selectTab={selectTab} selectedTab={selectedTab} />
      <div className="fixed-table">
        <HistoryTable historyRows={filteredHistoryRows} type="history-distributions-fixed" />
      </div>

      <div className="fixed-table">
        <BatchScheduleTable
          batchScheduleRows={filteredBatchSchedule}
          type="batch-schedule-distributions-fixed"
        />{' '}
      </div>
    </>
  );
};

export default CompareSection;
