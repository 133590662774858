import { useNotifications } from 'hooks';
import remote from 'screens/CustomClaims/remote';

const useCustomClaimActions = () => {
  const startJob = remote.useStartJob();
  const stopJob = remote.useStopJob();
  const runJobManually = remote.useRunJobManually();
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const handleStartJob = async (jobName) => {
    try {
      await startJob.mutateAsync({ jobName });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePauseJob = async (jobName) => {
    try {
      await stopJob.mutateAsync({ jobName });
    } catch (err) {
      console.log(err);
    }
  };

  const onActionClick = async (jobName, isActive) => {
    try {
      if (isActive) {
        await handlePauseJob(jobName);
      } else {
        await handleStartJob(jobName);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRunJobManually = async (type, jobName) => {
    try {
      await runJobManually.mutateAsync({ type, jobName });
      showSuccessNotification('Job started!');
    } catch (err) {
      console.log(err);
      showErrorNotification('Error running job!');
    }
  };

  return {
    onActionClick,
    handleRunJobManually,
    runJobManuallyLoading: runJobManually.isLoading,
  };
};

export default useCustomClaimActions;
