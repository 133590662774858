import { getTransfersFromTx } from 'contracts/tokenTransfers';
import { useQuery } from 'react-query';
import { validateTxHash } from 'utils';

const useGetTransfers = (txHash, chainId) => {
  return useQuery(['transfers', txHash, chainId], () => getTransfersFromTx(txHash, chainId), {
    enabled: validateTxHash(txHash),
  });
};

export default useGetTransfers;
