import React, { useEffect, useState } from 'react';
import { verifyEmail } from 'services/apiService';
import queryString from 'query-string';
import RoundedButton from 'components/button/rounded-button';
import './index.scss';
import background from 'assets/images/verify-email-background.webp';
import SvgIcon from 'components/svgIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const EmailVerify = () => {
  const [isVerified, setIsVerified] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const { token } = queryString.parse(window.location.search);

    (async () => {
      try {
        await verifyEmail(token);
        setIsVerified(true);
      } catch {
        setIsVerified(false);
      }
    })();
  }, []);

  if (isVerified === null) return null;

  return (
    <div className="verify-email__wrapper" style={{ backgroundImage: `url(${background})` }}>
      <div className="verify-email__container">
        <div className="bd-logo">
          <SvgIcon name="iconBlackDragon" />
        </div>
        {isVerified ? (
          <>
            <h1>Thanks for verifying your email address.</h1>
            <p>Have fun using the BlackDragon platform.</p>
          </>
        ) : (
          <>
            <h1>Token invalid!</h1>
            <p>The token is expired or invalid. Please try again.</p>
          </>
        )}
        <RoundedButton type="secondary" onClick={() => history.push('/')}>
          Launch App
        </RoundedButton>
      </div>
    </div>
  );
};

export default EmailVerify;
