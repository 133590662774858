import { useMutation } from 'react-query';
import { apiPut, baseUrl } from 'services/apiService';

const updateDeal = async (dealId, data) => {
  const response = await apiPut(`${baseUrl}/api/deal/admin/${dealId}`, data);
  return response.data;
};

const useUpdateDeal = () => {
  return useMutation(({ dealId, data }) => updateDeal(dealId, data), {
    onSuccess: (newDeal) => newDeal,
  });
};

export default { useUpdateDeal };
