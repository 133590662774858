import React from 'react';
import { useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import './index.scss';

const CustomLoading = () => {
  const globalReducer = useSelector((state) => state.global);
  const { loading } = globalReducer;

  if (!loading) return null;
  return (
    <div className="loading-container">
      <ClipLoader size={50} color="#44d7b6" loading={loading} />
    </div>
  );
};

export default CustomLoading;
