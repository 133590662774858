import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { PrimaryIntentions } from './constants';

const PrimaryIntention = ({ value, otherValue, error, onChange }) => (
  <FormControl error={error} fullWidth>
    <p className="profile-col-title">
      What is the primary intention of using the BlackDragon platform?
    </p>
    <div className="radio-group">
      <div style={{ width: '100%' }}>
        <RadioGroup
          aria-label="primary-intention"
          name="primaryIntentions"
          value={value}
          onChange={onChange}
          fullWidth
        >
          {PrimaryIntentions.map((option) => (
            <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
          ))}
          <div className="other-option">
            <FormControlLabel value="other" control={<Radio />} label="Other" />
            <TextField
              variant="filled"
              margin="normal"
              fullWidth
              name="otherIntentions"
              placeholder="Please specify"
              type="text"
              disabled={value !== 'other'}
              value={otherValue}
              onChange={onChange}
            />
          </div>
        </RadioGroup>
        {error && <FormHelperText>This field is required.</FormHelperText>}
      </div>
    </div>
  </FormControl>
);

export default PrimaryIntention;
