import { ethers } from 'ethers';
import { LOCK_ADDRESS, ARBITRUM_CHAIN_ID, BDT_ADDRESS } from 'constants/config';
import { getProviderByChainId } from './providers';
import ERC677Artifact from './abis/ERC677.json';
import LockArtifact from './abis/Lock.json';

export const lockTokens = async (amount) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const bdtContract = new ethers.Contract(
      BDT_ADDRESS,
      ERC677Artifact.abi,
      injectedProvider.getSigner()
    );

    const tx = await bdtContract.transferAndCall(
      LOCK_ADDRESS,
      ethers.utils.parseUnits(amount || '0'),
      '0x'
    );

    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const startUnlock = async () => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const lockContract = new ethers.Contract(
      LOCK_ADDRESS,
      LockArtifact.abi,
      injectedProvider.getSigner()
    );

    const tx = await lockContract.startUnlock();
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const unlockTokens = async () => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const lockContract = new ethers.Contract(
      LOCK_ADDRESS,
      LockArtifact.abi,
      injectedProvider.getSigner()
    );
    const tx = await lockContract.unlock();
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const specialUnlock = async () => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const lockContract = new ethers.Contract(
      LOCK_ADDRESS,
      LockArtifact.abi,
      injectedProvider.getSigner()
    );
    const tx = await lockContract.specialUnlock();
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getLockInfo = async (address) => {
  const lockContract = new ethers.Contract(
    LOCK_ADDRESS,
    LockArtifact.abi,
    getProviderByChainId(ARBITRUM_CHAIN_ID)
  );
  const [releaseTimeBN, amountBN] = await lockContract.getAddressLockInfo(address);
  return {
    releaseTime: releaseTimeBN.toString(),
    amount: ethers.utils.formatEther(amountBN),
  };
};

export const getOwnerUnlocked = async () => {
  const lockContract = new ethers.Contract(
    LOCK_ADDRESS,
    LockArtifact.abi,
    getProviderByChainId(ARBITRUM_CHAIN_ID)
  );

  const ownerUnlocked = await lockContract.getOwnerUnlocked();

  return ownerUnlocked;
};

export const getTvl = async () => {
  const lockContract = new ethers.Contract(
    LOCK_ADDRESS,
    LockArtifact.abi,
    getProviderByChainId(ARBITRUM_CHAIN_ID)
  );
  const tvlBN = await lockContract.getTvl();
  return ethers.utils.formatEther(tvlBN);
};

export const setOwnerUnlocked = async (ownerUnlocked) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);

  try {
    const lockContract = new ethers.Contract(
      LOCK_ADDRESS,
      LockArtifact.abi,
      injectedProvider.getSigner()
    );
    const tx = await lockContract.setOwnerUnlocked(ownerUnlocked);
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};
