import { Dialog, Divider, TextField } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import CustomInput from 'components/input/custom-input';
import NumberInput from 'components/input/number-input';
import { ethers } from 'ethers';
import DatePicker from 'react-datepicker';
import React, { useEffect, useState } from 'react';
import { SUPPORTED_NETWORKS } from 'constants/config';
import CustomSelect from 'components/select/custom-select';
import { useQuery } from 'react-query';
import { useNotifications } from 'hooks';
import './index.scss';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import useGetTransfers from './remote';

const initialState = {
  txHash: null,
  sold: 0,
  received: 0,
  date: null,
  chainId: '',
  deal: null,
  exchange: '',
  disableDealSelect: false,
};

const AddSellTransactionDialog = ({ open, onOk, onCancel, existingTx, deals }) => {
  const [transaction, setTransaction] = useState(initialState);
  const [, showErrorNotification] = useNotifications();

  const { data } = useGetTransfers(transaction.txHash, transaction.chainId);
  const exchanges = useQuery(['exchanges'], () =>
    axios.get('https://api.coingecko.com/api/v3/exchanges/list')
  );

  useEffect(() => {
    if (!open) {
      setTransaction(initialState);
      return;
    }

    setTransaction({
      ...initialState,
      ...existingTx,
      exchange:
        existingTx.exchange && exchanges.data
          ? exchanges.data.data.find(({ id }) => id === existingTx.exchange)
          : '',
    });
  }, [open, deals, existingTx, exchanges.data]);

  const handleChange = (e) => {
    e.persist();
    setTransaction((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const loadTxData = () => {
    if (!data || existingTx?.id) return;
    const { transfers, block } = data;

    const [sent, recieved] = transfers.sort((a, b) =>
      a.token.decimals < b.token.decimals ? 1 : -1
    );

    const sold =
      sent?.amount && sent.token
        ? ethers.utils.formatUnits(
            sent.amount.toLocaleString('fullwide', { useGrouping: false }),
            sent.token.decimals
          )
        : 0;

    const received =
      recieved?.amount && recieved.token
        ? +(+ethers.utils.formatUnits(
            recieved.amount.toLocaleString('fullwide', { useGrouping: false }),
            recieved.token.decimals
          )).toFixed(6)
        : 0;

    setTransaction((prev) => ({
      ...prev,
      sold,
      received,
      date: block ? new Date(block?.timestamp * 1000) : prev.date,
    }));
  };

  const handleSave = () => {
    if (!transaction.date || !transaction.deal) {
      showErrorNotification('Deal, date, received and sold are required');
      return;
    }

    onOk(transaction);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <div className="bd-modal transaction-modal">
        <div className="bd-modal-inner">
          <div className="bd-modal-header">
            <div>
              <h2>Add transaction details</h2>
            </div>
            <div>
              <RoundedButton type="transparent" onClick={onCancel}>
                Cancel
              </RoundedButton>
              <RoundedButton type="primary" onClick={handleSave}>
                <div className="d-flex">Add</div>
              </RoundedButton>
            </div>
          </div>
          <div className="add-sell-modal-form">
            <Divider />
            <div className="smaller-input">
              <div>
                <span>Deal name</span>
                <Autocomplete
                  classes={{ root: 'deals-input' }}
                  disablePortal
                  options={deals.sort((a, b) => (a.name > b.name ? 1 : -1))}
                  getOptionSelected={(option) => option.id}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => <TextField {...params} />}
                  value={transaction.deal}
                  onChange={(_, value) => setTransaction((prev) => ({ ...prev, deal: value }))}
                />
              </div>
              <div>
                <span className="date-picker">Sales Date</span>
                <DatePicker
                  selected={transaction.date}
                  onChange={(date) => setTransaction((prev) => ({ ...prev, date }))}
                  dateFormat="dd-MMM-yyyy"
                />
              </div>
              <NumberInput
                label="Tokens for Sale"
                name="sold"
                value={transaction.sold}
                onChange={handleChange}
                decimalNumber="18"
              />
              <NumberInput
                label="Amount Received ($)"
                name="received"
                value={transaction.received}
                onChange={handleChange}
                decimalNumber="6"
              />
              <div className="price">
                {+transaction.received / +transaction.sold
                  ? `Price: $${+transaction.received / +transaction.sold}`
                  : ''}
              </div>
              <div />
            </div>
            <Divider />
            <div className="tx-input">
              {exchanges.data && (
                <Autocomplete
                  classes={{ root: 'exchange-input' }}
                  disablePortal
                  options={exchanges.data?.data}
                  getOptionSelected={(option) => option.id}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => <TextField {...params} label="Exchange (optional)" />}
                  value={transaction.exchange}
                  onChange={(_, value) => setTransaction((prev) => ({ ...prev, exchange: value }))}
                />
              )}
              <div className="tx-hash_label">
                <label>TX hash (optional)</label>
                {transaction.txHash && transaction.chainId && (
                  <button onClick={loadTxData} type="button">
                    Load
                  </button>
                )}
              </div>
              <CustomInput name="txHash" value={transaction.txHash || ''} onChange={handleChange} />
              <CustomSelect
                label="Network (optional)"
                items={[
                  { name: '', value: '' },
                  ...SUPPORTED_NETWORKS.map(({ chainId, name }) => ({
                    name: name.replace('-', ' ').toUpperCase(),
                    value: chainId,
                  })),
                ]}
                value={transaction.chainId}
                onSelect={(_, value) => setTransaction((prev) => ({ ...prev, chainId: value }))}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddSellTransactionDialog;
