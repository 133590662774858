import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Popper } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import IconButton from 'components/button/icon-button';
import { CSVLink } from 'react-csv';
import { getAdminInvoiceCsvData, getInvoiceCsvData } from 'services/apiService';
import { useSelector } from 'react-redux';
import { MultiSelect } from 'react-multi-select-component';

const adminCsvHeader = [
  'Transaction #',
  'Invoice #',
  'Invoice Date',
  'User',
  'User Wallet',
  'User Level',
  'Deal Name',
  'Contract',
  'Date Created',
  'Date Closed',
  'Tx Timestamp',
  'Invesment',
  'Fee',
  'Price',
];

const userCsvHeader = [
  'Transaction #',
  'Invoice #',
  'Invoice Date',
  'Deal Name',
  'Contract',
  'Date Created',
  'Date Closed',
  'Tx Timestamp',
  'Invesment',
  'Fee',
  'Price',
];

const InvoiceTableHeader = ({ invoices, onChange }) => {
  const buttonRef = useRef();
  const csvExportRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportData, setExportData] = useState([]);
  const authReducer = useSelector((state) => state.auth);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { isAdmin } = authReducer;

  const uniqueDealOptions = useMemo(() => {
    return invoices
      .filter(
        (invoice, index, self) =>
          index === self.findIndex((t) => t.dealAddress === invoice.dealAddress)
      )
      .map(({ dealAddress, dealName }) => ({ label: dealName, value: dealAddress }));
  }, [invoices]);

  const uniqueUserOptions = useMemo(() => {
    return invoices
      .filter(
        (invoice, index, self) =>
          index === self.findIndex((t) => t.userAddress === invoice.userAddress)
      )
      .map(({ userEmail, userAddress }) => ({ label: userEmail, value: userAddress }));
  }, [invoices]);

  const toggleInput = () => {
    if (window.innerWidth < 767) {
      setShowInput(!showInput);
    }
  };

  useEffect(() => {
    if (!value && !startDate && !endDate && !selectedDeals.length && !selectedUsers.length) {
      onChange(invoices);
      return;
    }
    onChange(
      invoices.filter(
        ({ dealName, date, dealAddress, userAddress }) =>
          dealName.toLowerCase().includes(value.toLowerCase()) &&
          (!startDate || new Date(date) > startDate) &&
          (!endDate || new Date(date) < endDate) &&
          (!selectedDeals.length || selectedDeals.some((item) => item.value === dealAddress)) &&
          (!selectedUsers.length || selectedUsers.some((item) => item.value === userAddress))
      )
    );
  }, [onChange, invoices, selectedDeals, value, startDate, endDate, selectedUsers]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
    });
  }, [setShowInput]);

  const handleCsvDownload = async () => {
    const csvEndpoint = isAdmin ? getAdminInvoiceCsvData : getInvoiceCsvData;
    const invoiceCsvData = await csvEndpoint(
      startDate,
      endDate,
      selectedDeals.map((item) => item.value),
      selectedUsers.map((item) => item.value)
    );
    setExportData(invoiceCsvData);
    setTimeout(() => {
      csvExportRef.current.link.click();
      setExportData([]);
    }, 0);
  };

  return (
    <div className="invoices__header">
      <div className="invoices__title-container">
        <h1>Invoices</h1>
        <span>{invoices.length} Total</span>
        <div onClick={handleCsvDownload} className="invoices__export-container">
          <SvgIcon name="invoiceExport" />
          <span>Export</span>
        </div>
      </div>
      <div className="invoices__filter-container">
        <CSVLink
          ref={csvExportRef}
          data={[isAdmin ? adminCsvHeader : userCsvHeader, ...exportData]}
          style={{ display: 'none' }}
          filename="invoices.csv"
        >
          Export basic analytics to CSV
        </CSVLink>
        <div className={`search-input${showInput ? ' active' : ''}`}>
          <div className="input-icon-wrapper vertical-center" onClick={toggleInput}>
            <SvgIcon name="search" />
          </div>
          <input
            type="text"
            value={value}
            placeholder="Search invoices by name"
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="invoice-filter-picker" ref={buttonRef}>
          <RoundedButton onClick={() => setIsOpen((prev) => !prev)}>
            <SvgIcon name="filter" />
            <span>Filter</span>
          </RoundedButton>
          <Popper open={isOpen} anchorEl={buttonRef.current} placement="bottom-end">
            <div className="invoice-filter-picker__dropdown">
              <span>Invoice created</span>
              <div className="date-picker-wrapper">
                {startDate && (
                  <div className="close-icon">
                    <IconButton icon="iconDeleteX" onClick={() => setStartDate(null)} />
                  </div>
                )}
                <DatePicker
                  selected={startDate}
                  onChange={setStartDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start date"
                />
              </div>
              <div className="date-picker-wrapper">
                {endDate && (
                  <div className="close-icon">
                    <IconButton icon="iconDeleteX" onClick={() => setEndDate(null)} />
                  </div>
                )}
                <DatePicker
                  selected={endDate}
                  onChange={setEndDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End date"
                />
              </div>
              {isAdmin && (
                <>
                  <span>Deal</span>
                  <MultiSelect
                    options={uniqueDealOptions}
                    value={selectedDeals}
                    valueRenderer={(selected) => {
                      return selected.length ? `${selected.length} Selected` : 'All';
                    }}
                    onChange={setSelectedDeals}
                    className="multi-select"
                  />
                  <span>User</span>
                  <MultiSelect
                    options={uniqueUserOptions}
                    value={selectedUsers}
                    valueRenderer={(selected) => {
                      return selected.length ? `${selected.length} Selected` : 'All';
                    }}
                    onChange={setSelectedUsers}
                    className="multi-select"
                  />
                </>
              )}
            </div>
          </Popper>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTableHeader;
