import * as actionTypes from '../constants';

export const updateAuth = (payload) => ({
  type: actionTypes.ACTION_UPDATE_AUTH,
  payload,
});

export const setProfileIsUs = (payload) => ({
  type: actionTypes.ACTION_SET_PROFILE_IS_US,
  payload,
});

export const subBdtBalance = (payload) => ({
  type: actionTypes.ACTION_SUB_BDT_BALANCE,
  payload,
});

export const addBdtBalance = (payload) => ({
  type: actionTypes.ACTION_ADD_BDT_BALANCE,
  payload,
});

export const subUsdtBalance = (payload) => ({
  type: actionTypes.ACTION_SUB_USDT_BALANCE,
  payload,
});

export const addUsdtBalance = (payload) => ({
  type: actionTypes.ACTION_ADD_USDT_BALANCE,
  payload,
});

export const resetAuth = () => ({
  type: actionTypes.ACTION_RESET_AUTH,
});
