import React, { forwardRef } from 'react';
import Countdown from '../countdown';
import './index.scss';

const DealModelTooltip = ({ title, timestamp }) => {
  return (
    <div className="deal-model-tooltip">
      <div className="timer-holder">
        <h3>{title}</h3>
        <div className="cd-holder">
          <span>Starts in:</span>
          <Countdown timestamp={timestamp} />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(DealModelTooltip);
