import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAuth } from 'store/actions';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import './index.scss';
import { ethers } from 'ethers';
import { checkEmail } from 'utils/helpers';
import {
  getUser,
  updateUser,
  getKycUid,
  sendVerificationEmail,
  updateOtherWallet,
} from 'services/apiService';
import { validateChain } from 'utils';
import ChainList from 'contracts/json/ChainList.json';
import { useNotifications } from 'hooks';
import { KYC_URL, KYC_CLIENT_ID, KYC_REDIRECT_URL } from 'constants/config';
import IconButton from 'components/button/icon-button';
import ProfileEditModal from 'features/userProfile/ProfileEditModal';
import NotificationManagement from 'features/userProfile/NotificationManagement';
import AdditionalInfo from 'features/userProfile/AdditionalInfo';
import { getInitialPepData } from 'features/userProfile/AdditionalInfo/components/PoliticallyExposedPerson/helpers';

const UserProfile = () => {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const { isInitialLoadFinished } = globalReducer;
  const {
    idVerified,
    profileWallets,
    profileIsUs,
    imageUrl,
    email,
    isAdmin,
    isPep,
    primaryIntentions,
    sourceOfFunds,
    ...rest
  } = authReducer;
  const [profileEmailModalOpen, setProfileEmailModalOpen] = useState(false);
  const [profileDiscordModalOpen, setProfileDiscordModalOpen] = useState(false);
  const [profileWalletModalOpen, setProfileWalletModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const [chainData, setChainData] = useState([]);
  const [kycUid, setKycUid] = useState('');

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const mappedProfileWallets = useMemo(() => {
    return profileWallets.reduce(
      (accum, { address, chain }) => ({ ...accum, [chain.toLowerCase()]: address }),
      {}
    );
  }, [profileWallets]);

  const verifyKYC = () => {
    openInNewTab(
      `https://${KYC_URL}/authorize?client_id=${KYC_CLIENT_ID}&redirect_uri=${KYC_REDIRECT_URL}&response_type=code&scope=contact%3Aread%20verification.plus%3Aread%20verification.plus.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.wallet%3Aread%20verification.wallet.details%3Aread&state=${kycUid}`
    );
  };

  const editKyc = () => {
    window.open(`https://${KYC_URL}`, '_blank');
  };

  const openProfileEmailModal = (type) => {
    setModalType(type);
    setProfileEmailModalOpen(true);
  };

  const onGetUser = async () => {
    try {
      const {
        otherWallets,
        email: profileEmail,
        kycStatus,
        isUs,
        discord,
        username,
        enabledNotifications: notifications,
        cloudMessagingToken,
        primaryIntentions,
        sourceOfFunds,
        isPep,
        ...rest
      } = await getUser();

      dispatch(
        updateAuth({
          profileWallets: otherWallets || [],
          email: profileEmail,
          idVerified: kycStatus,
          profileIsUs: isUs,
          imageUrl,
          discord,
          username,
          enabledNotifications: notifications,
          cloudMessagingToken,
          primaryIntentions,
          sourceOfFunds,
          isPep,
          ...rest,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onCloseEmailModal = () => {
    setModalType('');
    setProfileEmailModalOpen(false);
  };

  const onCloseDiscordModal = () => {
    setModalType('');
    setProfileDiscordModalOpen(false);
  };

  const onCloseWalletModal = () => {
    setModalType('');
    setProfileWalletModalOpen(false);
  };

  const onDiscordUpdate = async (discordNameToUpdate) => {
    const newData = {
      isUs: profileIsUs,
      discord: discordNameToUpdate,
      imageUrl,
    };
    try {
      await updateUser(newData);
      onGetUser();
      onCloseDiscordModal();
      showSuccessNotification('Updated successfully!');
    } catch (err) {
      console.error(err);
      showErrorNotification(err.response.data.message);
    }
  };

  const onAdditionalInfoUpdate = async (additionalInfo) => {
    try {
      await updateUser({ ...additionalInfo, isUs: profileIsUs });
      await onGetUser();
      showSuccessNotification('Updated successfully!');
    } catch (err) {
      console.error(err);
      showErrorNotification(err.response.data.message);
    }
  };

  const onWalletAdd = async (walletToAdd) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signedMessage = await provider
        .getSigner()
        .signMessage(`Update ${modalType} wallet address to ${walletToAdd}`);

      await updateOtherWallet({ address: walletToAdd, chain: modalType }, signedMessage);
      onGetUser();
      onCloseWalletModal();
      showSuccessNotification('Updated successfully!');
    } catch (err) {
      console.log(err);
      showErrorNotification('Something went wrong whil updating wallet');
    }
  };

  const onEmailModalConfirm = async (emailToEdit) => {
    if (modalType === 'add') {
      const newData = {
        email: { address: emailToEdit },
        isUs: profileIsUs,
        imageUrl,
      };
      try {
        await updateUser(newData);
        onCloseEmailModal();
        onGetUser();
        showSuccessNotification('Updated successfully!');
      } catch (err) {
        console.error(err);
        showErrorNotification(err.response.data.message);
      }
    }
    if (modalType === 'edit') {
      const newData = {
        email: { address: emailToEdit },
        isUs: profileIsUs,
        imageUrl,
      };
      try {
        await updateUser(newData);
        onCloseEmailModal();
        onGetUser();
        showSuccessNotification('Updated successfully!');
      } catch (err) {
        showErrorNotification('Something went wrong while updating wallet');
      }
    }
  };

  const createProfile = async () => {
    const newData = {
      isUs: false,
      imageUrl,
    };
    try {
      await updateUser(newData);
      await onGetUser();
      verifyKYC();
    } catch (err) {
      console.error(err);
      showErrorNotification(err.response.data.message);
    }
  };

  const onGetKycUid = async () => {
    try {
      const response = await getKycUid();
      setKycUid(response.kycUid);
    } catch (err) {
      console.error(err);
    }
  };

  const getVerificationIcon = () => {
    if (
      idVerified === 'verification_rejected' ||
      idVerified === 'rejected' ||
      idVerified === 'forbidden_country' ||
      idVerified === null ||
      idVerified === 'id_not_verified' ||
      idVerified === 'wrong_data'
    ) {
      return <SvgIcon name="iconProfileNotVerified" />;
    }
    if (idVerified === 'pending') {
      return <SvgIcon name="iconNotificationPending" />;
    }
    return <SvgIcon name="iconProfileVerified" />;
  };

  const sendVerificationLink = async () => {
    try {
      await sendVerificationEmail(email.address, `${window.location.origin}/verify-email`);
      showSuccessNotification(
        `Succesfully sent verification email to ${email.address}, check your inbox`
      );
    } catch {
      showErrorNotification('Please wait 1min before you start new verification process');
    }
  };

  const handleAddEditDiscordModalOpen = () => {
    setProfileDiscordModalOpen(true);
    setModalType(authReducer.discord ? 'edit' : 'add');
  };

  useEffect(() => {
    if (idVerified === 'wrong_data') {
      showErrorNotification(
        "Invalid wallet address. Click on 'Edit profile', update your wallet address and re-verify it."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idVerified]);

  useEffect(() => {
    onGetUser();
    onGetKycUid();
    setChainData(ChainList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="user-profile">
      {profileEmailModalOpen && (
        <ProfileEditModal
          onOk={onEmailModalConfirm}
          onCancel={onCloseEmailModal}
          field={email?.address}
          title={`${modalType === 'add' ? 'Add' : 'Edit'} new email`}
          fieldLabel="Email"
          fieldValidator={checkEmail}
        />
      )}
      {profileDiscordModalOpen && (
        <ProfileEditModal
          onOk={onDiscordUpdate}
          onCancel={onCloseDiscordModal}
          field={authReducer.discord || ''}
          title={`${modalType === 'add' ? 'Add' : 'Edit'} discord`}
          fieldLabel="Discord username"
          fieldValidator={(discord) => /^((?!.*\.\.)[\w.]){2,32}$/.test(discord)}
        />
      )}
      {profileWalletModalOpen && (
        <ProfileEditModal
          onOk={onWalletAdd}
          onCancel={onCloseWalletModal}
          field={authReducer.profileWallets.find(({ chain }) => chain === modalType)?.address || ''}
          title={`${modalType} wallet`}
          fieldLabel="Wallet"
          fieldValidator={(address) => validateChain(address, modalType)}
        />
      )}
      {isInitialLoadFinished && !isAdmin && !email?.isVerified ? (
        <div className="profile-app-locked-banner">
          <h1>Please add and verify your email in order to unlock the app!</h1>
        </div>
      ) : isInitialLoadFinished && !isPep && !primaryIntentions && !sourceOfFunds ? (
        <div className="profile-app-locked-banner">
          <h1>Please fill in the additional information</h1>
        </div>
      ) : null}

      <div className="profile-title-holder">
        <div className="title">Profile</div>
      </div>
      <div className="profile-row">
        <div className="profile-col">
          <p className="profile-col-title profile-col-title">
            Personal Info
            <span className="profile-col-title-subtitle" style={{ left: '110px' }}>
              (required)
            </span>
          </p>

          <div className="profile-col-box coming-soon-col-box">
            <div className="box-info">
              <div className="box-info-title-holder">
                {(idVerified === 'id_not_verified' ||
                  idVerified === null ||
                  idVerified === 'wrong_data') && (
                  <h3 className="box-info-title">KYC not verified</h3>
                )}
                {idVerified === 'id_verified' && (
                  <h3 className="box-info-title-large">ID verified</h3>
                )}
                {(idVerified === 'verification_approved' || idVerified === 'approved') && (
                  <h3 className="box-info-title-large">ID verified</h3>
                )}
                {idVerified === 'pending' && (
                  <h3 className="box-info-title">KYC verification pending</h3>
                )}
                {(idVerified === 'verification_rejected' || idVerified === 'rejected') && (
                  <h3 className="box-info-title">KYC verification rejected</h3>
                )}
                {getVerificationIcon()}
              </div>
            </div>
            <div className="box-actions">
              {(idVerified === 'wrong_data' ||
                idVerified === 'pending' ||
                idVerified === 'verification_rejected') && (
                <RoundedButton type="primary" onClick={() => verifyKYC(profileIsUs)}>
                  <span>Re-verify</span>
                </RoundedButton>
              )}
              {(idVerified === 'id_not_verified' ||
                idVerified === 'verification_rejected' ||
                idVerified === 'forbidden_country' ||
                idVerified === 'pending' ||
                idVerified === 'wrong_data') && (
                <RoundedButton type="primary" onClick={editKyc}>
                  Edit
                </RoundedButton>
              )}
              {idVerified === null && (
                <RoundedButton type="primary" onClick={createProfile}>
                  Create
                </RoundedButton>
              )}
            </div>
          </div>
        </div>
        <div className="profile-col">
          <p className="profile-col-title">
            Email
            <span className="profile-col-title-subtitle" style={{ left: '50px' }}>
              (required)
            </span>
          </p>
          {!email ? (
            <div className="profile-col-box">
              <div className="box-info">
                <div className="box-info-title-holder">
                  <h3 className="box-info-title orange-icon">
                    <SvgIcon name="error" /> Please add your email
                  </h3>
                </div>
              </div>
              <div className="box-actions">
                <RoundedButton type="primary" onClick={() => openProfileEmailModal('add')}>
                  Add
                </RoundedButton>
              </div>
            </div>
          ) : (
            <div className="profile-col-box active email-col">
              <div className="box-info">
                <div className="box-info-title-holder">
                  <h3 className="box-info-title">
                    <SvgIcon name="mail" />
                    {email.address}
                    {email.isVerified && <SvgIcon name="iconProfileVerified" />}
                  </h3>
                </div>
              </div>
              <div className="box-actions">
                {!email.isVerified && (
                  <RoundedButton type="primary" onClick={sendVerificationLink}>
                    Verify
                  </RoundedButton>
                )}
                <RoundedButton
                  type="primary"
                  onClick={() => openProfileEmailModal('edit', email.address)}
                >
                  Edit
                </RoundedButton>
              </div>
            </div>
          )}
        </div>
        <div className="profile-col">
          <p className="profile-col-title">Discord</p>
          <div className="profile-col-box coming-soon-col-box">
            <div className="box-info">
              <div className="box-info-title-holder discord-info-title-holder">
                {authReducer.discord ? (
                  <h3 className="box-info-title">
                    {' '}
                    <SvgIcon name="discord" /> {authReducer.discord}
                  </h3>
                ) : (
                  <h3 className="box-info-title orange-icon">
                    <SvgIcon name="error" /> Please add Discord username
                  </h3>
                )}
              </div>
            </div>
            <div className="discord-form">
              <RoundedButton type="primary" onClick={handleAddEditDiscordModalOpen}>
                {authReducer.discord ? 'Edit' : 'Add'}
              </RoundedButton>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-row large">
        <div className="profile-col large">
          <p className="profile-col-title profile-col-title">Wallet</p>
          <div className="profile-col-box coming-soon-col-box">
            <div className="box-info profile-wallet-list">
              <table>
                <tbody>
                  <tr>
                    <th>Token</th>
                    <th>Address</th>
                    <th />
                  </tr>
                  {chainData.map(({ value }) => (
                    <tr key={value}>
                      <td>{value}</td>
                      <td>{mappedProfileWallets[value.toLowerCase()]}</td>
                      <td className="profile-wallet-edit-btn-holder">
                        {mappedProfileWallets[value.toLowerCase()] ? (
                          validateChain(mappedProfileWallets[value.toLowerCase()], value) ? (
                            <SvgIcon name="iconDealsCheckmarkClicked" />
                          ) : (
                            <SvgIcon name="error" />
                          )
                        ) : null}
                        <IconButton
                          onClick={() => {
                            setProfileWalletModalOpen(true);
                            setModalType(value);
                          }}
                          icon="iconProfileWalletEdit"
                          className="profile-wallet-edit-btn"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="profile-col large">
          <div style={{ marginBottom: '20px' }}>
            <p className="profile-col-title profile-col-title" style={{ position: 'relative' }}>
              Notification Management
            </p>
            <div className="profile-col-box">
              <NotificationManagement onGetUser={onGetUser} />
            </div>
          </div>
          <AdditionalInfo
            data={{
              ...getInitialPepData(rest),
              isPep,
              primaryIntentions,
              sourceOfFunds,
            }}
            onUpdate={onAdditionalInfoUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
