import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { getInvoiceData, getInvoicePdf } from 'services/apiService';
import SvgIcon from 'components/svgIcon';
import moment from 'moment';
import TxHashLink from 'components/TxHashLink';
import PdfHandler from 'components/PdfHandler';
import './index.scss';

const InvoiceTab = ({ deal }) => {
  const [invoiceData, setInvoiceData] = useState([]);

  useEffect(() => {
    (async () => {
      const invoice = await getInvoiceData(deal.invoiceId);
      setInvoiceData(invoice);
    })();
  }, [deal.invoiceId]);

  return (
    <div className="invoice-list">
      <div className="header-column">
        <div className="header-invoice-labels">
          <label>#</label>
          <label>Invoice #</label>
          <label>Invoice date</label>
          <label>Due date</label>
          <label>Fee</label>
          <label>Total amount</label>
        </div>
      </div>
      {invoiceData.length &&
        invoiceData.map(
          ({ id, totalTransactionAmount, totalFeeUsd, date, number, year, items, isStorno }, i) => (
            <Accordion
              key={`${id}${totalTransactionAmount}`}
              classes={{ root: 'accordion', rounded: 'accordion__rounded' }}
            >
              <AccordionSummary
                classes={{
                  content: 'accordion-summary__content',
                  root: 'accordion-summary',
                  focusVisible: 'accordion-summary__focused',
                }}
                expandIcon={<SvgIcon name="arrowDown" />}
              >
                <div className="invoice-row">
                  <div className="invoice-labels">
                    <label>{i + 1}.</label>
                    <label>
                      {year}/{number}
                    </label>
                    <label>{moment(date).format('D-MMMM-YYYY')}</label>
                    <label>{moment(deal.dateClosed).format('D-MMMM-YYYY')}</label>
                    <label>{totalFeeUsd}</label>
                    <label>{totalTransactionAmount}</label>
                  </div>
                  <div className="icons-container">
                    <div className="icon-download">
                      <PdfHandler
                        id={id}
                        getInvoicePdf={(invoiceId) => getInvoicePdf(invoiceId, isStorno)}
                      />
                    </div>
                    <PdfHandler
                      id={id}
                      getInvoicePdf={(invoiceId) => getInvoicePdf(invoiceId, isStorno)}
                      open
                    />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails classes={{ root: 'accordion-details' }}>
                <Divider />
                <div className="invoice-content">
                  <Table className="invoice-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>TX HASH</TableCell>
                        <TableCell>TX DATE</TableCell>
                        <TableCell>CONTIRBUTION FEE</TableCell>
                        <TableCell>CONTIRBUTION AMOUNT</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items.length &&
                        items.map(
                          (
                            { position, txHash, dateOfContribution, feeUsd, transactionAmount },
                            index
                          ) => (
                            <TableRow key={position}>
                              <TableCell>{index + 1}.</TableCell>
                              <TableCell>
                                <TxHashLink hash={txHash} chainId={deal.chainId} />
                              </TableCell>
                              <TableCell>
                                {moment(dateOfContribution).format('D-MMMM-YYYY')}
                              </TableCell>
                              <TableCell>{feeUsd}</TableCell>
                              <TableCell>{transactionAmount}</TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </div>
              </AccordionDetails>
            </Accordion>
          )
        )}
    </div>
  );
};

export default InvoiceTab;
