import { useQuery } from 'react-query';
import remote from '../remote';

function useUserLegalDetails(address) {
  const { data, isFetching } = useQuery({
    queryKey: ['user-legal', address],
    queryFn: () => remote.getUserLegalDetails(address),
  });

  return {
    data,
    isLoading: isFetching,
  };
}

export default useUserLegalDetails;
