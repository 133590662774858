import React, { useEffect, useRef, useState } from 'react';
import { Popper, Divider, ClickAwayListener } from '@material-ui/core';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { formatAddress } from 'utils';
import SvgIcon from 'components/svgIcon';
import IconButton from 'components/button/icon-button';
import { NavLink, useHistory } from 'react-router-dom';
import RoundedButton from 'components/button/rounded-button';
import { useNotifications } from 'hooks';
import { updateUser, uploadImage } from 'services/apiService';
import { updateAuth, updateSystemPart } from 'store/actions';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import ViewAsUser from 'features/header/ViewAsUser';
import { getSystemPartsBaseRoute, ROLES, SYSTEM_PARTS } from 'features/sidebar/LeftSideBar/utils';
import dropdownOptions from './utils';

const ProfileDropdown = ({ handleLogout }) => {
  const buttonRef = useRef();
  const inputRef = useRef();
  const nameEditInputRef = useRef();
  const authReducer = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [nameToEdit, setNameToEdit] = useState('');
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalReducer = useSelector((state) => state.global);
  const { isPreviewMode } = globalReducer;

  const { accountInfo, username, profileIsUs, imageUrl, role, features } = authReducer;
  const availableDropdownOptions = dropdownOptions.get(role ?? ROLES.USER) ?? [];

  const updateSystemPartWith = (part) => {
    dispatch(updateSystemPart(part));
    setIsOpen(false);
    const redirectRoute = getSystemPartsBaseRoute(part, features);
    history.push(redirectRoute);
  };

  const updateSystemPartWithRedirect = (route) => {
    dispatch(updateSystemPart(SYSTEM_PARTS.DEALS));
    setIsOpen(false);
    history.push(route);
  };

  useEffect(() => {
    setNameToEdit(username);
  }, [username]);

  const onImageUpload = async (files) => {
    const file = files[0];
    if (!file) return showErrorNotification('No file selected');
    if (!(file.type === 'image/png' || file.type === 'image/svg' || file.type === 'image/jpeg')) {
      return showErrorNotification('Invalid file type');
    }
    const data = new FormData();
    data.append('image', file);
    try {
      const { path } = await uploadImage(data);

      await updateUser({
        imageUrl: path,
        isUs: profileIsUs,
        username,
      });

      dispatch(
        updateAuth({
          imageUrl: path,
        })
      );
      showSuccessNotification('Profile picture successfully updated');
    } catch {
      showErrorNotification(`Something went wrong while uploading image`);
    }
  };

  const onUsernameUpdate = async () => {
    try {
      if (nameToEdit !== username) {
        await updateUser({
          imageUrl,
          isUs: profileIsUs,
          username: nameToEdit,
        });

        dispatch(
          updateAuth({
            username: nameToEdit,
          })
        );
        showSuccessNotification('Profile username successfully updated');
      }
    } catch {
      showErrorNotification(`Something went wrong while updating username`);
    }
  };

  const removeAvatar = async (e) => {
    e.stopPropagation();
    await updateUser({
      imageUrl: null,
      isUs: profileIsUs,
    });

    dispatch(
      updateAuth({
        imageUrl: null,
      })
    );
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div className="profile-dropdown" ref={buttonRef}>
          {accountInfo?.address && (
            <div className="profile-dropdown__container" onClick={() => setIsOpen((prev) => !prev)}>
              <RoundedAvatar
                src={
                  imageUrl ||
                  `https://api.dicebear.com/7.x/bottts/svg?seed=${accountInfo.address}&background=%2370bceb&size=100`
                }
              />
            </div>
          )}
          <Popper disablePortal open={isOpen} anchorEl={buttonRef.current} placement="bottom-end">
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              style={{ display: 'none' }}
              onChange={(e) => onImageUpload(e.target.files)}
            />
            <div className="profile-dropdown__popper">
              <div className="profile-dropdown__popper-info">
                <div>
                  <div onClick={() => inputRef.current.click()}>
                    <div className="profile-dropdown__popper-info-edit">
                      <SvgIcon name="iconUserProfileEdit" width={8} height={8} />
                    </div>
                    <RoundedAvatar
                      width={55}
                      className="profile-dropdown__popper-info-avatar"
                      src={
                        imageUrl ||
                        `https://api.dicebear.com/7.x/bottts/svg?seed=${accountInfo.address}&background=%2370bceb&size=100`
                      }
                    />
                    {imageUrl && (
                      <div className="profile-dropdown__popper-info-delete" onClick={removeAvatar}>
                        <span>x</span>
                      </div>
                    )}
                  </div>
                  <div>
                    <span className="profile-dropdown__popper-info-username">
                      <input
                        value={nameToEdit}
                        placeholder="No name set"
                        disabled={!isNameEditing}
                        ref={nameEditInputRef}
                        onChange={(e) => e.target.value !== ' ' && setNameToEdit(e.target.value)}
                      />
                      {isNameEditing ? (
                        <RoundedButton
                          onClick={() => {
                            onUsernameUpdate();
                            setIsNameEditing((prev) => !prev);
                          }}
                        >
                          Confirm
                        </RoundedButton>
                      ) : (
                        <IconButton
                          icon="iconDealsEdit"
                          onClick={() => {
                            setIsNameEditing((prev) => !prev);
                            setTimeout(() => {
                              nameEditInputRef.current.focus();
                            }, 100);
                          }}
                        />
                      )}
                    </span>
                    <span className="profile-dropdown__popper-info-address">
                      {formatAddress(accountInfo.address)}
                    </span>
                  </div>
                </div>
                <IconButton icon="iconChevronUp" onClick={() => setIsOpen(false)} />
              </div>
              {(availableDropdownOptions?.includes(SYSTEM_PARTS.PROFILE_SETTINGS) ||
                features?.includes(SYSTEM_PARTS.PROFILE_SETTINGS)) && (
                <>
                  <NavLink
                    to="/profile"
                    activeClassName="sidebar-menu__item--active"
                    className="sidebar-menu__item vertical-center text-decoration-none"
                  >
                    <div className="profile-dropdown__popper-settings">
                      <div
                        className="option"
                        onClick={() => updateSystemPartWithRedirect('/profile')}
                      >
                        <SvgIcon name="iconSettingsGray" />
                        <p> Profile Settings</p>
                      </div>
                    </div>
                  </NavLink>
                  <Divider />
                </>
              )}
              {(availableDropdownOptions?.includes(SYSTEM_PARTS.DEALS) ||
                features?.includes(SYSTEM_PARTS.DEALS)) && (
                <>
                  <NavLink
                    to={getSystemPartsBaseRoute(SYSTEM_PARTS.DEALS, features)}
                    activeClassName="sidebar-menu__item--active"
                    className="sidebar-menu__item vertical-center text-decoration-none"
                  >
                    <div className="profile-dropdown__popper-settings">
                      <div
                        className="option"
                        onClick={() => updateSystemPartWith(SYSTEM_PARTS.DEALS)}
                      >
                        <SvgIcon name="iconSettingsGray" />
                        <p>Deals</p>
                      </div>
                    </div>
                  </NavLink>
                  <Divider />
                </>
              )}
              {(availableDropdownOptions?.includes(SYSTEM_PARTS.DISTRO_MANAGER) ||
                features?.includes(SYSTEM_PARTS.DISTRO_MANAGER)) && (
                <>
                  <NavLink
                    to={getSystemPartsBaseRoute(SYSTEM_PARTS.DISTRO_MANAGER, features)}
                    activeClassName="sidebar-menu__item--active"
                    className="sidebar-menu__item vertical-center text-decoration-none"
                  >
                    <div className="profile-dropdown__popper-settings">
                      <div
                        className="option"
                        onClick={() => updateSystemPartWith(SYSTEM_PARTS.DISTRO_MANAGER)}
                      >
                        <SvgIcon name="iconSettingsGray" />
                        <p>Distro Manager</p>
                      </div>
                    </div>
                  </NavLink>
                  <Divider />
                </>
              )}
              {(availableDropdownOptions?.includes(SYSTEM_PARTS.USER_MANAGER) ||
                features?.includes(SYSTEM_PARTS.USER_MANAGER)) && (
                <>
                  <NavLink
                    to={getSystemPartsBaseRoute(SYSTEM_PARTS.USER_MANAGER, features)}
                    activeClassName="sidebar-menu__item--active"
                    className="sidebar-menu__item vertical-center text-decoration-none"
                  >
                    <div className="profile-dropdown__popper-settings">
                      <div
                        className="option"
                        onClick={() => updateSystemPartWith(SYSTEM_PARTS.USER_MANAGER)}
                      >
                        <SvgIcon name="iconSettingsGray" />
                        <p>User Manager</p>
                      </div>
                    </div>
                  </NavLink>
                  <Divider />
                </>
              )}
              {(availableDropdownOptions?.includes(SYSTEM_PARTS.INVOICES) ||
                features?.includes(SYSTEM_PARTS.INVOICES)) && (
                <>
                  <NavLink
                    to="/invoices"
                    activeClassName="sidebar-menu__item--active"
                    className="sidebar-menu__item vertical-center text-decoration-none"
                  >
                    <div className="profile-dropdown__popper-settings">
                      <div
                        className="option"
                        onClick={() => updateSystemPartWithRedirect('/invoices')}
                      >
                        <SvgIcon name="iconInvoice" />
                        <p>Invoices</p>
                      </div>
                    </div>
                  </NavLink>
                </>
              )}
              <Divider />
              {(availableDropdownOptions?.includes(SYSTEM_PARTS.VIEW_AS_USER) ||
                features?.includes('view as user') ||
                isPreviewMode) && <ViewAsUser />}
              <div className="profile-dropdown__popper-logout">
                <RoundedButton onClick={handleLogout}>Log out</RoundedButton>
              </div>
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default ProfileDropdown;
