import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getServerImage } from 'services/apiService';

function Image({ src, ...rest }) {
  const [isExternal, setIsExternal] = useState(false);
  const [base64Image, setBase64Image] = useState(null);

  useEffect(() => {
    try {
      new URL(src);
      setIsExternal(true);
    } catch {
      if (!src.includes('public/')) return setIsExternal(true);

      setIsExternal(false);
      getServerImage(src)
        .then(setBase64Image)
        .catch(() => {});
    }
  }, [src]);

  if (isExternal) return <img src={src} alt={rest.alt} {...rest} />;

  return <img src={base64Image} alt={rest.alt} {...rest} />;
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Image;
