import { ethers } from 'ethers';
import DealArtifact from '../abis/Deal.json';
import DealArtifactV2 from '../abis/DealV2.json';
import ClaimerArtifact from '../abis/Claimer.json';
import ERC20Artifact from '../abis/ERC20.json';

function buildDealInfoCall(targetAddress) {
  return {
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(DealArtifact.abi).encodeFunctionData('getDealInfo', []),
    returnTypes: ['string', 'string', 'uint256[12]'],
  };
}

function buildCurrentPhaseCall(targetAddress) {
  return {
    allowFailure: true,
    target: targetAddress,
    name: 'phase',
    callData: new ethers.utils.Interface(DealArtifactV2.abi).encodeFunctionData(
      'getCurrentPhase',
      []
    ),
    returnTypes: [
      'uint8',
      'uint8',
      'uint8',
      'uint256',
      'uint256[4]',
      'uint256',
      'uint256',
      'uint256',
      'uint256',
      'bytes32',
      'uint256',
    ],
  };
}

function buildClaimAmountCall(targetAddress, chainId, callParams) {
  return {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(ClaimerArtifact.abi).encodeFunctionData(
      'getAddressAvailableClaimAmount',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint256'], ['0']),
    returnTypes: ['uint256'],
  };
}

function buildDealClaimAmountCall(targetAddress, chainId, callParams) {
  return {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(DealArtifact.abi).encodeFunctionData(
      'getAddressAvailableClaimAmount',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint256'], ['0']),
    returnTypes: ['uint256'],
  };
}

function buildMaxContributionAmountCall(targetAddress, callParams) {
  return {
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(DealArtifact.abi).encodeFunctionData(
      'getMaxContributionAmount',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint256'], ['0']),
    returnTypes: ['uint256'],
  };
}

function buildMaxContributionAmountCallV2(targetAddress, callParams) {
  return {
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(DealArtifactV2.abi).encodeFunctionData(
      'getMaxContributionAmount',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint256'], ['0']),
    returnTypes: ['uint256'],
  };
}

function buildAddressContributionAmountCall(targetAddress, callParams) {
  return {
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(DealArtifact.abi).encodeFunctionData(
      'getAddressContributionAmount',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint256'], ['0']),
    returnTypes: ['uint256'],
  };
}

function buildIsWhitelistedCall(targetAddress, callParams) {
  return {
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(DealArtifact.abi).encodeFunctionData(
      'isWhitelisted',
      callParams
    ),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['bool'], [false]),
    returnTypes: ['bool'],
  };
}

function buildTokenInfoCall(targetAddress, chainId) {
  const nameCall = {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(ERC20Artifact.abi).encodeFunctionData('name'),
    fallbackValue: 'Unknown',
    returnTypes: ['string'],
  };

  const symbolCall = {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(ERC20Artifact.abi).encodeFunctionData('symbol'),
    fallbackValue: 'Unknown',
    returnTypes: ['string'],
  };

  const decimalsCall = {
    chainId,
    allowFailure: true,
    target: targetAddress,
    callData: new ethers.utils.Interface(ERC20Artifact.abi).encodeFunctionData('decimals'),
    fallbackValue: ethers.utils.defaultAbiCoder.encode(['uint8'], ['18']),
    returnTypes: ['uint8'],
  };

  return [nameCall, symbolCall, decimalsCall];
}

export {
  buildDealInfoCall,
  buildClaimAmountCall,
  buildCurrentPhaseCall,
  buildIsWhitelistedCall,
  buildTokenInfoCall,
  buildDealClaimAmountCall,
  buildMaxContributionAmountCall,
  buildMaxContributionAmountCallV2,
  buildAddressContributionAmountCall,
};
