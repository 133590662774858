import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SortableTable from 'components/sortable-table';
import { TableCell, TableRow } from '@material-ui/core';
import DealStatus from 'components/deal-status';
import SvgIcon from 'components/svgIcon';
import AddressLink from 'components/address-link';
import utils from 'screens/CustomClaims/utils';
import CustomClaimHistoryModal from './CustomClaimHistoryModal';
import CustomClaimTooltipButton from './CustomClaimTooltipButton';
import useCustomClaimActions from '../hooks/useCustomClaimActions';
import tableHead from '../utils/getTableHead';
import './index.scss';

const CustomClaimsTable = ({ jobs }) => {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedJobName, setSelectedJobName] = useState(null);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const openHistoryModal = () => setShowHistoryModal(true);
  const closeHistoryModal = () => setShowHistoryModal(false);
  const history = useHistory();

  const { onActionClick, handleRunJobManually, runJobManuallyLoading } = useCustomClaimActions();

  const handleHistoryClick = (jobId, jobName) => {
    setSelectedJobId(jobId);
    setSelectedJobName(jobName);
    openHistoryModal();
  };

  const handleRowClick = (dealId, isOldDeal) => {
    history.push(`/all-deals/${dealId}?isOldDeal=${isOldDeal}`, {
      pathname: window.location.pathname,
    });
  };

  return (
    <div className="custom-claims-table">
      <SortableTable tableHead={tableHead.customClaimsTableHead} rows={jobs} type="custom-claims">
        {(sortedRows) =>
          sortedRows.map(
            ({
              id,
              jobName,
              dealId,
              isOldDeal,
              dealName,
              dealStatus,
              chainName,
              isActive,
              lastRunDate,
              nextRun,
              receiveWallet,
              claimContractAddress,
              tokenName,
              tokenAddress,
              successfulRun,
              jobType,
            }) => (
              <TableRow key={id} onClick={() => handleRowClick(dealId, isOldDeal)}>
                <TableCell>
                  <p className="deal-name">{dealName}</p>
                </TableCell>
                <TableCell>
                  <DealStatus dealStatus={dealStatus} />
                </TableCell>
                <TableCell>{chainName}</TableCell>
                <TableCell className="last-run">
                  <div className="d-flex">
                    {utils.formatJobDate(lastRunDate)}
                    {utils.isValidDate(lastRunDate) && (
                      <div className="last-run-status">
                        <SvgIcon name={successfulRun ? 'success' : 'iconProfileNotVerified'} />
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell>{utils.formatJobDate(nextRun)}</TableCell>
                <TableCell>
                  <AddressLink chainName={chainName} chainAddress={receiveWallet} />
                </TableCell>
                <TableCell>
                  <AddressLink chainName={chainName} chainAddress={claimContractAddress} />
                </TableCell>
                <TableCell>
                  {tokenName} <AddressLink chainName={chainName} chainAddress={tokenAddress} />
                </TableCell>
                <TableCell>{`${isActive ? 'Running' : 'Stopped'}`}</TableCell>
                <TableCell>
                  <div className="action-cell">
                    <CustomClaimTooltipButton
                      title={isActive ? 'Stop' : 'Start'}
                      action={() => onActionClick(jobName, isActive)}
                      icon={isActive ? 'pause' : 'play'}
                      className={`action-button ${isActive ? 'paused' : 'running'}`}
                    />

                    <CustomClaimTooltipButton
                      title="Run job manually"
                      action={() => handleRunJobManually(jobType, jobName)}
                      icon={runJobManuallyLoading ? 'iconDealsCheckmarkClicked' : 'iconSwapGreen'}
                      disabled={runJobManuallyLoading}
                    />

                    <CustomClaimTooltipButton
                      title="View History"
                      action={() => handleHistoryClick(id, jobName)}
                      icon="iconTokensClaimed"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )
          )
        }
      </SortableTable>
      <CustomClaimHistoryModal
        jobId={selectedJobId}
        jobName={selectedJobName}
        isModalOpen={showHistoryModal}
        closeModal={closeHistoryModal}
      />
    </div>
  );
};

export default CustomClaimsTable;
