import React, { useEffect, useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import CustomInput from 'components/input/custom-input';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from 'components/svgIcon';
import { Box } from '@material-ui/core';

const ProfileEditModal = ({
  onOk,
  title,
  fieldLabel,
  onCancel,
  field,
  fieldValidator = () => true,
}) => {
  const [fieldToEdit, setFieldToEdit] = useState('');

  useEffect(() => {
    if (field) setFieldToEdit(field);
  }, [field]);

  const isFieldValid = fieldValidator(fieldToEdit);

  const onChangeData = (e) => {
    setFieldToEdit(e.target.value);
  };

  const onConfirm = () => {
    onOk(fieldToEdit);
  };

  return (
    <Dialog open className="close-deal-modal" id="close-deal-modal" onClose={onCancel}>
      <div className="close-deal-modal-header d-flex">
        <div className="close-deal-modal-header__left d-flex vertical-center">
          <div className="deal-avatar vertical-center">
            <SvgIcon name="iconProfileWalletModalHeader" />
          </div>
          <div className="deal-name vertical-center">
            <div className="full-width">
              <span>{title}</span>
            </div>
          </div>
        </div>
        <div className="close-deal-modal-header__right vertical-center">
          <RoundedButton onClick={onCancel}>Cancel</RoundedButton>
          <RoundedButton
            disabled={!isFieldValid || field === fieldToEdit}
            type="primary"
            onClick={onConfirm}
          >
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
      </div>
      <Box mb={4} mt={3}>
        <div className="destination-address">
          <CustomInput
            label={fieldLabel || ''}
            name={fieldLabel}
            value={fieldToEdit}
            onChange={onChangeData}
            status={!isFieldValid ? 'error' : 'success'}
          />
        </div>
      </Box>
    </Dialog>
  );
};

export default ProfileEditModal;
