import RoundedButton from 'components/button/rounded-button';
import React from 'react';

const MyDealsTabs = ({ filters, actions, showLegacyDeals }) => {
  return (
    <div className="deals-table-options d-flex">
      <div className="filter-btn-wrapper">
        <RoundedButton
          className={`filter-btn ${filters.type === 'platform' ? 'filter-btn--active' : ''}`}
          onClick={() => actions.dispatchUpdateType('platform')}
        >
          <span>Platform Deals</span>
        </RoundedButton>
      </div>
      {showLegacyDeals && (
        <>
          <div className="filter-btn-wrapper">
            <RoundedButton
              className={`filter-btn ${filters.type === 'old' ? 'filter-btn--active' : ''}`}
              onClick={() => actions.dispatchUpdateType('old')}
            >
              <span>Legacy Deals</span>
            </RoundedButton>
          </div>
          <div className="filter-btn-wrapper">
            <RoundedButton
              className={`filter-btn ${filters.type === 'old-archive' ? 'filter-btn--active' : ''}`}
              onClick={() => actions.dispatchUpdateType('old-archive')}
            >
              <span>Archived Legacy Deals</span>
            </RoundedButton>
          </div>
        </>
      )}
      <div className="filter-btn-wrapper">
        <RoundedButton
          className={`filter-btn ${filters.type === 'archive' ? 'filter-btn--active' : ''}`}
          onClick={() => actions.dispatchUpdateType('archive')}
        >
          <span>Archived</span>
        </RoundedButton>
      </div>
    </div>
  );
};

export default MyDealsTabs;
