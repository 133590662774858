import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import SearchInput from 'components/filters/SearchInput';
import useFilters from '../hooks/useFilters';
import filtersPipeline from '../utils/filtersPipeline';
import AllDealsFilters from '../AllDealsFilters';
import AllDealsTable from '../AllDealsTable';

const AllDealsContent = ({ allDeals }) => {
  const { filters, actions } = useFilters(allDeals);
  const filteredDeals = filtersPipeline(allDeals, filters);

  const [showInput, setShowInput] = useState(false);

  return (
    <>
      <div className="deals-header d-flex">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">Distros</div>
          {allDeals && (
            <div className="deals-header__deals-cnt vertical-center">
              <span>{filteredDeals.length} Total</span>
            </div>
          )}
        </div>

        <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
          <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
            <SearchInput
              searchValue={filters.search}
              updateSearchValue={actions.dispatchUpdateSearch}
              showInput={showInput}
              setShowInput={setShowInput}
            />
          </div>
          <Box display="flex" alignItems="center" ml={1}>
            <AllDealsFilters filters={filters} actions={actions} />
          </Box>
        </div>
      </div>
      {allDeals && <AllDealsTable deals={filteredDeals} filters={filters} />}
    </>
  );
};

export default AllDealsContent;
