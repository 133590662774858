import SortableTable from 'components/sortable-table';
import React from 'react';
import utils from './utils';
import AllDealsTableRow from './AllDealsTableRow';
import './index.scss';

const AllDealsTable = ({ deals }) => {
  return (
    <div className="all-deals-table">
      <SortableTable tableHead={utils.allDealsTableHead} rows={deals} type="all-deals">
        {(sortedRows) => sortedRows.map((row) => <AllDealsTableRow key={row.id} {...row} />)}
      </SortableTable>
    </div>
  );
};

export default AllDealsTable;
