import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'components/svgIcon';
import IconButton from 'components/button/icon-button';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import { formatAddress } from 'utils/helpers';
import { useOnClickOutside } from 'hooks';
import './index.scss';
import notificationTypes from 'constants/notificationTypes';
import { getTransactionLinkByChainName, getAddressLinkByChainName } from 'contracts/explorers';

function NotificationSelect({ opened, notifications, onToggle, onClose }) {
  // const dispatch = useDispatch();
  const ref = useRef();
  const [isPending, setIsPending] = useState(false);

  useOnClickOutside(ref, () => {
    onClose();
  });

  const notificationsNumber = () => {
    return notifications.length;
  };

  // const newNotificationsNumber = () => {
  //   const arr = [];
  //   notifications.forEach((el) => {
  //     if (el.seen === false) {
  //       arr.push(el);
  //     }
  //   });
  //   return arr.length;
  // };

  // const onClearNotifications = () => {
  //   dispatch(clearNotifications());
  // };

  useEffect(() => {
    if (notifications.some((x) => x.status === 'pending')) {
      setIsPending(true);
    } else {
      setIsPending(false);
    }
  }, [notifications]);

  const getNotificationHref = (notification) => {
    if (notification.actionType === 'createDeal')
      return getAddressLinkByChainName(notification.chain, notification.dealAddress);

    return getTransactionLinkByChainName(notification.chain, notification.name);
  };

  const getNotificationBadge = (type, image) => {
    switch (type) {
      case notificationTypes.GENERAL:
        return <RoundedAvatar src={image} />;
      case notificationTypes.LOCKUP:
        return <SvgIcon name="iconLockupGreen" />;
      default:
        return <RoundedAvatar src={image} />;
    }
  };

  return (
    <div className="notification-dropdown" ref={ref}>
      <div
        onClick={onToggle}
        className="notification-dropdown-toggle d-flex"
        id="notification-dropdown-toggle"
      >
        <IconButton onClick={onToggle} icon="notification" pending={isPending} />
        <span onClick={onToggle} className="notifications-number">
          {notificationsNumber()}
        </span>
      </div>
      {opened && (
        <div className="notification-dropdown-menu">
          <div className="notification-dropdown-menu-header">
            <div className="notification-dropdown-menu-header__title">Transaction History</div>
          </div>
          <div className="notification-dropdown-menu-content">
            {notifications
              .sort((a, b) => (a.time < b.time ? -1 : 1))
              .map((notification) => (
                <div
                  type="button"
                  key={notification.id}
                  className="notification-dropdown-menu__item d-flex"
                  role="presentation"
                >
                  <div className="notification-avatar vertical-center">
                    {getNotificationBadge(notification.type, notification.dealImage)}
                  </div>
                  <a
                    href={getNotificationHref(notification)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="notification-content"
                  >
                    <div className="notification-content__name">
                      {formatAddress(notification.name)}
                    </div>
                    <div className={`notification-content__text ${notification.status}`}>
                      {notification.statusText}
                    </div>
                  </a>
                  <div className="notification-icon vertical-center">
                    {notification.status === 'success' && (
                      <SvgIcon name="iconNotificationSuccess" />
                    )}
                    {notification.status === 'pending' && (
                      <SvgIcon name="iconNotificationPending" />
                    )}
                    {notification.status === 'failed' && <SvgIcon name="iconNotificationFailed" />}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

NotificationSelect.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape),
  onToggle: PropTypes.func,
  onClose: PropTypes.func,
};

NotificationSelect.defaultProps = {
  notifications: [],
  onToggle: () => {},
  onClose: () => {},
};

export default NotificationSelect;
