import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserDeal } from 'store/actions';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import remote from './remote';

const BlackDragonDealModal = ({ open, onClose, deal }) => {
  const updateDeal = remote.useUpdateDeal();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    price: deal.price,
    fee: deal.fee,
    vesting: deal.vesting,
    round: deal.round,
  });

  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async (e) => {
    e.preventDefault();

    try {
      await updateDeal.mutateAsync({ dealId: deal.id, data: { ...data } });
      showSuccessNotification(`Updated successfully`);
      dispatch(setUserDeal({ ...deal, ...data }));
      onClose();
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal blackdragon-deal-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>BlackDragon Deal</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" buttonType="submit" form="blackdragon-deal-form">
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="blackdragon-deal-form" onSubmit={onConfirm}>
        <div className="col col-left">
          <div className="input-holder">
            <label>Price:</label>
            <input name="price" value={data.price} onChange={onChangeData} />
          </div>
          <div className="input-holder">
            <label>Round:</label>
            <input name="round" type="text" value={data.round} onChange={onChangeData} />
          </div>
          <div className="input-holder">
            <label>Vesting:</label>
            <input name="vesting" value={data.vesting} onChange={onChangeData} />
          </div>
          <div className="input-holder">
            <label>Fee:</label>
            <input name="fee" value={data.fee} onChange={onChangeData} />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default BlackDragonDealModal;
