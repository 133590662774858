import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSharedNotification } from 'store/actions';
import SvgIcon from 'components/svgIcon';
import './index.scss';

const SharedNotification = () => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.global);
  const { sharedNotification } = globalReducer;

  const onClose = () => {
    dispatch(setSharedNotification({ status: '' }));
  };

  if (!sharedNotification.status) return null;
  return (
    <div className={`shared-notification ${sharedNotification.status}`}>
      <div className="shared-notification-icon vertical-center">
        {sharedNotification.status === 'pending' ? (
          <SvgIcon name="iconSpinner" />
        ) : (
          <SvgIcon name="notification" />
        )}
      </div>
      <div className="shared-notification-content">
        <div className="title">{sharedNotification.title}</div>
        <div className="description">{sharedNotification.description}</div>
      </div>
      <div
        className="shared-notification-action vertical-center"
        role="presentation"
        onClick={onClose}
      >
        Close
      </div>
    </div>
  );
};

export default SharedNotification;
