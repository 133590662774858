import { ethers } from 'ethers';
import { decodeDealInfo, decodePhase } from 'models/common/decoders';

function parseEssentialData(multicallResults, callIndex) {
  const rawChainInfo = multicallResults[callIndex.dealInfoIndex].returnData;
  const rawContributionAmount = multicallResults[callIndex.contributionIndex].returnData[0];
  const rawUserWhitelist = multicallResults[callIndex.whitelistIndex]?.returnData[0];

  return {
    dealChainData: decodeDealInfo(rawChainInfo),
    userWhitelisted: rawUserWhitelist || false,
    contributedAmount:
      rawContributionAmount && ethers.utils.formatUnits(rawContributionAmount, 'mwei'),
  };
}

function parseOptionalData(multicallResults, callIndex) {
  let rawMaxContribution = null;
  if (callIndex.maxContributionIndex !== null) {
    rawMaxContribution = multicallResults[callIndex.maxContributionIndex]?.returnData[0];
  }

  let rawPhaseInfo = null;
  if (callIndex.phaseInfoIndex !== null) {
    rawPhaseInfo = multicallResults[callIndex.phaseInfoIndex]?.returnData;
  }

  return {
    phaseInfo: rawPhaseInfo ? decodePhase(rawPhaseInfo) : {},
    maxContributionAmount: rawMaxContribution && Number(rawMaxContribution),
  };
}

function filterAccessibleDeals(liveDealResults, accountInfo) {
  return liveDealResults.filter(
    ({ minViewLevel, userWhitelisted, contributedAmount, minAccessLevel }) => {
      const isWhitelistedForMinViewLevel = minViewLevel === 4 && userWhitelisted;
      const isWhitelistedForMinAccessLevel = minAccessLevel === 4 && userWhitelisted;

      const hasSufficientViewLevel = (minViewLevel || 0) <= accountInfo.userViewLevel;
      const hasContributed = !!+contributedAmount;

      return (
        hasSufficientViewLevel ||
        isWhitelistedForMinViewLevel ||
        isWhitelistedForMinAccessLevel ||
        hasContributed
      );
    }
  );
}

export { parseEssentialData, parseOptionalData, filterAccessibleDeals };
