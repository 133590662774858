import React from 'react';
import RoundedButton from 'components/button/rounded-button';

const Tabs = ({ selectTab, selectedTab }) => {
  return (
    <div className="tabs">
      <RoundedButton
        className={`tab-btn ${selectedTab === 'wallets' ? 'selected' : ''}`}
        onClick={() => selectTab('wallets')}
      >
        <span>Wallets</span>
      </RoundedButton>
      <RoundedButton
        className={`tab-btn ${selectedTab === 'assigned-wallets' ? 'selected' : ''}`}
        onClick={() => selectTab('assigned-wallets')}
      >
        <span>Assigned Wallets</span>
      </RoundedButton>
      <RoundedButton
        className={`tab-btn ${selectedTab === 'new-wallets' ? 'selected' : ''}`}
        onClick={() => selectTab('new-wallets')}
      >
        <span>New Wallets</span>
      </RoundedButton>
    </div>
  );
};

export default Tabs;
