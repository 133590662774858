import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import IconButton from 'components/button/icon-button';
import SvgIcon from 'components/svgIcon';
import './index.scss';

const LockupNotification = ({ open, onClose, relockMessage }) => {
  return (
    <Dialog open={open} className="disclaim-modal" id="disclaim-modal" onClose={onClose}>
      <div className="disclaim-modal-header d-flex">
        <div className="disclaim-modal-header__title d-flex vertical-center">
          <SvgIcon name="report" />
          <span>Please Note!</span>
        </div>
        <div className="disclaim-modal-header__btn vertical-center">
          <IconButton icon="close" onClick={onClose} />
        </div>
      </div>
      <div className="disclaim-modal-body d-flex">
        <div className="disclaim-rules">
          <p className="disclaim-rules__item">{relockMessage[0]}</p>
          <p className="disclaim-rules__item">
          {relockMessage[1]}
          </p>
        </div>
      </div>
      <div className="disclaim-modal-footer">
        <div className="disclaim-actions">
          <RoundedButton type="secondary" onClick={onClose}>
            Confirm
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default LockupNotification;
