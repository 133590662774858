import React from 'react';
import { Divider, Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import HistoryTable from '../../../HistoryContent/HistoryTable';
import remote from '../../../remote';
import './index.scss';

const CustomClaimHistoryModal = ({ jobId, jobName, isModalOpen, closeModal }) => {
  const { data: jobHistory } = remote.useGetLogsForJob(jobId, isModalOpen);

  if (!jobHistory) return null;

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      classes={{ paper: 'custom-claim-history-modal' }}
    >
      <DialogTitle>
        {jobName}{' '}
        <IconButton aria-label="close" onClick={closeModal}>
          X
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!!jobHistory && (
          <HistoryTable isModalOpen={isModalOpen} logs={jobHistory} type="single-job-logs" />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomClaimHistoryModal;
