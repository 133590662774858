import React, { useMemo } from 'react';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import RoundedButton from 'components/button/rounded-button';
import numeral from 'numeral';
import SvgIcon from 'components/svgIcon';
import Tooltip from 'components/tooltip';
import { ethers, utils } from 'ethers';
import { formatChartPrice } from 'utils';
import useDealAnalytics from './remote';
import DealAnalyticChart from '../DealAnalyticChart';
import './index.scss';

const calculatePnL = (sellTransactionsCount, pnlFormula) => {
  if (!sellTransactionsCount) return numeral(0).format('$0,0');
  return numeral(pnlFormula).format('$0,0');
};

const DealAnalytics = () => {
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const { walletAddress } = authReducer;
  const { userDeals } = globalReducer;
  const { params } = useRouteMatch();
  const { search } = useLocation();
  const history = useHistory();
  const isOldDeal = queryString.parse(search).isOldDeal === 'true';
  const dealAnalytics = useDealAnalytics(walletAddress, params.dealId, isOldDeal,  history.location.state.pathname);

  const deal = userDeals.find(({ id }) => id === params?.dealId);

  const totalClaimable = useMemo(() => {
    if (!params.dealId) return 0;

    if (!deal || !deal?.claimers) return 0;
    return deal.claimers.reduce(
      (accum, { claimableAmounts }) =>
        accum +
          claimableAmounts?.reduce(
            (amountAccum, { claimAmount }) => amountAccum + +claimAmount,
            0
          ) || 0,
      0
    );
  }, [deal, params.dealId]);

  return (
    <div>
      <div className="analytics-header">
        <div>
          <div className="analytics-header__title">{dealAnalytics.data?.name}</div>
        </div>
        <div>
          <RoundedButton
            type="primary"
            onClick={() =>
              history.location.state.pathname === '/analytics'
                ? history.push('/analytics')
                : history.push('/dashboard', { activeTab: 'overview' })
            }
          >
            Go back
          </RoundedButton>
        </div>
      </div>
      {dealAnalytics.error && <h1>Not available yet</h1>}
      {!dealAnalytics.data && !dealAnalytics.error && <h1>Loading...</h1>}
      {dealAnalytics.data && (
        <div className="analytics-content__details">
          <div className="stats">
            <div className="analytic-container">
              <SvgIcon width={80} height={48} name="iconRoi" />
              <div className="analytic-info">
                <span>CURRENT ROI</span>
                <span>
                  {dealAnalytics.data.roi ? `${dealAnalytics.data.roi.toFixed(2)}x` : '-'}
                </span>
              </div>
            </div>
            <div className="analytic-container">
              <SvgIcon width={80} height={48} name="iconAthRoi" />
              <div className="analytic-info">
                <span>ATH ROI</span>
                <span>
                  {dealAnalytics.data.athRoi ? `${dealAnalytics.data.athRoi.toFixed(2)}x` : '-'}
                </span>
              </div>
            </div>
            <div className="analytic-container">
              <SvgIcon width={48} height={48} name="iconInvest" />
              <div className="analytic-info">
                <span>INVESTED</span>
                <span>${numeral(dealAnalytics.data.invested).format('0,0')}</span>
              </div>
            </div>
            <div className="analytic-container">
              <SvgIcon width={48} height={48} name="iconReturn" />
              <div className="analytic-info">
                <span>REALISED GAINS</span>
                <span>${numeral(dealAnalytics.data.dealReturn).format('0,0')}</span>
              </div>
            </div>
            <div className="analytic-container">
              <SvgIcon width={48} height={48} name="iconProfit" />
              <div className="analytic-info">
                <span>PNL</span>
                <span>
                  {calculatePnL(
                    dealAnalytics.data.sellTransactions.length,
                    dealAnalytics.data.dealReturn - dealAnalytics.data.invested
                  )}
                </span>
              </div>
            </div>
          </div>
          <DealAnalyticChart dealAnalytics={dealAnalytics} />
          <div className="token-stats">
            <div>
              <span>Total Expected</span>
              <span>{numeral(dealAnalytics.data.tokensExpected).format('0,0')}</span>
            </div>
            <div className="stat-container">
              <Tooltip
                title={
                  <div>
                    <p>Claim method</p>
                    <h6 style={{ fontSize: 12 }}>{deal?.claimMethod ?? '-'}</h6>
                    {deal?.claimUrl && (
                      <div style={{ marginTop: '0.5rem' }}>
                        <p>Claim URL</p>
                        <a
                          style={{ display: 'block' }}
                          href={deal.claimUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {deal.claimUrl || <strong>-</strong>}
                        </a>
                      </div>
                    )}
                  </div>
                }
                interactive
              >
                <div className="icon" onClick={() => {}}>
                  <SvgIcon name="iconInfo" color="white" />
                </div>
              </Tooltip>
              <span className="title">Total Received</span>
              <span>
                {numeral(
                  totalClaimable +
                    +utils.formatUnits(
                      dealAnalytics.data.totalReceived.toLocaleString('fullwide', {
                        useGrouping: false,
                      }),
                      18
                    )
                ).format('0,0.')}
              </span>
            </div>
            <div>
              <span>Total Claimed</span>
              <span>
                {numeral(
                  ethers.utils.formatUnits(
                    dealAnalytics.data.totalClaimed.toLocaleString('fullwide', {
                      useGrouping: false,
                    }),
                    18
                  )
                ).format('0,0.')}
              </span>
            </div>
            <div>
              <span>Current Price</span>
              <span>${formatChartPrice(dealAnalytics.data.currentPrice)}</span>
            </div>
            <div>
              <span>ATH Price</span>
              <span>${formatChartPrice(dealAnalytics.data.athPrice)}</span>
            </div>
            <div>
              <span>Market Cap</span>
              <span>${numeral(dealAnalytics.data.marketCap).format('0,0')}</span>
            </div>
            <div>
              <span>24h Trading Volume</span>
              <span>${numeral(dealAnalytics.data.tradingVolume24h).format('0,0.')}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealAnalytics;
