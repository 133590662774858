import { useReducer } from 'react';

const parseFilterArray = (arr) => arr.reduce((acc, item) => ({ ...acc, [item]: false }), {}); // {Live: false, Paused: false}

function init(deals) {
  const rawDealStatus = [...new Set(deals.map((deal) => deal.dealStatus))];
  const rawBlockchain = [...new Set(deals.map((deal) => deal.chainName))];

  const dealStatus = parseFilterArray(rawDealStatus);
  const blockchain = parseFilterArray(rawBlockchain);

  return {
    search: '',
    dealStatus,
    blockchain,
    jobStatus: 'all',
    successfulRun: false,
    unsuccessfulRun: false,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'updateSearch':
      return { ...state, search: action.payload };
    case 'updateDealStatus':
      return {
        ...state,
        dealStatus: { ...state.dealStatus, [action.payload.key]: action.payload.value },
      };
    case 'updateBlockchain':
      return {
        ...state,
        blockchain: { ...state.blockchain, [action.payload.key]: action.payload.value },
      };
    case 'updateJobStatus':
      return { ...state, jobStatus: action.payload };
    case 'updateSuccessfulRun':
      return { ...state, successfulRun: action.payload };
    case 'updateUnsuccessfulRun':
      return { ...state, unsuccessfulRun: action.payload };
    default:
      throw new Error();
  }
}

const useFilters = (deals) => {
  const [filters, dispatch] = useReducer(reducer, deals, init);

  const dispatchUpdateSearch = (search) => dispatch({ type: 'updateSearch', payload: search });
  const dispatchUpdateDealStatus = ({ key, value }) =>
    dispatch({ type: 'updateDealStatus', payload: { key, value } });
  const dispatchUpdateBlockchain = ({ key, value }) =>
    dispatch({ type: 'updateBlockchain', payload: { key, value } });
  const dispatchUpdateJobStatus = (jobStatus) =>
    dispatch({ type: 'updateJobStatus', payload: jobStatus });
  const dispatchUpdateSuccessfulRun = (successfulRun) =>
    dispatch({ type: 'updateSuccessfulRun', payload: successfulRun });
  const dispatchUpdateUnsuccessfulRun = (unsuccessfulRun) =>
    dispatch({ type: 'updateUnsuccessfulRun', payload: unsuccessfulRun });

  return {
    filters,
    actions: {
      dispatchUpdateSearch,
      dispatchUpdateDealStatus,
      dispatchUpdateBlockchain,
      dispatchUpdateJobStatus,
      dispatchUpdateSuccessfulRun,
      dispatchUpdateUnsuccessfulRun,
    },
  };
};

export default useFilters;
