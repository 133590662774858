import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { getNativeTokenName } from 'utils';
import Checkbox from 'components/checkbox';
import DealStatus from 'components/deal-status';
import AddressLink from 'components/address-link';
import './index.scss';
import { useHistory } from 'react-router-dom';

const BalanceTableRow = ({
  row,
  selectedClaimers,
  setSelectedClaimers,
  setIsSelectAllClaimersChecked,
}) => {
  const {
    claimerId,
    walletId,
    walletAddress,
    walletName,
    seedPath,
    seedName,
    dealName,
    dealStatus,
    claimerAddress,
    chain,
    balance,
    isOldClaimer,
    isOldDeal,
    dealId,
  } = row;

  const history = useHistory();
  const handleRowClick = () => {
    history.push(`/all-deals/${dealId}?isOldDeal=${isOldDeal}`, {
      pathname: window.location.pathname,
    });
  };

  const findClaimer = (claimer) => claimer.walletId === walletId && claimer.chain === chain;
  const filterClaimer = (claimer) => claimer.walletId !== walletId || claimer.chain !== chain;

  const isCheckboxActive = !!selectedClaimers.find(findClaimer);

  const onCheckboxChange = (e) => {
    if (isCheckboxActive) {
      setSelectedClaimers((prev) => prev.filter(filterClaimer));
      setIsSelectAllClaimersChecked(false);
    } else {
      setSelectedClaimers((prev) => [...prev, { walletId, chain, claimerId, isOldClaimer }]);
    }
  };

  return (
    <TableRow onClick={handleRowClick}>
      <TableCell onClick={(e) => e.stopPropagation()}>
        <Checkbox checked={isCheckboxActive} onChange={onCheckboxChange} />
      </TableCell>
      <TableCell>{walletId}</TableCell>
      <TableCell>
        <p className="deal-name">{dealName}</p>
      </TableCell>
      <TableCell>
        <DealStatus dealStatus={dealStatus} />
      </TableCell>
      <TableCell>{seedName}</TableCell>
      <TableCell>{seedPath}</TableCell>
      <TableCell>
        <AddressLink chainName={chain} chainAddress={walletAddress} />
      </TableCell>
      <TableCell>
        <AddressLink chainName={chain} chainAddress={claimerAddress} />
      </TableCell>
      <TableCell>{getNativeTokenName(chain)}</TableCell>
      <TableCell>{balance}</TableCell>
      <TableCell>{walletName}</TableCell>
    </TableRow>
  );
};

export default BalanceTableRow;
