const calculatePercentage = (tokensReceived, tokensExpected) => {
  if (!tokensReceived || !tokensExpected) return 0.0;
  return parseFloat((tokensReceived / tokensExpected) * 100).toFixed(2);
};

const parseBatchScheduleRow = (schedule) => {
  return {
    id: schedule.dealId,
    date: schedule.date,
    dealName: schedule.dealName,
    ticker: schedule.ticker,
    blockchain: schedule.blockchains?.length > 0 ? schedule.blockchains[0] : null,
    expectedBatch: `${schedule.currentBatch} of ${schedule.totalBatches}`,
    expectedTokens: schedule.expected,
    expectedTokensPercentage: calculatePercentage(schedule.expected, schedule.totalExpected),
    totalTokensReceived: schedule.totalReceived,
    totalTokensReceivedPercentage: calculatePercentage(
      schedule.totalReceived,
      schedule.totalExpected
    ),
    totalTokensExpected: schedule.totalExpected,
    distributionModel: schedule.distributionModel,
    receiveWallet: schedule.receiveWallet,
    blockchains: schedule.blockchains,
    currentBatch: schedule.currentBatch,
    isDelivered: schedule.isDelivered,
    vestingItemId: schedule.vestingItemId,
  };
};

const getParsedBatchScheduleRows = (batchSchedule) => {
  if (!batchSchedule) return null;
  return batchSchedule.map(parseBatchScheduleRow);
};

export default getParsedBatchScheduleRows;
