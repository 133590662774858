import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import SvgIcon from 'components/svgIcon';
import './index.scss';
import { Popper } from '@material-ui/core';
import IconButton from 'components/button/icon-button';
import moment from 'moment';
import { MultiSelect } from 'react-multi-select-component';

const InvoiceTabFilter = ({ invoices, onChange, walletList, emailList }) => {
  const buttonRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedWallets, setSelectedWallets] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);

  useEffect(() => {
    const selectedWalletValues = selectedWallets.map((selected) => selected.value);
    const selectedEmailValues = selectedEmails.map((selected) => selected.value);
    if (!selectedEmails.length && !selectedWallets.length && !startDate && !endDate) return onChange(invoices);
    onChange(
      invoices.filter(
        ({ date, wallet, email }) =>
          (!selectedEmails.length || selectedEmailValues.includes(email)) && (!selectedWallets.length || selectedWalletValues.includes(wallet)) &&
          (!startDate || moment(date)._d > startDate) &&
          (!endDate || moment(date)._d < endDate)
      )
    );
  }, [onChange, invoices, walletList, emailList, startDate, endDate, selectedWallets, selectedEmails]);

  const customValueRenderer = (selected, _options) => {
    return selected.length ? `${selected.length} Selected` : 'All';
  };

  return (
    <>
      <div
        className="filterContainer"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
        ref={buttonRef}
      >
        <SvgIcon name="filter" />
        <span className="filter-label">FILTER</span>
      </div>
      <Popper open={isOpen} anchorEl={buttonRef.current} placement="bottom-end">
        <div className="invoice-filter-picker__dropdown">
          <span>Invoice created</span>
          <div className="date-picker-wrapper">
            {startDate && (
              <div className="close-icon">
                <IconButton icon="iconDeleteX" onClick={() => setStartDate(null)} />
              </div>
            )}
            <DatePicker
              selected={startDate}
              onChange={setStartDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start date"
            />
          </div>
          <div className="date-picker-wrapper">
            {endDate && (
              <div className="close-icon">
                <IconButton icon="iconDeleteX" onClick={() => setEndDate(null)} />
              </div>
            )}
            <DatePicker
              selected={endDate}
              onChange={setEndDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End date"
            />
          </div>
          <span>User email</span>
          {emailList && (
            <MultiSelect
              options={emailList}
              value={selectedEmails}
              valueRenderer={customValueRenderer}
              onChange={setSelectedEmails}
              className="multi-select"
              hasSelectAll={false}
            />
          )}
          <span>Wallet ID</span>
          {walletList && (
            <MultiSelect
              options={walletList}
              value={selectedWallets}
              valueRenderer={customValueRenderer}
              onChange={setSelectedWallets}
              className="multi-select"
              hasSelectAll={false}
            />
          )}
        </div>
      </Popper>
    </>
  );
};

export default InvoiceTabFilter;
