import SortableTable from 'components/sortable-table';
import React from 'react';
import utils from './utils/getTableHeadings';
import BatchScheduleTableRow from './BatchScheduleTableRow';

const BatchScheduleTable = ({ batchScheduleRows, type }) => {
  if (!batchScheduleRows) return null;

  return (
    <section className="deal-info-section">
      <h2 className="section-title">Batch schedule</h2>
      <div className="table-wrapper">
        <SortableTable
          tableHead={utils.batchScheduleTableHead}
          rows={batchScheduleRows}
          type={type}
        >
          {(sortedRows) =>
            sortedRows.map((row) => (
              <BatchScheduleTableRow
                row={row}
                key={`${row.id} - ${row.currentBatch} - ${row.date}`}
              />
            ))
          }
        </SortableTable>
      </div>
    </section>
  );
};

export default BatchScheduleTable;
