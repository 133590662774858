import React, { useRef, useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import ImageCrop from 'components/imageCrop';
import { uploadImage } from 'services/apiService';
import { useNotifications } from 'hooks';

const ImageEditModal = ({ deal, onOk, onCancel, defaultValue }) => {
  const cropInputRef = useRef();
  const [data, setData] = useState({
    imageUrl: defaultValue ? defaultValue : '',
  });
  // eslint-disable-next-line no-unused-vars
  const [_, showErrorNotification] = useNotifications();

  const onConfirm = async () => {
    await onOk(deal, { ...data });
    onCancel();
  };

  const onImageUpload = async (file) => {
    const imageData = new FormData();
    imageData.append('image', file);
    try {
      const { path } = await uploadImage(imageData);

      setData({
        ...data,
        imageUrl: path,
      });
    } catch {
      showErrorNotification(`Something went wrong while uploading image`);
    }
  };

  return (
    <div className="bd-modal">
      <div className="bd-modal-inner">
        <div className="bd-modal-header">
          <RoundedButton onClick={onCancel}>Cancel</RoundedButton>
          <RoundedButton type="secondary" onClick={onConfirm}>
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
        <div className="bd-modal-form">
          <ImageCrop onImageCropped={onImageUpload} label="Deal photo" inputRef={cropInputRef} />
        </div>
      </div>
    </div>
  );
};

export default ImageEditModal;
