import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';

const DeleteRoleDialog = ({ open, onOk, onClose, role }) => {
  return (
    <Dialog
      open={open}
      className="close-deal-modal unlock-lockup-modal"
      id="close-deal-modal"
      onClose={onClose}
    >
      <div className="close-deal-modal-header d-flex">
        <div className="close-deal-modal-header__left d-flex vertical-center">
          <div className="deal-name vertical-center">
            <div>
              <span>Are you sure you want to delete role <strong>{role?.name}</strong>?</span>
            </div>
          </div>
        </div>
        <div className="close-deal-modal-header__right vertical-center">
          <RoundedButton type="primary" onClick={onClose}>Cancel</RoundedButton>
          <RoundedButton onClick={onOk}>
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
      </div>
      <div className="close-deal-modal-body">
        <div className="message-wrapper">
          <SvgIcon name="error" />
          <div className="modal-inputs">
            <label>
                Deleting the role will remove all users who have that role.
            </label>
          </div>
        </div>
        <div className="update-lockup-mobile-actions">
          <RoundedButton type="primary" onClick={onClose}>Cancel</RoundedButton>
          <RoundedButton onClick={onOk}>
            Confirm
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteRoleDialog;
