import React from 'react';
import { Tooltip as MUITooltip } from '@material-ui/core';
import './index.scss';

const Tooltip = ({ children, ...rest }) => {
  return (
    <MUITooltip enterTouchDelay={0} classes={{ popper: 'custom-tooltip' }} arrow {...rest}>
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
