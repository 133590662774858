import SortableTable from 'components/sortable-table';
import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import moment from 'moment';
import { thousandSeparatorRound } from 'utils';
import { useHistory } from 'react-router-dom';
import utils from './utils/getTableHeadings';

const HistoryTable = ({ historyRows, type }) => {
  const history = useHistory();

  if (!historyRows) return null;

  const handleRowClick = (dealId, isOldDeal) => {
    history.push(`/all-deals/${dealId}?isOldDeal=${isOldDeal}`, {
      pathname: window.location.pathname,
    });
  };

  return (
    <section className="deal-info-section no-padding">
      <h2 className="section-title">History</h2>
      <div className="table-wrapper">
        <SortableTable tableHead={utils.historyTableHead} rows={historyRows} type={type}>
          {(sortedRows) =>
            sortedRows.map((row, index) => (
              <TableRow
                key={`${row.dealName} - ${row.currentBatch} - ${
                  row.blockchain
                } - ${index.toString()}`}
                onClick={() => handleRowClick(row.dealId, row.isOldDeal)}
              >
                <TableCell>{moment(row.date).format('YYYY-MM-DD, hh:mm')}</TableCell>
                <TableCell>{row.dealName}</TableCell>
                <TableCell>{row.ticker}</TableCell>
                <TableCell>{row.blockchain}</TableCell>
                <TableCell>{`${row.currentBatch}-of-${row.totalBatches}`}</TableCell>
                <TableCell>{thousandSeparatorRound(row.received)}</TableCell>
                <TableCell>{row.receivedPercentage}%</TableCell>
                <TableCell>{thousandSeparatorRound(row.totalReceived)}</TableCell>
                <TableCell>{thousandSeparatorRound(row.totalExpected)}</TableCell>
                <TableCell>{row.totalReceivedPercentage}%</TableCell>
              </TableRow>
            ))
          }
        </SortableTable>
      </div>
    </section>
  );
};

export default HistoryTable;
