import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiGet, apiPost, apiDelete, baseUrl } from 'services/apiService';

const getVestingData = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/vesting-calendar/${dealId}`);
  return response.data;
};

const useGetVestingData = (dealId) => {
  return useQuery(['deal', 'vesting-calendar', dealId], () => getVestingData(dealId));
};

const upsertVesting = async (dealId, items) => {
  const response = await apiPost(`${baseUrl}/api/deal/admin/vesting-calendar/${dealId}`, items);
  return response.data;
};

const useUpsertVesting = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation((vestingItems) => upsertVesting(dealId, vestingItems), {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', 'vesting-calendar', dealId]);
    },
  });
};

const deleteVestingData = async (dealId) => {
  const response = await apiDelete(`${baseUrl}/api/deal/admin/vesting-calendar/${dealId}`);
  return response.data;
};

const useDeleteVesting = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(() => deleteVestingData(dealId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', 'vesting-calendar', dealId]);
    },
  });
};

const getDealBatchInfo = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/${dealId}/deal-batch-info`);
  return response.data;
};

const useGetDealBatchInfo = (dealId) => {
  return useQuery(['deal', dealId, 'deal-batch-info'], () => getDealBatchInfo(dealId));
};

export default {
  useGetVestingData,
  useUpsertVesting,
  useDeleteVesting,
  useGetDealBatchInfo,
};
