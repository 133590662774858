import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateSystemPart } from 'store/actions';
import { SYSTEM_PARTS } from 'features/sidebar/LeftSideBar/utils';
import AllDealsContent from './AllDealsContent';
import remote from './remote';
import './index.scss';

const AllDeals = () => {
  const { data: allDeals } = remote.useGetAllDeals();

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(updateSystemPart(SYSTEM_PARTS.DISTRO_MANAGER));
  }, [dispatch]);

  return (
    <div className="all-deals-page">{allDeals && <AllDealsContent allDeals={allDeals} />}</div>
  );
};

export default AllDeals;
