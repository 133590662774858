import React from 'react';
import { Switch } from '@material-ui/core';
import './index.scss';

const SwitchInput = ({ title, value, updateValue }) => {
  const handleChange = (e) => {
    updateValue(e.target.checked);
  };

  return (
    <div className="switch-input">
      <span>{title}</span>
      <Switch checked={value} onChange={handleChange} />
    </div>
  );
};

export default SwitchInput;
