import React, { useState, useMemo, useEffect } from 'react';
import { Box } from '@material-ui/core';
import './index.scss';
import RoundedButton from 'components/button/rounded-button';
import PoliticallyExposedPerson from './components/PoliticallyExposedPerson';
import PrimaryIntention from './components/PrimaryIntention';
import SourceOfFunds from './components/SourceOfFunds';
import { getInitialFormData } from './formHelpers';
import isFormValid from './formValidation';
import {
  getInitialPepData,
  getInitialPepError,
} from './components/PoliticallyExposedPerson/helpers';

function AdditionalInfo({ data, onUpdate }) {
  const [initialState, setInitialState] = useState({});
  const [formState, setFormState] = useState({
    isPep: '',
    primaryIntentions: '',
    otherIntentions: '',
    sourceOfFunds: '',
    otherSourceOfFunds: '',
    errors: {
      isPep: false,
      primaryIntentions: false,
      sourceOfFunds: false,
      ...getInitialPepError(),
    },
  });

  useEffect(() => {
    if (!data) return;

    const initialData = getInitialFormData(data);

    setFormState((prevState) => ({
      ...prevState,
      ...initialData,
    }));

    setInitialState(initialData);
  }, [data]);

  const handleInputChange = (event, dependentFields) => {
    const { name, value } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: false,
        ...(name === 'isPep' ? getInitialPepError() : {}),
      },
    }));

    if (Array.isArray(dependentFields)) {
      dependentFields.forEach((field) => {
        setFormState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field]: false },
        }));
      });
    }
  };

  const validateForm = () => {
    const newErrors = isFormValid(formState);

    setFormState((prevState) => ({
      ...prevState,
      errors: newErrors,
    }));

    return Object.values(newErrors).every((error) => !error);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    let { _, ...formData } = formState;

    if (formState.primaryIntentions === 'other') {
      formData.primaryIntentions = formState.otherIntentions;
    }

    if (formState.sourceOfFunds === 'other') {
      formData.sourceOfFunds = formState.otherSourceOfFunds;
    }

    if (formState.isPep === 'false') {
      const initialPepData = getInitialPepData({ isPep: 'false' });

      formData = { ...formData, ...initialPepData };
    }

    if (formState.pepDetails === 'relatedPepPoliticalPosition') {
      const initialPepData = getInitialPepData({
        isPep: 'true',
        relatedPepPoliticalPosition: formState.relatedPepPoliticalPosition,
      });

      formData = { ...formData, ...initialPepData };
    }

    if (formState.pepDetails === 'relatedPepRelationship') {
      formData.relatedPepPoliticalPosition = '';
    }

    onUpdate(formData);
  };

  const isFormDirty = useMemo(() => {
    return Object.keys(formState).some((key) => {
      if (formState[key] === undefined || initialState[key] === undefined) {
        return false;
      }

      return formState[key] !== initialState[key];
    });
  }, [formState, initialState]);

  return (
    <Box component="form" className="additional-info">
      <p className="additional-info-header">
        Additional Info <span style={{ color: '#676767' }}>(required)</span>
      </p>
      <div className="profile-col-box align-start">
        <PoliticallyExposedPerson
          values={formState}
          errors={formState.errors}
          onChange={handleInputChange}
        />
        <PrimaryIntention
          value={formState.primaryIntentions}
          otherValue={formState.otherIntentions}
          error={formState.errors.primaryIntentions}
          onChange={handleInputChange}
        />
        <SourceOfFunds
          value={formState.sourceOfFunds}
          otherValue={formState.otherSourceOfFunds}
          error={formState.errors.sourceOfFunds}
          onChange={handleInputChange}
        />
        <RoundedButton
          type="primary"
          buttonType="submit"
          className="update-button"
          onClick={handleSubmit}
          disabled={!isFormDirty}
        >
          Update
        </RoundedButton>
      </div>
    </Box>
  );
}

export default AdditionalInfo;
