import { TableCell, TableRow } from '@material-ui/core';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import IconButton from 'components/button/icon-button';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatChartPrice } from 'utils';

const AnalyticTableRows = ({ analyticDeals, onDealEditClick }) => {
  const history = useHistory();
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin } = authReducer;

  return (
    <>
      {analyticDeals.map(
        ({
          name,
          price,
          currentPrice,
          currentRoi,
          athPrice,
          athRoi,
          numberOfContributors,
          filled,
          dateClosed,
          dealId,
          isOldDeal,
          deal,
          manualAthPrice,
        }) => {
          const displayAthPrice = manualAthPrice
            ? `$${formatChartPrice(+manualAthPrice)}`
            : athPrice
            ? `$${formatChartPrice(+athPrice)}`
            : '';
          return (
            <TableRow
              key={`${dealId}${isOldDeal ? '-old' : ''}`}
              onClick={() =>
                history.push(`/analytics/${dealId}?isOldDeal=${!!isOldDeal}`, {
                  pathname: window.location.pathname,
                })
              }
            >
              <TableCell classes={{ root: 'deal-cell' }} width={180}>
                <div>
                  <RoundedAvatar src={deal.imageUrl} />
                  {name}
                </div>
              </TableCell>
              <TableCell align="center">{price && `$${formatChartPrice(price)}`}</TableCell>
              <TableCell align="center">
                {currentPrice && `$${formatChartPrice(currentPrice)}`}
              </TableCell>
              <TableCell align="center">{currentRoi && `${+currentRoi.toFixed(1)}x`}</TableCell>
              <TableCell align="center">
                {displayAthPrice}{' '}
                {isAdmin && (
                  <div className={`edit-icon-wrapper ${manualAthPrice ? 'selected' : ''}`}>
                    <IconButton
                      icon="iconPollEdit"
                      onClick={(e) => onDealEditClick(e, dealId, manualAthPrice, isOldDeal)}
                    />
                  </div>
                )}
              </TableCell>
              <TableCell align="center">{athRoi && `${+athRoi.toFixed(1)}x`}</TableCell>
              <TableCell align="center">{numberOfContributors}</TableCell>
              <TableCell align="center">${numeral(filled).format('0,0')}</TableCell>
              <TableCell align="center">{moment(dateClosed).format('DD-MMM-YYYY')}</TableCell>
            </TableRow>
          );
        }
      )}
    </>
  );
};

export default AnalyticTableRows;
