import React from 'react';
import useUserLegalDetails from 'screens/Legal/hooks/useUserLegalDetails';
import PersonalInfo from './components/PersonalInfo';
import KycInfo from './components/KycInfo';
import './index.scss';
import ContributionInfo from './components/ContributionInfo';
import LastLogins from './components/LastLogins';
import PepInfo from './components/PepInfo';

const UserLegalDetails = ({ address }) => {
  const { data: user } = useUserLegalDetails(address);

  if (!user) return null;

  return (
    <section className="deal-info-section grid">
      <KycInfo user={user} />
      <LastLogins user={user} />
      <PersonalInfo user={user} />
      <ContributionInfo user={user} />
      <PepInfo user={user} />
    </section>
  );
};

export default UserLegalDetails;
