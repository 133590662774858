import React, { useLayoutEffect } from 'react';
import { updateSystemPart } from 'store/actions';
import { SYSTEM_PARTS } from 'features/sidebar/LeftSideBar/utils';
import { useDispatch } from 'react-redux';
import useBalances from './hooks/useBalances';
import BalancesContent from './BalancesContent';
import './index.scss';

const Balances = () => {
  const { formattedBalances } = useBalances();

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(updateSystemPart(SYSTEM_PARTS.DISTRO_MANAGER));
  }, [dispatch]);

  return (
    <div className="balances-page">
      {formattedBalances && <BalancesContent balances={formattedBalances} />}
    </div>
  );
};

export default Balances;
