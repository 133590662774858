import { SYSTEM_PARTS, ROLES } from 'features/sidebar/LeftSideBar/utils';

const ADMIN_DROPDOWN_OPTIONS = [
  SYSTEM_PARTS.DEALS,
  SYSTEM_PARTS.DISTRO_MANAGER,
  SYSTEM_PARTS.USER_MANAGER,
  SYSTEM_PARTS.USER_LEGAL,
  SYSTEM_PARTS.INVOICES,
  SYSTEM_PARTS.VIEW_AS_USER,
];

const USER_DROPDOWN_OPTIONS = [
  SYSTEM_PARTS.PROFILE_SETTINGS,
  SYSTEM_PARTS.INVOICES,
  SYSTEM_PARTS.DEALS,
];

const dropdownOptions = new Map([
  [ROLES.ADMIN, ADMIN_DROPDOWN_OPTIONS],
  [ROLES.USER, USER_DROPDOWN_OPTIONS],
]);

export default dropdownOptions;
