import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateSystemPart } from 'store/actions';
import { SYSTEM_PARTS } from 'features/sidebar/LeftSideBar/utils';
import HistoryContent from './HistoryContent';
import CustomClaimsContent from './CustomClaimsContent';
import remote from './remote';
import './index.scss';

const CustomClaims = () => {
  const history = useHistory();
  const { data: jobs } = remote.useGetAllClaims();
  const { data: logs } = remote.useGetJobHistory();

  const [selectedTab, setSelectedTab] = useState(
    history.location.state?.activeTab === 'history' ? 'history' : 'custom-claims'
  );
  const selectTab = (tabValue) => setSelectedTab(tabValue);

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(updateSystemPart(SYSTEM_PARTS.DISTRO_MANAGER));
  }, [dispatch]);

  return (
    <div className="custom-claims-page">
      {jobs && <CustomClaimsContent jobs={jobs} selectedTab={selectedTab} selectTab={selectTab} />}
      {logs && <HistoryContent logs={logs} selectedTab={selectedTab} selectTab={selectTab} />}
    </div>
  );
};

export default CustomClaims;
