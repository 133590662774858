const calculatePercentage = (tokensReceived, tokensExpected) => {
  if (!tokensReceived || !tokensExpected) return 0.0;
  return parseFloat((tokensReceived / tokensExpected) * 100).toFixed(2);
};

const parseHistoryRow = (deal) => {
  return {
    date: deal.date,
    dealId: deal.dealId,
    isOldDeal: deal.isOldDeal,
    dealName: deal.dealName,
    ticker: deal.ticker || 'N/A',
    blockchain: deal.blockchain ?? '-',
    currentBatch: deal.currentBatch ?? '-',
    totalBatches: deal.totalBatches ?? 0,
    received: parseInt(deal.received, 10),
    receivedPercentage: calculatePercentage(deal.received, deal.totalExpected),
    totalReceived: parseInt(deal.totalReceived, 10),
    totalExpected: parseInt(deal.totalExpected, 10),
    totalReceivedPercentage: calculatePercentage(deal.totalReceived, deal.totalExpected),
    distributionModel: deal.distributionModel,
    origin: deal.origin,
  };
};

const getParsedHistoryRows = (history) => {
  if (!history) return null;
  return history.map(parseHistoryRow);
};

export default getParsedHistoryRows;
