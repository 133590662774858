import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import './index.scss';

const ConfimUnlockModal = ({ open, onOk, onClose }) => {
  return (
    <Dialog
      open={open}
      className="close-deal-modal unlock-lockup-modal"
      id="close-deal-modal"
      onClose={onClose}
    >
      <div className="close-deal-modal-header d-flex">
        <div className="close-deal-modal-header__left d-flex vertical-center">
          <div className="deal-name vertical-center">
            <div className="full-width d-flex">
              <SvgIcon name="iconLockupGreen" />
              <span>Start Unlock</span>
            </div>
          </div>
        </div>
        <div className="close-deal-modal-header__right vertical-center">
          <RoundedButton onClick={onClose}>Cancel</RoundedButton>
          <RoundedButton type="secondary" onClick={onOk}>
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
      </div>
      <div className="close-deal-modal-body">
        <div className="message-wrapper">
          <SvgIcon name="error" />
          <div className="modal-inputs">
            <label>
              By pressing confirm you will start 180 days unlock timer and disable new
              contributions. After timer expires your tokens will be available for claiming to your
              wallet.
            </label>
          </div>
        </div>
        <div className="update-lockup-mobile-actions">
          <RoundedButton onClick={onClose}>Cancel</RoundedButton>
          <RoundedButton type="secondary" onClick={onOk}>
            Confirm
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfimUnlockModal;
