import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function DragAndDrop({ children, onDrop }) {
  const dropRef = useRef();
  const [isDragging, setIsDragging] = useState();
  const dragCount = useRef();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCount.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCount.current--;
    if (dragCount.current > 0) return;
    setIsDragging(false);
  };
  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        onDrop(e.dataTransfer.files);
        e.dataTransfer.clearData();
        dragCount.current = 0;
      }
    },
    [onDrop]
  );

  useEffect(() => {
    let div = dropRef.current;
    dragCount.current = 0;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);

    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  }, [handleDrop]);

  return (
    <div className="drag-and-drop" ref={dropRef}>
      {isDragging && <div className="hover-border"></div>}
      {children}
    </div>
  );
}

DragAndDrop.prototype = {
  onDrop: PropTypes.func.isRequired,
};

export default DragAndDrop;
