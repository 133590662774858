import React from 'react';
import './index.scss';
import useRiskReportData from 'screens/Legal/screens/Details/hooks/useRiskReportData';
import { RiskReportType } from 'screens/Legal/screens/Details/hooks/constants';
import DownloadReportButton from 'screens/Legal/components/DownloadReportButton';
import InfoSection from 'screens/Legal/components/sections/InfoSection';
import CategorizedSection from 'screens/Legal/components/sections/CategorizedSection';
import { getStatementValue } from 'utils';
import { categorizeRisks } from './helpers';

const AppendixTwo = ({ address }) => {
  const { data: riskReportData } = useRiskReportData(RiskReportType.AppendixTwo, address);

  if (!riskReportData) return null;

  const categorizedRisks = categorizeRisks(riskReportData.risks);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '20px',
      }}
    >
      <DownloadReportButton type={RiskReportType.AppendixTwo} address={address} />
      <section className="deal-info-section grid">
        <InfoSection title="Stranka" data={riskReportData.client} />
        <InfoSection title="Ukupno" data={riskReportData.summary} />

        <CategorizedSection
          title="Rizici"
          categories={categorizedRisks}
          className="span-2"
          itemClassName="risk-item"
          valueTransform={getStatementValue}
        />
      </section>
    </div>
  );
};

export default AppendixTwo;
