import { useEffect } from 'react';
import { utils } from 'ethers';
import { useSelector, useDispatch } from 'react-redux';
import { handleV2Contribute } from 'store/actions';
import { arbitrumWsProvider } from 'contracts/providers';
import DealV2Artifact from 'contracts/abis/DealV2.json';

function useDealContributeSubscriber() {
  const globalReducer = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const { walletAddress } = authReducer;
  const { isInitialLoadFinished } = globalReducer;

  useEffect(() => {
    if (!isInitialLoadFinished) return () => null;

    const contributeV2Handler = async (log) => {
      const dealV2Interface = new utils.Interface(DealV2Artifact.abi);
      const { args } = dealV2Interface.parseLog(log);

      dispatch(
        handleV2Contribute({
          dealAddress: log.address,
          amount: args.amount,
          isOwnContribution: walletAddress.toLowerCase() === args.addr.toLowerCase(),
        })
      );
    };

    const contributeV2Filter = {
      topics: [utils.id('Contribute(address,uint256,uint256)')],
    };
    arbitrumWsProvider.on(contributeV2Filter, contributeV2Handler);

    return () => {
      arbitrumWsProvider.removeAllListeners(contributeV2Filter, contributeV2Handler);
    };
  }, [isInitialLoadFinished, walletAddress, dispatch]);
}

export default useDealContributeSubscriber;
