import { ethers } from 'ethers';
import ERC20Artifact from './abis/ERC20.json';

export const getErc20Balance = async (provider, erc20Address, address, decimals = 18) => {
  const erc20Contract = new ethers.Contract(erc20Address, ERC20Artifact.abi, provider);
  const balanceBN = await erc20Contract.balanceOf(address);

  return ethers.utils.formatUnits(balanceBN, decimals);
};

export const approveErc20 = async (erc20Address, spenderAddress, amount) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);

  try {
    const erc20Contract = new ethers.Contract(
      erc20Address,
      ERC20Artifact.abi,
      injectedProvider.getSigner()
    );

    const tx = await erc20Contract.approve(spenderAddress, amount);

    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAllowance = async (provider, erc20Address, owner, spender, decimals = 18) => {
  const erc20Contract = new ethers.Contract(erc20Address, ERC20Artifact.abi, provider);

  const allowance = await erc20Contract.allowance(owner, spender);

  return ethers.utils.formatUnits(allowance, decimals);
};

export const getTokenInfo = async (provider, erc20Address) => {
  try {
    const erc20Contract = new ethers.Contract(erc20Address, ERC20Artifact.abi, provider);

    const name = await erc20Contract.name();
    const symbol = await erc20Contract.symbol();
    const decimals = await erc20Contract.decimals();

    return { name, symbol, decimals };
  } catch (error) {
    console.log('error during get erc20 info', error);
    return [18, 'Unknown', 'Unknown'];
  }
};

export const parseTransferLog = (log) => {
  const erc20Interface = new ethers.utils.Interface(ERC20Artifact.abi);
  return erc20Interface.parseLog(log);
};

export const getTransferEventFilter = (erc20Address, from, to) => {
  const topics = [ethers.utils.id('Transfer(address,address,uint256)')];

  if (from) {
    topics.push(ethers.utils.hexZeroPad(from, 32));
  }

  if (to) {
    topics.push(ethers.utils.hexZeroPad(to, 32));
  }

  return {
    address: erc20Address,
    topics,
  };
};
