import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import remote from 'screens/AllDeals/remote';

const EditContactInfoModal = ({ open, onClose, deal, isOldDeal }) => {
  const updateContactInfo = remote.useUpdateContactInfo(deal.id);
  const [data, setData] = useState({
    contactPerson: deal.contactPerson || '',
    contactEmail: deal.contactEmail || '',
    telegram: deal.telegram || '',
    telegramGroupLink: deal.telegramGroupLink || '',
  });
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      await updateContactInfo.mutateAsync({
        contactPerson: data.contactPerson,
        contactEmail: data.contactEmail,
        telegram: data.telegram,
        telegramGroupLink: data.telegramGroupLink,
        isOldDeal,
      });
      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal basic-metrics-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Contact info</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" buttonType="submit" form="basic-metrics-form">
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="basic-metrics-form" onSubmit={onConfirm}>
        <div className="col col-left">
          <div className="input-holder">
            <label>Contact Person</label>
            <input name="contactPerson" value={data.contactPerson} onChange={onChangeData} />
          </div>

          <div className="input-holder">
            <label>Email</label>
            <input name="contactEmail" value={data.contactEmail} onChange={onChangeData} />
          </div>
        </div>

        <div className="col col-right">
          <div className="input-holder">
            <label>Telegram</label>
            <input name="telegram" value={data.telegram} onChange={onChangeData} />
          </div>

          <div className="input-holder">
            <label>Telegram Group Link</label>
            <input
              name="telegramGroupLink"
              value={data.telegramGroupLink}
              onChange={onChangeData}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default EditContactInfoModal;
