import React from 'react';
import SvgIcon from 'components/svgIcon';
import './index.scss';
import numeral from 'numeral';

const DashboardAnalytics = ({ dashboardOverview }) => {
  return (
    <div className="analytics-content__overview">
      <div className="analytic-container">
        <SvgIcon width={48} height={48} name="iconAnalyticDeals" />
        <div className="analytic-info">
          <span>USERS</span>
          <span>{dashboardOverview.kycUsersCount}</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={80} height={48} name="iconRoi" />
        <div className="analytic-info">
          <span>DEALS</span>
          <span>{dashboardOverview.numberOfDeals}</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={80} height={48} name="iconAthRoi" />
        <div className="analytic-info">
          <span>CONTRIBUTIONS</span>
          <span>{numeral(dashboardOverview.numOfContributions).format('0,0')}</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={48} height={48} name="iconInvest" />
        <div className="analytic-info">
          <span>LOCKED TOKENS</span>
          <span>{numeral(dashboardOverview.totalLockAmountOnPlatform).format('0,0')}</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={48} height={48} name="iconReturn" />
        <div className="analytic-info">
          <span>BDT PRICE</span>
          <span>${dashboardOverview.bdtPriceInUSD}</span>
        </div>
      </div>
      <div className="analytic-container">
        <SvgIcon width={48} height={48} name="iconReturn" />
        <div className="analytic-info">
          <span>TOTAL INVESTED</span>
          <span>${numeral(dashboardOverview.totalInvestedOnPlatform).format('0,0')}</span>
        </div>
      </div>
    </div>
  );
};

export default DashboardAnalytics;
