import React from 'react';
import InfoContainer from 'screens/Legal/components/InfoContainer';
import { renderSafeValue } from 'utils';
import './index.scss';

const CategorizedSection = ({
  title,
  className,
  itemClassName,
  valueTransform = renderSafeValue,
  categories = [],
}) => {
  if (!categories.length) return null;

  return (
    <InfoContainer title={title} className={className}>
      {categories.map(({ label, values }) => (
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}
          key={label}
        >
          <p className="category-label">{label}</p>
          <ul className="item-list">
            {values.map(({ label, value }) => (
              <li key={label} className={`item ${itemClassName}`}>
                <p className="label text-transform-none">{label}</p>
                <p className="value">{valueTransform(value)}</p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </InfoContainer>
  );
};

export default CategorizedSection;
