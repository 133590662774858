import { FundSources, PrimaryIntentions } from './components/constants';
import { getInitialPepData } from './components/PoliticallyExposedPerson/helpers';

function getInitialFormData(data) {
  const initialData = {
    isPep: data.isPep,
    primaryIntentions: data.primaryIntentions,
    sourceOfFunds: data.sourceOfFunds,
    ...(data.isPep === 'true' ? getInitialPepData(data) : {}),
  };

  if (data.primaryIntentions && !PrimaryIntentions.includes(data.primaryIntentions)) {
    initialData.primaryIntentions = 'other';
    initialData.otherIntentions = data.primaryIntentions;
  }

  if (data.sourceOfFunds && !FundSources.includes(data.sourceOfFunds)) {
    initialData.sourceOfFunds = 'other';
    initialData.otherSourceOfFunds = data.sourceOfFunds;
  }

  return initialData;
}

export { getInitialFormData };
