import { isAddress as checkEthereumAddress } from 'ethers/lib/utils';
import WAValidator from 'multicoin-address-validator';

export const validateEmail = (email) => {
  const emailRegex =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const validateStrongPassword = (password) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,}$/;
  return passwordRegex.test(password);
};

export const validatePhoneNumber = (password) => {
  // eslint-disable-next-line
  const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return phoneNumberRegex.test(password);
};

export const validatePassword = (password, confirmPassword) => {
  const errors = { count: true, uppercase: true, lowercase: true, number: true, matched: true };
  if (password.length >= 8) errors.count = false;
  if (/[A-Z]/.test(password)) errors.uppercase = false;
  if (/[a-z]/.test(password)) errors.lowercase = false;
  if (/\d/.test(password)) errors.number = false;
  if (password.length > 0 && password === confirmPassword) errors.matched = false;
  return errors;
};

export const isNumeric = (str) => {
  return /^\d+$/.test(str);
};

const checkSolana = (address) => {
  const addressFilter = /[1-9A-HJ-NP-Za-km-z]{32,44}/;
  return !!addressFilter.test(address);
};

const checkAvalanche = (address) => {
  const addressFilter =
    /^([XPC]|[a-km-zA-HJ-NP-Z1-9]{36,72})-[a-zA-Z]{1,83}1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{38}$/;
  return !!addressFilter.test(address);
};

const checkBinanceCoin = (address) => {
  const addressFilter = /^(bnb)([a-z0-9]{39})$/;
  return !!addressFilter.test(address);
};

const checkCosmos = (address) => {
  const addressFilter = /^(cosmos)1(['qpzry9x8gf2tvdw0s3jn54khce6mua7l']+)$/;
  return !!addressFilter.test(address);
};

const checkTerra = (address) => address.startsWith('terra') && address.length === 44;

const checkNEAR = (address) => {
  const addressFilter = /^(([a-z\d]+[-_])*[a-z\d]+\.)*([a-z\d]+[-_])*[a-z\d]+$/;
  return !!addressFilter.test(address) && address.length >= 2 && address.length <= 64;
};

const checkJuno = (address) => address.startsWith('juno') && address.length === 43;
const checkNolus = (address) => address.startsWith('nolus');
const checkAlephZero = (address) => address.startsWith('5') && address.length === 48;
const checkSelfChain = (address) => address.startsWith('self') && address.length === 43;
const checkTon = (address) =>
  (address.startsWith('E') || address.startsWith('U')) && address.length === 48;

const checkLava = (address) => address.startsWith('lava@') && address.length === 44;

const checkSui = (address) => {
  const addressFilter = /0[xX][a-fA-F0-9]{64}/;
  return !!addressFilter.test(address);
};

const chainValuesMapping = {
  Bitcoin: 'BTC',
  Polkadot: 'DOT',
  Cardano: 'ADA',
  Solana: 'SOL',
  Avalanche: 'AVAX',
  Tron: 'TRX',
  Algorand: 'ALGO',
  Cosmos: 'ATOM',
  Terra: 'Terra',
  NEAR: 'NEAR',
  Juno: 'Juno',
  Nolus: 'Nolus',
  AlephZero: 'AlephZero',
  SelfChain: 'SelfChain',
  Sui: 'Sui',
  Ton: 'Ton',
  Lava: 'Lava',
  Quai: 'Quai',
};

export const validateChain = (address, chain) => {
  const _chain = chainValuesMapping[chain];
  if (_chain === 'SOL') {
    if (!checkSolana(address)) {
      return false;
    }
  } else if (_chain === 'AVAX') {
    if (!checkAvalanche(address)) {
      return false;
    }
  } else if (_chain === 'BNB') {
    if (!checkBinanceCoin(address)) {
      return false;
    }
  } else if (_chain === 'ATOM') {
    if (!checkCosmos(address)) {
      return false;
    }
  } else if (_chain === 'Terra') {
    if (!checkTerra(address)) {
      return false;
    }
  } else if (_chain === 'NEAR') {
    if (!checkNEAR(address)) {
      return false;
    }
  } else if (_chain === 'Juno') {
    if (!checkJuno(address)) {
      return false;
    }
  } else if (_chain === 'Nolus') {
    if (!checkNolus(address)) {
      return false;
    }
  } else if (_chain === 'AlephZero') {
    if (!checkAlephZero(address)) {
      return false;
    }
  } else if (_chain === 'SelfChain') {
    if (!checkSelfChain(address)) {
      return false;
    }
  } else if (_chain === 'Sui') {
    if (!checkSui(address)) {
      return false;
    }
  } else if (_chain === 'Ton') {
    if (!checkTon(address)) {
      return false;
    }
  } else if (_chain === 'Lava') {
    if (!checkLava(address)) {
      return false;
    }
  } else if (_chain === 'Quai') {
    if (!checkEthereumAddress(address)) {
      return false;
    }
  } else if (!WAValidator.validate(address, _chain)) {
    return false;
  }
  return true;
};
