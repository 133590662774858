import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { formatFiatPrice } from 'utils/helpers';
import RoundedButton from 'components/button/rounded-button';
import IconButton from 'components/button/icon-button';
import FileSaver from 'file-saver';
import moment from 'moment';
import { getBookkeepingReport, manuallyGenerateReport } from 'services/apiService';
import { useNotifications } from 'hooks';

const BookkeepingTable = ({ reports, refetchReports }) => {
  const [, showErrorNotification] = useNotifications();

  const handlePdf = async ({ month, year }, open) => {
    try {
      const arrayBuffer = await getBookkeepingReport(month, year);
      const pdfFile = new Blob([arrayBuffer], { type: 'application/pdf' });
      FileSaver.saveAs(pdfFile, `invoice-${year}/${month}.pdf`);
      const pdfFileURL = URL.createObjectURL(pdfFile);
      if (open) window.open(pdfFileURL);
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  if (!reports?.length) return null;

  return (
    <div className="invoices__body">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">MM/YYYY</TableCell>
            <TableCell>TOTAL INVOICES</TableCell>
            <TableCell>FEE (USD) </TableCell>
            <TableCell>FEE (HRK)</TableCell>
            <TableCell>REPORT DATE</TableCell>
            <TableCell>REPORT STATUS</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map(
            ({ id, totalFeeUsd, totalFeeHrk, totalInvoices, year, month, date, isCreated }) => (
              <TableRow key={id}>
                <TableCell align="center">
                  {month}/{year}.
                </TableCell>
                <TableCell>{totalInvoices}</TableCell>
                <TableCell>{totalFeeUsd && `${formatFiatPrice(totalFeeUsd, true)} USDT`}</TableCell>
                <TableCell>{totalFeeHrk && `${formatFiatPrice(totalFeeHrk, true)} HRK`}</TableCell>
                <TableCell>{date && moment(date).format('D-MMMM-YYYY')}</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    {isCreated ? (
                      <>
                        <div className="green-dot" />
                        <span>Created</span>
                      </>
                    ) : (
                      <>
                        <div className="red-dot" />
                        <span>Not created</span>
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <RoundedButton
                    disabled={!isCreated}
                    type="primary"
                    onClick={() => handlePdf({ month, year }, true)}
                  >
                    View
                  </RoundedButton>
                  <div className="icon-download">
                    <IconButton
                      disabled={!isCreated}
                      icon="iconDownload"
                      onClick={() => handlePdf({ month, year })}
                    />
                  </div>
                  {!isCreated && (
                    <div className="icon-download">
                      <IconButton
                        icon="iconBookkeepingReport"
                        onClick={() => manuallyGenerateReport(month, year).then(refetchReports)}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default BookkeepingTable;
