import { getProviderByChainId } from 'contracts/providers';
import { ethers } from 'ethers';
import ERC20Artifact from 'contracts/abis/ERC20.json';
import { useQuery } from 'react-query';

const getErc20Contract = (chainId, tokenAddress) => {
  if (!chainId || !tokenAddress) return null;

  const provider = getProviderByChainId(chainId);
  if (!provider) return null;

  const erc20Contract = new ethers.Contract(tokenAddress, ERC20Artifact.abi, provider);
  return erc20Contract;
};

const useTokenName = (chainId, tokenAddress) => {
  const erc20Contract = getErc20Contract(chainId, tokenAddress);
  return useQuery([chainId, tokenAddress], () => erc20Contract.name(), {
    enabled: !!erc20Contract,
  });
};

export default useTokenName;
