const tableHead = [
  {
    column: 'address',
    label: 'Address',
    sort: (a, b) => (b.address.trim().toLowerCase() > a.address.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'name',
    label: 'Wallet',
    sort: (a, b) => (b.dealName.trim().toLowerCase() > a.dealName.trim().toLowerCase() ? 1 : -1),
  },
];

export default { tableHead };
