import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderBar from 'features/header/HeaderBar';
import LeftSideBar from 'features/sidebar/LeftSideBar';
import SharedNotification from 'features/notifications/SharedNotification';
import ResolvedNotification from 'features/notifications/ResolvedNotification';
import LockupNotification from 'components/lockup-notification';
import useLoadAppData from 'hooks/useLoadAppData';
import {
  useBdtTransferSubscriber,
  useUsdtTransferSubscriber,
  useDealContributeSubscriber,
} from 'hooks';
import './index.scss';
import AppRouter from 'router';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function PageContent({ loadWeb3Modal, logoutOfWeb3Modal }) {
  const globalReducer = useSelector((state) => state.global);
  const authReducer = useSelector((state) => state.auth);
  const { accountInfo, isAdmin } = authReducer;
  const {
    notificationDropdownOpened,
    resolvedNotification,
    activeDeal,
    isSidebarExpanded,
  } = globalReducer;
  const headerRef = useRef();
  const [showLockupNotification, setShowLockupNotification] = useState(false);
  useLoadAppData(loadWeb3Modal, logoutOfWeb3Modal);

  useBdtTransferSubscriber();
  useUsdtTransferSubscriber();
  useDealContributeSubscriber();

  const toggleLockupNotification = () => {
    setShowLockupNotification(!showLockupNotification);
  };

  useEffect(() => {
    if (notificationDropdownOpened) {
      headerRef.current.style.zIndex = 1004;
    } else {
      headerRef.current.style.zIndex = 1002;
    }
  }, [notificationDropdownOpened]);

  useEffect(() => {
    if (accountInfo.relockMessage && !isAdmin) {
      toggleLockupNotification(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  const isOnHideSidebarRoute =
    location.pathname.includes('/role-management') ||
    location.pathname.includes('/invoices') ||
    location.pathname.includes('/profile');
  const showSidebarExpandedContent = isSidebarExpanded && !isOnHideSidebarRoute;

  return (
    <div className="dashboard-container">
      {accountInfo.relockMessage && (
        <LockupNotification
          relockMessage={accountInfo.relockMessage}
          open={showLockupNotification}
          onClose={toggleLockupNotification}
        />
      )}
      <SharedNotification />
      {resolvedNotification && <ResolvedNotification />}
      <div
        className={`dashboard-header${
          showSidebarExpandedContent ? ' dashboard-header__expanded' : ''
        }${isOnHideSidebarRoute ? ' dashboard-header__no-sidebar' : ''} 
          }`}
        id="dashboard-header"
        ref={headerRef}
      >
        <HeaderBar logoutOfWeb3Modal={logoutOfWeb3Modal} />
      </div>
      <div className="dashboard-content d-flex">
        {!isOnHideSidebarRoute && (
          <div className="dashboard-content__left">
            <LeftSideBar />
          </div>
        )}
        <div
          className={`dashboard-content__right${
            showSidebarExpandedContent ? ' dashboard-content__right__expanded' : ''
          }${isOnHideSidebarRoute ? ' dashboard-content__right__no-sidebar' : ''}
          }`}
        >
          {(notificationDropdownOpened || (activeDeal && !isAdmin)) && (
            <div className="overlay-background-container" />
          )}
          <AppRouter />
        </div>
      </div>
    </div>
  );
}

export default PageContent;
