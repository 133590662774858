import { ethers } from 'ethers';
import { getAllocationModelId, getAllocationModelName } from 'models/dealAllocationModel';
import { getDealStatusName } from 'models/dealStatusModel';

import DealArtifact from './abis/Deal.json';
import { getUserLevelLabel } from 'models/userLevelModel';

export const getMyContributionAmount = async (provider, dealAddress, walletAddress) => {
  const dealContract = new ethers.Contract(dealAddress, DealArtifact.abi, provider);

  try {
    const contributionAmountBN = await dealContract.getAddressContributionAmount(walletAddress);

    return ethers.utils.formatUnits(contributionAmountBN, 'mwei');
  } catch (error) {
    console.error(
      `error ${error.message} during getAddressContributionAmount call for ${dealAddress} ${walletAddress}`
    );
    return '0.0';
  }
};

export const isWalletWhitelisted = async (provider, dealAddress, wallet) => {
  const dealContract = new ethers.Contract(dealAddress, DealArtifact.abi, provider);

  const isWhitelisted = await dealContract.isWhitelisted(wallet);

  return isWhitelisted;
};

export const getDealInfo = async (dealAddress, provider) => {
  if (!provider) throw new Error('Provider not passed');

  const dealContract = new ethers.Contract(dealAddress, DealArtifact.abi, provider);

  const dealInfo = await dealContract.getDealInfo();

  return parseDealInfo(dealInfo, dealAddress);
};

const parseDealInfo = (dealInfo, dealAddress) => {
  const intData = dealInfo[2];
  const dealSize = ethers.utils.formatUnits(intData[0], 'mwei');
  const circulatingSupply = ethers.utils.formatEther(intData[1]);
  const minContribution = ethers.utils.formatUnits(intData[2], 'mwei');
  const minAccessLevel = intData[3].toNumber();
  const minAccessLevelLabel = getUserLevelLabel(minAccessLevel);
  const raisedAmount = ethers.utils.formatUnits(intData[4], 'mwei');
  const status = getDealStatusName(intData[5]);
  const unlimitedTimestamp = intData[8].toNumber();
  const allocationModel = getAllocationModelName(intData[6].toString(), unlimitedTimestamp);
  const userCap = ethers.utils.formatUnits(intData[7], 'mwei');
  const minViewLevel = intData[9].toNumber();
  const delayedMinAccessLevel = intData[10].toNumber();
  const whitelistEndTimestamp = intData[11].toNumber();

  return {
    dealSize,
    address: dealAddress,
    circulatingSupply,
    minContribution,
    minAccessLevel,
    minAccessLevelLabel,
    raisedAmount,
    status,
    allocationModel,
    userCap,
    unlimitedTimestamp,
    minViewLevel,
    delayedMinAccessLevel,
    whitelistEndTimestamp,
  };
};

export async function updateDeal(deal) {
  const {
    address,
    name,
    dealSize,
    minContribution,
    allocationModel,
    minViewLevel,
    userCap,
    minAccessLevel,
    unlimitedTimestamp,
    whitelistEndTimestamp,
    delayedMinAccessLevel,
  } = deal;

  const allocationModelId = getAllocationModelId(allocationModel);
  const dealSizeWei = ethers.utils.parseUnits(dealSize, 'mwei');
  const userCapWei = ethers.utils.parseUnits(userCap, 'mwei');
  const minContributionWei = ethers.utils.parseUnits(minContribution, 'mwei');

  const imageUrl = deal.id;

  const intData = [
    dealSizeWei,
    minContributionWei,
    allocationModelId,
    minViewLevel,
    minAccessLevel,
    userCapWei || 0,
    unlimitedTimestamp || 0,
    whitelistEndTimestamp || 0,
    delayedMinAccessLevel || 0,
  ];

  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  try {
    const dealContract = new ethers.Contract(
      address,
      DealArtifact.abi,
      injectedProvider.getSigner()
    );
    const tx = await dealContract.updateDeal(name, intData, imageUrl.toString());
    return tx;
  } catch (error) {
    console.error(error);
    return false;
  }
}
