import { ethers } from 'ethers';
import { getProviderByChainId } from './providers';
import { getTokenInfo } from './erc20';

// eslint-disable-next-line import/prefer-default-export
export const getTransfersFromTx = async (txHash, chainId) => {
  const provider = getProviderByChainId(+chainId);

  const res = await provider.getTransactionReceipt(txHash);

  if (!res) return { transfers: [], block: null };
  const { logs, blockNumber } = res;
  const block = await provider.getBlock(blockNumber);

  const transfers = logs.reduce((accum, { topics: [signature, from, to], data, address }) => {
    if (signature !== ethers.utils.id('Transfer(address,address,uint256)')) return accum;
    return [...accum, { from, to, amount: +data, address }];
  }, []);

  const tokenPromises = transfers.map(async (transfer) => {
    try {
      const token = await getTokenInfo(provider, transfer.address);
      return {
        ...transfer,
        token,
      };
    } catch {
      return {
        ...transfer,
        token: null,
      };
    }
  });

  return { transfers: await Promise.all(tokenPromises), block };
};
