const getTruthyFields = (filter) =>
  Object.entries(filter)
    .filter((entry) => Boolean(entry[1]))
    .map((el) => el[0].toLowerCase());

const applySearchFilter = (search, deal) => {
  return deal.dealName.toLowerCase().includes(search.toLowerCase());
};

const applyDealStatusFilter = (statuses, deal) => {
  if (!statuses.length) return true;
  return statuses.includes(deal.dealStatus.toLowerCase());
};

const applyBlockchainFilter = (blockchains, deal) => {
  if (!blockchains.length) return true;
  return blockchains.includes(deal.chainName.toLowerCase());
};

const applyJobStatusFilter = (filter, deal) => {
  if (filter.toLowerCase() === 'all') return true;
  if (filter.toLowerCase() === 'active') return deal.isActive;
  return !deal.isActive;
};

const applySuccessfulRunFilter = (successfulRun, deal) => {
  if (!successfulRun) return true;
  return deal.successfulRun;
};

const applyUnsuccessfulRunFilter = (unsuccessfulRun, deal) => {
  if (!unsuccessfulRun) return true;
  return !deal.successfulRun;
};

const filtersPipeline = (deals, filters) => {
  if (!deals) return [];

  const appliedDealStatuses = getTruthyFields(filters.dealStatus);
  const appliedBlockchains = getTruthyFields(filters.blockchain);

  const applyFilters = [
    (deal) => applySearchFilter(filters.search, deal),
    (deal) => applyDealStatusFilter(appliedDealStatuses, deal),
    (deal) => applyBlockchainFilter(appliedBlockchains, deal),
    (deal) => applyJobStatusFilter(filters.jobStatus, deal),
    (deal) => applySuccessfulRunFilter(filters.successfulRun, deal),
    (deal) => applyUnsuccessfulRunFilter(filters.unsuccessfulRun, deal),
  ];

  const filterResult = applyFilters.reduce(
    (filteredDeals, filterFunc) => filteredDeals.filter(filterFunc),
    deals
  );

  return filterResult;
};

export default filtersPipeline;
