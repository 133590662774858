import Checkbox from 'components/checkbox';
import React from 'react';

const SelectAllCheckbox = ({
  balances,
  setSelectedClaimers,
  setIsSelectAllClaimersChecked,
  isSelectAllClaimersChecked,
}) => {
  const selectAllClaimers = () => {
    setSelectedClaimers([]);
    balances.forEach(({ claimerId, isOldClaimer }) => {
      setSelectedClaimers((prev) => [...prev, { claimerId, isOldClaimer }]);
    });
  };

  const unselectAllClaimers = () => {
    setSelectedClaimers([]);
  };

  const onCheckboxChange = (event) => {
    if (event.target.checked) {
      selectAllClaimers();
    } else {
      unselectAllClaimers();
    }
    setIsSelectAllClaimersChecked((current) => !current);
  };

  return <Checkbox onChange={onCheckboxChange} checked={isSelectAllClaimersChecked} />;
};

export default SelectAllCheckbox;
