import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import IconButton from 'components/button/icon-button';
import RoundedButton from 'components/button/rounded-button';
import './index.scss';

const VestingItemsDeleteModal = ({
  isModalOpen,
  closeModal,
  deleteVestingItems,
  onVestingItemsDelete,
}) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      classes={{ paper: 'vesting-delete-items-modal' }}
    >
      <DialogTitle>
        Delete Vesting Items{' '}
        <IconButton aria-label="close" onClick={closeModal}>
          X
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <p>Do you really want to delete the vesting table?</p>
      </DialogContent>
      <DialogActions>
        <div className="action-buttons">
          <RoundedButton
            className="action-button action-button--close"
            onClick={closeModal}
            disabled={deleteVestingItems.isLoading}
          >
            Close
          </RoundedButton>
          <RoundedButton
            form="seed-form"
            className="action-button action-button--submit"
            disabled={deleteVestingItems.isLoading}
            onClick={onVestingItemsDelete}
          >
            Delete
          </RoundedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default VestingItemsDeleteModal;
