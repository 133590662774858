import { Divider, Grid } from '@material-ui/core';
import IconButton from 'components/button/icon-button';
import React, { useEffect, useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import CustomInput from 'components/input/custom-input';
import NumberInput from 'components/input/number-input';
import { addRole, updateRole, deleteRoleFromDb } from 'services/apiService';
import { switchCurrentNetwork } from 'contracts/browserWallet';
import { createRole, deleteRole, updateRole as updateRoleChain } from 'contracts/roleContract';
import { useSelector } from 'react-redux';
import Checkbox from 'components/checkbox';
import { useNotifications } from 'hooks';
import { ARBITRUM_CHAIN_ID } from 'constants/config';
import DeleteRoleDialog from './DeleteRoleDialog';

const RoleManagmentRoles = ({ roles, refetchRoles, refetchUsers, features }) => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleBeforeChange, setSelectedRoleBeforeChange] = useState(null);
  const [selectedRoleToDelete, setSelectedRoleToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDealManagementSelected, setIsDealManagementSelected] = useState(false);
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const globalReducer = useSelector((state) => state.global);
  const { chainId } = globalReducer;

  useEffect(() => {
    setIsDealManagementSelected(!!selectedRoleBeforeChange?.maxCloseAmount);
  }, [selectedRoleBeforeChange]);

  const handleChange = (e) => {
    e.persist();
    setSelectedRole((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSave = async () => {
    try {
      const save = selectedRole.id ? updateRole : addRole;
      const deploy =
        selectedRole.id && selectedRoleBeforeChange.maxCloseAmount ? updateRoleChain : createRole;

      const savedRole = await save(selectedRole);

      if (isDealManagementSelected) {
        const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);
        const isRoleChanged =
          selectedRoleBeforeChange?.name !== selectedRole.name ||
          selectedRoleBeforeChange?.maxCloseAmount !== selectedRole.maxCloseAmount;
        if (networkChecked && isRoleChanged)
          await deploy(selectedRole.maxCloseAmount, selectedRole.name);
      } else if (selectedRoleBeforeChange?.maxCloseAmount && !selectedRole.maxCloseAmount) {
        await deleteRole(selectedRole.name);
      }

      if (savedRole.id) showSuccessNotification('Role saved successfully!');
      else showErrorNotification('Error occured!');

      setSelectedRole(null);
      setSelectedRoleBeforeChange(null);
      refetchRoles();
    } catch (error) {
      showErrorNotification('Error occured!');
    }
  };

  const handleDeleteRole = async () => {
    try {
      if (selectedRoleToDelete.maxCloseAmount) {
        const networkChecked = await switchCurrentNetwork(chainId, ARBITRUM_CHAIN_ID);
        if (networkChecked) await deleteRole(selectedRoleToDelete.name);
      }

      await deleteRoleFromDb(selectedRoleToDelete.id);

      showSuccessNotification('Role deleted successfully!');

      setIsDeleteModalOpen(false);
      setSelectedRoleToDelete(null);
      setSelectedRole(null);
      setSelectedRoleBeforeChange(null);
      refetchRoles();
      refetchUsers();
    } catch (error) {
      showErrorNotification('Error occured!');
    }
  };

  const handleCheckboxChange = (e, featureId) => {
    if (e.target.checked) {
      selectedRole.featuresIds.push(featureId);
    } else {
      const indexOfId = selectedRole.featuresIds.indexOf(featureId);
      selectedRole.featuresIds.splice(indexOfId, 1);
    }
    setSelectedRole((prev) => ({ ...prev, featuresIds: selectedRole.featuresIds }));
  };

  const handleAllListCheckboxChange = (e, featuresListIds) => {
    let removedFeaturesListIds = selectedRole.featuresIds.filter(
      (id) => !featuresListIds.includes(id)
    );

    if (e.target.checked) removedFeaturesListIds = removedFeaturesListIds.concat(featuresListIds);

    setSelectedRole((prev) => ({ ...prev, featuresIds: removedFeaturesListIds }));
  };

  const handleEditRoleIconClick = (role) => {
    setSelectedRole(role);
    setSelectedRoleBeforeChange(role);
  };

  const handleDeleteRoleIconClick = (role) => {
    setIsDeleteModalOpen(true);
    setSelectedRoleToDelete(role);
  };

  const handleDealManagement = (isChecked) => {
    setIsDealManagementSelected(isChecked);
    if (!selectedRoleBeforeChange?.maxCloseAmount) {
      if (!isChecked) setSelectedRole((prev) => ({ ...prev, maxCloseAmount: '' }));
      return;
    }

    if (isChecked)
      setSelectedRole((prev) => ({
        ...prev,
        maxCloseAmount: selectedRoleBeforeChange.maxCloseAmount,
      }));
    else setSelectedRole((prev) => ({ ...prev, maxCloseAmount: '' }));
  };

  const handleCreateNew = () => {
    setSelectedRole({ name: '', amount: '0', featuresIds: [] });
    setSelectedRoleBeforeChange(null);
  };

  return (
    <Grid container>
      <DeleteRoleDialog
        open={isDeleteModalOpen}
        role={selectedRoleToDelete}
        onClose={() => setIsDeleteModalOpen(false)}
        onOk={handleDeleteRole}
      />
      <Grid item xs={4} classes={{ root: 'role-list' }}>
        <div className="role-list__title">
          <h3>Role list</h3>
          <RoundedButton type="primary" onClick={handleCreateNew}>
            Create new
          </RoundedButton>
        </div>
        <Divider />
        <div className="role-list__items">
          {roles.map((role) => (
            <div key={role.id} className="role-list__item">
              <label>{role.name}</label>
              <div className="role-list__icons">
                <IconButton icon="iconDealsEdit" onClick={() => handleEditRoleIconClick(role)} />
                <IconButton icon="iconDealsErase" onClick={() => handleDeleteRoleIconClick(role)} />
              </div>
            </div>
          ))}
        </div>
      </Grid>
      <Grid item xs={8}>
        {selectedRole && (
          <div className="role-list__details">
            <div className="details-header">
              <h3>Role details</h3>
              <RoundedButton
                type="primary"
                disabled={!selectedRole.name || !selectedRole.featuresIds.length}
                onClick={handleSave}
              >
                Save
              </RoundedButton>
            </div>
            <Divider />
            <div className="details-body">
              <div className="role-info">
                <CustomInput
                  label="Role Name"
                  name="name"
                  value={selectedRole.name}
                  onChange={handleChange}
                  disabled={!!selectedRole.id}
                />
              </div>
              <div className="deal-management-container">
                <div className="deal-management-checkbox role-info-container checkbox-option-container">
                  <Checkbox
                    checked={isDealManagementSelected}
                    onChange={(e) => handleDealManagement(e.target.checked)}
                  />
                  <span>Deal Management</span>
                </div>
                <div className="role-info">
                  <NumberInput
                    label={!isDealManagementSelected ? '' : 'Amount'}
                    value={selectedRole.maxCloseAmount || ''}
                    onChange={handleChange}
                    name="maxCloseAmount"
                    decimalNumber="2"
                    disabled={!isDealManagementSelected}
                  />
                </div>
              </div>
              <div className="role-info-container">
                <span className="features-label">Features</span>
                {features.map(({ featuresGroup, featureList, ids }) => {
                  const isEntireFeatureListChecked = ids.every((id) =>
                    selectedRole?.featuresIds?.includes(id)
                  );

                  return (
                    <div key={featuresGroup}>
                      <div className="checkbox-option-container">
                        <Checkbox
                          checked={isEntireFeatureListChecked}
                          onChange={(e) => handleAllListCheckboxChange(e, ids)}
                        />
                        <span>{featuresGroup}</span>
                      </div>
                      <div className="features-list-container">
                        {featureList[0].feature &&
                          featureList.map((featureItem) => (
                            <div key={featureItem.id} className="feature-list-item-container">
                              <Checkbox
                                checked={selectedRole.featuresIds?.includes(featureItem.id)}
                                onChange={(e) => handleCheckboxChange(e, featureItem.id)}
                              />
                              <span>{featureItem.feature}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default RoleManagmentRoles;
