import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';

const TransferModalTableRows = ({ rows }) => {
  return (
    <>
      {rows.map(({ chain, total }, idx) => (
        <TableRow key={`${idx + idx}`}>
          <TableCell>{chain}</TableCell>
          <TableCell>{total}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TransferModalTableRows;
