import React from 'react';
import PropTypes from 'prop-types';
import { TempAvatar } from 'constants/index';
import './index.scss';
import Image from 'components/Image';

function RoundedAvatar({ src, width, borderWidth, onClick, className }) {
  return (
    <div className="img-wrapper rounded-img-wrapper" style={{ width, height: width }}>
      {src ? (
        <Image
          src={src}
          alt="avatar"
          style={{ borderWidth }}
          role="presentation"
          onClick={onClick}
          className={className}
        />
      ) : (
        <Image
          src={TempAvatar}
          alt="avatar"
          style={{ borderWidth }}
          role="presentation"
          onClick={onClick}
          className={className}
        />
      )}
    </div>
  );
}

RoundedAvatar.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  borderWidth: PropTypes.number,
  onClick: PropTypes.func,
};

RoundedAvatar.defaultProps = {
  src: TempAvatar,
  width: 36,
  borderWidth: 2,
  onClick: () => {},
};

export default RoundedAvatar;
