import React from 'react';
import SvgIcon from 'components/svgIcon';
import VestingClaimInfoModal from 'features/deals/VestingClaimInfoModal';
import { useSelector } from 'react-redux';

const ClaimInfo = ({ deal, vestingClaimInfoModalOpen, toggleVestingClaimInfoModal }) => {
  const authReducer = useSelector((state) => state.auth);
  const { isAdmin } = authReducer;

  return (
    <div className="col col-4 vesting-box">
      <VestingClaimInfoModal
        open={vestingClaimInfoModalOpen}
        onClose={toggleVestingClaimInfoModal}
        deal={deal}
      />
      {isAdmin && (
        <div className="edit-button" onClick={toggleVestingClaimInfoModal}>
          <SvgIcon name="iconDealsEdit" />
        </div>
      )}
      <h3 className="col-title">CLAIM INFO</h3>

      <div className="vesting-box-content">
        <div className="item">
          <p className="label">Claim method</p>
          <p className="value">{deal.claimMethod ?? '-'}</p>
        </div>

        <div className="item">
          <p className="label">Claim URL</p>
          <p className="value">
            <a href={deal.claimUrl} target="_blank" rel="noopener noreferrer">
              {deal.claimUrl || <strong>-</strong>}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClaimInfo;
