import IconButton from 'components/button/icon-button';
import RoundedButton from 'components/button/rounded-button';
import AnalyticList from 'features/analytics/AnalyticList';
import GeneralAnalytics from 'features/analytics/GeneralAnalytics';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SvgIcon from 'components/svgIcon';
import CustomSelect from 'components/select/custom-select';
import SellTransactions from '../SellTransactions';
import remote from './remote';
import './index.scss';

const allYears = 'All';

const InitialAnalytics = () => {
  const authReducer = useSelector((state) => state.auth);
  const { walletAddress, isAdmin } = authReducer;

  const [activeTab, setActiveTab] = useState('portfolio');
  const [searchValue, setSearchValue] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [txToAddEdit, setTxToAddEdit] = useState(null);

  const [year, setYear] = useState(allYears);
  const allPortfolioAnalytics = remote.useAllPortfolioAnalytics(walletAddress, activeTab);
  const portfolioAnalytics = remote.usePortfolioAnalytics(walletAddress, year, activeTab);

  const filterableYears = useMemo(() => {
    const years = new Set([allYears]);
    if (!allPortfolioAnalytics?.data) return [...years];
    allPortfolioAnalytics.data.deals.forEach((deal) => {
      if (deal.dateClosed) {
        const dealYear = new Date(deal.dateClosed).getFullYear();
        years.add(dealYear);
      }
    });
    return [...years].sort((a, b) => a - b);
  }, [allPortfolioAnalytics.data]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleInput = () => {
    if (window.innerWidth < 767) {
      setShowInput(!showInput);
    }
  };

  return (
    <>
      <div className="analytics-header">
        <div className="analytics-header-left">
          <div className="analytics-header__title">My Analytics</div>
        </div>
        <div className="analytics-header-right">
          {activeTab === 'sell-transactions' && (
            <IconButton icon="plus" onClick={() => setTxToAddEdit({})} />
          )}
          <div className={`search-input${showInput ? ' active' : ''}`}>
            <div className="input-icon-wrapper vertical-center" onClick={toggleInput}>
              <SvgIcon name="search" />
            </div>
            <input
              type="text"
              value={searchValue}
              placeholder="Search deals by name"
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="analytics-content">
        <div className="analytics-tabs-header">
          <div className="analytics-tabs">
            <RoundedButton
              className={activeTab === 'portfolio' ? 'active' : ''}
              onClick={() => setActiveTab('portfolio')}
            >
              Portfolio
            </RoundedButton>
            <RoundedButton
              className={activeTab === 'sell-transactions' ? 'active' : ''}
              onClick={() => setActiveTab('sell-transactions')}
            >
              Sell Transactions
            </RoundedButton>
          </div>
          {activeTab === 'portfolio' && (
            <CustomSelect
              items={filterableYears.map((item) => ({ value: item }))}
              value={year}
              onSelect={(_, value) => {
                setYear(value);
              }}
            />
          )}
        </div>
        {activeTab === 'portfolio' && portfolioAnalytics.data && (
          <>
            {!isAdmin && <GeneralAnalytics analytics={portfolioAnalytics.data} />}
            <AnalyticList
              analyticDeals={portfolioAnalytics.data.deals}
              searchValue={searchValue}
              type="portfolio"
            />
          </>
        )}
        {activeTab === 'sell-transactions' && portfolioAnalytics.data && (
          <SellTransactions
            txToAddEdit={txToAddEdit}
            setTxToAddEdit={setTxToAddEdit}
            searchValue={searchValue}
            deals={portfolioAnalytics.data.deals}
          />
        )}
      </div>
    </>
  );
};

export default InitialAnalytics;
