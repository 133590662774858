import { getProviderByChainId } from '../../contracts/providers';
import { queryCalls } from 'contracts/multicallContract';
import { groupDealsByStatus, groupByChain } from '../common/helpers';
import buildCall from 'contracts/calls';
import { getPersonalCaps } from './personalCap';
import { enrichWithMetadata } from './metadata';
import { decodeDealInfo, decodePhase } from 'models/common/decoders';
import { CALL_TYPE } from 'contracts/calls/constants';

// TODO: step2 : get deals of the connected account
export async function getAdminDealModels(newAuthState, apiDeals) {
  const { accountInfo } = newAuthState;

  const walletAddress = accountInfo.address;

  let { liveDeals, otherDeals } = groupDealsByStatus(apiDeals);

  liveDeals = await processLiveDeals(liveDeals, walletAddress);

  otherDeals = otherDeals.map((otherDeal) => ({
    ...otherDeal,
    status: otherDeal.status.toLowerCase(),
    id: otherDeal.id.toString(),
  }));

  const results = [...liveDeals, ...otherDeals];

  return results.sort((a, b) => b.creationBlockNumber - a.creationBlockNumber);
}

async function processLiveDeals(live, walletAddress) {
  const dealsByChain = groupByChain(live);

  const rawDeals = await processDealsPerChain(dealsByChain, walletAddress);

  return await enrichWithMetadata(rawDeals, walletAddress);
}

async function processDealsPerChain(dealsByChain, walletAddress) {
  const results = [];

  for await (const [chainId, dealsApi] of Object.entries(dealsByChain)) {
    const provider = getProviderByChainId(chainId);

    const multicallCalls = dealsApi.flatMap((deal) => [
      buildCall(CALL_TYPE.DEAL_INFO)(deal.address),
      buildCall(CALL_TYPE.PHASE_INFO)(deal.address),
    ]);

    const multicallResults = await queryCalls(provider, multicallCalls);

    let rawDeals = processDeals(dealsApi, multicallResults);

    if (walletAddress) {
      rawDeals = await getPersonalCaps({ deals: dealsApi, rawDeals, walletAddress, provider });
    }

    results.push(...rawDeals);
  }

  return results;
}

function processDeals(deals, multicallResults) {
  return deals.map((deal, idx) => {
    const dealInfoIndex = idx * 2;
    const currentPhaseIndex = dealInfoIndex + 1;

    const rawDealInfo = multicallResults[dealInfoIndex].returnData;
    const dealInfo = decodeDealInfo(rawDealInfo);

    const rawPhaseInfo = multicallResults[currentPhaseIndex].returnData;
    const phaseInfo = decodePhase(rawPhaseInfo);

    return {
      deal,
      dealInfo,
      currentPhaseIndex: phaseInfo?.currentPhaseIndex,
      ...phaseInfo,
    };
  });
}
