import { TableCell, TableRow } from '@material-ui/core';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import IconButton from 'components/button/icon-button';
import moment from 'moment';
import React from 'react';
import { formatFiatPrice, formatTokenPrice } from 'utils';
import { getTransactionLinkByChainName } from 'contracts/explorers';

const SellTransactionsRows = ({ sellTransactions, handleDelete, deal, setTxToAddEdit }) => {
  return (
    <>
      {sellTransactions.map(
        ({
          sold,
          blockchain,
          date,
          received,
          id,
          txHash,
          deal: txDeal,
          oldDealId,
          dealId,
          exchange,
        }) => (
          <TableRow
            key={id}
            onClick={() =>
              txHash &&
              window.open(
                getTransactionLinkByChainName(blockchain.name, txHash),
                '_blank',
                'noopener,noreferrer'
              )
            }
          >
            {!deal && (
              <TableCell align="left" classes={{ root: 'deal-cell' }}>
                <div>
                  <RoundedAvatar src={txDeal.imageUrl} />
                  {txDeal.name}
                </div>
              </TableCell>
            )}
            <TableCell align={deal ? 'left' : 'right'}>
              {moment(date).format('DD-MMM-YYYY')}
            </TableCell>
            <TableCell align="right">{exchange?.name}</TableCell>
            <TableCell align="right">{blockchain?.name}</TableCell>
            <TableCell align="right">{formatTokenPrice(sold)}</TableCell>
            <TableCell align="right">${formatFiatPrice(received)}</TableCell>
            <TableCell align="right" classes={{ root: 'controls-cell' }}>
              <div>
                <IconButton
                  icon="iconDealsEdit"
                  onClick={() =>
                    setTxToAddEdit({
                      txHash,
                      sold,
                      received,
                      date: new Date(date),
                      chainId: blockchain?.chainId,
                      dealId: +dealId,
                      oldDealId: +oldDealId,
                      deal: txDeal,
                      id,
                      exchange,
                    })
                  }
                />
                <IconButton
                  icon="iconDealsErase"
                  onClick={() =>
                    handleDelete({
                      id,
                      dealId: dealId || oldDealId,
                      isOldDeal: !!oldDealId,
                    })
                  }
                />
              </div>
            </TableCell>
          </TableRow>
        )
      )}
    </>
  );
};

export default SellTransactionsRows;
