import React, { useState } from 'react';
import HistorySection from '../HistorySection';
import BatchScheduleSection from '../BatchScheduleSection';
import CompareSection from '../CompareSection';

const HistoryContent = ({ batchScheduleRows, historyRows }) => {
  const [selectedTab, setSelectedTab] = useState('history');
  const selectTab = (tabValue) => setSelectedTab(tabValue);

  return (
    <div className="distro-page">
      <HistorySection selectTab={selectTab} selectedTab={selectedTab} historyRows={historyRows} />
      <BatchScheduleSection
        selectTab={selectTab}
        selectedTab={selectedTab}
        batchScheduleRows={batchScheduleRows}
      />
      <CompareSection
        selectTab={selectTab}
        selectedTab={selectedTab}
        batchScheduleRows={batchScheduleRows}
        historyRows={historyRows}
      />
    </div>
  );
};

export default HistoryContent;
