import React from 'react';
import SortableTable from 'components/sortable-table';
import utils from './utils';
import InvoiceTableRows from './InvoiceTableRows';
import './index.scss';

const InvoicesContent = ({ invoices }) => {
  if (!invoices?.length) return null;

  return (
    <div className="invoices-table">
      <SortableTable tableHead={utils.invoicesTableHead} rows={invoices}>
        {(sortedRows) => <InvoiceTableRows rows={sortedRows} />}
      </SortableTable>
    </div>
  );
};

export default InvoicesContent;
