import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeResolvedNotification } from 'store/actions';
import SvgIcon from 'components/svgIcon';
import './index.scss';

const ResolvedNotification = () => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.global);
  const { resolvedNotification } = globalReducer;

  const onClose = () => {
    dispatch(removeResolvedNotification());
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeResolvedNotification());
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!resolvedNotification) return null;

  return (
    <div className="resolved-notification">
      <div className="resolved-notification-icon vertical-center">
        <SvgIcon name="notification" />
      </div>
      <div className="resolved-notification-content">
        <div className="title">{resolvedNotification.title}</div>
        <div className="description">{resolvedNotification.description}</div>
      </div>
      <div
        className="resolved-notification-action vertical-center"
        role="presentation"
        onClick={onClose}
      >
        Close
      </div>
    </div>
  );
};

export default ResolvedNotification;
