import React from 'react';
import './index.scss';
import useRiskReportData from 'screens/Legal/screens/Details/hooks/useRiskReportData';
import { RiskReportType } from 'screens/Legal/screens/Details/hooks/constants';
import DownloadReportButton from 'screens/Legal/components/DownloadReportButton';
import InfoSection from 'screens/Legal/components/sections/InfoSection';
import StatementSection from 'screens/Legal/components/sections/StatementSection';

const AppendixOne = ({ address }) => {
  const { data: riskReportData } = useRiskReportData(RiskReportType.AppendixOne, address);

  if (!riskReportData) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '20px',
      }}
    >
      <DownloadReportButton type={RiskReportType.AppendixOne} address={address} />
      <section className="deal-info-section grid">
        <InfoSection title="Predmet" data={riskReportData.assessment} />
        <StatementSection title="Procjene" data={riskReportData.considerations} />
      </section>
    </div>
  );
};

export default AppendixOne;
