import { MULTICALL_UNSUPPORTED_CHAIN_IDS } from 'constants/config';
import { NOT_MULTICALL_SUPPORTED_FLAG } from './constants';

const groupByChain = (apiDeals) => {
  return apiDeals.reduce((acc, deal) => {
    if (!acc[deal.chainId]) {
      acc[deal.chainId] = [];
    }
    acc[deal.chainId].push(deal);
    return acc;
  }, {});
};

function groupDealsByStatus(deals) {
  return deals.reduce(
    (acc, deal) => {
      const groupKey =
        [1, 2, 3, 4, 5].includes(deal.statusId) && deal.address ? 'liveDeals' : 'otherDeals';

      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }

      acc[groupKey].push(deal);

      return acc;
    },
    { liveDeals: [], otherDeals: [] }
  );
}

const getAllocationModelName = (allocationModelId, unlimitedTimestamp) => {
  if (unlimitedTimestamp > 0 && unlimitedTimestamp * 1000 < Date.now()) {
    return 'Unlimited';
  }

  switch (allocationModelId.toString()) {
    case '0':
      return 'ProRata';
    case '1':
      return 'Capped';
    case '2':
      return 'Unlimited';
    case '3':
      return 'CappedPerLevel';
    case '4':
      return 'CappedPerWallet';
    case '5':
      return 'PhaseCap';
    default:
      throw new Error(`Invalid allocation model ${allocationModelId.toString()}`);
  }
};

const getUserLevelLabel = (accessLevel) => {
  switch (accessLevel) {
    case 0:
      return 'Level 0';
    case 1:
      return 'Level 1';
    case 2:
      return 'Level 2';
    case 3:
      return 'Level 3';
    case 4:
      return 'Whitelist';
    default:
      throw new Error(`Invalid allocation model ${accessLevel}`);
  }
};

function markDealForManualFetch(deal) {
  return {
    deal,
    phaseInfo: NOT_MULTICALL_SUPPORTED_FLAG,
    dealChainData: NOT_MULTICALL_SUPPORTED_FLAG,
    userWhitelisted: NOT_MULTICALL_SUPPORTED_FLAG,
    contributedAmount: NOT_MULTICALL_SUPPORTED_FLAG,
    maxContributionAmount: NOT_MULTICALL_SUPPORTED_FLAG,
  };
}

function isChainMulticallSupported(chainId) {
  return !MULTICALL_UNSUPPORTED_CHAIN_IDS.includes(chainId.toString());
}

const isFieldMulticallSupported = (field) => {
  return field !== NOT_MULTICALL_SUPPORTED_FLAG;
};

export {
  groupByChain,
  groupDealsByStatus,
  getUserLevelLabel,
  getAllocationModelName,
  markDealForManualFetch,
  isChainMulticallSupported,
  isFieldMulticallSupported,
};
