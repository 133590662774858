import React from 'react';
import getParsedBatchScheduleRows from 'components/distributions/utils/getParsedBatchScheduleRows';
import BatchScheduleTable from 'components/distributions/BatchScheduleTable';
import remote from 'screens/AllDealsSingle/remote';

const BatchSchedule = ({ dealId, isOldDeal }) => {
  const { data: batchSchedule } = remote.useGetDealSchedule(dealId, isOldDeal);

  const parsedBatchSchedule = getParsedBatchScheduleRows(batchSchedule);

  return (
    <BatchScheduleTable
      batchScheduleRows={parsedBatchSchedule}
      type="batch-schedule-distributions"
    />
  );
};

export default BatchSchedule;
