import { useReducer } from 'react';

const parseFilterArray = (arr) => arr.reduce((acc, item) => ({ ...acc, [item]: false }), {}); // {Live: false, Paused: false}

function init(balances) {
  const rawDealStatus = [...new Set(balances.map((balance) => balance.dealStatus))];
  const rawBlockchain = [...new Set(balances.map((balance) => balance.chain))];

  const dealStatus = parseFilterArray(rawDealStatus);
  const blockchain = parseFilterArray(rawBlockchain);

  return {
    search: '',
    dealStatus,
    blockchain,
    hasNativeTokens: false,
    hasClaimer: 'all',
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'updateSearch':
      return { ...state, search: action.payload };
    case 'updateDealStatus':
      return {
        ...state,
        dealStatus: { ...state.dealStatus, [action.payload.key]: action.payload.value },
      };
    case 'updateBlockchain':
      return {
        ...state,
        blockchain: { ...state.blockchain, [action.payload.key]: action.payload.value },
      };
    case 'updateHasNativeTokens':
      return { ...state, hasNativeTokens: action.payload };
    case 'updateHasClaimer':
      return { ...state, hasClaimer: action.payload };
    default:
      throw new Error();
  }
}

const useFilters = (balances) => {
  const [filters, dispatch] = useReducer(reducer, balances, init);

  const dispatchUpdateSearch = (search) => dispatch({ type: 'updateSearch', payload: search });
  const dispatchUpdateDealStatus = ({ key, value }) =>
    dispatch({ type: 'updateDealStatus', payload: { key, value } });
  const dispatchUpdateBlockchain = ({ key, value }) =>
    dispatch({ type: 'updateBlockchain', payload: { key, value } });
  const dispatchUpdateHasNativeTokens = (hasNativeTokens) =>
    dispatch({ type: 'updateHasNativeTokens', payload: hasNativeTokens });
  const dispatchUpdateHasClaimer = (hasClaimer) =>
    dispatch({ type: 'updateHasClaimer', payload: hasClaimer });

  return {
    filters,
    actions: {
      dispatchUpdateSearch,
      dispatchUpdateDealStatus,
      dispatchUpdateBlockchain,
      dispatchUpdateHasNativeTokens,
      dispatchUpdateHasClaimer,
    },
  };
};

export default useFilters;
