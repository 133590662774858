import RoundedButton from 'components/button/rounded-button';
import React, { useState } from 'react';
import TransferModal from './TransferModal';
import './index.scss';

function getTransfers(selectedClaimers, balances) {
  const chainWithAmountObject = selectedClaimers.reduce((acc, item) => {
    if (!balances) return acc;

    const foundClaimer = balances.find(
      (balance) =>
        balance.walletId === item.walletId && balance.chain === item.chain
    );
    if (foundClaimer) {
      if (!acc[foundClaimer.chain]) {
        acc[foundClaimer.chain] = +foundClaimer.balance;
      } else {
        acc[foundClaimer.chain] += +foundClaimer.balance;
      }
    }
    return acc;
  }, {});

  const transfers = Object.entries(chainWithAmountObject).map((entry) => ({
    chain: entry[0],
    total: entry[1],
  }));

  return transfers;
}

function getTransferData(selectedClaimers, balances) {
  const transferData = selectedClaimers.reduce((acc, item) => {
    if (!balances) return acc;

    const foundClaimer = balances.find(
      (balance) =>
        balance.walletId === item.walletId && balance.chain === item.chain
    );
    if (foundClaimer) {
      return [...acc, { walletId: foundClaimer.walletId, chainId: foundClaimer.chainId }];
    }
    return acc;
  }, []);

  return transferData;
}

const TransferButton = ({ selectedClaimers, balances }) => {
  const isDisabled = selectedClaimers.length === 0 || !balances;

  const [showTransferModal, setShowTransferModal] = useState(false);
  const openTransferModal = () => setShowTransferModal(true);
  const closeTransferModal = () => setShowTransferModal(false);

  const transfers = getTransfers(selectedClaimers, balances);
  const transferData = getTransferData(selectedClaimers, balances);

  return (
    <>
      <RoundedButton onClick={openTransferModal} className="transfer-button" disabled={isDisabled}>
        <span>Transfer balance</span>
      </RoundedButton>
      <TransferModal
        isModalOpen={showTransferModal}
        closeModal={closeTransferModal}
        transfers={transfers}
        transferData={transferData}
      />
    </>
  );
};

export default TransferButton;
