import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiDelete, apiGet, apiPost, apiPut, baseUrl } from 'services/apiService';

const getAllDeals = async () => {
  const response = await apiGet(`${baseUrl}/api/distribution/deals`);
  return response.data;
};

const useGetAllDeals = (select) => {
  return useQuery(['distribution', 'deals'], getAllDeals, { select });
};

const selectSingleDeal = (dealId, isOldDeal) => (deals) =>
  deals.find((deal) => deal.dealId === dealId && deal.isOldDeal === isOldDeal);

const useGetSingleDeal = (dealId, isOldDeal) => useGetAllDeals(selectSingleDeal(dealId, isOldDeal));

const getDealInfo = async (dealId, isOldDeal) => {
  const response = await apiGet(
    `${baseUrl}/api/distribution/deals/${dealId}?isOldDeal=${isOldDeal}`
  );
  return response.data;
};

const useGetDealInfo = (dealId, isOldDeal) => {
  return useQuery(
    ['distribution', 'deals', dealId, isOldDeal],
    () => getDealInfo(dealId, isOldDeal),
    {
      refetchInterval: 1000 * 60 * 5, // 5 minutes
    }
  );
};

const createNote = async (dealId, message, isOldDeal) => {
  const response = await apiPost(`${baseUrl}/api/distribution/deals/${dealId}/notes`, {
    message,
    isOldDeal,
  });
  return response.data;
};

const useCreateNote = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(({ message, isOldDeal }) => createNote(dealId, message, isOldDeal), {
    onSuccess: () => {
      queryClient.invalidateQueries(['distribution', 'deals', dealId]);
    },
  });
};

const deleteNote = async (dealId, noteId) => {
  const response = await apiDelete(`${baseUrl}/api/distribution/deals/${dealId}/notes/${noteId}`);
  return response.data;
};

const useDeleteNote = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(({ noteId }) => deleteNote(dealId, noteId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['distribution', 'deals', dealId]);
    },
  });
};

const updateReceiveWallet = async (dealId, walletId, isOldDeal) => {
  const response = await apiPut(`${baseUrl}/api/deal/${dealId}/receive-wallet`, {
    walletId,
    isOldDeal,
  });
  return response.data;
};

const useUpdateReceiveWallet = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ walletId, isOldDeal }) => updateReceiveWallet(dealId, walletId, isOldDeal),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['distribution', 'deals', dealId]);
        queryClient.invalidateQueries(['wallet']);
      },
    }
  );
};

const updateContactInfo = async (
  dealId,
  contactPerson,
  contactEmail,
  telegram,
  telegramGroupLink,
  isOldDeal
) => {
  const response = await apiPut(`${baseUrl}/api/distribution/deals/${dealId}/contact-info`, {
    contactPerson,
    contactEmail,
    telegram,
    telegramGroupLink,
    isOldDeal,
  });
  return response.data;
};

const useUpdateContactInfo = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ contactPerson, contactEmail, telegram, telegramGroupLink, isOldDeal }) =>
      updateContactInfo(
        dealId,
        contactPerson,
        contactEmail,
        telegram,
        telegramGroupLink,
        isOldDeal
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['distribution', 'deals', dealId]);
      },
    }
  );
};

export default {
  useGetAllDeals,
  useGetSingleDeal,
  useGetDealInfo,
  useCreateNote,
  useDeleteNote,
  useUpdateContactInfo,
  useUpdateReceiveWallet,
};
