import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import UnlockSeedDialog from './UnlockSeedDialog';
import '../index.scss';

const SeedTable = ({ seeds }) => {
  const [selectedSeedId, setSelectedSeedId] = useState(null);
  const [isDecryptOpen, setIsDecryptOpen] = useState(false);
  const openDecryptModal = (seedId) => {
    setSelectedSeedId(seedId);
    setIsDecryptOpen(true);
  };
  const closeDecryptModal = () => {
    setSelectedSeedId(null);
    setIsDecryptOpen(false);
  };

  return (
    <>
      <div className="distro-container">
        <TableContainer classes={{ root: 'distro-table' }}>
          <Table className="sticky-table-header">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Seed Type</TableCell>
                <TableCell>Unlocked</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {seeds.map((seed) => (
                <TableRow key={seed.id}>
                  <TableCell>{seed.id}</TableCell>
                  <TableCell>{seed.name}</TableCell>
                  <TableCell>{seed.seedType.name}</TableCell>
                  <TableCell>
                    <Box sx={{ marginLeft: '1rem' }}>
                      <SvgIcon name={seed.unlocked ? 'iconFinishedPoll' : 'iconLogoOrange'} />
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <RoundedButton
                      onClick={() => openDecryptModal(seed.id)}
                      disabled={seed.unlocked}
                      className="unlock-button"
                    >
                      <span>Unlock</span>
                    </RoundedButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <UnlockSeedDialog
        isDecryptOpen={isDecryptOpen}
        closeDecryptModal={closeDecryptModal}
        selectedSeedId={selectedSeedId}
      />
    </>
  );
};

export default SeedTable;
