export const CALL_TYPE = {
  DEAL_INFO: 'deal-info',
  PHASE_INFO: 'phase-info',
  CLAIM_AMOUNT: 'claim-amount',
  DEAL_CLAIM_AMOUNT: 'deal-claim-amount',
  ADDRESS_CONTRIBUTION_AMOUNT: 'address-contribution-amount',
  MAX_CONTRIBUTION_AMOUNT: 'max-contribution-amount',
  MAX_CONTRIBUTION_AMOUNT_V2: 'max-contribution-amount-v2',
  IS_WHITELISTED: 'is-whitelisted',
  TOKEN_INFO: 'token-info',
};
