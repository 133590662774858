import moment from 'moment';
import useTableColumns from '../../hooks/useTableColumns';

const useLoginsTableColumns = () => {
  const columns = [
    {
      column: 'date',
      label: 'Date',
      type: 'string',
      accessor: (row) => moment(row.lastLogin).format('YYYY-MM-DD'),
      value: (row) => row.lastLogin,
    },
    {
      column: 'ip',
      label: 'IP Address',
      type: 'string',
      accessor: (row) => row.ipAddress,
      value: (row) => row.ipAddress,
    },
    {
      column: 'countryName',
      label: 'Country',
      type: 'string',
      accessor: (row) => row.countryName,
      value: (row) => row.countryName,
    },
  ];

  return useTableColumns(columns);
};

export default useLoginsTableColumns;
