const useTableColumns = (columns) => {
  // TODO -> do this on database level

  const getSortFunction = (type, value) => {
    switch (type) {
      case 'string':
        return (a, b) => {
          if (!value(a)) return -1;
          if (!value(b)) return 1;
          return value(b)?.trim().toLowerCase() > value(a)?.trim().toLowerCase() ? 1 : -1;
        };

      case 'number':
        return (a, b) => {
          if (!value(a)) return -1;
          if (!value(b)) return 1;
          return parseFloat(value(a)) > parseFloat(value(b)) ? 1 : -1;
        };

      case 'date':
        return (a, b) => {
          if (!value(a)) return -1;
          if (!value(b)) return 1;
          return new Date(value(a)) > new Date(value(b)) ? 1 : -1;
        };

      default:
        return null;
    }
  };

  return columns.map(({ type, value, ...rest }) => ({
    ...rest,
    sort: getSortFunction(type, value),
  }));
};

export default useTableColumns;
