import { ClickAwayListener, Divider, Popper } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import CheckboxAccordion from 'components/filters/CheckboxAccordion';
import RadioButtonsAccordion from 'components/filters/RadioButtonsAccordion';
import SwitchInput from 'components/filters/SwitchInput';
import SvgIcon from 'components/svgIcon';
import React, { useState } from 'react';
import './index.scss';

const hasClaimerOptions = ['all', 'yes', 'no'];

const BalancesFilters = ({ filters, actions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'balances-filters-popper' : undefined;

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div className="balances-filter">
        <RoundedButton onClick={handleClick}>
          <SvgIcon name="filter" />
          <span>Filter</span>
        </RoundedButton>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <div className="balances-filters-popper-dropdown">
            <CheckboxAccordion
              title="Deal Status"
              options={filters.dealStatus}
              updateOptions={actions.dispatchUpdateDealStatus}
              className="gray-filter-options"
            />
            <Divider />
            <CheckboxAccordion
              title="Blockchain"
              options={filters.blockchain}
              updateOptions={actions.dispatchUpdateBlockchain}
              className="gray-filter-options"
            />
            <Divider />
            <SwitchInput
              title="Non-Zero Balances"
              value={filters.hasNativeTokens}
              updateValue={actions.dispatchUpdateHasNativeTokens}
            />
            <Divider />
            <RadioButtonsAccordion
              title="Has Claimer"
              selectedValue={filters.hasClaimer}
              options={hasClaimerOptions}
              updateOptions={actions.dispatchUpdateHasClaimer}
              className="gray-filter-options"
            />
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default BalancesFilters;
