import { getErc20Balance } from 'contracts/erc20';
import { getProviderByChainId } from 'contracts/providers';
import { ethers } from 'ethers';
import { useMutation, useQueries, useQuery } from 'react-query';
import { apiGet, apiPost, baseUrl } from 'services/apiService';
import { useNotifications } from 'hooks';

const getAllClaimerBalances = async () => {
  const response = await apiGet(`${baseUrl}/api/balances/all`);
  return response.data;
};

const useGetAllClaimerBalances = () => {
  return useQuery(['balances', 'all'], getAllClaimerBalances);
};

const getNativeTokenBalance = async (chainId, walletAddress) => {
  const provider = getProviderByChainId(+chainId);
  const amount = await provider.getBalance(walletAddress);
  return { amount: ethers.utils.formatUnits(amount), chainId, walletAddress };
};

const useGetClaimerNativeTokenBalanceAmountQueries = (balances = []) => {
  return useQueries({
    queries: balances.map((balance) => ({
      queryKey: ['balances', 'all', balance.isOldClaimer, balance.chainId, balance.walletAddress],
      queryFn: () => getNativeTokenBalance(balance.chainId, balance.walletAddress),
    })),
  });
};

const getClaimerTokensInfo = async (claimerId) => {
  const response = await apiGet(`${baseUrl}/api/balances/${claimerId}/tokens`);
  return response.data;
};

const useGetClaimerTokensInfo = (claimerId) => {
  return useQuery(['balances', claimerId, 'tokens'], () => getClaimerTokensInfo(claimerId));
};

const getOldClaimerTokensInfo = async (oldClaimerId) => {
  const response = await apiGet(`${baseUrl}/api/balances/oldClaimer/${oldClaimerId}/tokens`);
  return response.data;
};

const useGetOldClaimerTokensInfo = (oldClaimerId) => {
  return useQuery(['balances', 'oldClaimer', oldClaimerId, 'tokens'], () =>
    getOldClaimerTokensInfo(oldClaimerId)
  );
};

const getClaimerTokenBalance = async (chainId, erc20Address, claimerAddress) => {
  const provider = getProviderByChainId(+chainId);
  const amount = await getErc20Balance(provider, erc20Address, claimerAddress);
  return { claimerAddress, amount, chainId, tokenAddress: erc20Address };
};

const useGetTokenBalanceAmountQueries = (tokens = [], isOldClaimer) => {
  return useQueries({
    queries: tokens.map((token) => ({
      queryKey: [
        'balances',
        isOldClaimer,
        'tokens',
        token.claimerId,
        token.chainId,
        token.tokenAddress,
        token.claimerAddress,
      ],
      queryFn: () =>
        getClaimerTokenBalance(token.chainId, token.tokenAddress, token.claimerAddress),
    })),
  });
};

const transferBalancesToWallets = async (wallets) => {
  const response = await apiPost(`${baseUrl}/api/wallets/transfer-funds`, { wallets });
  return response.data;
};

const useTransferBalancesToWallets = () => {
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  return useMutation((wallets) => transferBalancesToWallets(wallets), {
    onSuccess: () => {
      showSuccessNotification('Funds transfered!');
    },
    onError: () => {
      showErrorNotification('Error while transfering funds!');
    },
  });
};

const getAllWallets = async () => {
  const response = await apiGet(`${baseUrl}/api/wallet`);
  return response.data;
};

const useGetAllWallets = (select) => {
  return useQuery(['wallet'], getAllWallets, {
    select,
  });
};

const selectAssignedWallets = (wallets) => wallets.filter((wallet) => wallet.deal.length > 0);

const useGetAllAssignedWallets = () => useGetAllWallets(selectAssignedWallets);

const selectNewWallets = (wallets) =>
  wallets.filter((wallet) => wallet.deal.length === 0 && wallet.oldDeal.length === 0);

const useGetAllNewWallets = () => useGetAllWallets(selectNewWallets);

export default {
  useGetAllClaimerBalances,
  useGetClaimerTokensInfo,
  useGetOldClaimerTokensInfo,
  useGetClaimerNativeTokenBalanceAmountQueries,
  useGetTokenBalanceAmountQueries,
  useTransferBalancesToWallets,
  useGetAllAssignedWallets,
  useGetAllNewWallets,
};
