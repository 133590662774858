const categorizeRisks = (risks) => {
  const categorized = [
    {
      label: '1. RIZIK STRANKE - ČIMBENICI KOJI UKAZUJU NA POTENCIJALNO NIŽI RIZIK',
      values: risks.slice(0, 3),
    },
    {
      label: '2. RIZIK STRANKE - ČIMBENICI KOJI UKAZUJU NA POTENCIJALNO VIŠI RIZIK',
      values: risks.slice(3, 14),
    },
    {
      label: '3. GEOGRAFSKI STRANKE - ČIMBENICI KOJI UKAZUJU NA POTENCIJALNO NIŽI RIZIK',
      values: risks.slice(14, 18),
    },
    {
      label: '4. GEOGRAFSKI STRANKE - ČIMBENICI KOJI UKAZUJU NA POTENCIJALNO VIŠI RIZIK',
      values: risks.slice(18, 22),
    },
    {
      label:
        '5. RIZIK POVEZAN S PROIZVODIMA, USLUGAMA, TRANSAKCIJAMA ILI KANALIMA DOSTAVE - ČIMBENICI KOJI UKAZUJU NA POTENCIJALNO NIŽI RIZIK',
      values: risks.slice(22, 23),
    },
    {
      label:
        '6. RIZIK POVEZAN S PROIZVODIMA, USLUGAMA, TRANSAKCIJAMA ILI KANALIMA DOSTAVE - ČIMBENICI KOJI UKAZUJU NA POTENCIJALNO VIŠI RIZIK',
      values: risks.slice(23, risks.length),
    },
  ];

  return categorized;
};

export { categorizeRisks };
