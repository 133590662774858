import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import { PepExplaination } from './data';
import './index.scss';
import PepDetails from './PepDetails';

const PoliticallyExposedPerson = ({ values, errors, onChange }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormControl error={!!errors.isPep}>
      <p
        className="profile-col-title"
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '4px' }}
      >
        Are you a Politically Exposed Person?
        <div style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
          <SvgIcon width={16} height={16} name="iconInfo" />
        </div>
      </p>
      <div className="radio-group">
        <div style={{ width: '100%' }}>
          <RadioGroup
            aria-label="politically-exposed"
            name="isPep"
            value={values.isPep}
            onChange={onChange}
          >
            <FormControlLabel value="false" control={<Radio />} label="No" />
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            {values.isPep === 'true' ? (
              <PepDetails values={values} errors={errors} onChange={onChange} />
            ) : null}
          </RadioGroup>
          {errors.isPep && <FormHelperText>This field is required.</FormHelperText>}
        </div>
      </div>
      <Dialog maxWidth="lg" open={open} onClose={handleClose} classes={{ paper: 'dialog' }}>
        <DialogTitle>Politically Exposed Person</DialogTitle>
        <DialogContent>
          <div style={{ whiteSpace: 'pre-wrap' }}>{PepExplaination}</div>
        </DialogContent>
        <DialogActions>
          <RoundedButton type="primary" onClick={handleClose}>
            Close
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
};

export default PoliticallyExposedPerson;
