import SortableTable from 'components/sortable-table';
import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import AddressLink from 'components/address-link';
import IconButton from 'components/button/icon-button';
import remote from 'screens/Balances/remote';
import AssignWalletModal from './AssignWalletModal';
import AddressTableFooter from './AddressTableFooter';
import utils from '../utils';

const AddressTable = ({ dealInfo }) => {
  const { data: wallets } = remote.useGetAllNewWallets();

  const [isAssignWalletModalOpen, setIsAssignWalletModalOpen] = useState(false);
  const openAssignModal = () => setIsAssignWalletModalOpen(true);
  const closeAssignModal = () => setIsAssignWalletModalOpen(false);

  const [assignedWallet, setAssignedWallet] = useState(null);
  const assigneWallet = (walletId) => {
    if (assignedWallet === walletId) return setAssignedWallet(null);
    return setAssignedWallet(walletId);
  };

  return (
    <>
      <SortableTable
        tableHead={utils.addressTableHead}
        rows={dealInfo.addressTable}
        type="deal-info-addresses"
        tableFooter={<AddressTableFooter dealInfo={dealInfo} openAssignModal={openAssignModal} />}
      >
        {(sortedRows) =>
          sortedRows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.blockchain}</TableCell>
              <TableCell>
                <AddressLink chainName={row.blockchain} chainAddress={row.tokenAddress} />
              </TableCell>
              <TableCell>
                {dealInfo.dealAddress && (
                  <AddressLink chainName="xDai" chainAddress={dealInfo.dealAddress} />
                )}
                {dealInfo.dealWallet && (
                  <AddressLink chainName="ethereum" chainAddress={dealInfo.dealWallet} />
                )}
              </TableCell>
              <TableCell>
                {dealInfo.receiveWallet ? (
                  <AddressLink chainName={row.blockchain} chainAddress={dealInfo.receiveWallet} />
                ) : (
                  <div className="address-add-button" onClick={openAssignModal}>
                    <b>Assign</b>
                    <IconButton onClick={openAssignModal} icon="addDeal" />
                  </div>
                )}
              </TableCell>
              <TableCell>
                <AddressLink chainName={row.blockchain} chainAddress={row.claimerAddress} />
              </TableCell>
            </TableRow>
          ))
        }
      </SortableTable>
      <AssignWalletModal
        isModalOpen={isAssignWalletModalOpen}
        closeModal={closeAssignModal}
        assigneWallet={assigneWallet}
        assignedWallet={assignedWallet}
        wallets={wallets}
        dealId={dealInfo.dealId}
        isOldDeal={dealInfo.platformOrLegacy === 'Legacy'}
      />
    </>
  );
};

export default AddressTable;
