import * as calls from './builders';
import { CALL_TYPE } from './constants';

export default function buildCall(type) {
  switch (type) {
    case CALL_TYPE.DEAL_INFO:
      return calls.buildDealInfoCall;
    case CALL_TYPE.PHASE_INFO:
      return calls.buildCurrentPhaseCall;
    case CALL_TYPE.CLAIM_AMOUNT:
      return calls.buildClaimAmountCall;
    case CALL_TYPE.ADDRESS_CONTRIBUTION_AMOUNT:
      return calls.buildAddressContributionAmountCall;
    case CALL_TYPE.MAX_CONTRIBUTION_AMOUNT:
      return calls.buildMaxContributionAmountCall;
    case CALL_TYPE.MAX_CONTRIBUTION_AMOUNT_V2:
      return calls.buildMaxContributionAmountCallV2;
    case CALL_TYPE.IS_WHITELISTED:
      return calls.buildIsWhitelistedCall;
    case CALL_TYPE.TOKEN_INFO:
      return calls.buildTokenInfoCall;
    case CALL_TYPE.DEAL_CLAIM_AMOUNT:
      return calls.buildDealClaimAmountCall;
    default:
      return null;
  }
}
