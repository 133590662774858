import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Radio } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import './index.scss';

const RadioButtonsAccordion = ({ title, selectedValue, options, updateOptions, className }) => {
  const handleChange = (e) => {
    updateOptions(e.target.value);
  };

  return (
    <Accordion className="custom-accordion">
      <AccordionSummary expandIcon={<SvgIcon name="arrowDown" />}>{title}</AccordionSummary>
      <AccordionDetails>
        {options.map((option) => (
          <div key={option}>
            <span className={className}>{option.charAt(0).toUpperCase() + option.slice(1)}</span>
            <Radio
              checked={selectedValue === option}
              onChange={handleChange}
              value={option}
              name="radio-buttons"
            />
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default RadioButtonsAccordion;
