import { useQuery } from 'react-query';
import {
  getUserDealAnalytic,
  getUserOldDealAnalytic,
  getUserDealDashboardAnalytic,
  getUserOldDealDashboardAnalytic,
} from 'services/apiService';

const useDealAnalytics = (walletAddress, dealId, isOldDeal, path) => {
  return useQuery(
    ['dealAnalytics', walletAddress, dealId, isOldDeal],
    () => (isOldDeal ?
      (path === '/analytics' ? getUserOldDealAnalytic(dealId) : getUserOldDealDashboardAnalytic(dealId))
      : (path === '/analytics' ? getUserDealAnalytic(dealId) : getUserDealDashboardAnalytic(dealId))),
    {
      enabled: !!dealId,
    }
  );
};

export default useDealAnalytics;
