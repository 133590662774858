import React, { useState } from 'react';
import AssignedWalletTable from '../AssignedWalletTable';
import BalancesTable from '../BalancesTable';
import NewWalletTable from '../NewWalletTable';

const BalancesContent = ({ balances }) => {
  const [selectedTab, setSelectedTab] = useState('wallets');
  const selectTab = (tabValue) => setSelectedTab(tabValue);

  return (
    <div className="distro-page">
      <BalancesTable selectTab={selectTab} selectedTab={selectedTab} balances={balances} />
      <AssignedWalletTable selectTab={selectTab} selectedTab={selectedTab} />
      <NewWalletTable selectTab={selectTab} selectedTab={selectedTab} />
    </div>
  );
};

export default BalancesContent;
