import {
  XDAI_CHAIN_ID,
  ETHEREUM_CHAIN_ID,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  BASE_CHAIN_ID,
  MERLIN_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  GNOSISSCAN_ADDRESS_LINK,
  ETHERSCAN_ADDRESS_LINK,
  BSCSCAN_ADDRESS_LINK,
  POLYGONSCAN_ADDRESS_LINK,
  SNOWTRACE_ADDRESS_LINK,
  BASE_ADDRESS_LINK,
  MERLIN_ADDRESS_LINK,
  ARBISCAN_ADDRESS_LINK,
  GNOSISSCAN_TX_LINK,
  ETHERSCAN_TX_LINK,
  BSCSCAN_TX_LINK,
  POLYGONSCAN_TX_LINK,
  SNOWTRACE_TX_LINK,
  BASE_TX_LINK,
  MERLIN_TX_LINK,
  ARBISCAN_TX_LINK,
} from 'constants/config';

export const getAddressLinkByChainId = (chainId, address) => {
  switch (+chainId) {
    case XDAI_CHAIN_ID:
      return `${GNOSISSCAN_ADDRESS_LINK}${address}`;

    case ETHEREUM_CHAIN_ID:
      return `${ETHERSCAN_ADDRESS_LINK}${address}`;

    case BSC_CHAIN_ID:
      return `${BSCSCAN_ADDRESS_LINK}${address}`;

    case POLYGON_CHAIN_ID:
      return `${POLYGONSCAN_ADDRESS_LINK}${address}`;

    case AVALANCHE_CHAIN_ID:
      return `${SNOWTRACE_ADDRESS_LINK}${address}`;

    case BASE_CHAIN_ID:
      return `${BASE_ADDRESS_LINK}${address}`;

    case MERLIN_CHAIN_ID:
      return `${MERLIN_ADDRESS_LINK}${address}`;

    case ARBITRUM_CHAIN_ID:
      return `${ARBISCAN_ADDRESS_LINK}${address}`;

    // HACK: using a good hack, since we had a lot of issues,
    // and all new deals have correctly set arbitrum chain id
    default:
      return `${GNOSISSCAN_ADDRESS_LINK}${address}`;
  }
};

export const getTransactionLinkByChainId = (chainId, txHash) => {
  switch (+chainId) {
    case XDAI_CHAIN_ID:
      return `${GNOSISSCAN_TX_LINK}${txHash}`;

    case ETHEREUM_CHAIN_ID:
      return `${ETHERSCAN_TX_LINK}${txHash}`;

    case BSC_CHAIN_ID:
      return `${BSCSCAN_TX_LINK}${txHash}`;

    case POLYGON_CHAIN_ID:
      return `${POLYGONSCAN_TX_LINK}${txHash}`;

    case AVALANCHE_CHAIN_ID:
      return `${SNOWTRACE_TX_LINK}${txHash}`;

    case BASE_CHAIN_ID:
      return `${BASE_TX_LINK}${txHash}`;

    case MERLIN_CHAIN_ID:
      return `${MERLIN_TX_LINK}${txHash}`;

    case ARBITRUM_CHAIN_ID:
      return `${ARBISCAN_TX_LINK}${txHash}`;

    // HACK: using a good hack, since we had a lot of issues,
    // and all new deals have correctly set arbitrum chain id
    default:
      return `${GNOSISSCAN_TX_LINK}${txHash}`;
  }
};

export const getAddressLinkByChainName = (chain, address) => {
  switch (chain) {
    case 'ethereum':
    case 'goerli':
      return `${ETHERSCAN_ADDRESS_LINK}${address}`;

    case 'xDai':
      return `${GNOSISSCAN_ADDRESS_LINK}${address}`;

    case 'bsc':
    case 'bsc-testnet':
      return `${BSCSCAN_ADDRESS_LINK}${address}`;

    case 'polygon':
    case 'polygon-testnet':
      return `${POLYGONSCAN_ADDRESS_LINK}${address}`;

    case 'avalanche':
    case 'avalanche-testnet':
      return `${SNOWTRACE_ADDRESS_LINK}${address}`;

    case 'base':
      return `${BASE_ADDRESS_LINK}${address}`;

    case 'merlin':
      return `${MERLIN_ADDRESS_LINK}${address}`;

    case 'arbitrum':
    case 'arbitrum-sepolia':
      return `${ARBISCAN_ADDRESS_LINK}${address}`;

    // HACK: using a good hack, since we had a lot of issues,
    // and all new deals have correctly set arbitrum chain id
    default:
      return `${GNOSISSCAN_ADDRESS_LINK}${address}`;
  }
};

export const getTransactionLinkByChainName = (chain, txHash) => {
  switch (chain) {
    case 'ethereum':
    case 'goerli':
      return `${ETHERSCAN_TX_LINK}${txHash}`;

    case 'xDai':
      return `${GNOSISSCAN_TX_LINK}${txHash}`;

    case 'bsc':
    case 'bsc-testnet':
      return `${BSCSCAN_TX_LINK}${txHash}`;

    case 'polygon':
    case 'polygon-testnet':
      return `${POLYGONSCAN_TX_LINK}${txHash}`;

    case 'avalanche':
    case 'avalanche-testnet':
      return `${SNOWTRACE_TX_LINK}${txHash}`;

    case 'base':
      return `${BASE_TX_LINK}${txHash}`;

    case 'merlin':
      return `${MERLIN_TX_LINK}${txHash}`;

    case 'arbitrum':
    case 'arbitrum-sepolia':
      return `${ARBISCAN_TX_LINK}${txHash}`;

    // HACK: using a good hack, since we had a lot of issues,
    // and all new deals have correctly set arbitrum chain id
    default:
      return `${GNOSISSCAN_TX_LINK}${txHash}`;
  }
};
