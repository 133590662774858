export const DealStatus = {
  Live: 1,
  Paused: 2,
  Closed: 3,
  Canceled: 4,
  Distributed: 5,
  None: 6,
  PledgeOpened: 7,
  PledgeClosed: 8,
  Hidden: 9,
};
