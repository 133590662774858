import { useQuery } from 'react-query';
import remote from '../../../remote';

function useRiskReportData(type, address) {
  const { data, isFetching } = useQuery({
    queryKey: ['risk-report', type, address],
    queryFn: () => remote.getRiskReportData(type, address),
  });

  return {
    data,
    isLoading: isFetching,
  };
}

export default useRiskReportData;
