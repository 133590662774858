import { useReducer } from 'react';

const parseFilterArray = (arr) => arr.reduce((acc, item) => ({ ...acc, [item]: false }), {}); // {Live: false, Paused: false}

function init(deals) {
  const rawBlockchain = [
    ...new Set(
      deals.map((deal) => deal.claimers?.map((claimer) => claimer?.name).filter(Boolean)).flat()
    ),
  ];

  const blockchain = parseFilterArray(rawBlockchain);

  return {
    search: '',
    blockchain,
    type: 'platform',
    isClaimable: false,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'updateSearch':
      return { ...state, search: action.payload };
    case 'updateBlockchain':
      return {
        ...state,
        blockchain: { ...state.blockchain, [action.payload.key]: action.payload.value },
      };
    case 'updateType':
      return { ...state, type: action.payload };
    case 'updateIsClaimable':
      return { ...state, isClaimable: action.payload };
    default:
      throw new Error();
  }
}

const useFilters = (deals) => {
  const [filters, dispatch] = useReducer(reducer, deals, init);

  const dispatchUpdateSearch = (search) => dispatch({ type: 'updateSearch', payload: search });
  const dispatchUpdateBlockchain = ({ key, value }) =>
    dispatch({ type: 'updateBlockchain', payload: { key, value } });
  const dispatchUpdateType = (type) => dispatch({ type: 'updateType', payload: type });
  const dispatchUpdateIsClaimable = (isClaimable) =>
    dispatch({ type: 'updateIsClaimable', payload: isClaimable });

  return {
    filters,
    actions: {
      dispatchUpdateSearch,
      dispatchUpdateBlockchain,
      dispatchUpdateType,
      dispatchUpdateIsClaimable,
    },
  };
};

export default useFilters;
