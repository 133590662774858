import React from 'react';
import remote from 'screens/AllDeals/remote';
import DealInfoTable from './DealInfoTable';
import AddressTable from './AddressTable';
import MiscInfo from './MiscInfo';
import NotesContainer from './NotesContainer';
import './index.scss';

const DealInfo = ({ dealId, isOldDeal }) => {
  const { data: dealInfo } = remote.useGetDealInfo(dealId, isOldDeal);

  if (!dealInfo) return null;

  return (
    <section className="deal-info-section">
      <h2 className="section-title">Deal info</h2>
      <DealInfoTable dealInfo={dealInfo} />

      <h2 className="section-title">Addresses</h2>
      <AddressTable dealInfo={dealInfo} />

      <h2 className="section-title">Misc info</h2>
      <MiscInfo dealInfo={dealInfo} />

      <NotesContainer dealInfo={dealInfo} />
    </section>
  );
};

export default DealInfo;
