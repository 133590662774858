import React from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

export const AdditionalPepInfoFields = [
  {
    id: 'relatedPepPoliticalPosition',
    label: 'I am a politically exposed person because (please state your political position):',
    required: {
      dependentFields: ['relatedPepRelationship'],
    },
  },
  {
    id: 'relatedPepRelationship',
    label:
      'I am a family member or close associate of a politically exposed person because (please describe the relationship):',
    required: {
      dependentFields: ['relatedPepPoliticalPosition'],
    },
    dependentFields: [
      'relatedPepDob',
      'relatedPepFullName',
      'relatedPepResidence',
      'relatedPepNationality',
      'relatedPepDocumentType',
      'relatedPepIssuingAuthority',
      'relatedPepCountryOfIssuance',
      'relatedPepPoliticalPositionInfo',
    ],
  },
  { id: 'relatedPepFullName', label: 'Full Name:' },
  {
    id: 'relatedPepDob',
    label: 'Date of Birth:',
    accessor: (user) =>
      user.pepInfo?.relatedPepDob
        ? moment(user.pepInfo?.relatedPepDob).format('DD MMM YYYY')
        : null,
    props: (values, id, onChange) => ({
      InputProps: {
        inputComponent: () => (
          <ReactDatePicker
            selected={values[id]}
            onChange={(date) => onChange({ target: { name: id, value: date } })}
            dateFormat="yyyy-MM-dd"
            showYearDropdown
            showMonthDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            maxDate={new Date()}
          />
        ),
      },
      InputLabelProps: { shrink: true },
    }),
  },
  { id: 'relatedPepResidence', label: 'Residence:' },
  { id: 'relatedPepNationality', label: 'Nationality:' },
  { id: 'relatedPepDocumentType', label: 'Type of Official Document:' },
  { id: 'relatedPepIssuingAuthority', label: 'Issuing Authority:' },
  { id: 'relatedPepCountryOfIssuance', label: 'Country of Issuance:' },
  { id: 'relatedPepPoliticalPositionInfo', label: 'Political Position:' },
];
