import React from 'react';
import { renderSafeValue } from 'utils';
import { AdditionalPepInfoFields } from 'features/userProfile/AdditionalInfo/components/PoliticallyExposedPerson/PepRelationshipInfo/constants';
import InfoContainer from '../../../../../components/InfoContainer';

const PepInfo = ({ user }) => {
  if (!user) return null;

  const pepInfo = AdditionalPepInfoFields.map((field) => ({
    label: field.label,
    value: field.accessor ? field.accessor(user) : user.pepInfo?.[field.id],
  }));

  return (
    <InfoContainer title="POLITICALLY EXPOSED PERSON">
      <ul className="item-list">
        {pepInfo.map((info) => (
          <li key={info.label} className="item">
            <p className="label">{info.label}</p>
            <p className="value">{renderSafeValue(info.value)}</p>
          </li>
        ))}
      </ul>
    </InfoContainer>
  );
};

export default PepInfo;
