import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import SvgIcon from 'components/svgIcon';
import {
  getPledgeAnalytics,
  updateDeal,
  updateRequiredChain,
  openPledge,
} from 'services/apiService';
import { getAddressLinkByChainId } from 'contracts/explorers';
import { ARBITRUM_CHAIN_ID } from 'constants/config';

import { addStringAmounts, thousandSeparator, roundNumberToDecimals } from 'utils/helpers';
import moment from 'moment';
import { setUserDeal } from 'store/actions';
import RoundedButton from 'components/button/rounded-button';
import { useNotifications } from 'hooks';
import { CSVLink } from 'react-csv';
import ChainList from 'contracts/json/ChainList.json';
import CustomSelect from 'components/select/custom-select';
import BasicAnalyticsPickerModal from 'features/deals/BasicAnalyticsPickerModal';

const PledgeTab = ({ deal, pledges }) => {
  const dispatch = useDispatch();
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const [selectedChain, setSelectedChain] = useState(deal?.requiredChain || 'Unset');
  const [pledgeAnalytics, setPledgeAnalytics] = useState([]);
  const [isPledgeAnalyticsModalOpen, setIsPledgeAnalyticsModalOpen] = useState(false);
  const pledgeAnalyticsRef = useRef();
  const [pledgeAnalyticsToExport, setPledgeAnalyticsToExport] = useState([]);

  useEffect(() => {
    (async () => {
      setPledgeAnalytics(await getPledgeAnalytics(deal.id));
    })();
  }, [deal]);

  const handleOpenPledge = async () => {
    try {
      await openPledge(deal.id);
      dispatch(
        setUserDeal({ ...deal, status: 'Pledge Opened', statusId: 7, dateCreated: new Date() })
      );
      showSuccessNotification('Pledge opened successfully');
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong');
    }
  };

  const closePledge = async () => {
    try {
      const response = await updateDeal(deal.id, { statusId: 8 });
      const changedData = JSON.parse(response.config.data);
      const newDeal = JSON.parse(JSON.stringify(deal));
      newDeal.statusId = changedData.statusId;
      newDeal.status = 'PledgeClosed';
      dispatch(setUserDeal(newDeal));
      showSuccessNotification('Pledge closed successfully');
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong');
    }
  };

  const onChangeSelect = (name, value) => {
    setSelectedChain(value);
  };

  const onConfirm = async () => {
    try {
      const chain = selectedChain === 'Unset' ? undefined : selectedChain;
      await updateRequiredChain({
        id: deal.id,
        chain,
      });

      const newDeal = JSON.parse(JSON.stringify(deal));
      newDeal.requiredChain = chain;
      dispatch(setUserDeal(newDeal));
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      showErrorNotification('Something went wrong.');
    }
  };

  const onAnalyticsDownload = (choosenFields) => {
    const { result } = pledgeAnalytics.reduce(
      (accum, item, i) => {
        let { choosenIndexes } = accum;

        if (i === 0) {
          choosenIndexes = choosenFields.map(({ value }) => item.indexOf(value));
        }

        return {
          choosenIndexes,
          result: [...accum.result, item.filter((_, i) => choosenIndexes.includes(i))],
        };
      },
      { choosenIndexes: [], result: [] }
    );
    setPledgeAnalyticsToExport(result);
    setTimeout(() => pledgeAnalyticsRef.current.link.click(), 0);
    setIsPledgeAnalyticsModalOpen(false);
  };

  return (
    <div className="content pledge-content">
      <BasicAnalyticsPickerModal
        open={isPledgeAnalyticsModalOpen}
        onClose={() => setIsPledgeAnalyticsModalOpen(false)}
        onOk={onAnalyticsDownload}
        options={pledgeAnalytics?.[0] || []}
      />
      <div className="content-row">
        <div className="col col-4 pledge-left transparent p-0">
          <div className="col col-12 pledge-control">
            <p>Pledge Control</p>
            <div className="pledge-control-content">
              <h1 className={deal.statusId === 7 ? 'active' : ''}>
                {deal.statusId === 7 ? 'OPEN' : 'CLOSED'}
              </h1>
              {[6, 7, 8].includes(deal.statusId) && (
                <div className="pledge-control-actions">
                  <RoundedButton
                    type="secondary"
                    onClick={handleOpenPledge}
                    disabled={deal.statusId === 7}
                  >
                    Open
                  </RoundedButton>
                  <RoundedButton
                    type="primary"
                    onClick={closePledge}
                    disabled={deal.status === 'PledgeClosed' || deal.status === 'None'}
                  >
                    Close
                  </RoundedButton>
                </div>
              )}
            </div>
          </div>
          <div className="col col-12 pledge-box">
            <div className="pledge-box-image">
              <SvgIcon name="iconLogoGreen" />
            </div>
            <div className="pledge-box-info">
              <p className="label">Total amount</p>
              {pledges && (
                <h3 className="value">
                  ${' '}
                  {thousandSeparator(
                    roundNumberToDecimals(
                      Number(
                        pledges
                          .map((p) => p.amount)
                          .reduce((a, b) => addStringAmounts(a, b, 6), '0.0')
                      ),
                      0
                    )
                  )}
                </h3>
              )}
            </div>
          </div>
          <div className="col col-12 pledge-box">
            <div className="pledge-box-image">
              <SvgIcon name="iconSumma" />
            </div>
            <div className="pledge-box-info">
              <p className="label">Total count</p>
              <h3 className="value">{pledges?.length}</h3>
            </div>
          </div>
          <div className="col col-12 pledge-box">
            <div className="pledge-box-image">
              <SvgIcon name="iconSumma" />
            </div>
            <div className="pledge-box-info">
              <p className="label">Chain select</p>
              <CustomSelect
                name="chain"
                value={selectedChain}
                items={[{ value: 'Unset' }, ...ChainList]}
                onSelect={onChangeSelect}
                absolute
              />
              <Box mt={1} display="flex" justifyContent="flex-end">
                <RoundedButton
                  onClick={onConfirm}
                  type="primary"
                  disabled={
                    deal.requiredChain === selectedChain ||
                    (!deal.requiredChain && selectedChain === 'Unset')
                  }
                >
                  Save
                </RoundedButton>
              </Box>
            </div>
          </div>
        </div>
        <div className="col col-8">
          <div className="pledge-transactions-top">
            <div className="pledge-transactions-title-holder">
              <h3>List of pledges</h3>
            </div>
            <div className="pledge-transactions-controls">
              <div className="export-btn">
                {pledgeAnalytics && (
                  <CSVLink
                    ref={pledgeAnalyticsRef}
                    data={pledgeAnalyticsToExport}
                    style={{ display: 'none' }}
                    filename={`pledges_${deal.name}.csv`}
                  >
                    Export basic analytics to CSV
                  </CSVLink>
                )}
                <RoundedButton onClick={() => setIsPledgeAnalyticsModalOpen(true)}>
                  Export
                </RoundedButton>
              </div>
            </div>
          </div>
          <hr />
          <div className="pledge-transactions-header">
            <div className="pledge-transactions-col">
              <p>Latest transactions</p>
            </div>
            <div className="pledge-transactions-col address-col">
              <p>Wallet</p>
            </div>
            <div className="pledge-transactions-col amount-col">
              <p>Amount</p>
            </div>
          </div>
          <div className="pledge-transactions">
            {pledges?.map((p) => (
              <div className="pledge-transaction-row" key={p.id.toString()}>
                <div className="pledge-transactions-col">
                  <p>{moment(p.updatedAt).format('D-MMMM-YYYY, HH:mm:ss')}</p>
                </div>
                <div className="pledge-transactions-col address-col">
                  <a
                    href={getAddressLinkByChainId(ARBITRUM_CHAIN_ID, p.wallet)}
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {p.wallet}
                  </a>
                </div>
                <div className="pledge-transactions-col amount-col">
                  <p>{roundNumberToDecimals(Number(p.amount), 0)} $</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PledgeTab;
