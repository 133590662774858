import React, { useState, useEffect } from 'react';
import RoundedButton from 'components/button/rounded-button';
import NumberInput from 'components/input/number-input';
import './index.scss';
import { roundNumberToDecimals } from 'utils/helpers';
import { Dialog } from '@material-ui/core';

const PledgeModal = ({ onOk, onCancel, fetchPledge, defaultValue, isOpen }) => {
  const [data, setData] = useState({
    amount: defaultValue ? roundNumberToDecimals(Number(defaultValue), 0) : '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const getErrorMessage = () => {
    if (errorMessage === 'min') return 'Min. = 250';
    return '';
  };

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async () => {
    if (Number(data.amount) >= 250 || Number(data.amount) === 0) {
      await onOk({ ...data });
      onCancel();
      await fetchPledge();
    }
  };

  useEffect(() => {
    if (Number(data.amount) < 250 && Number(data.amount) !== 0) {
      setErrorMessage('min');
    } else {
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <div className="bd-modal">
        <div className="bd-modal-inner">
          <div className="bd-modal-header">
            <div className="input-holder">
              <NumberInput
                label="Pledge amount:"
                name="amount"
                value={data.amount.toString()}
                onChange={onChangeData}
                error={getErrorMessage()}
                decimalNumber="0"
              />
            </div>
            <RoundedButton onClick={onCancel}>Cancel</RoundedButton>
            <RoundedButton type="secondary" onClick={onConfirm}>
              <div className="d-flex">Confirm</div>
            </RoundedButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PledgeModal;
