import React, { useState } from 'react';
import SellTransactions from 'features/analytics/SellTransactions';
import './index.scss';

const SellTransactionsTab = ({ deal }) => {
  const [txToAddEdit, setTxToAddEdit] = useState(null);

  return (
    <>
      <SellTransactions
        txToAddEdit={txToAddEdit}
        setTxToAddEdit={setTxToAddEdit}
        searchValue=""
        deal={deal}
      />
      <div
        className="sell-transactions__add-button"
        onClick={() => {
          setTxToAddEdit({ dealId: deal.id, disableDealSelect: true });
        }}
      >
        <span>Add sell transaction</span>
      </div>
    </>
  );
};

export default SellTransactionsTab;
