import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import './index.scss';
import InitialAnalytics from 'features/analytics/InitialAnalytics';
import DealAnalytics from 'features/analytics/DealAnalytics';

const Analytics = () => {
  const { params } = useRouteMatch();

  return (
    <>
      <div className="analytics-container">
        {params.dealId ? <DealAnalytics /> : <InitialAnalytics />}
      </div>
    </>
  );
};

export default Analytics;
