import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiGet, apiPut, baseUrl } from 'services/apiService';

const getDealRaiseStats = async (dealId) => {
  const response = await apiGet(`${baseUrl}/api/deal/${dealId}/raise-stats`);
  return response.data;
};

const useGetDealRaiseStats = (dealId, enabled) => {
  return useQuery(['deal', dealId, 'raise-stats'], () => getDealRaiseStats(dealId), {
    enabled: !!dealId && !!enabled,
    placeholderData: [],
  });
};

const updateDealRaiseStats = async (dealId, id, data) => {
  const response = await apiPut(`${baseUrl}/api/deal/admin/${dealId}/raise-stats`, {
    id,
    dealId: +dealId,
    round: data.round || null,
    tokenPrice: data.tokenPrice || null,
    tokensSold: data.tokensSold || null,
    vesting: data.vesting || null,
    amount: data.amount || null,
    sortField: data.sortField || null,
  });

  return response.data;
};

const useUpdateDealRaiseStats = (dealId) => {
  const queryClient = useQueryClient();

  return useMutation(({ id, data }) => updateDealRaiseStats(dealId, id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', dealId, 'raise-stats']);
    },
  });
};

export default { useGetDealRaiseStats, useUpdateDealRaiseStats };
