import SortableTable from 'components/sortable-table';
import React from 'react';
import './index.scss';
import { TableRow, TableCell } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { renderSafeValue } from 'utils';
import useUsersLegalColumns from './hooks/useUsersLegalColumns';

const UsersLegalTable = ({ users }) => {
  const columns = useUsersLegalColumns();

  const history = useHistory();

  const onNavigate = (wallet) => {
    history.push(`/users-legal/${wallet}`, {
      pathname: window.location.pathname,
    });
  };

  if (users.length === 0) return <div className="no-data-found">No users found</div>;

  return (
    <div className="all-deals-table">
      <SortableTable tableHead={columns} rows={users} type="users-legal" paginationItemsCount={50}>
        {(sortedRows) => {
          return sortedRows.map((row) => (
            <TableRow key={row.id} onClick={() => onNavigate(row.wallet)}>
              {columns.map(({ column, accessor }) => (
                <TableCell key={column}>{renderSafeValue(accessor(row))}</TableCell>
              ))}
            </TableRow>
          ));
        }}
      </SortableTable>
    </div>
  );
};

export default UsersLegalTable;
