import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateSystemPart } from 'store/actions';
import { SYSTEM_PARTS } from 'features/sidebar/LeftSideBar/utils';
import getParsedBatchScheduleRows from 'components/distributions/utils/getParsedBatchScheduleRows';
import getParsedHistoryRows from 'components/distributions/utils/getParsedHistoryRows';
import HistoryContent from './HistoryContent';
import remote from './remote';
import './index.scss';

const History = () => {
  const { data: batchSchedule } = remote.useGetSchedule();
  const { data: history } = remote.useGetHistory();

  const parsedBatchSchedule = getParsedBatchScheduleRows(batchSchedule);
  const parsedHistory = getParsedHistoryRows(history);

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(updateSystemPart(SYSTEM_PARTS.DISTRO_MANAGER));
  }, [dispatch]);

  return (
    <div className="history-page">
      {parsedBatchSchedule && parsedHistory && (
        <HistoryContent batchScheduleRows={parsedBatchSchedule} historyRows={parsedHistory} />
      )}
    </div>
  );
};

export default History;
