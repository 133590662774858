import { ethers } from 'ethers';

import {
  ETHEREUM_PROVIDER_URL,
  ETHEREUM_CHAIN_ID,
  XDAI_PROVIDER_URL,
  XDAI_CHAIN_ID,
  BSC_PROVIDER_URL,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
  POLYGON_PROVIDER_URL,
  AVALANCHE_CHAIN_ID,
  AVALANCHE_PROVIDER_URL,
  BASE_CHAIN_ID,
  BASE_PROVIDER_URL,
  MERLIN_CHAIN_ID,
  MERLIN_PROVIDER_URL,
  ARBITRUM_CHAIN_ID,
  ARBITRUM_PROVIDER_URL,
  ARBITRUM_WS_PROVIDER_URL,
} from 'constants/config';

export const xDaiProvider = new ethers.providers.StaticJsonRpcProvider(
  XDAI_PROVIDER_URL,
  XDAI_CHAIN_ID
);

export const ethereumProvider = new ethers.providers.StaticJsonRpcProvider(
  ETHEREUM_PROVIDER_URL,
  ETHEREUM_CHAIN_ID
);

export const bscProvider = new ethers.providers.StaticJsonRpcProvider(
  BSC_PROVIDER_URL,
  BSC_CHAIN_ID
);

export const avalancheProvider = new ethers.providers.StaticJsonRpcProvider(
  AVALANCHE_PROVIDER_URL,
  AVALANCHE_CHAIN_ID
);

export const baseProvider = new ethers.providers.StaticJsonRpcProvider(
  BASE_PROVIDER_URL,
  BASE_CHAIN_ID
);

export const merlinProvider = new ethers.providers.StaticJsonRpcProvider(
  MERLIN_PROVIDER_URL,
  MERLIN_CHAIN_ID
);

export const arbitrumProvider = new ethers.providers.StaticJsonRpcProvider(
  ARBITRUM_PROVIDER_URL,
  ARBITRUM_CHAIN_ID
);

export const arbitrumWsProvider = new ethers.providers.WebSocketProvider(
  ARBITRUM_WS_PROVIDER_URL,
  ARBITRUM_CHAIN_ID
);

export const polygonProvider = new ethers.providers.StaticJsonRpcProvider(
  POLYGON_PROVIDER_URL,
  POLYGON_CHAIN_ID
);

export const getProviderByChainId = (chainId) => {
  switch (+chainId) {
    case XDAI_CHAIN_ID:
      return xDaiProvider;

    case ETHEREUM_CHAIN_ID:
      return ethereumProvider;

    case BSC_CHAIN_ID:
      return bscProvider;

    case POLYGON_CHAIN_ID:
      return polygonProvider;

    case AVALANCHE_CHAIN_ID:
      return avalancheProvider;

    case BASE_CHAIN_ID:
      return baseProvider;

    case MERLIN_CHAIN_ID:
      return merlinProvider;

    case ARBITRUM_CHAIN_ID:
      return arbitrumProvider;

    default:
      throw new Error(`getProviderByChainId - unkown chain id ${chainId}`);
  }
};

export const getProviderByChainName = (chainName) => {
  switch (chainName) {
    case 'xDai':
      return xDaiProvider;

    case 'ethereum':
      return ethereumProvider;

    case 'bsc':
      return bscProvider;

    case 'polygon':
      return polygonProvider;

    case 'avalanche':
      return avalancheProvider;

    case 'arbitrum':
    case 'arbitrum-sepolia':
      return arbitrumProvider;

    case 'base':
      return baseProvider;

    case 'merlin':
      return merlinProvider;

    default:
      throw new Error(`getProviderByChainName - unkown chain id ${chainName}`);
  }
};
