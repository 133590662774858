import { sortDeals } from 'utils/helpers';
import MyDealsTable from 'features/deals/MyDealsTable';
import React, { useMemo, useState } from 'react';
import useDeals from '../hooks/useDeals';
import useFilters from '../hooks/useFilters';
import MyDealsHeader from '../MyDealsHeader';

const MyDealsContent = ({ userDeals, oldDeals }) => {
  const [sort, setSort] = useState(null);

  const { filters, actions } = useFilters([...userDeals, ...oldDeals]);
  const filteredDeals = useDeals(userDeals, oldDeals, filters);

  const sortedDeals = useMemo(() => {
    if (!sort) return sortDeals('datePublished', filteredDeals, '');
    return sortDeals(sort.column, filteredDeals, sort.direction, false, sort.isNumber);
  }, [sort, filteredDeals]);

  const getNextSortDirection = () => {
    if (sort?.direction === 'asc') {
      return 'desc';
    }
    if (sort?.direction === 'desc') {
      return '';
    }
    return 'asc';
  };

  const onSortDeals = (field, isNumber) => {
    const nextSortDirection = getNextSortDirection();
    if (!nextSortDirection) {
      setSort(null);
      return;
    }
    setSort({
      direction: nextSortDirection,
      column: field,
      isNumber,
    });
  };
  return (
    <>
      <MyDealsHeader deals={filteredDeals} filters={filters} actions={actions} />
      <div className="deals-content">
        <MyDealsTable
          sort={sort}
          onSort={onSortDeals}
          deals={sortedDeals}
          filters={filters}
          actions={actions}
          oldDeals={oldDeals}
        />
      </div>
    </>
  );
};

export default MyDealsContent;
