import React from 'react';
import SortableTable from 'components/sortable-table';
import TransferModalTableRows from './TransferModalTableRows';
import './index.scss';

const transferModalTableHead = [
  {
    column: 'chain',
    label: 'Chain',
    sort: (a, b) => (b.chain.trim().toLowerCase() > a.chain.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'total',
    label: 'Total',
    sort: (a, b) => (parseFloat(b.total) > parseFloat(a.total) ? 1 : -1),
  },
];

const TransferModalTable = ({ transfers }) => {
  return (
    <div className="transfer-modal-table">
      <SortableTable tableHead={transferModalTableHead} rows={transfers} type="transfer-modal">
        {(sortedRows) => <TransferModalTableRows rows={sortedRows} />}
      </SortableTable>
    </div>
  );
};

export default TransferModalTable;
