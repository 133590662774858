import React, { useState } from 'react';
import { Dialog, DialogContent, Divider } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import Whitelist from 'features/deals/AdminDealsTable/Whitelist';

const AddressEditDialog = ({ addresses, setAddresses, onSave, whitelistId }) => {
  const [isValid, setIsValid] = useState(false);

  if (!addresses) return null;
  return (
    <Dialog
      open={!!addresses}
      onClose={() => setAddresses(null)}
      classes={{ paper: 'whitelists-modal' }}
    >
      <div className="whitelists-modal__header">
        <h2>
          <SvgIcon name="iconDealsModalEdit" />
          <span>Edit</span>
        </h2>
        <div>
          <RoundedButton type="transparent" onClick={() => setAddresses(null)}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={onSave} disabled={!isValid}>
            Save
          </RoundedButton>
        </div>
      </div>
      <DialogContent>
        <Divider />
        <div className="deal-edit-modal__content whitelist-content">
          <Whitelist
            whitelistId={whitelistId}
            whitelist={addresses.map(({ address }) => ({ address, userCap: 0 }))}
            onlyBasicInfo
            onChange={(updatedWhitelist) => {
              setAddresses(updatedWhitelist);
            }}
            onValidityChange={setIsValid}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddressEditDialog;
