import { ethers } from 'ethers';
import { getProviderByChainId } from '../contracts/providers';
import { getTokenInfo } from '../contracts/erc20';
import ClaimerArtifact from '../contracts/abis/Claimer.json';

// eslint-disable-next-line import/prefer-default-export
export async function getOldDealClaimer(claimerApi, wallet, amount) {
  try {
    const provider = getProviderByChainId(claimerApi.chainId);
    const claimerContract = new ethers.Contract(claimerApi.address, ClaimerArtifact.abi, provider);

    const [claimable] = await Promise.all([
      claimerContract.getAddressAvailableClaimAmount(
        wallet,
        ethers.utils.parseUnits(amount, 6),
        claimerApi.tokenAddress
      ),
      claimerContract.getAddressClaimedAmount(wallet, claimerApi.tokenAddress),
    ]);

    const tokenInfo = await getTokenInfo(provider, claimerApi.tokenAddress);

    return {
      id: claimerApi.id,
      claimable,
      token: {
        ...tokenInfo,
        address: claimerApi.tokenAddress,
      },
      claims: claimerApi.claims,
      chainId: claimerApi.chainId,
      chainName: claimerApi.chainName,
      merkleRoot: claimerApi.root,
      tokenAddress: claimerApi.tokenAddress,
      address: claimerApi.address,
    };
  } catch (error) {
    console.log('getOldDealClaimer error', error);
    return null;
  }
}
