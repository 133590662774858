import React, { useState } from 'react';
import moment from 'moment';
import { formatChartPrice, thousandSeparatorRound } from 'utils';
import SvgIcon from 'components/svgIcon';
import MiscContainer from './MiscContainer';
import EditContactInfoModal from './EditContactInfoModal';
import EditDealStatusModal from './EditDealStatusModal';
import EditVestingStatusModal from './EditVestingStatusModal';

const DECIMAL_OFFEST = 1_000_000;

export const VESTING_STATUS = new Map([
  ['Vesting', 'Vesting'],
  ['Distributed', 'Distributed'],
]);

const MiscInfo = ({ dealInfo }) => {
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const openEditContactModal = () => setIsEditContactModalOpen(true);
  const closeEditContactModal = () => setIsEditContactModalOpen(false);

  const [isEditDealStatusModalOpen, setIsEditDealStatusModalOpen] = useState(false);
  const openEditDealStatusModal = () => setIsEditDealStatusModalOpen(true);
  const closeEditDealStatusModal = () => setIsEditDealStatusModalOpen(false);

  const [isEditVestingStatusModalOpen, setIsEditVestingStatusModalOpen] = useState(false);
  const openEditVestingStatusModal = () => setIsEditVestingStatusModalOpen(true);
  const closeEditVestingStatusModal = () => setIsEditVestingStatusModalOpen(false);

  if (!dealInfo) return null;

  return (
    <div className="misc-info">
      <MiscContainer
        title="Vesting"
        actionButton={
          <div className="edit-row" onClick={openEditVestingStatusModal}>
            <SvgIcon name="iconDealsEdit" />
          </div>
        }
      >
        <ul className="item-list">
          <li className="item">
            <p className="label">Vesting status</p>
            <p className="value">
              {VESTING_STATUS.has(dealInfo.vestingStatus?.name)
                ? VESTING_STATUS.get(dealInfo?.vestingStatus?.name)
                : '-'}
            </p>
          </li>
          <li className="item">
            <p className="label">Deal date</p>
            <p className="value">
              {dealInfo.dealDate ? moment(dealInfo.dealDate).format('YYYY-MM-DD') : '-'}
            </p>
          </li>
          <li className="item">
            <p className="label">TGE Date</p>
            <p className="value">
              {dealInfo.tgeDate ? moment(dealInfo.tgeDate).format('YYYY-MM-DD') : '-'}
            </p>
          </li>
          <li className="item">
            <p className="label">Vesting info</p>
            <p className="value">{dealInfo.vestingInfo ? dealInfo.vestingInfo : '-'}</p>
          </li>
          <li className="item">
            <p className="label">Claim type</p>
            <p className="value">{dealInfo.claimType}</p>
          </li>
        </ul>
      </MiscContainer>

      <MiscContainer
        title="Contact"
        actionButton={
          <div className="edit-row" onClick={openEditContactModal}>
            <SvgIcon name="iconDealsEdit" />
          </div>
        }
      >
        <ul className="item-list">
          <li className="item">
            <p className="label">Dealmaker</p>
            <p className="value">{dealInfo.dealmakerName ?? '-'}</p>
          </li>
          <li className="item">
            <p className="label">Contact person</p>
            <p className="value">{dealInfo.contactPerson ? dealInfo.contactPerson : '-'}</p>
          </li>
          <li className="item">
            <p className="label">Email</p>
            <p className="value">{dealInfo.contactEmail ? dealInfo.contactEmail : '-'}</p>
          </li>
          <li className="item">
            <p className="label">Telegram</p>
            <p className="value">{dealInfo.telegram ? dealInfo.telegram : '-'}</p>
          </li>
          <li className="item">
            <p className="label">Telegram group link</p>
            <p className="value">{dealInfo.telegramGroupLink ? dealInfo.telegramGroupLink : '-'}</p>
          </li>
        </ul>
      </MiscContainer>

      <MiscContainer
        title="Deal"
        actionButton={
          dealInfo.platformOrLegacy === 'Legacy' && (
            <div className="edit-row" onClick={openEditDealStatusModal}>
              <SvgIcon name="iconDealsEdit" />
            </div>
          )
        }
      >
        <ul className="item-list">
          <li className="item">
            <p className="label">Deal status</p>
            <p className="value">{dealInfo.dealStatus?.name ?? '-'}</p>
          </li>
          <li className="item">
            <p className="label">Platform or Legacy</p>
            <p className="value">{dealInfo.platformOrLegacy}</p>
          </li>
          <li className="item">
            <p className="label">Amount Invested</p>
            <p className="value">
              {dealInfo.amountInvested
                ? `$ ${thousandSeparatorRound(dealInfo.amountInvested / DECIMAL_OFFEST)}`
                : '-'}
            </p>
          </li>
          <li className="item">
            <p className="label">Token Price</p>
            <p className="value">
              {dealInfo.tokenPrice
                ? `$ ${formatChartPrice(dealInfo.tokenPrice / DECIMAL_OFFEST)}`
                : '-'}
            </p>
          </li>
          <li className="item">
            <p className="label">Fee (%)</p>
            <p className="value">{dealInfo.fee ? `${dealInfo.fee}%` : '-'}</p>
          </li>
        </ul>
      </MiscContainer>

      <EditContactInfoModal
        open={isEditContactModalOpen}
        onClose={closeEditContactModal}
        deal={{ ...dealInfo, id: dealInfo.dealId }}
        isOldDeal={dealInfo.platformOrLegacy === 'Legacy'}
      />
      <EditDealStatusModal
        open={isEditDealStatusModalOpen}
        onClose={closeEditDealStatusModal}
        deal={{ ...dealInfo, id: dealInfo.dealId }}
      />
      <EditVestingStatusModal
        open={isEditVestingStatusModalOpen}
        onClose={closeEditVestingStatusModal}
        deal={{ ...dealInfo, id: dealInfo.dealId }}
        isOldDeal={dealInfo.platformOrLegacy === 'Legacy'}
      />
    </div>
  );
};

export default MiscInfo;
