import React from 'react';
import SvgIcon from 'components/svgIcon';
import './index.scss';

const RiskFactor = ({ riskFactor }) => {
  if (!riskFactor) return null;
  return (
    <div className={`risk-factor risk-factor--${riskFactor.toLowerCase()}`}>
      <span className="deal-status-icon">
        <SvgIcon name="dot" />
      </span>
      <span className="deal__field-status__name">{riskFactor}</span>
    </div>
  );
};

export default RiskFactor;
