import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MyDealsTabs from 'screens/MyDeals/MyDealsTabs';
import { usePagination } from 'hooks';
import DealEditRow from '../UserDealsTable/DealEditRow';
import DealRow from '../UserDealsTable/DealRow';
import OldDealRow from '../UserDealsTable/OldDealRow';

const MyDealsTable = ({ sort, onSort, deals, filters, actions, oldDeals }) => {
  const globalReducer = useSelector((state) => state.global);
  const { activeDeal } = globalReducer;
  const { Pagination, paginatedItems, resetPage } = usePagination(deals, 50);

  useEffect(() => {
    resetPage();
  }, [resetPage, filters.type]);

  const getSortArrow = (val) => {
    if (!sort || val !== sort.column || sort.direction === '') return null;
    if (sort.direction === 'desc') return <span style={{ color: '#e9504f' }}>&nbsp;&uarr;</span>;
    if (sort.direction === 'asc') return <span style={{ color: '#e9504f' }}>&nbsp;&darr;</span>;
    return null;
  };

  return (
    <div className="deals-table">
      <MyDealsTabs filters={filters} actions={actions} showLegacyDeals={oldDeals.length > 0} />
      <div className="deals-table-content">
        {(filters.type === 'platform' || filters.type === 'archive') && (
          <>
            <div className="deals-table-header d-flex">
              <div className="deal__field  deal__field-large deal__field-avatar vertical-center" />
              <div
                className="deal__field  deal__field-large deal__field-name vertical-center"
                onClick={() => onSort('name')}
              >
                PROJECT
                {getSortArrow('name')}
              </div>
              <div
                className="deal__field  deal__field-large deal__field-status vertical-center"
                onClick={() => onSort('status')}
              >
                STATUS
                {getSortArrow('status')}
              </div>
              <div
                className="deal__field  deal__field-large deal__field-raised-amount vertical-center"
                onClick={() => onSort('raisedAmount', true)}
              >
                FILLED
                {getSortArrow('raisedAmount')}
              </div>
              <div
                className="deal__field  deal__field-large deal__field-size vertical-center"
                onClick={() => onSort('dealSize', true)}
              >
                DEAL SIZE
                {getSortArrow('dealSize')}
              </div>
              <div
                className="deal__field  deal__field-large deal__field-contribution vertical-center"
                onClick={() => onSort('contributedAmount', true)}
              >
                <span>MY CONTRIBUTION{getSortArrow('contributedAmount')}</span>
              </div>
              <div className="deal_field-actions deal_field-actions-large" />
            </div>
            <div>
              {paginatedItems.map((deal) => (
                <div
                  key={deal.id}
                  className={`deals-table-content__row ${
                    activeDeal && activeDeal.id === deal.id
                      ? 'deals-table-content__row--active'
                      : ''
                  }`}
                >
                  {activeDeal && activeDeal.id === deal.id ? (
                    <DealEditRow deal={deal} />
                  ) : (
                    <DealRow deal={deal} isMyDeals />
                  )}
                </div>
              ))}
            </div>
          </>
        )}
        {(filters.type === 'old' || filters.type === 'old-archive') && (
          <>
            <div className="deals-table-header old-deal-table-header d-flex">
              <div className="deal__field  deal__field-large deal__field-avatar vertical-center" />
              <div className="deal__field  deal__field-large deal__field-name vertical-center">
                PROJECT
              </div>
              <div
                className="deal__field  deal__field-large deal__field-model vertical-center row"
                onClick={() => onSort('unlimitedTimestamp', true)}
              >
                RAISE MODEL
                {getSortArrow('unlimitedTimestamp')}
              </div>

              <div className="deal__field  deal__field-large deal__field-action vertical-center" />
            </div>
            <div>
              {paginatedItems.map((deal) => {
                return (
                  <div
                    key={deal.id}
                    className={`deals-table-content__row ${
                      activeDeal && activeDeal.id === deal.id
                        ? 'deals-table-content__row--active'
                        : ''
                    }`}
                  >
                    <OldDealRow deal={deal} />
                  </div>
                );
              })}
            </div>
          </>
        )}
        <Pagination />
      </div>
    </div>
  );
};

export default MyDealsTable;
