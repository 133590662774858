import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotifications } from 'hooks';
import VestingGenerator from './VestingGenerator';
import VestingTable from './VestingTable';
import ClaimInfo from './ClaimInfo';
import remote from './remote';
import './index.scss';

const units = {
  YEAR: 'years',
  MONTH: 'months',
  WEEK: 'weeks',
  DAY: 'days',
};

const VestingTab = ({
  deal,
  readOnly,
  vestingClaimInfoModalOpen,
  toggleVestingClaimInfoModal,
}) => {
  const [vesting, setVesting] = useState(null);
  const vestingData = remote.useGetVestingData(deal.id);
  const upsertVestingItems = remote.useUpsertVesting(deal.id);
  const deleteVestingItems = remote.useDeleteVesting(deal.id);
  const [successNotification, errorNotification] = useNotifications();

  const [isDeleteVestingItemsModalOpen, setIsDeleteVestingItemsModalOpen] = useState(false);
  const openDeleteVestingItemsModal = () => setIsDeleteVestingItemsModalOpen(true);
  const closeDeleteVestingItemsModal = () => setIsDeleteVestingItemsModalOpen(false);

  const setInitialVestingState = useCallback(() => {
    setVesting({
      tgeDate: new Date(),
      cliffLength: 1,
      cliffUnit: units.MONTH,
      vestingDurationLength: 1,
      vestingDurationUnit: units.MONTH,
      frequencyLength: 1,
      frequencyUnit: units.MONTH,
      calendar: [],
    });
  }, []);

  useEffect(() => {
    if (!readOnly) {
      setInitialVestingState();
    }
  }, [readOnly, setInitialVestingState]);

  const isDirty = useMemo(() => {
    if (readOnly || !vesting) return false;
    return (
      !(new Date(vesting.tgeDate).getTime() === new Date(vestingData.data?.tgeDate).getTime()) ||
      Object.entries(vesting).some(
        ([key, value]) =>
          !['calendar', 'tgeDate'].includes(key) &&
          vestingData.data &&
          vestingData.data[key] !== value
      ) ||
      vesting.calendar.length !== vestingData.data?.calendar.length ||
      vesting.calendar.some(
        (item) =>
          !vestingData.data.calendar.some(
            ({ date, percentage }) =>
              new Date(item.date).getTime() === new Date(date).getTime() &&
              item.percentage === percentage
          )
      )
    );
  }, [vesting, vestingData.data, readOnly]);

  const dismissChanges = useCallback(
    () =>
      setVesting((prev) => ({
        ...prev,
        ...vestingData.data,
        tgeDate: vestingData.data.tgeDate ? new Date(vestingData.data.tgeDate) : prev.tgeDate,
        calendar: vestingData.data.calendar.map((data) => ({
          ...data,
          date: new Date(data.date),
          amount: deal.expectedTokens && (deal.expectedTokens * (+data.percentage / 100)) / 1000000,
        })),
      })),
    [deal.expectedTokens, vestingData.data]
  );

  const onDismissClick = () => {
    if (isDirty) {
      dismissChanges();
    } else {
      openDeleteVestingItemsModal();
    }
  };

  const onVestingItemsDelete = async () => {
    try {
      await deleteVestingItems.mutateAsync();
      setInitialVestingState();
      successNotification('Vesting items deleted!');
      closeDeleteVestingItemsModal();
    } catch (err) {
      errorNotification('Error occured!');
    }
  };

  useEffect(() => {
    if (vestingData.data && vestingData.data.calendar.length) {
      dismissChanges();
    }
  }, [vestingData.data, dismissChanges]);

  const handleSave = async () => {
    await upsertVestingItems.mutateAsync({
      ...vesting,
      calendar: vesting.calendar.map((c) => ({
        ...c,
        amount: c.amount.toString().replace(/,/g, ''),
      })),
    });
  };

  if (!vestingData.data) return <h1>Loading...</h1>;

  return (
    <div className="vesting-tab">
      <div className="content">
        <div className="content-row">
          <ClaimInfo
            deal={deal}
            vestingClaimInfoModalOpen={vestingClaimInfoModalOpen}
            toggleVestingClaimInfoModal={toggleVestingClaimInfoModal}
          />
        </div>
      </div>

      {!readOnly && (
        <VestingGenerator
          vesting={vesting}
          setVesting={setVesting}
          units={units}
          expectedTokens={deal.expectedTokens}
        />
      )}

      <VestingTable
        vesting={vesting}
        setVesting={setVesting}
        expectedTokens={deal.expectedTokens}
        handleSave={handleSave}
        handleDismiss={onDismissClick}
        isDirty={isDirty}
        readOnly={readOnly}
        deleteVestingItems={deleteVestingItems}
        onVestingItemsDelete={onVestingItemsDelete}
        isDeleteVestingItemsModalOpen={isDeleteVestingItemsModalOpen}
        closeDeleteVestingItemsModal={closeDeleteVestingItemsModal}
      />
    </div>
  );
};

export default VestingTab;
