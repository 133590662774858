export const PepExplaination = `
A Politically Exposed Person (PEP) is any individual who currently holds, or has held in the past 12 months, a prominent public function in a member state or a third country. This includes immediate family members (see definition below) and known close associates (see definition below) of the politically exposed person. (This definition aligns with Article 3, Sections 9-11 of EU Directive 2015/849/EC from 20.05.2015 and the Croatian Law on Anti-Money Laundering and Terrorist Financing (NN. 108/2017, 39/2019)):
	1.	Heads of state, heads of government, ministers, deputy ministers or state secretaries, and assistant ministers.
	2.	Elected members of legislative bodies.
	3.	Members of governing bodies of political parties.
	4.	Judges of supreme or constitutional courts or other senior judicial officials whose decisions, except in exceptional cases, are not subject to appeal.
	5.	Judges of audit courts.
	6.	Members of central bank boards.
	7.	Ambassadors, charges d'affaires, and high-ranking officers of the armed forces.
	8.	Members of administrative and supervisory boards of legal entities owned or majority-owned by the state, or individuals performing equivalent functions.
	9.	Directors, deputy directors, board members, and persons performing equivalent functions in an international organization.
	10.	Mayors, municipal chiefs, county prefects, and their deputies elected under the law governing local elections in the Republic of Croatia.

These public functions should not be interpreted to include lower and mid-level officials.

Immediate Family Members of Politically Exposed Persons include:
	1.	A spouse or partner (common-law partner, life partner, or informal life partner) of a politically exposed person.
	2.	Children and their spouses or partners (common-law, life partners, or informal life partners) of a politically exposed person.
	3.	Parents of a politically exposed person.

Close Associates of Politically Exposed Persons include:
	1.	An individual known to share joint ownership of a legal entity or arrangement or to have other close business relations with the politically exposed person.
	2.	An individual who is the sole beneficial owner of a legal entity or arrangement known to have been established for the benefit of the politically exposed person.`;
