const allDealsTableHead = [
  {
    column: 'dealName',
    label: 'Name',
    sort: (a, b) => (b.dealName.trim().toLowerCase() > a.dealName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'ticker',
    label: 'Ticker',
    sort: (a, b) => (b.ticker.trim().toLowerCase() > a.ticker.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'dealStatus',
    label: 'Deal Status',
    sort: (a, b) =>
      b.dealStatus.trim().toLowerCase() > a.dealStatus.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'batchesReceived',
    label: 'Batch',
    sort: (a, b) => (parseInt(b.batchesReceived, 10) > parseInt(a.batchesReceived, 10) ? 1 : -1),
  },
  {
    column: 'tokensReceived',
    label: 'Tokens Received',
    sort: (a, b) => (parseFloat(b.tokensReceived) > parseFloat(a.tokensReceived) ? 1 : -1),
  },
  {
    column: 'tokensExpected',
    label: 'Tokens Expected',
    sort: (a, b) => (parseFloat(b.tokensExpected) > parseFloat(a.tokensExpected) ? 1 : -1),
  },
  {
    column: 'tokensReceivedPercentage',
    label: 'Tokens Received (%)',
    sort: (a, b) =>
      parseFloat(b.tokensReceivedPercentage) > parseFloat(a.tokensReceivedPercentage) ? 1 : -1,
  },
  {
    column: 'blockchain',
    label: 'Blockchain',
    sort: (a, b) =>
      b.blockchain.trim().toLowerCase() > a.blockchain.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'claimerAddress',
    label: 'Claimer Address',
    sort: (a, b) =>
      b.claimerAddress.trim().toLowerCase() > a.claimerAddress.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'dateOfLastDistribution',
    label: 'Date of Last Distribution',
    sort: (a, b) => {
      if (!a.dateOfLastDistribution) {
        return 1;
      }
      if (!b.dateOfLastDistribution) {
        return -1;
      }
      return new Date(b.dateOfLastDistribution) > new Date(a.dateOfLastDistribution) ? 1 : -1;
    },
    defaultSortField: true,
  },
];

export default { allDealsTableHead };
