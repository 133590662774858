import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserDeal } from 'store/actions';
import { updateDeal } from 'services/apiService';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';

const BasicMetricsModal = ({ open, onClose, deal }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    ticker: deal.ticker,
    tokenType: deal.tokenType,
    tge: deal.tge,
    hardCapSymbol: deal.hardCapSymbol || '',
    hardCap: deal.hardCap || '',
    initialCirculatingSupply: deal.initCircSupply || '',
    initialMarketCap: deal.initMarketCap || '',
    totalSupply: deal.totalySupply || '',
    fullyDilutedMarketCap: deal.fullyDilutedMarketCap || '',
  });
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    const newData = {
      ticker: data.ticker || '',
      tokenType: data.tokenType || '',
      tge: data.tge || '',
      hardCap: data.hardCap || null,
      hardCapSymbol: data.hardCapSymbol || null,
      initCircSupply: data.initialCirculatingSupply || null,
      initMarketCap: data.initialMarketCap || null,
      totalySupply: data.totalSupply || null,
      fullyDilutedMarketCap: data.fullyDilutedMarketCap || null,
    };
    try {
      const response = await updateDeal(deal.id, { ...newData });
      const changedData = JSON.parse(response.config.data);
      const newDeal = JSON.parse(JSON.stringify(deal));
      newDeal.ticker = changedData.ticker;
      newDeal.tokenType = changedData.tokenType;
      newDeal.tge = changedData.tge;
      newDeal.hardCap = changedData.hardCap;
      newDeal.hardCapSymbol = changedData.hardCapSymbol;
      newDeal.initCircSupply = changedData.initCircSupply;
      newDeal.initMarketCap = changedData.initMarketCap;
      newDeal.totalySupply = changedData.totalySupply;
      newDeal.fullyDilutedMarketCap = changedData.fullyDilutedMarketCap;
      dispatch(setUserDeal(newDeal));
      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal basic-metrics-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Basic Metrics</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" buttonType="submit" form="basic-metrics-form">
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="basic-metrics-form" onSubmit={onConfirm}>
        <div className="col col-left">
          <div className="input-holder">
            <label>Ticker:</label>
            <input name="ticker" value={data.ticker} onChange={onChangeData} />
          </div>
          <div className="input-holder">
            <label>Token Type:</label>
            <input name="tokenType" value={data.tokenType} onChange={onChangeData} />
          </div>
          <div className="input-holder">
            <label>TGE:</label>
            <input name="tge" value={data.tge} onChange={onChangeData} />
          </div>
          <div className="input-holder input-holder-flex">
            <div className="input-holder-col symbol-col">
              <label>Total Raise Symbol:</label>
              <input name="hardCapSymbol" value={data.hardCapSymbol} onChange={onChangeData} />
            </div>
            <div className="input-holder-col amount-col">
              <label>Total Raise:</label>
              <input name="hardCap" value={data.hardCap} onChange={onChangeData} />
            </div>
          </div>
        </div>
        <div className="col col-right">
          <div className="input-holder">
            <label>Initial Circulating Supply:</label>
            <input
              name="initialCirculatingSupply"
              value={data.initialCirculatingSupply}
              onChange={onChangeData}
            />
          </div>
          <div className="input-holder">
            <label>Initial Market Cap:</label>
            <input name="initialMarketCap" value={data.initialMarketCap} onChange={onChangeData} />
          </div>
          <div className="input-holder">
            <label>Total Supply:</label>
            <input name="totalSupply" value={data.totalSupply} onChange={onChangeData} />
          </div>
          <div className="input-holder">
            <label>Fully Diluted Market Cap:</label>
            <input
              name="fullyDilutedMarketCap"
              value={data.fullyDilutedMarketCap}
              onChange={onChangeData}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default BasicMetricsModal;
