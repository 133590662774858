export const OMNI_DEAL_ADDRESS = '0x028d1a69cC246eae5248bF5cf59e12dF4a0004B6';
export const OMNI_TOKEN_ADDRESS = '0xaBc6790673a60b8A7f588450f59D2d256b1aeF7F';
export const OMNI_FIRST_CLAIMER_ADDRESS = '0x9046f5Db2Dd1F8aDf15868308A15b6F69BD2Bc03';
export const OMNI_FIRST_CLAIMERS = [
  '0x2C0d00da4Cc0df7303baf92447a04d76171a9383',
  '0xB31a371ED6b52E1871ae6EB6ffb99b8B4FA04CED',
  '0x6a025C8E763b667800BCff5c439c3F7d4aCa157E',
  '0xEA53d4445f98341A211c108b26608872eb659a57',
  '0xEA29a51b283eA6C0d3872a5c3dF4ddE3cba94128',
  '0x0598D2c365A5661032a91Da03ED74E298ceEA67b',
  '0x195670d7a2B73aCe3249507D4DA0d602793192F5',
  '0x848b8767F339ab093EE06a2c6A00f694D744b06b',
  '0xADC3546Ea680E21Afa7D5d45dCd03F2f8957241A',
  '0x99D218Ad1eC627E786C50c5FFE6732410B8C78fd',
  '0xB047e5845cE7c26C915A5b4861d990f61B77e4a4',
  '0x19775E1817BC7a1e41060F80E0d00EabAE2959A7',
  '0x3b39e987A0259e74bC5656bfDB30AE6B9aFfBc08',
  '0xe04dC0DE2494173bA631bE8B1d5d36E41d9Dd28B',
  '0x822eAe753f03be87b41382E1b429e22a99A43330',
  '0xD9947cDDBaF0F552d8A7bDC2Bd70cEDCCe48d7F5',
  '0x0EDa0e3C15cc49C0e5b1af3897ACB7825a4a54C9',
  '0x737D1bE798Efa2278FB46807b2ea3ec36397f5da',
  '0x20481b79a4F03b624D214d23aDf5bF5f33bEB4aA',
  '0x4dF82D4dE3B3C6dEafa6dc282B80e5029C63293b',
  '0x573267A8c5d5b3965FA778fa4de1B5f676073F92',
  '0xe8252d84C71cc260F7b6d0258963B6cC075D2dA6',
  '0x343EDB5Cf738f945D938048EcaF7eAAfB9c4F6BB',
  '0x3fb9a34E8b2D6B4F1b2519e8b7D210Ef6B2b80b2',
  '0x5A42Fef1c1067FB73b2B68a509C194e0E01b261f',
  '0xC6fb253f699077082440d1d712f082F3a55E02bD',
  '0x1C37a70c998c8D8a5D8dbB7eD6d4a470B4681fc7',
  '0x53b5c15d0423859Fd5DaceB167c984F5Ea3EE59d',
  '0x89df2dF851a8d97afEd88A492c1496cfC534A172',
  '0xa43589Fc8f89f02AB33f3a96De1601624f7Ee06c',
  '0x024336Ae03AB34351DE0D0e52B04648a3A00FDFC',
  '0xA6A2654eeD2C6871fCCdc718e8E6F3a3Ef32aa01',
  '0x1bBb39a0eF5Ee31eEe11e39C861f2Ef5723c3C88',
  '0x57dFD0C4ADF205e4DC89Db246e16aF0060244FC8',
  '0x636fa6ebD900953E1e0841e275502f36dd8A2C49',
];

// c.id, c.deal_id, b2.chain_id, c.address, b1.timestamp, b1.number, c.token_address
export const makeOmniClaimer = () => {
  return {
    id: 1001,
    dealId: 18,
    chainId: 56,
    address: OMNI_FIRST_CLAIMER_ADDRESS,
    timestamp: 1629300283,
    number: 10140111,
    tokenAddress: OMNI_TOKEN_ADDRESS,
  };
};
