import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import './index.scss';

const Tabs = ({ selectTab, selectedTab }) => {
  return (
    <div className="tabs">
      <RoundedButton
        className={`tab-btn ${selectedTab === 'custom-claims' ? 'selected' : ''}`}
        onClick={() => selectTab('custom-claims')}
      >
        <span>Custom Claims</span>
      </RoundedButton>
      <RoundedButton
        className={`tab-btn ${selectedTab === 'history' ? 'selected' : ''}`}
        onClick={() => selectTab('history')}
      >
        <span>History</span>
      </RoundedButton>
    </div>
  );
};

export default Tabs;
