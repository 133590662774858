import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import NumberFormat from 'react-number-format';
import { formatAddress } from 'utils';

const RoleManagementContract = ({ contractRoles }) => {
    return (
        <>
            <h2 className='contract-table-title'>Contract Roles:</h2>
            <TableContainer classes={{ root: 'users-table' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ROLE NAME</TableCell>
                            <TableCell>MAX CLOSE AMOUNT</TableCell>
                            <TableCell>CREATED AT</TableCell>
                            <TableCell>DEAL FACTORY ADDRESS</TableCell>
                            <TableCell>TX HASH</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contractRoles.map(({ id, roleName, maxCloseAmount, createdAt, dealFactoryAddress, txHash }, index) => (
                            <TableRow key={`${id}-${index}`}>
                                <TableCell>{roleName}</TableCell>
                                <TableCell>
                                    <NumberFormat
                                        value={Number(maxCloseAmount / 1000000).toFixed(0)}
                                        thousandSeparator
                                        displayType="text"
                                        prefix="$"
                                    />
                                </TableCell>
                                <TableCell>{moment(createdAt).format('DD-MMM-YYYY')}</TableCell>
                                <TableCell>{dealFactoryAddress}</TableCell>
                                <TableCell>
                                    <a href={`https://gnosisscan.io/tx/${txHash}`} target="_blank" rel="noopener noreferrer">
                                        {formatAddress(txHash)}
                                    </a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <h2 className='contract-table-title'>Contract Users:</h2>
            <TableContainer classes={{ root: 'users-table' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>NAME</TableCell>
                            <TableCell>WALLET</TableCell>
                            <TableCell>ROLE NAME</TableCell>
                            <TableCell>MAX CLOSE AMOUNT</TableCell>
                            <TableCell>CREATED AT</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contractRoles.map(({ id, roleName, maxCloseAmount, AssignedDealFactoryRole }) => (
                            AssignedDealFactoryRole?.map(({ createdAt, user }, index) => (
                                <TableRow key={`${id}-${index}`}>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.wallet}</TableCell>
                                    <TableCell>{roleName || 'N/A'}</TableCell>
                                    <TableCell>
                                        <NumberFormat
                                            value={Number(maxCloseAmount / 1000000).toFixed(0)}
                                            thousandSeparator
                                            displayType="text"
                                            prefix="$"
                                        />
                                    </TableCell>
                                    <TableCell>{moment(createdAt).format('DD-MMM-YYYY')}</TableCell>
                                </TableRow>
                            ))
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default RoleManagementContract;
