import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addDealSellTransaction,
  deleteDealSellTransaction,
  getDealSellTransactions,
  updateDealSellTransaction,
} from 'services/apiService';

const useGetSellTransactions = (walletAddress) => {
  return useQuery(['sell-transactions', walletAddress], getDealSellTransactions);
};

const useAddTx = (walletAddress) => {
  const queryClient = useQueryClient();

  return useMutation((transaction) => addDealSellTransaction(transaction), {
    onSuccess: () => {
      queryClient.invalidateQueries(['userPortfolio', walletAddress]);
      queryClient.invalidateQueries(['sell-transactions', walletAddress]);
      queryClient.invalidateQueries(['dealAnalytics', walletAddress]);
    },
  });
};

const useUpdateTx = (walletAddress) => {
  const queryClient = useQueryClient();

  return useMutation((transaction) => updateDealSellTransaction(transaction), {
    onSuccess: () => {
      queryClient.invalidateQueries(['userPortfolio', walletAddress]);
      queryClient.invalidateQueries(['sell-transactions', walletAddress]);
      queryClient.invalidateQueries(['dealAnalytics', walletAddress]);
    },
  });
};

const useDeleteTx = (walletAddress) => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteDealSellTransaction(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['userPortfolio', walletAddress]);
      queryClient.invalidateQueries(['sell-transactions', walletAddress]);
      queryClient.invalidateQueries(['dealAnalytics', walletAddress]);
    },
  });
};

export default { useGetSellTransactions, useAddTx, useUpdateTx, useDeleteTx };
