import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import remote from 'screens/AllDeals/remote';
import { useNotifications } from 'hooks';

const DeleteNoteModal = ({ open, onClose, deal, noteId }) => {
  const deleteNote = remote.useDeleteNote(deal.id);
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const onConfirm = async () => {
    try {
      await deleteNote.mutateAsync({ noteId });
      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog
      open={open}
      className="close-deal-modal unlock-lockup-modal"
      id="close-deal-modal"
      onClose={onClose}
    >
      <div className="close-deal-modal-header d-flex">
        <div className="close-deal-modal-header__left d-flex vertical-center">
          <div className="deal-name vertical-center">
            <div>
              <span>Are you sure you want to delete note?</span>
            </div>
          </div>
        </div>
        <div className="close-deal-modal-header__right vertical-center">
          <RoundedButton type="primary" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton onClick={onConfirm}>
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteNoteModal;
