import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addAccountInfoAnimation, subUsdtBalance, addUsdtBalance } from 'store/actions';
import { arbitrumWsProvider } from 'contracts/providers';
import { USDT_ADDRESS } from 'constants/index';
import { getTransferEventFilter, parseTransferLog } from '../contracts/erc20';

function useUsdtTransferSubscriber() {
  const globalReducer = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const { walletAddress } = authReducer;
  const { isInitialLoadFinished } = globalReducer;

  useEffect(() => {
    if (!isInitialLoadFinished) return () => null;

    const usdtTransferFromWalletFilter = getTransferEventFilter(USDT_ADDRESS, walletAddress, null);
    const usdtTransferToWalletFilter = getTransferEventFilter(USDT_ADDRESS, null, walletAddress);

    const usdtTransferFromWalletHandler = async (rawLog) => {
      const log = parseTransferLog(rawLog);

      const accountInfoAnimation = {
        fields: ['usdtBalance'],
      };

      dispatch(addAccountInfoAnimation(accountInfoAnimation));
      dispatch(subUsdtBalance(log.args.value));
    };

    const usdtTransferToWalletHandler = async (rawLog) => {
      const log = parseTransferLog(rawLog);

      const accountInfoAnimation = {
        fields: ['usdtBalance'],
      };

      dispatch(addAccountInfoAnimation(accountInfoAnimation));
      dispatch(addUsdtBalance(log.args.value));
    };

    arbitrumWsProvider.on(usdtTransferFromWalletFilter, usdtTransferFromWalletHandler);
    arbitrumWsProvider.on(usdtTransferToWalletFilter, usdtTransferToWalletHandler);

    return () => {
      arbitrumWsProvider.off(usdtTransferFromWalletFilter, usdtTransferFromWalletHandler);
      arbitrumWsProvider.off(usdtTransferToWalletFilter, usdtTransferToWalletHandler);
    };
  }, [isInitialLoadFinished, walletAddress, dispatch]);
}

export default useUsdtTransferSubscriber;
