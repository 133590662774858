import React, { useMemo, useState } from 'react';
import SortableTable from 'components/sortable-table';
import EditAthModal from './EditAthModal';
import AnalyticTableRows from './AnalyticTableRows';
import './index.scss';

const AnalyticList = ({ analyticDeals, searchValue, type }) => {
  const [isEditAthModalOpen, setIsEditAthModalOpen] = useState(false);
  const closeEditAthModal = () => setIsEditAthModalOpen(false);

  const [selectedDealId, setSelectedDealId] = useState(null);
  const [selectedIsOldDeal, setSelectedIsOldDeal] = useState(false);
  const [initAthPrice, setInitAthPrice] = useState(null);
  const onDealEditClick = (e, dealId, initPrice, isOldDeal) => {
    e.stopPropagation();
    setSelectedDealId(dealId);
    setInitAthPrice(initPrice);
    setIsEditAthModalOpen(true);
    setSelectedIsOldDeal(isOldDeal);
  };

  const searchedAnalyticDeals = useMemo(() => {
    let deals = [...analyticDeals];
    if (searchValue !== '') {
      deals = deals.filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()));
    }
    return deals;
  }, [analyticDeals, searchValue]);

  const tableHead = [
    {
      column: 'name',
      label: 'PROJECT',
      sort: (a, b) => (b.name.trim().toLowerCase() > a.name.trim().toLowerCase() ? 1 : -1),
    },
    { column: 'price', label: 'OUR PRICE' },
    { column: 'currentPrice', label: 'CURRENT PRICE' },
    {
      column: 'currentRoi',
      label: 'CURRENT ROI',
    },
    {
      column: 'athPrice',
      label: 'ATH PRICE',
    },
    {
      column: 'athRoi',
      label: 'ATH ROI',
      sort: (a, b) => (b.athRoi > a.athRoi ? 1 : -1),
      defaultSortField: true,
    },
    { column: 'numberOfContributors', label: 'NO. PARTICIPANTS' },
    {
      column: 'filled',
      label: 'TOTAL RAISED',
      sort: (a, b) => (parseInt(b.filled, 10) > parseInt(a.filled, 10) ? 1 : -1),
    },
    {
      column: 'dateClosed',
      label: ' DATE ENDED',
      sort: (a, b) => (new Date(b.dateClosed) > new Date(a.dateClosed) ? 1 : -1),
    },
  ];

  return (
    <>
      <div className="analytics-content__table">
        <SortableTable tableHead={tableHead} rows={searchedAnalyticDeals} type={type}>
          {(sortedRows) => (
            <AnalyticTableRows analyticDeals={sortedRows} onDealEditClick={onDealEditClick} />
          )}
        </SortableTable>
      </div>
      <EditAthModal
        isModalOpen={isEditAthModalOpen}
        closeModal={closeEditAthModal}
        dealId={selectedDealId}
        dealInitialAth={initAthPrice}
        isOldDeal={selectedIsOldDeal}
      />
    </>
  );
};

export default AnalyticList;
