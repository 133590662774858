import React, { useEffect } from 'react';
import SvgIcon from 'components/svgIcon';
import './index.scss';

function SearchInput({
  searchValue,
  updateSearchValue,
  showInput,
  setShowInput,
  placeholder = 'Search deals by name',
}) {
  const handleChange = (e) => {
    updateSearchValue(e.target.value);
  };

  const toggleInput = () => {
    if (window.innerWidth < 767) {
      setShowInput(!showInput);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
    });
  }, [setShowInput]);

  return (
    <div className={`search-input${showInput ? ' active' : ''}`}>
      <div className="input-icon-wrapper vertical-center" onClick={toggleInput}>
        <SvgIcon name="search" />
      </div>
      <input type="text" value={searchValue} placeholder={placeholder} onChange={handleChange} />
    </div>
  );
}

export default SearchInput;
