import { useQuery } from 'react-query';
import { apiGet, baseUrl } from 'services/apiService';

const getHistory = async () => {
  const response = await apiGet(`${baseUrl}/api/distribution/history`);
  return response.data;
};

const useGetHistory = () => {
  return useQuery(['distribution', 'history'], getHistory);
};

const getSchedule = async () => {
  const response = await apiGet(`${baseUrl}/api/distribution/schedule`);
  return response.data;
};

const useGetSchedule = () => {
  return useQuery(['distribution', 'schedule'], getSchedule);
};

export default {
  useGetHistory,
  useGetSchedule,
};
