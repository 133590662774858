import { ethers } from 'ethers';
import DealFactoryArtifact from './abis/DealFactory.json';
import { DEAL_FACTORY_ADDRESS } from '../constants/config';
import { arbitrumProvider } from './providers';

export const unassignRoleFromUser = (address) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  const factoryContract = new ethers.Contract(
    DEAL_FACTORY_ADDRESS,
    DealFactoryArtifact.abi,
    injectedProvider.getSigner()
  );

  return factoryContract.unassignRoleFromUser(address);
};

export const assignRoleToUser = (address, role) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  const factoryContract = new ethers.Contract(
    DEAL_FACTORY_ADDRESS,
    DealFactoryArtifact.abi,
    injectedProvider.getSigner()
  );

  return factoryContract.assignRoleToUser(address, ethers.utils.formatBytes32String(role));
};

export const getRoleRights = async (role) => {
  const roleContract = new ethers.Contract(
    DEAL_FACTORY_ADDRESS,
    DealFactoryArtifact.abi,
    arbitrumProvider
  );
  const [amountToCloseOnDeal6Decimals] = await roleContract.getRoleRights(
    ethers.utils.formatBytes32String(role)
  );
  return {
    amount: amountToCloseOnDeal6Decimals.toNumber() / 1000000,
  };
};

export const createRole = (closeAmount, role) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  const factoryContract = new ethers.Contract(
    DEAL_FACTORY_ADDRESS,
    DealFactoryArtifact.abi,
    injectedProvider.getSigner()
  );
  return factoryContract.createRole(ethers.utils.formatBytes32String(role), {
    amountToCloseOnDeal6Decimals: ethers.utils.parseUnits(closeAmount, 6),
    hasRole: true,
  });
};

export const updateRole = (closeAmount, role) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  const factoryContract = new ethers.Contract(
    DEAL_FACTORY_ADDRESS,
    DealFactoryArtifact.abi,
    injectedProvider.getSigner()
  );
  return factoryContract.updateRole(ethers.utils.formatBytes32String(role), {
    amountToCloseOnDeal6Decimals: ethers.utils.parseUnits(closeAmount, 6),
    hasRole: true,
  });
};

export const deleteRole = (role) => {
  const injectedProvider = new ethers.providers.Web3Provider(window.ethereum);
  const factoryContract = new ethers.Contract(
    DEAL_FACTORY_ADDRESS,
    DealFactoryArtifact.abi,
    injectedProvider.getSigner()
  );
  return factoryContract.deleteRole(ethers.utils.formatBytes32String(role));
};
