import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import InvoiceTabFilter from 'features/deals/InvoiceTabFilter';
import { getInvoicesByDealId, getInvoicePdfForAdmin } from 'services/apiService';
import SvgIcon from 'components/svgIcon';
import moment from 'moment';
import './index.scss';
import { formatAddress, formatFiatPrice } from 'utils';
import TxHashLink from 'components/TxHashLink';
import PdfHandler from 'components/PdfHandler';

const InvoiceTab = ({ deal }) => {
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [invoicesData, setInvoicesData] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [emailList, setEmailList] = useState([]);
  const [walletList, setWalletList] = useState([]);

  useEffect(() => {
    (async () => {
      const invoices = await getInvoicesByDealId(deal.id);
      setInvoicesData(invoices);
      const wallets = invoices.map((invoice) => ({
        label: `${formatAddress(invoice.wallet)}`,
        value: invoice.wallet,
      }));
      setWalletList(
        Object.values(wallets.reduce((accum, item) => ({ ...accum, [item.value]: item }), {}))
      );
      const emails = invoices.map((invoice) => ({
        label: invoice.email,
        value: invoice.email,
      }));
      setEmailList(
        Object.values(emails.reduce((accum, item) => ({ ...accum, [item.value]: item }), {}))
      );
    })();
  }, [deal.id]);

  const handleChange = (invoice) => (e, isExpanded) => {
    setExpandedId(isExpanded ? `${invoice.id}${invoice.totalTransactionAmount}` : null);
  };

  if (!invoicesData.length) return <></>;

  return (
    <div className="invoice-list">
      <div className="header-column">
        <div className="header-labels">
          <label>#</label>
          <label>Invoice #</label>
          <label>Invoice date</label>
          <label>Due date</label>
          <label>Fee</label>
          <label>Total amount</label>
        </div>
        <InvoiceTabFilter
          invoices={invoicesData}
          walletList={walletList}
          emailList={emailList}
          onChange={setFilteredInvoices}
        />
      </div>
      {filteredInvoices.length &&
        filteredInvoices.map((invoice, i) => (
          <Accordion
            key={`${invoice.id}${invoice.totalTransactionAmount}`}
            classes={{ root: 'accordion', rounded: 'accordion__rounded' }}
            expanded={`${invoice.id}${invoice.totalTransactionAmount}` === expandedId}
            onChange={handleChange(invoice)}
          >
            <AccordionSummary
              classes={{
                content: 'accordion-summary__content',
                root: 'accordion-summary',
                focusVisible: 'accordion-summary__focused',
              }}
              expandIcon={<SvgIcon name="arrowDown" />}
            >
              <div className="invoice-row">
                <div className="invoice-labels">
                  <label>{i + 1}.</label>
                  <label>
                    {invoice.year}/{invoice.number}
                  </label>
                  <label>{moment(invoice.date).format('D-MMMM-YYYY')}</label>
                  <label>{moment(deal.dateClosed).format('D-MMMM-YYYY')}</label>
                  <label>{formatFiatPrice(invoice.totalFeeUsd, true)} USDT</label>
                  <label>{formatFiatPrice(invoice.totalTransactionAmount, true)} USDT</label>
                </div>
                <div className="icons-container">
                  <div className="icon-download">
                    <PdfHandler
                      id={invoice.id}
                      getInvoicePdf={(invoiceId) =>
                        getInvoicePdfForAdmin(invoiceId, invoice.isStorno)
                      }
                    />
                  </div>
                  <PdfHandler
                    id={invoice.id}
                    getInvoicePdf={(invoiceId) =>
                      getInvoicePdfForAdmin(invoiceId, invoice.isStorno)
                    }
                    open
                  />
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails classes={{ root: 'accordion-details' }}>
              <Divider />
              <div className="invoice-content">
                <Table className="invoice-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>TX hash</TableCell>
                      <TableCell>TX Date</TableCell>
                      <TableCell>Contribution Fee</TableCell>
                      <TableCell>Contribution Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice.items.map((invoiceItem, index) => (
                      <TableRow key={invoiceItem.position}>
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>
                          <TxHashLink chainId={deal.chainId} hash={invoiceItem.txHash} />
                        </TableCell>
                        <TableCell>
                          {moment(invoiceItem.dateOfContribution).format('D-MMMM-YYYY')}
                        </TableCell>
                        <TableCell>{formatFiatPrice(invoiceItem.feeUsd, true)} USDT</TableCell>
                        <TableCell>
                          {formatFiatPrice(invoiceItem.transactionAmount, true)} USDT
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

export default InvoiceTab;
