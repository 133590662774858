const customClaimsTableHead = [
  {
    column: 'dealName',
    label: 'Deal Name',
    sort: (a, b) => (b.dealName.trim().toLowerCase() > a.dealName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'dealStatus',
    label: 'Deal Status',
    sort: (a, b) => (b.dealStatus > a.dealStatus ? 1 : -1),
  },
  {
    column: 'chainName',
    label: 'Chain Name',
    sort: (a, b) => (b.chainName.trim().toLowerCase() > a.chainName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'lastRunDate',
    label: 'Last Run',
    sort: (a, b) => {
      if (!a.lastRunDate) {
        return 1;
      }
      if (!b.lastRunDate) {
        return -1;
      }
      return new Date(b.lastRunDate) > new Date(a.lastRunDate) ? 1 : -1;
    },
    defaultSortField: true,
  },
  {
    column: 'nextRun',
    label: 'Next Run',
    sort: (a, b) => (new Date(b.nextRun) > new Date(a.nextRun) ? 1 : -1),
  },
  {
    column: 'receiveWallet',
    label: 'Receive Wallet',
    sort: (a, b) =>
      b.receiveWallet.trim().toLowerCase() > a.receiveWallet.trim().toLowerCase() ? 1 : -1,
  },
  {
    column: 'claimContractAddress',
    label: 'Claim Contract',
    sort: (a, b) =>
      b.claimContractAddress.trim().toLowerCase() > a.claimContractAddress.trim().toLowerCase()
        ? 1
        : -1,
  },
  {
    column: 'tokenName',
    label: 'Token',
    sort: (a, b) => (b.tokenName.trim().toLowerCase() > a.tokenName.trim().toLowerCase() ? 1 : -1),
  },
  {
    column: 'isActive',
    label: 'Job status',
  },
  {},
];

export default { customClaimsTableHead };
