import { apiGet, apiPost, baseUrl } from 'services/apiService';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const getAllSeeds = async () => {
  const response = await apiGet(`${baseUrl}/api/seed`);
  return response.data;
};

const useGetAllSeeds = (select) => {
  return useQuery(['seed'], getAllSeeds, { select });
};

const getAllSeedTypes = async () => {
  const response = await apiGet(`${baseUrl}/api/seed/types`);
  return response.data;
};

const useGetAllSeedTypes = () => {
  return useQuery(['seedTypes'], getAllSeedTypes);
};

const selectUnlockedSeeds = (seeds) => seeds.filter((seed) => Boolean(seed.unlocked));
const selectUnlockedSeedsForSelect = (seeds) =>
  seeds.filter((seed) => Boolean(seed.unlocked)).map((seed) => ({ value: seed.name, ...seed }));

const useGetUnlockedSeeds = () => useGetAllSeeds(selectUnlockedSeeds);
const useGetUnlockedSeedsForSelect = () => useGetAllSeeds(selectUnlockedSeedsForSelect);

const postDecryptSeed = async (seedId, password) => {
  const response = await apiPost(`${baseUrl}/api/seed-decrypt`, { seedId, password });
  return response.data;
};

const usePostDecryptSeed = () => {
  const queryClient = useQueryClient();

  return useMutation(({ seedId, password }) => postDecryptSeed(seedId, password), {
    onSuccess: () => {
      queryClient.invalidateQueries(['seed']);
    },
  });
};

const createNewSeed = async (name, hash, seedTypeId) => {
  const response = await apiPost(`${baseUrl}/api/seed`, { name, hash, seedTypeId });
  return response.data;
};

const usePostNewSeed = () => {
  const queryClient = useQueryClient();

  return useMutation(({ name, hash, seedTypeId }) => createNewSeed(name, hash, seedTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['seed']);
    },
  });
};

const createNewWallet = async (name, seedId, path) => {
  const response = await apiPost(`${baseUrl}/api/wallet`, { name, seedId, path });
  return response.data;
};

const usePostNewWallet = () => {
  const queryClient = useQueryClient();

  return useMutation(({ name, seedId, path }) => createNewWallet(name, seedId, path), {
    onSuccess: () => {
      queryClient.invalidateQueries(['wallet']);
    },
  });
};

const getSeedWallets = async (pathModel, seedId) => {
  const response = await apiGet(`${baseUrl}/api/seed/${seedId}/wallet?pathModel=${pathModel}`);
  return response.data;
};

const useGetSeedWallets = (pathModel, seedId, select) => {
  return useQuery(['seed', seedId, 'wallet', pathModel], () => getSeedWallets(pathModel, seedId), {
    enabled: !!pathModel && !!seedId,
    placeholderData: [],
    select,
  });
};

const selectSeedWalletsForSelect = (wallets) =>
  wallets.map((wallet) => ({ id: wallet.address, name: wallet.path, value: wallet.path }));

const useGetSeedWalletsForSelect = (pathModel, seedId) =>
  useGetSeedWallets(pathModel, seedId, selectSeedWalletsForSelect);

export default {
  useGetAllSeeds,
  useGetAllSeedTypes,
  useGetUnlockedSeeds,
  useGetUnlockedSeedsForSelect,
  usePostDecryptSeed,
  usePostNewSeed,
  usePostNewWallet,
  useGetSeedWallets,
  useGetSeedWalletsForSelect,
};
