import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import ClaimDealModal from 'features/deals/ClaimDealModal';
import { archiveDeal, getInvoicePdf, unarchiveDeal } from 'services/apiService';
import { updateGlobal, removeAnimateDeal, setUserDeal, updateDeal } from 'store/actions';
import { Box, Button } from '@material-ui/core';
import { roundNumberToDecimals, getDealStatusClass } from 'utils/helpers';
import Tooltip from 'components/tooltip';
import { useNotifications } from 'hooks';
import { getAddressLinkByChainId } from 'contracts/explorers';
import { getDealInfo } from 'contracts/dealContract';
import { getCurrentPhaseInfo } from 'contracts/dealV2Contract';
import { getProviderByChainId } from 'contracts/providers';
import ClaimCheckModal from 'features/deals/ClaimCheckModal';
import './index.scss';
import IconButton from 'components/button/icon-button';
import Countdown from 'components/countdown';
import DealModelTooltip from 'components/deal-model-tooltip';
import PdfHandler from 'components/PdfHandler';
import DealInfoDesktop from '../DealInfoUser/DealInfoDesktop';

const DealRow = ({ deal, isMyDeals }) => {
  const dispatch = useDispatch();
  const [claimModalOpened, setClaimModalOpened] = useState(false);
  const [claimCheckModalOpened, setClaimCheckModalOpened] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const { accountInfo, profileWallets, walletAddress } = authReducer;
  const { animateDeals } = globalReducer;
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  useEffect(() => {
    if (deal.phaseEndTimestamp && deal.statusId === 1) {
      const msLeft = deal.phaseEndTimestamp.toString() * 1000 - new Date().getTime() + 500;
      const timer = setTimeout(async () => {
        const provider = getProviderByChainId(deal.chainId);
        const currentPhaseInfo = await getCurrentPhaseInfo(provider, deal, walletAddress);
        const chainInfo = await getDealInfo(deal.address, provider);
        dispatch(
          updateDeal({
            id: deal.id,
            ...currentPhaseInfo,
            ...chainInfo,
            userWhitelisted: !!currentPhaseInfo.personalCap,
          })
        );
        return () => clearTimeout(timer);
      }, msLeft);
    }
  }, [deal, dispatch, walletAddress]);

  const getNoContributeMessage = () => {
    if (deal.statusId !== 1) {
      return '';
    }

    const { usdtBalance, relockMessage, userAccessLevel } = accountInfo;

    if (relockMessage && relockMessage.length && userAccessLevel === -1) {
      return relockMessage.join(' ');
    }

    if (deal.raisedAmount === deal.dealSize) {
      return 'Deal allocation is filled.';
    }

    if (deal.minAccessLevel === 4 && !deal.userWhitelisted) {
      return 'Deal is for whitelisted only.';
    }

    if (deal.minAccessLevel !== 4 && deal.minAccessLevel > userAccessLevel) {
      return `Min access level is ${deal.minAccessLevel} and user has ${userAccessLevel}.`;
    }

    if (
      deal.requiredChain &&
      !profileWallets.some(({ chain }) => chain === deal.requiredChain) &&
      deal.status === 'opened'
    ) {
      return `You need to add ${deal.requiredChain} wallet in order to contribute to this deal.`;
    }

    if (deal.status === 'paused') {
      return 'Deal is paused.';
    }
    if (parseFloat(deal.minContribution) > parseFloat(usdtBalance)) {
      return `User has ${usdtBalance} USDT and min contribution is ${deal.minContribution}. Personal max is ${deal.personalCap}`;
    }
    if (
      parseFloat(deal.personalCap) === 0 &&
      deal.allocationModel === 'Personal Cap' &&
      parseFloat(deal.personalCap) <= parseFloat(deal.contributedAmount)
    ) {
      return `Personal Max ${deal.personalCap} is already contributed.`;
    }
    if (parseFloat(deal.personalCap) < parseFloat(deal.minContribution)) {
      return `Personal Max ${deal.personalCap} is less than min contribution ${deal.minContribution}`;
    }

    return '';
  };

  const myDealsClass = isMyDeals ? 'deal__field-large' : '';

  const onToggleClaimModal = () => {
    setClaimModalOpened(!claimModalOpened);
  };

  const onContribute = () => {
    dispatch(updateGlobal({ activeDeal: deal }));
  };

  const toggleDrop = () => {
    setShowDropdown(!showDropdown);
  };

  const onManage = (e) => {
    e.persist();
    const disabledTags = ['input', 'button'];
    if (disabledTags.includes(e.target.tagName.toLowerCase())) return;
    toggleDrop();
    setTimeout(() => {
      const closest = e.target.closest('.deal-row-top');
      if (!closest) return;

      const headerOffset = 135;
      const elementPosition = e.target.closest('.deal-row-top').getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }, 200);
  };

  const getAnimateClass = (val) => {
    if (
      animateDeals &&
      animateDeals.fields &&
      deal.address === animateDeals.address &&
      (animateDeals?.fields.includes(val) || animateDeals?.fields.includes('allFields'))
    ) {
      return 'animate';
    }
    return val;
  };

  useEffect(() => {
    if (animateDeals && animateDeals.fields && deal.address === animateDeals.address) {
      setTimeout(() => {
        dispatch(removeAnimateDeal());
      }, 3000);
    }
  }, [animateDeals, deal, dispatch]);

  const handleArchive = async () => {
    try {
      await archiveDeal(deal.id);
      const updatedDeal = JSON.parse(JSON.stringify(deal));
      updatedDeal.isArchived = true;
      dispatch(setUserDeal(updatedDeal));
      showSuccessNotification('Deal archived successfully');
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  const onToggleClaimCheckModal = () => {
    setClaimCheckModalOpened(!claimCheckModalOpened);
  };

  const onClaim = (e) => {
    e.stopPropagation();
    if (deal.claimUrl) window.open(deal.claimUrl);
    else setClaimModalOpened(true);
  };

  const handleUnarchive = async () => {
    try {
      await unarchiveDeal(deal.id);
      const updatedDeal = JSON.parse(JSON.stringify(deal));
      updatedDeal.isArchived = false;
      dispatch(setUserDeal(updatedDeal));
      showSuccessNotification('Deal unarchived successfully');
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  const isClaimDisabled =
    !deal.claimers.some(({ claimableAmounts }) =>
      claimableAmounts?.some(({ claimAmount }) => +claimAmount > 0)
    ) && !+deal.dealClaimInfo?.claimAmount > 0;

  const isContributeDisabled =
    Number(accountInfo.usdtBalance) < Number(deal.minContribution) ||
    Number(deal.personalCap) < Number(deal.minContribution) ||
    (deal.minAccessLevel === 4 && !deal.userWhitelisted) ||
    (deal.minAccessLevel !== 4 && deal.minAccessLevel > accountInfo.userAccessLevel) ||
    (!profileWallets.some(({ chain }) => chain === deal.requiredChain) && deal.requiredChain);

  return (
    <>
      {claimModalOpened && (
        <ClaimDealModal open={claimModalOpened} deal={deal} onClose={onToggleClaimModal} />
      )}
      {claimCheckModalOpened && (
        <ClaimCheckModal
          open={claimCheckModalOpened}
          deal={deal}
          onClose={onToggleClaimCheckModal}
        />
      )}
      <div className="deal-holder d-flex full-width justify-space-between deal-row-wrapper">
        <div className="deal-row-top" onClick={onManage}>
          <div className={`deal__field ${myDealsClass} deal__field-avatar vertical-center`}>
            <a
              href={getAddressLinkByChainId(deal.chainId, deal.address)}
              target="_blank"
              rel="noopener noreferrer"
              className="deal-link"
            >
              <RoundedAvatar src={deal.imageUrl} />
            </a>
          </div>
          <div className={`deal__field ${myDealsClass} deal__field-name vertical-center`}>
            <div>
              <span>{deal.name}</span>
              <CustomProgressBar
                total={Number(deal.dealSize)}
                value={Number(Number(deal.raisedAmount).toFixed(0))}
              />
            </div>
          </div>
          <div
            className={`deal__field ${myDealsClass} deal__field-status deal__field-status--${getDealStatusClass(
              deal.status
            )} vertical-center ${getAnimateClass('status')}`}
          >
            <span className="deal__field-status__icon">
              <SvgIcon name="dot" />
            </span>
            <span className="deal__field-status__name">
              {deal.status === 'opened'
                ? deal.raisedAmount === deal.dealSize
                  ? 'filled'
                  : 'live'
                : deal.status === 'distributed'
                ? 'completed'
                : deal.status}
            </span>
          </div>
          <div
            className={`deal__field ${myDealsClass} deal__field-raised-amount vertical-center ${getAnimateClass(
              'raisedAmount'
            )}`}
          >
            <NumberFormat
              value={Number(deal.raisedAmount).toFixed(0)}
              thousandSeparator
              displayType="text"
              prefix="$"
            />
          </div>
          <div
            className={`deal__field ${myDealsClass} deal__field-size vertical-center ${getAnimateClass(
              'dealSize'
            )}`}
          >
            <NumberFormat
              value={Number(deal.dealSize).toFixed(0)}
              thousandSeparator
              displayType="text"
              prefix="$"
            />
          </div>
          {!isMyDeals && (
            <>
              <div
                className={`deal__field ${myDealsClass} deal__field-model vertical-center ${getAnimateClass(
                  'whitelistEndTimestamp'
                )}`}
              >
                <div className="left">
                  <span>{deal.minAccessLevelLabel ? deal.minAccessLevelLabel : '-'}</span>
                  {deal.phaseEndTimestamp && deal.statusId === 1 && (
                    <div className="absolute-div">
                      <Countdown timestamp={deal.phaseEndTimestamp} />
                      {deal.phases?.[+deal.currentPhaseIndex + 1] && (
                        <Tooltip
                          title={
                            <DealModelTooltip
                              deal={deal}
                              title={`Next phase access level: ${
                                deal.phases[+deal.currentPhaseIndex + 1]?.minAccessLevel
                              }`}
                              timestamp={deal.phaseEndTimestamp}
                            />
                          }
                        >
                          <div className="tooltip-btn">
                            <SvgIcon name="help" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`deal__field ${myDealsClass} deal__field-model vertical-center ${getAnimateClass(
                  'allocationModel'
                )}`}
              >
                <div className="left">
                  <span>{deal.allocationModel ? deal.allocationModel : '-'}</span>
                  {deal.phaseEndTimestamp && deal.statusId === 1 && (
                    <div className="absolute-div">
                      <Countdown timestamp={deal.phaseEndTimestamp} />
                      {deal.phases?.[+deal.currentPhaseIndex + 1] && (
                        <Tooltip
                          title={
                            <DealModelTooltip
                              deal={deal}
                              title={`${deal.phases[+deal.currentPhaseIndex + 1]?.model}`}
                              timestamp={deal.phaseEndTimestamp}
                            />
                          }
                        >
                          <div className="tooltip-btn">
                            <SvgIcon name="help" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`deal__field ${myDealsClass}  deal__field-maximum vertical-center ${getAnimateClass(
                  'personalCap'
                )}`}
              >
                <NumberFormat
                  value={Number(roundNumberToDecimals(deal.personalCap, 0))}
                  thousandSeparator
                  displayType="text"
                  prefix="$"
                />
              </div>
            </>
          )}
          <div
            className={`deal__field ${myDealsClass} deal__field-contribution vertical-center ${getAnimateClass(
              'contributedAmount'
            )}`}
          >
            <span>
              <NumberFormat
                value={Number(roundNumberToDecimals(deal.contributedAmount, 0))}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </span>
          </div>
          <div className="deal_field-actions">
            <Tooltip title={getNoContributeMessage()}>
              <div
                className={`deal__field ${myDealsClass} deal__field-action vertical-center ${getAnimateClass(
                  'minContribution'
                )}`}
              >
                {deal.status === 'opened' ? (
                  <RoundedButton
                    type="primary"
                    disabled={isContributeDisabled}
                    onClick={onContribute}
                  >
                    Contribute
                  </RoundedButton>
                ) : (
                  <Tooltip
                    title={
                      <div>
                        <p className="bold-label">Claim method:</p>
                        <h6 style={{ fontSize: 12 }}>{deal.claimMethod ?? '-'}</h6>
                        {deal?.claimUrl && (
                          <div style={{ marginTop: '0.5rem' }}>
                            <p className="bold-label">Claim URL:</p>
                            <a
                              style={{ display: 'block' }}
                              href={deal.claimUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {deal.claimUrl || <strong>-</strong>}
                            </a>
                          </div>
                        )}
                      </div>
                    }
                    interactive
                  >
                    <div>
                      <Button
                        className={`claim-button claim-btn--secondary ${
                          isClaimDisabled ? 'claim-btn--disabled' : ''
                        }`}
                        type="button"
                        disabled={isClaimDisabled}
                        onClick={(e) => onClaim(e)}
                      >
                        Claim
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </div>
            </Tooltip>
            {isMyDeals && (
              <>
                <Box display="flex" width={60} justifyContent="space-between">
                  <span>
                    {deal.claimers.some(({ claims }) => claims?.length) ? (
                      <IconButton icon="iconTokensClaimed" onClick={onToggleClaimCheckModal} />
                    ) : (
                      <IconButton icon="iconTokensClaimedDisabled" />
                    )}
                  </span>
                  <Tooltip title={deal.isArchived ? 'Unarchive' : 'Archive'}>
                    <div>
                      {deal.isArchived ? (
                        <IconButton icon="iconVisibility" onClick={handleUnarchive} />
                      ) : (
                        <IconButton icon="iconVisibilityOffGray" onClick={handleArchive} />
                      )}
                    </div>
                  </Tooltip>
                  <Tooltip title="Download Invoice">
                    <div>
                      {deal.invoiceId && (
                        <PdfHandler id={deal.invoiceId} getInvoicePdf={getInvoicePdf} />
                      )}
                    </div>
                  </Tooltip>
                </Box>
              </>
            )}
            <div>
              <SvgIcon name="iconChevronDown" />
            </div>
          </div>
        </div>

        <div className={`deal-info-mobile${showDropdown ? ' show' : ''}`}>
          <div className="deal-info-mobile-box">
            <p>Status</p>
            <h3 className="capitalize">{deal.status === 'opened' ? 'live' : deal.status}</h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>Filled</p>
            <h3>
              <NumberFormat
                value={Number(deal.raisedAmount).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>Deal size</p>
            <h3>
              <NumberFormat
                value={Number(deal.dealSize).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>Raise model</p>
            <span>{deal.allocationModel}</span>
            {deal.phaseEndTimestamp && deal.statusId === 1 && (
              <div className="absolute-div">
                <Countdown timestamp={deal.phaseEndTimestamp} />
                {deal.phases?.[+deal.currentPhaseIndex + 1] && (
                  <Tooltip
                    title={
                      <DealModelTooltip
                        deal={deal}
                        title={`${deal.phases[+deal.currentPhaseIndex + 1]?.model}`}
                        timestamp={deal.phaseEndTimestamp}
                      />
                    }
                  >
                    <div className="tooltip-btn">
                      <SvgIcon name="help" />
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          <div className="deal-info-mobile-box">
            <p>Personal Max</p>
            <h3>
              <NumberFormat
                value={Number(deal.personalCap).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>My contribution</p>
            <h3>
              <NumberFormat
                value={Number(deal.contributedAmount).toFixed(0)}
                thousandSeparator
                displayType="text"
                prefix="$"
              />
            </h3>
          </div>
          <div className="deal-info-mobile-actions">
            {deal.status === 'opened' ? (
              <Tooltip title={getNoContributeMessage()}>
                <div>
                  <RoundedButton
                    type="primary"
                    disabled={isContributeDisabled}
                    onClick={onContribute}
                  >
                    Contribute
                  </RoundedButton>
                </div>
              </Tooltip>
            ) : (
              <div>
                <RoundedButton
                  type="secondary"
                  disabled={isClaimDisabled}
                  onClick={() => onToggleClaimModal()}
                >
                  Claim
                </RoundedButton>
              </div>
            )}
          </div>
        </div>

        <DealInfoDesktop deal={deal} showDropdown={showDropdown} />
      </div>
    </>
  );
};

DealRow.propTypes = {
  deal: PropTypes.shape(),
};

DealRow.defaultProps = {
  deal: {},
};

export default React.memo(DealRow);
