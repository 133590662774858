import React from 'react';
import { Tooltip } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import './index.scss';

const LogInfoTooltip = ({ log, successfulRun, children }) => {
  if (!successfulRun) {
    return (
      <Tooltip title={log.substring(0, 300)} disableFocusListener disableRestoreFocus>
        <div className="status">
          <SvgIcon name="iconProfileNotVerified" />
          {children}
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={log.substring(0, 300)}>
      <div className="status">
        <SvgIcon name="success" />
        {children}
      </div>
    </Tooltip>
  );
};

export default LogInfoTooltip;
