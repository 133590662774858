import { Box, Divider } from '@material-ui/core';
import SearchInput from 'components/filters/SearchInput';
import React, { useState } from 'react';
import Tabs from '../Tabs';
import CustomClaimsFilters from './CustomClaimsFilters';
import CustomClaimsTable from './CustomClaimsTable';
import useFilters from './hooks/useFilters';
import filtersPipeline from './utils/filtersPipeline';

const CustomClaimsContent = ({ jobs, selectedTab, selectTab }) => {
  const { filters, actions } = useFilters(jobs);
  const filteredJobs = filtersPipeline(jobs, filters);

  const [showInput, setShowInput] = useState(false);

  if (selectedTab !== 'custom-claims') return null;

  return (
    <>
      <div className="deals-header">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">Custom Claims</div>
          <div className="deals-header__deals-cnt vertical-center">
            <span>{filteredJobs.length} Total</span>
          </div>
        </div>

        <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
          <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
            <SearchInput
              searchValue={filters.search}
              updateSearchValue={actions.dispatchUpdateSearch}
              showInput={showInput}
              setShowInput={setShowInput}
            />
          </div>
          <Box display="flex" alignItems="center" ml={1}>
            <CustomClaimsFilters filters={filters} actions={actions} />
          </Box>
        </div>
      </div>
      <Tabs selectedTab={selectedTab} selectTab={selectTab} />
      <Divider />
      <CustomClaimsTable jobs={filteredJobs} />
    </>
  );
};

export default CustomClaimsContent;
