import { Box } from '@material-ui/core';
import SearchInput from 'components/filters/SearchInput';
import React, { useState } from 'react';
import MyDealsFilter from '../MyDealsFilter';

const MyDealsHeader = ({ deals, filters, actions }) => {
  const [showInput, setShowInput] = useState(false);

  return (
    <div className="deals-header">
      <div className="deals-header-left d-flex">
        <div className="deals-header__title vertical-center">My Deals</div>
        <div className="deals-header__deals-cnt vertical-center">
          <span>{`${deals.length} Total`}</span>
        </div>
      </div>
      <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
        <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
          <SearchInput
            searchValue={filters.search}
            updateSearchValue={actions.dispatchUpdateSearch}
            showInput={showInput}
            setShowInput={setShowInput}
          />
        </div>
        <Box display="flex" alignItems="center" ml={1}>
          <MyDealsFilter filters={filters} actions={actions} />
        </Box>
      </div>
    </div>
  );
};

export default MyDealsHeader;
