import React, { useRef, useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import { CSVLink } from 'react-csv';
import { getUserAnalytics } from 'services/apiService';

function DownloadUserAnalytics() {
  const csvRef = useRef();
  const [csvData, setCsvData] = useState();

  const getCsvHeaders = () => {
    return Object.keys(csvData && csvData.length ? csvData[0] : {}).map((key) => ({
      label: key,
      key,
    }));
  };

  return (
    <>
      <RoundedButton
        onClick={() => {
          getUserAnalytics().then((data) => {
            setCsvData(data);
            csvRef.current.link.click();
          });
        }}
        className="footer-actions__button"
        type="secondary"
      >
        Download analytics
      </RoundedButton>
      <CSVLink
        headers={getCsvHeaders()}
        data={csvData || []}
        style={{ display: 'none' }}
        filename={`user_analytics_${Date.now()}`}
        ref={csvRef}
      />
    </>
  );
}

export default DownloadUserAnalytics;
