const getTruthyFields = (filter) =>
  Object.entries(filter)
    .filter((entry) => Boolean(entry[1]))
    .map((el) => el[0].toLowerCase());

const applySearchFilter = (search, deal) => {
  return (
    deal.dealName.toLowerCase().includes(search.toLowerCase()) ||
    deal.ticker.toLowerCase().includes(search.toLowerCase())
  );
};

const applyBlockchainFilter = (blockchains, deal) => {
  if (!blockchains.length) return true;
  return blockchains.includes(deal.blockchain?.toLowerCase());
};

const applyOriginFilter = (origins, deal) => {
  if (!origins.length) return true;
  return origins.includes(deal?.origin?.toLowerCase());
};

const applyDistributionModelFilter = (distributionModels, deal) => {
  if (!distributionModels.length) return true;
  return distributionModels.includes(deal.distributionModel.toLowerCase());
};

const filtersPipeline = (deals, filters) => {
  if (!deals) return [];

  const appliedBlockchains = getTruthyFields(filters.blockchain);
  const appliedOrigins = getTruthyFields(filters.origin);
  const appliedDistributionModels = getTruthyFields(filters.distributionModel);

  const applyFilters = [
    (deal) => applySearchFilter(filters.search, deal),
    (deal) => applyBlockchainFilter(appliedBlockchains, deal),
    (deal) => applyOriginFilter(appliedOrigins, deal),
    (deal) => applyDistributionModelFilter(appliedDistributionModels, deal),
  ];

  const filterResult = applyFilters.reduce(
    (filteredDeals, filterFunc) => filteredDeals.filter(filterFunc),
    deals
  );

  return filterResult;
};

export default filtersPipeline;
