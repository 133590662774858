import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ImageCrop from 'components/imageCrop';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import SvgIcon from 'components/svgIcon';
import { uploadImage } from 'services/apiService';
import './index.scss';
import { useNotifications } from 'hooks';

const EntityEditField = ({
  uploadUrl,
  websiteUrl,
  onUploadUrlChange,
  onWebsiteUrlChange,
  aspectRatio,
}) => {
  const [isUploadSelected, setIsUploadSelected] = useState(true);
  const [, showErrorNotification] = useNotifications();
  const imageCropInputRef = useRef();

  const onImageUpload = async (file) => {
    try {
      const data = new FormData();
      data.append('image', file);
      const { path } = await uploadImage(data);

      onUploadUrlChange(path);
    } catch {
      showErrorNotification(`Something went wrong while uploading image`);
    }
  };

  const shouldShowClear = isUploadSelected ? !!uploadUrl : !!websiteUrl;

  return (
    <div className="entity-edit-field">
      <div className="label-container">
        <label
          onClick={() => setIsUploadSelected(true)}
          className={`label ${isUploadSelected ? ' label__active' : ''}`}
        >
          Upload Logo
        </label>
        <label
          onClick={() => setIsUploadSelected(false)}
          className={`label ${!isUploadSelected ? ' label__active' : ''}`}
        >
          Website URL
        </label>
      </div>
      <div className="content">
        <div className="image-container">
          <RoundedAvatar src={uploadUrl} />
          <div className="edit-icon" onClick={() => imageCropInputRef.current.click()}>
            <SvgIcon name="iconDealsModalEdit" />
          </div>
        </div>
        {isUploadSelected ? (
          <ImageCrop
            onImageCropped={onImageUpload}
            inputRef={imageCropInputRef}
            aspectRatio={aspectRatio}
          />
        ) : (
          <input
            className="input"
            value={websiteUrl}
            onChange={(e) => onWebsiteUrlChange(e.target.value)}
          />
        )}
        {shouldShowClear && (
          <div
            className="delete-icon"
            onClick={() => {
              isUploadSelected ? onUploadUrlChange('') : onWebsiteUrlChange('');
            }}
          >
            <SvgIcon name="iconDeleteX" />
          </div>
        )}
      </div>
    </div>
  );
};

EntityEditField.propTypes = {
  uploadUrl: PropTypes.string.isRequired,
  websiteUrl: PropTypes.string.isRequired,
  onUploadUrlChange: PropTypes.func.isRequired,
  onWebsiteUrlChange: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
};

export default EntityEditField;
