import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import IconButton from 'components/button/icon-button';
import { useNotifications } from 'hooks';
import { addWhitelist, getWhitelists } from 'services/apiService';
import WhitelistAddDialog from 'features/whitelists/WhitelistAddDialog';
import WhitelistList from 'features/whitelists/WhitelistList';
import './index.scss';

const Whitelists = () => {
  const [whitelists, setWhitelists] = useState([]);
  const [whitelistToAdd, setWhitelistToAdd] = useState(null);
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const fetchWhitelists = () => getWhitelists().then(setWhitelists);

  const onSave = async () => {
    try {
      await addWhitelist(whitelistToAdd);
      showSuccessNotification(`Whitelist added successfully`);
      fetchWhitelists();
      setWhitelistToAdd(null);
    } catch {
      showErrorNotification('Something went wrong, please try again!');
    }
  };

  useEffect(() => {
    fetchWhitelists();
  }, []);

  return (
    <div className="whitelists-container">
      <div className="whitelists-header">
        <div>
          <h2>Whitelists</h2>
          <span>{whitelists.length} total</span>
        </div>
        <div>
          <div className="whitelists-header__add-button">
            <IconButton
              icon="plus"
              onClick={() => setWhitelistToAdd({ name: '', addresses: [] })}
            />
          </div>
        </div>
      </div>
      <Divider />
      <WhitelistList whitelists={whitelists} fetchWhitelists={fetchWhitelists} />
      <WhitelistAddDialog
        whitelist={whitelistToAdd}
        setWhitelist={setWhitelistToAdd}
        onSave={onSave}
      />
    </div>
  );
};

export default Whitelists;
