import RoundedButton from 'components/button/rounded-button';
import NumberInput from 'components/input/number-input';
import CustomSelect from 'components/select/custom-select';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import './index.scss';

const VestingGenerator = ({ vesting, setVesting, units, expectedTokens }) => {
  const generateVestingCalendar = () => {
    if (vesting.frequencyLength < 1) return;
    const startDate = moment(vesting.tgeDate).add(vesting.cliffLength, vesting.cliffUnit);

    const endDate = startDate
      .clone()
      .add(vesting.vestingDurationLength, vesting.vestingDurationUnit);
    const items = [];
    while (startDate.isBefore(endDate)) {
      items.push({
        date: startDate.toDate(),
        isCustom: false,
      });
      startDate.add(vesting.frequencyLength, vesting.frequencyUnit);
    }

    setVesting((prev) => ({
      ...prev,
      calendar: items.map((item) => {
        const percentage = Math.round((100 / items.length) * 1000000) / 1000000;
        return {
          ...item,
          percentage,
          amount: expectedTokens && (expectedTokens * (percentage / 100)) / 1000000,
        };
      }),
    }));
  };

  if (!vesting) return null;

  return (
    <div className="vesting-tab__generator">
      <div>
        <span>TGE</span>
        <DatePicker
          selected={vesting.tgeDate}
          onChange={(date) => setVesting((prev) => ({ ...prev, tgeDate: date }))}
          dateFormat="dd-MMM-yyyy"
        />
      </div>
      <div className="field-container">
        <span>Cliff</span>
        <div>
          <NumberInput
            value={vesting.cliffLength}
            onChange={(e) => setVesting((prev) => ({ ...prev, cliffLength: +e.target.value }))}
            decimalNumber="0"
          />
          <CustomSelect
            items={Object.values(units).map((unit) => ({ value: unit, name: unit }))}
            value={vesting.cliffUnit}
            onSelect={(_, value) => setVesting((prev) => ({ ...prev, cliffUnit: value }))}
          />
        </div>
      </div>
      <div className="field-container">
        <span>Vesting Duration</span>
        <div>
          <NumberInput
            value={vesting.vestingDurationLength}
            onChange={(e) =>
              setVesting((prev) => ({ ...prev, vestingDurationLength: +e.target.value }))
            }
            decimalNumber="0"
          />
          <CustomSelect
            items={Object.values(units).map((unit) => ({ value: unit, name: unit }))}
            value={vesting.vestingDurationUnit}
            onSelect={(_, value) => setVesting((prev) => ({ ...prev, vestingDurationUnit: value }))}
          />
        </div>
      </div>
      <div className="field-container">
        <span>Frequency</span>
        <div>
          <NumberInput
            value={vesting.frequencyLength}
            onChange={(e) => setVesting((prev) => ({ ...prev, frequencyLength: +e.target.value }))}
            decimalNumber="0"
          />
          <CustomSelect
            items={Object.values(units).map((unit) => ({ value: unit, name: unit }))}
            value={vesting.frequencyUnit}
            onSelect={(_, value) => setVesting((prev) => ({ ...prev, frequencyUnit: value }))}
          />
        </div>
      </div>
      <div className="button-container">
        <RoundedButton onClick={generateVestingCalendar} type="secondary">
          Generate
        </RoundedButton>
      </div>
    </div>
  );
};

export default VestingGenerator;
